import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import NewHealthCareComp from "./NewHealthCareComp";
import { useParams } from "react-router-dom";
import { useGetSinglePlanQuery } from "../../../store/Plans/PlansApi";

function NewHealthCare() {
  const {id} =useParams()
  const {data,isLoading} =useGetSinglePlanQuery(id,{
     skip:!id
  });

  console.log(data,id,'useGetSinglePlanQuery')

  return (
    <div>
      {/* <AdminLayout active={1}> */}
        <NewHealthCareComp Data={data?.data?.plan} />
      {/* </AdminLayout> */}
    </div>
  );
}

export default NewHealthCare;
