import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const EventApi = createApi({
  reducerPath: "EventApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getEvent: builder.query({
      query: () => ({
        url: `/events`,
        method: "GET",
      }),
    }),
    createEvent: builder.mutation({
      query: (payload) => ({
        url: `/events`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["getMedia"],
    }),
    updateEvent: builder.mutation({
      query: (payload) => ({
        url: `/events/${payload.id}`,
        method: "PATCH",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["getMedia"],
    }),
    getHCP: builder.query({
      query: () => ({
        url: `/hcp/company`,
        method: "GET",
      }),
    }),
    getHOC: builder.query({
      query: () => ({
        url: `/hcp/hcos`,
        method: "GET",
      }),
    }),
    getNewHOC: builder.query({
      query: ({ page, search }) => ({
        url: `/hcp/hcos?page=${page}&search=${search}`,
        method: "GET",
      }),
    }),
    getSingleHCO: builder.query({
      query: (payload) => ({
        url: `/hcp/hco/${payload}`,
        method: "GET",
      }),
    }),
    createHCP: builder.mutation({
      query: (payload) => ({
        url: `/hcp`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["getMedia"],
    }),
    getHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: "GET",
      }),
    }),
  }),
});

//hcp/hcos

export const {
  useLazyGetNewHOCQuery,
  useGetEventQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
  useCreateHCPMutation,
  useGetHOCQuery,
  useGetSingleHCOQuery,
} = EventApi;
