import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import OverviewComp from "./OverviewComp";
import { useOverviewMetricsQuery } from "../../store/DashBoard/DashBoardApi";
import OffCanvasComp from "../../Components/Ui/OffCanvas";
import { storage } from "../../utils/helper";
import { useGetCallReportQuery } from "../../store/CallReport/CallReportApi";

function Overview() {
  const {data:OverviewData,isLoading:OMLoader} =useOverviewMetricsQuery()

  return (
    <>
      <OverviewComp 
      // CallReport={allCallReport?.data}
      Overview={OverviewData?.data?.overview} OMLoader={OMLoader}/>
      
    </>
  );
}

export default Overview;
