import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
// import ButtonComp from '../Ui/Button'
import { useTranslation } from "react-i18next";
import ButtonComp from "./Ui/Button";
import { registerLocale } from "react-datepicker";
import enUS from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr"; // Import the French locale if needed
registerLocale('en-US', enUS);
registerLocale('fr', fr); // Register the French locale





export default function DateFilter({
  filterTitle = "DashBoard Metric",
  filterLoader = false,
  filterEndDate,
  filterStartDate,
  filterEndSetDate,
  filterStartSetDate,
  filterBtn,
  dateFormat = "dd/MM/yyyy",
  title,
}) {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language === 'fr' ? 'fr' : 'en';
  return (
    <div>
      <Dropdown>
        <Dropdown.Toggle
          variant=""
          className="border-2 rounded-[30px] border-[#4F008A] px-4 text-black"
          id="dropdown-basic"
        >
          <h5 className="font-2 ms-2 mb-0">{t("lang.Filter")} </h5>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <div className="py-3 px-3">
            {/* <div className="primaryI text-[18px] font-semibold">{filterTitle} </div> */}
            <div className="px-2 flex gap-2 mb-3 ">
              <div>
                <div>Start Date</div>
                <ReactDatePicker
                  className="border text-[12px] px-2 py-2"
                  selected={filterStartDate}
                  onChange={(date) =>
                    filterStartSetDate && filterStartSetDate(date)
                  }
                  maxDate={new Date()}
                  dateFormat={dateFormat}
                  locale={currentLocale}
                />
              </div>
              <div>
                <div>End Date</div>
                <ReactDatePicker
                  className="border text-[12px] px-2 py-2"
                  selected={filterEndDate}
                  onChange={(date) => {
                    console.log(date, "Checking");
                    filterEndSetDate && filterEndSetDate(date);
                  }}
                  maxDate={new Date()}
                  dateFormat={dateFormat}
                  locale={currentLocale}
                />
              </div>
            </div>
            <div className="px-3">
              <ButtonComp
                onClick={() => filterBtn()}
                loader={filterLoader}
                text={"Filter"}
                btnClassName={
                  "w-full py-[8px] bg-primaryI text-white text-center font-medium"
                }
              />
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
