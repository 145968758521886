import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import EventsComp from "./EventsComp";
import { useGetEventQuery } from "../../store/Event/EventApi";

function Events() {
  const { data: EventData, isLoading, refetch } = useGetEventQuery();
  return (
    <div>
      <>
        <EventsComp
          Events={EventData?.data?.events}
          eventLoader={isLoading}
          refetch={refetch}
        />
      </>
    </div>
  );
}

export default Events;
