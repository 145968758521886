import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import { Typewriter } from "react-simple-typewriter";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ButtonComp from "../../Components/Ui/Button";
import { useCreateSingleMediaFolderMutation } from "../../store/Media/MediaApi";
import { DocIcon, ImageIcon, VideoIcon } from "../../utils/svg";
import { useUploadFileMutation } from "../../store/User/userApi";
import { LanguageName, storage } from "../../utils/helper";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";

export default function MediaSingleModal({ setShowModal }) {
  const [csvFile, setCsvFile] = useState([{ name: "" }]);
  const { id } = useParams();
  // console.log(location,'location')
  const navigate = useNavigate();
  const [useData, setUserData] = useState();
  // console.log(csvFile)
  const [addProductCsv, { isLoading, isError, isSuccess, data }] =
    useCreateSingleMediaFolderMutation();
  const [uploadFile, { isLoading: FLoader }] = useUploadFileMutation();
  const [progress, setProgress] = useState(0);
  const [link, setLink] = useState("");

  const ImageShowCase = (key) => {
    if (key?.includes("image")) {
      return <ImageIcon />;
    }
    if (key?.includes("audio")) {
      return <VideoIcon />;
    }
    if (key?.includes("application") || key?.includes("text")) {
      return <DocIcon />;
    }
  };
  function convertBytesToKBOrMB(bytes) {
    if (bytes >= 1024) {
      return (bytes / 1024).toFixed(1) + " KB";
    } else {
      return bytes + " bytes";
    }
  }

  const handleChange = async (file) => {
    setCsvFile(file);
    const formData = new FormData();
    formData.append("file", file);

    // Retrieve the token from local storage
    const token = storage["localStorage"].get("user_details_profile")?.token;

    // Create headers object
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    // If the token exists, add it to the headers
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}file/upload`,
        data: formData,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          // Update UI with progress
          setProgress(progress);
          console.log(`Upload progress: ${progress}%`);
        },
      });

      console.log("response", response);
      if (response) {
        setUserData({
          name: file.name,
          type: file.type,
          link: response?.data?.data?.file_link,
          size: convertBytesToKBOrMB(file.size),
          id: id,
        });
      } else {
        console.error("Invalid response format. Missing file_link.");
      }
    } catch (error) {
      // Handle the error
      console.error("Upload failed:", error);
    }
  };

  console.log(csvFile, ImageShowCase("image"), useData, "csvFile");
  console.log("useDataaaaaa", useData);

  // alert(csvFile?.name)

  const handleSubmit = async () => {
    const response = await addProductCsv(useData);
    console.log(response);
    if (response?.data) {
      toast.success(response?.data?.message);
      setShowModal(false);
    }
    if (response?.error) {
      toast.error("check your file format");
    }
    //

    console.log(response, "response");
  };

  function downloadFile(url) {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", true);
    link.click();
  }

  function handleDownload() {
    const fileUrl = "/Document/priceList.xlsx";
    downloadFile(link);

    // Navigate to a different route (optional)
    // history.push('/another-route');
  }
  return (
    <div className="px-4 py-4">
      <div className="text-[18px]  font-semibold">{LanguageName("UCSV")}</div>
      <div
        className="text-[16px] flex text-[#757575] mb-3  hover:cursor-pointer "
        onClick={handleDownload}
      >
        {/* CSV file upload guidelines? {" "} */}
        <div className="text-[#4F008A]">
          {/* <Typewriter
            words={['Click', 'Download', 'Template']}
            loop={5}
            cursor
            ca
            cursorStyle='_'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
            // onLoopDone={handleDone}
            // onType={handleType}
          /> */}
        </div>
      </div>

      <div className="border-dashed border-2 border-[#4F008A] py-5 mb-3">
        <Dropzone
          // accept={{ "text/csv": [".csv"] }}
          onDrop={(acceptedFiles) => handleChange(acceptedFiles[0])}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="text-center flex justify-center mb-2">
                  <img src="/icon/draganddrop.svg" />
                </div>
                <p className="text-center text-[#4F008A] font-semibold mb-[4px]">
                  {/* {LanguageName("SCVU")} */}
                  Select a file or drag and drop to upload <br />
                  Supported formats: JPEG (.jpg), PDF (.pdf), TEXT
                  (.txt), CSV (.csv)
                </p>
                {/* <p className="text-center text-[#4F008A]"> */}
                {/*   {LanguageName("DDHE")} */}
                {/* </p> */}
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      {/* <div className="text-[#4F008A] text-[18px] font-semibold mb-2"> */}
      {/*   {LanguageName("UURL")} */}
      {/* </div> */}
      {/* <div className="bg-[#4F008A08] px-3 py-3 rounded-[10px] border[2px] border-[#D3D3D385] mb-5"> */}
      {/*   <div className="flex justify-between items-center"> */}
      {/*     <input */}
      {/*       className="w-full bg-transparent underline" */}
      {/*       defaultValue={link} */}
      {/*       onChange={(e) => */}
      {/*         setUserData((prev) => { */}
      {/*           return { ...prev, link: e.target.value }; */}
      {/*         }) */}
      {/*       } */}
      {/*     /> */}
      {/*     <div className="rounded-[5px] bg-[#fff] px-[20px] py-[10px]"> */}
      {/*       {LanguageName("Upload")} */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </div> */}
      {/*  */}
      <div className=" px-3 py-3 rounded-[5px] border-[2px] border-[#B7B7B766] mb-5">
        <div className="flex justify-between mb-3">
          <div className="flex justify-between">
            <div className="flex gap-3 items-center">
              <div>
                {csvFile?.type && ImageShowCase(csvFile?.type)}
                {/* <img src="/icon/documentcsv.svg" /> */}
              </div>
              <div>
                <div className="text-[#282728] font-medium">
                  {csvFile?.name || "No File Added..."}
                </div>
                <div className="text-[#A1A1A1] font-medium">
                  {convertBytesToKBOrMB(csvFile?.size || 0)}
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              setCsvFile();
              setProgress(0);
            }}
          >
            <MdClose size={20} color="#4F008A" />
          </div>
        </div>
        <div className="flex w-full items-center justify-between mb-3">
          <div className="w-full bg-[#B7B7B7] rounded-sm mr-4">
            {/* <div */}
            {/*   className={`bg-[#4F008A] h-[5px] ${csvFile?.name && "w-[100%]"}`} */}
            {/* /> */}
            <ProgressBar now={progress} />
          </div>
          {progress}%
        </div>
      </div>

      <div>
        <div className="flex justify-center  mb-[64px] gap-[15px]">
          <ButtonComp
            //   onClick={() => navigate("/")}
            text={LanguageName("CNC")}
            btnClassName={
              "text-[14px] lg:text-[16px]  py-[5px] lg:py-[13px]  px-[30px] lg:px-[64px] border-[0.64px] rounded-full border-[#D63434] text-[#282728]"
            }
          />
          <ButtonComp
            loader={isLoading}
            onClick={handleSubmit}
            btnClassName={
              "text-[14px] lg:text-[16px] py-[5px] lg:py-[13px] px-[25px] lg:px-[57px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#4F008A]"
            }
            text={LanguageName("UPF")}
          />
        </div>
      </div>
    </div>
  );
}
