import React, { useEffect } from "react";
import { FormLabelII } from "./Data";
import FormUi from "../../../../Components/Ui/FormUi";
import { LanguageName } from "../../../../utils/helper";
import { useForm } from "react-hook-form";
import ButtonComp from "../../../../Components/Ui/Button";

export default function PasswordPage({}) {
  const { control } = useForm({
    defaultValues: {
      currentPassword: "password",
      newPassword: "password",
      confirmNewPassword: "password",
    },
  });

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-5">Admin Console</div>
      <div className="">
        <div>
          <div className="text-[#333333] text-xl font-bold">
            {LanguageName("PW")}
          </div>
          <div className="text-[#555555] text-[16px] font-normal pb-[30px] border-b-[#EDE5F3] border-b-[1px] mb-[30px]">
            {LanguageName("UPA")}
          </div>
        </div>

        <div className="row gy-5 mb-5">
          <FormUi
            formData={FormLabelII()}
            control={control}
            containerClassName={"h-[35px] bg-[#FCFAFF] border-1"}
            labelClassName="!text-[14px] mb-1 text-[#4F008A]"
          />
        </div>
        <ButtonComp
          // onClick={handleSubmit(handleUpdateCompany)}
          text={"Save changes"}
          // loader={UCLoader}
          btnClassName={
            "bg-[#8652CE] text-white rounded px-3 py-1 font-semibold text-base"
          }
        />
      </div>
    </div>
  );
}
