import { LanguageName } from "../../utils/helper";
import CompanySettings from "./submodules/CompanySettings/CompanySettings";
import PasswordPage from "./submodules/PasswordPage/PasswordPage";
import PersonalInformation from "./submodules/PersonalInformation/PersonalInformation";
import PrivacyPolicies from "./submodules/PrivacyPolicies";

export const ListAdmin = (control) => {
  return [
    {
      name: LanguageName("PI"),
      icon: "/Admin/user-edit.png",
      component: <PersonalInformation control={control} />,
      id: 1,
    },
    // {
    //   name: LanguageName("PW"),
    //   icon: "/Admin/key-square.png",
    //   component: <PasswordPage control={control} />,
    //   id: 2,
    // },
    // {
    //     name:LanguageName('MR2'),
    //     icon:'/Admin/people.png',
    //     component:<MedRepresentatives/>,
    //     id:3
    // },
    {
      name: LanguageName("CPS"),
      icon: "/Admin/user-edit-2.png",
      component: <CompanySettings control={control} />,
      id: 4,
    },
    {
      name: LanguageName("PPPP"),
      icon: "/Admin/lock.png",
      component: <PrivacyPolicies />,
      id: 5,
    },
  ];
};

export const privacyPolicyData = [
  {
    title: "Data Centers",
    description:
      "Data centers are a critical component of modern organizations and play a crucial role in supporting business",
  },
  {
    title: "Network Security",
    description:
      "Network security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive.",
  },
  {
    title: "System Security",
    description:
      "Data centers are a critical component of modern organizations and play a crucial role in supporting business",
  },
  {
    title: "Security Operations",
    description:
      "Security Operations is crucial because networks are the backbone of modern organizations and are used to transmit sensitive.",
  },
  {
    title: "Access Control",
    description:
      "Access Control are a critical component of modern organizations and play a crucial role in supporting business",
  },
  {
    title: "Vulnerabilty Assesment and Penetration Testing",
    description:
      "Vulnerabilty Assesment and Penetration Testing is crucial because networks are the backbone of modern organizations and are used to transmit sensitive.",
  },
  {
    title: "Application Level Security",
    description:
      "Application Level Security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive",
  },
  {
    title: "Data Protection, Continuity, and Retention:",
    description:
      "Data Protection, Continuity, and Retention: is crucial because networks are the backbone of modern organizations and are used to transmit sensitive",
  },
];
