import React from "react";

export function AccordionPagination({
  current,
  next,
  numberOfPages,
  setPage,
  medRepId,
}) {
    console.log(next, numberOfPages)
  const id = medRepId;
  // Function to handle changing page
  const handlePageChange = (pageNumber) => {
    console.log(pageNumber)
    setPage(pageNumber);
  };
  return (
    <div className="items_container w-full min-w-[30rem]  overflow-x-scroll flex items-center justify-start">
      {/* <div className="page_number flex items-center justify-center gap-3">
        <span className="block font-semibold">{current}</span>
        <span>out of</span>
        <span className="font-semibold">{numberOfPages}</span>
      </div> */}
      <div className="pagination-controls  flex items-center justify-end gap-[2rem]">
        <button
          onClick={() => handlePageChange({ page: current - 1, id })}
          disabled={current === 1}
          className={`${current === 1 ? "text-[#757575] " : "text-[#282728]"}`}
        >
          Prev
        </button>
        <div className="item_container flex items-center justify-center gap-1">
          {Array.from({ length: numberOfPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange({ page: index + 1, id })}
              className={
                current === index + 1
                  ? "active text-white rounded-[5px] h-[2rem] w-[2rem] bg-[#4F008A] border-none outline-none"
                  : "in-active h-[2rem] w-[2rem] rounded-[5px] border-[1px] border-solid border-gray-300"
              }
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button
          onClick={() => handlePageChange({ page: next, id })}
          disabled={current === numberOfPages}
          className={`${
            current === numberOfPages ? "text-[#757575] " : "text-[#282728]"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
}
