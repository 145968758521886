import React from "react";
import Skeleton from "react-loading-skeleton";

export default function ActivitiesLoadingState() {
  return (
    <div>
      <div className="flex gap-3 mb-[16px]">
        <Skeleton borderRadius={"100%"} width={50} height={50} />
        <div>
          <Skeleton height={20} width={100} />
          <Skeleton height={20} width={400} />
        </div>
      </div>
    </div>
  );
}
