import React from "react";
import ButtonComp from "../../../Components/Ui/Button";
import { LanguageName } from "../../../utils/helper";
import { number } from "prop-types";

function KeyMessage({ userData, extraData }) {
  console.log(userData, "keymessage");
  return (
    <div>
      <div className="text-center opacity-75">
        {Array.isArray(userData?.detailingPrioirtyProduct) &&
          userData?.detailingPrioirtyProduct?.length === 0 &&
          "No Note"}
      </div>
      {userData?.detailingPrioirtyProduct?.map((item, i) => (
        <div className="flex justify-content-between border rounded mb-[32px] p-[35px]">
          <div className="">
            <div className="flex flex-col">
              <div className="font-[500] text-[15px] text-[#555555] mb-[32px]">
                {LanguageName("DPP")}
              </div>
              <div className="font-[500] text-[14px] text-[#000000] mb-[32px]">
                {/* Panadol */}
                {item?.product === null ? "-" : item?.product?.name } 
              </div>
            </div>
            <div className="flex flex-col">
              <div className="font-[500] text-[15px] text-[#555555] mb-[32px]">
                {LanguageName("ER")}
              </div>
              <div
                className={`font-[500] text-[14px] ${
                  item?.clinicalOverviewReaction === "Postive"
                    ? "text-[#3DA84A]"
                    : item?.clinicalOverviewReaction === "Negative"
                    ? "text-[#F65E5E]"
                    : "text-[#0071FB]"
                } mb-[32px]`}
              >
                {item?.efficacyReaction === "Positive" ? "Positive" : "Positive"}
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col">
              <div className="font-[500] text-[15px] text-[#555555] mb-[32px]">
                {LanguageName("COR")}
              </div>
              <div
                className={`font-[500] text-[14px] ${
                  item?.clinicalOverviewReaction === "Postive"
                    ? "text-[#3DA84A]"
                    : item?.clinicalOverviewReaction === "Negative"
                    ? "text-[#F65E5E]"
                    : "text-[#0071FB]"
                } mb-[32px]`}
              >
                {item?.clinicalOverviewReaction === "Positive" ? "Positive" : "Positive"}
              </div>
            </div>
            <div className="flex flex-col">
              <div className="font-[500] text-[15px] text-[#555555] mb-[32px]">
                {LanguageName("safetyReaction")}
              </div>
              <div
                className={`font-[500] text-[14px] ${
                  item?.clinicalOverviewReaction === "Postive"
                    ? "text-[#3DA84A]"
                    : item?.clinicalOverviewReaction === "Negative"
                    ? "text-[#F65E5E]"
                    : "text-[#0071FB]"
                } mb-[32px]`}
              >
                {item?.safetyReaction === "Positive" ? "Positive" : "Positive"}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default KeyMessage;
