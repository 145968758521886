import React from 'react'
import AdminLayout from '../../Layout/AdminLayout'
import AdminConsole from './AdminConsole'

function Admin() {
  return (
    // <AdminLayout active={1}>
        <AdminConsole/>
    // </AdminLayout>
  )
}

export default Admin