import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import NewHealthCareCompii from "./NewHealthCareCompii";
import { useParams } from "react-router-dom";
import { useGetSinglePlanQuery } from "../../../store/Plans/PlansApi";

function NewHealthCareii() {
  const {id} =useParams()
  const {data,isLoading} =useGetSinglePlanQuery(id,{
    // skip:!id
  });

  console.log(data,id,'useGetSinglePlanQuery')
  return (
    <div>
      <AdminLayout active={1}>
        {/* <NewHealthCareCompii /> */}
      </AdminLayout>
    </div>
  );
}

export default NewHealthCareii;
