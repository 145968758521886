import React, { useEffect, useState } from "react";
import ButtonComp from "../../../Components/Ui/Button";
import PlanMedRepsComp from "../PlanMedReps/PlanMedRepsComp";
import Overview from "./submodules/Overview";
import InstitutionsComp from "../PlanMedReps/Instituation";
import {
  useGetPlansAnalyticsQuery,
  useGetPlansQuery,
} from "../../../store/Plans/PlansApi";
import { LanguageName, storage } from "../../../utils/helper";
import { useSelector } from "react-redux";
import {
  selectAllCurrentUserData,
  selectCurrentUserData,
} from "../../../store/auth";
import { useNavigate } from "react-router-dom";
import { useGetMedRepsWithPlansQuery } from "../../../store/MedRep/MedRepApi";
import moment from "moment";

export default function PlanComp() {
  const [plan, setPlan] = useState("Overview");
  const { data: PlansData, isLoading: PDLoader } = useGetPlansQuery();
  const { data: MedRepWithPlansData, isLoading: PLoader } =
    useGetMedRepsWithPlansQuery();
  const { data: PlansAnalyticsData, isLoading: PALoader } =
    useGetPlansAnalyticsQuery();
  const navigate = useNavigate();
  const getUserAll = useSelector(selectAllCurrentUserData);
  const getUser = useSelector(selectCurrentUserData);
  const managerId = storage["localStorage"].get("user_details_profile").user.id;
  
  const TopList = [
    {
      name: LanguageName("Overview"),
      path: <Overview Data={PlansAnalyticsData?.data} />,
    },
    {
      name: LanguageName("MR"),
      path: <PlanMedRepsComp Data={PlansData?.data} />,
    },
  ];
  // // Get the first day of the month
  // let firstDay = new Date(year, month, 1);
  // let firstDayStr = firstDay.toLocaleDateString("en-GB", {
  //   day: "2-digit",
  //   month: "2-digit",
  //   year: "numeric",
  // });
  //
  // // Get the last day of the month
  // let lastDay = new Date(year, month + 1, 0);
  // let lastDayStr = lastDay.toLocaleDateString("en-GB", {
  //   day: "2-digit",
  //   month: "2-digit",
  //   year: "numeric",
  // });
  // Get today's date
  let today = new Date();
  let todayStr = today.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Get the first day of the current month
  let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  let firstDayStr = firstDay.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Get the last day of the current month
  let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  let lastDayStr = lastDay.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  // Get the current day of the month
  let currentDay = today.getDate();

  // Get the total number of days in the current month
  let totalDays = new Date(
    today.getFullYear(),
    today.getMonth() + 1,
    0,
  ).getDate();

  // Calculate the percentage of the month that has passed
  let percentagePassed1 = (currentDay / totalDays) * 100;

  const [percentagePassed, setPercentagePassed] = useState(0);
  const [percentagePassed2, setPercentagePassed2] = useState(0);

  useEffect(() => {
    let percentagePassed1 = (currentDay / totalDays) * 100;
    setPercentagePassed(percentagePassed1);
    setPercentagePassed2(percentagePassed1 - 5);
  }, [currentDay, totalDays]);



  return (
    <div className="container">
      <div>
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[20px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem] w-full">
            <div className="flex justify-start items-center w-full">
              <div>
                <div className="text-[25px] font-[700] mr-5">
                  Plan
                </div>
                {/* <div className="text-[#777777] text-[14px]"> */}
                {/*   {LanguageName("MR")}/{LanguageName("Institutions")}/ */}
                {/*   {LanguageName("CPN")} */}
                {/* </div> */}
              </div>
              <div className="flex space-x-3 bg-[#fcfaff] border-[1px] border-[#ebebeb] rounded align-items-center lg:w-[50%] md:w-100 w-[62%] py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
                <div className="ps-2 pe-3">
                  <img className="" src="/icon/search.png" alt="search" />
                </div>

                <input
                  className="w-100 text-[16px] font-[400] bg-[#fcfaff]"
                  type="text"
                  // placeholder={LanguageName("SCR")}
                  placeholder={`${LanguageName("SearchPlans")}`}
                />
              </div>
            </div>
            <div className="ms-2"></div>
          </div>
        </div>

        <div className="flex justify-between items-center lg:mb-[62px] mb-[34px]     border-b-[0.5px] flex-wrap" />
        {/* <div className="flex  lg:flex-row   "> */}
        {/*   {TopList?.map((item, index) => ( */}
        {/*     <div */}
        {/*       onClick={() => setPlan(item?.name)} */}
        {/*       key={index} */}
        {/*       className=" p-[10px]  pe-3 cursor-pointer" */}
        {/*     > */}
        {/*       <div */}
        {/*         className={`text-[18px] ${ */}
        {/*           item?.name === plan */}
        {/*             ? "text-[#A162F7] border-b-[#A162F7] border-b-[1px] px-2" */}
        {/*             : "text-[#333333]" */}
        {/*         } font-[500] `} */}
        {/*       > */}
        {/*         {item?.name} */}
        {/*       </div> */}
        {/*     </div> */}
        {/*   ))} */}
        {/* </div> */}
        {/* {plan === "Overview" && ( */}
        {/*   <div className="flex items-center space-x-2 "> */}
        {/*     <ButtonComp */}
        {/*       onClick={() => { */}
        {/*         navigate("/plan/summary"); */}
        {/*       }} */}
        {/*       className="text-[#FFFFFF] font-[500] text-[18px] bg-[#A162F7] rounded-[5px] py-[8px] px-[24px]" */}
        {/*       // text={LanguageName("VPL")} */}
        {/*       text={"View Plans"} */}
        {/*     /> */}
        {/*   </div> */}
        {/* )} */}
      </div>
      <div className="flex justify-between flex-wrap gap-y-8 items-center mb-4">
        <div className="w-1/2">
          <p className="font-[600] text-[24px]">{LanguageName("OV")}</p>
          <p className="font-[400] text-[14px] mb-4">
          {LanguageName("OverviewPlans")}
          </p>
          <div className="flex items-center">
            <p className="mr-5 font-[500] text-[16px]">{firstDayStr}</p>
            <div className="relative">
              <div
                className="absolute"
                style={{ left: `${percentagePassed2}%`, bottom: '-2px', zIndex: 50 }}
              >
                <div className="absolute top-[-46px] left-[-23px] flex flex-col items-center">
                  <p className="text-[14px] font-[500]">{moment().format('DD/MM/YYYY')}</p> 
                  <div className="border-[2px] border-[#3DA84A] w-[7px] h-[24px]"></div>
                </div>
              </div>
              <div
                 className="absolute left-0 bg-[#3DA84A] h-[20px] after:"
                 style={{ width: `${percentagePassed}%` }}
              ></div>
              <div className="bg-[#3DA84A26] h-[20px] w-[250px]"></div>
            </div>
            <p className="ml-5 font-[500] text-[16px]">{lastDayStr}</p>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="flex align-items-center bg-[#8652CE] lg:mb-0 md:mb-0 mb-[1rem] rounded px-4 py-3 lg:me-2 md:me-2 mr-6">
            <div className="mr-2">
              <svg
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 10.5605C11.6569 10.5605 13 9.2174 13 7.56055C13 5.90369 11.6569 4.56055 10 4.56055C8.34315 4.56055 7 5.90369 7 7.56055C7 9.2174 8.34315 10.5605 10 10.5605Z"
                  stroke="white"
                  stroke-width="2"
                />
                <path
                  d="M18.188 6.49455C18.576 6.96655 18.77 7.20155 18.77 7.56055C18.77 7.91955 18.576 8.15455 18.188 8.62655C16.768 10.3505 13.636 13.5605 9.99998 13.5605C6.36398 13.5605 3.23198 10.3505 1.81198 8.62655C1.42398 8.15455 1.22998 7.91955 1.22998 7.56055C1.22998 7.20155 1.42398 6.96655 1.81198 6.49455C3.23198 4.77055 6.36398 1.56055 9.99998 1.56055C13.636 1.56055 16.768 4.77055 18.188 6.49455Z"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
            </div>
            <ButtonComp
              onClick={() => {
                navigate("/plan/summary");
              }}
              className="text-[#FFFFFF] font-[500] text-[18px]  rounded-[5px]"
              // text={LanguageName("VPL")}
              text={`${LanguageName("VP")}`}
            />
          </div>
          <div className="flex align-items-center  bg-[#3da84a] lg:mb-0 md:mb-0 mb-[1rem] rounded px-4 py-3 lg:me-2 md:me-2 me-0">
            <div className="mr-2">
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 6.56055V0.560547H8V6.56055H14V8.56055H8V14.5605H6V8.56055H0V6.56055H6Z"
                  fill="white"
                />
              </svg>
            </div>
            <ButtonComp
              onClick={() => {
                navigate("/plan/add");
              }}
              className="text-[#FFFFFF] font-[500] text-[18px]  rounded-[5px]"
              // text={LanguageName("VPL")}
              text={`${LanguageName("createNewPlan")}`}
            />
          </div>
        </div>
      </div>
      {/* {TopList?.find((item, i) => item?.name === plan)?.path} */}
      <Overview Data={PlansAnalyticsData?.data} />
      <PlanMedRepsComp Data={PlansData?.data?.filter((medrep) => medrep?.medRep?._id !== managerId)} />
    </div>
  );
}
