import moment from "moment";
import DropDownComp from "../../../Components/Ui/DropDown";
import { Dots, TrackIcon, TrashIcon } from "../../../utils/svg";
import { Link } from "react-router-dom";
import { FaUser } from "react-icons/fa";

export const tableData = [
  {
    Serial: "1",
    Practitioner: "Olakunbi Olabode 0.",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "5.00",
    Completed: "3.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "2.00",
  },
  {
    Serial: "2",
    Practitioner: "Olakunbi Olabode 0.",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "3.00",
    Completed: "1.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "1.00",
  },
  {
    Serial: "3",
    Practitioner: "Olakunbi Olabode 0.",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "2.00",
    Completed: "2.00",
    Remaining: "0.00",
    Status: "Completed",
    Visit: "-",
  },
  {
    Serial: "4",
    Practitioner: "Olakunbi Olabode 0.",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "4.00",
    Completed: "2.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "-",
  },
  {
    Serial: "5",
    Practitioner: "Olakunbi Olabode 0.",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "5.00",
    Completed: "3.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "-",
  },
  {
    Serial: "6",
    Practitioner: "Olakunbi Olabode 0.",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "3.00",
    Completed: "1.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "2.00",
  },
  {
    Serial: "7",
    Practitioner: "Olakunbi Olabode 0.",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "2.00",
    Completed: "2.00",
    Remaining: "0.00",
    Status: "Completed",
    Visit: "-",
  },
  {
    Serial: "8",
    Practitioner: "Olakunbi Olabode 0.",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "5.00",
    Completed: "4.00",
    Remaining: "1.00",
    Status: "Pending",
    Visit: "-",
  },
  {
    Serial: "9",
    Practitioner: "Olakunbi Olabode 0.",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "4.00",
    Completed: "2.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "1.00",
  },
];

const OptionDropDown = (row) => [
  {
    name: (
      <Link
        to={`/plan/edit/${row?._id}`}
        className="flex gap-1 items-center text-[#656468] text-[12px]"
      >
        <TrackIcon />
        View Plan
      </Link>
    ),
    // action:;
  },
  {
    name: (
      <div className="flex gap-1 items-center text-[#D63434] text-[12px]">
        <TrashIcon />
        Report Schedule
      </div>
    ),
    // action:;
  },
];

export const columnsData = () => [
  {
    name: "S/N",
    selector: (row, id) => <span>#{row?._id && row?._id.substring(0, 6)}</span>,
    sortable: true,
    width: "100px",
  },
  {
    name: "Healthcare Practitioners",
    selector: (row) => (
      <div>
        <div className="flex items-center gap-1 flex-wrap">
          {console.log(row)}
          <div className="text-[14px] text-start text-[#1B2128] font-[600]">
            {row?.hcp?.firstName} {row?.hcp?.lastName} <br />{" "}
            <span className="text-[14px] text-[#959595] font-[400]">
              {row?.institutionDepartment || "-"} at{" "}
              {row?.institutionName || ""}
            </span>
          </div>
        </div>
      </div>
    ),
    width: "350px",
    // sortable: true,
  },

  {
    name: <div>Calls Per Target</div>,
    selector: (row) =>
      row?.targets?.callsPerMonth
        ? row?.targets?.callsPerMonth.toFixed(2)
        : "0.00",
    width: "150px",
    // sortable: true,
  },

  {
    name: <div className="whitespace-nowrap">Calls Completed</div>,
    selector: (row) => (row?.visitCount ? row?.visitCount?.toFixed(2) : "0.00"),
    width: "150px",
    // sortable: true,
  },

  {
    name: <div className="whitespace-nowrap">Calls Remaining</div>,
    selector: (row) =>
      row?.targets?.callsPerMonth && row?.visitCount
        ? (row?.targets?.callsPerMonth - row?.visitCount)?.toFixed(2)
        : !row?.visitCount
        ? row?.targets?.callsPerMonth.toFixed(2)
        : "0:00",
    width: "150px",
    // sortable: true,
  },
  {
    name: "Status",
    selector: (row) => (
      <div className={` `}>
        {row?.targets?.callsPerMonth === row?.visitCount
          ? "Completed"
          : "Pending"}
      </div>
    ),

    // width: "200px",

    // sortable: true,
  },
  // {
  //   name: "Out Of Plan Visit",
  //   selector: (row) => (
  //     <div className={``}>-</div>
  //   ),
  //   // sortable: true,
  // },
  // {
  //   name: "Action",
  //   // sortable: true,
  //   cell: (row) => (
  //     <div className="ms-3">
  //       <DropDownComp
  //         dropDownText={<Dots color="#7572EA" />}
  //         options={OptionDropDown(row)}
  //       />
  //     </div>
  //   ),
  //   width: "100px",
  // },
];
export const columnsDataII = () => [
  {
    name: "ID",
    selector: (row) => <span>#{row?._id && row?._id.substring(0, 6)}</span>,
    sortable: true,
    width: "100px",
  },
  {
    name: "Departments",
    selector: (row) => <div>{row?.institutionDepartment}</div>,
    width: "150px",
    // sortable: true,
  },

  {
    name: <div>Calls Per Target</div>,
    selector: (row) =>
      Array.isArray(row?.targets) && row?.targets[0]?.callsPerDay,
    width: "150px",
    // sortable: true,
  },

  {
    name: <div className="whitespace-nowrap">Calls Completed</div>,
    selector: (row) =>
      Array.isArray(row?.targets) && row?.targets[0]?.callsPerMonth,
    width: "150px",
    // sortable: true,
  },

  {
    name: "Calls Remaining",
    selector: (row) => <div className={` `}>-</div>,
    width: "150px",
    // sortable: true,
  },
  {
    name: "Status",
    selector: (row) => (
      <div className={`${StatusColor(row?.status)} `}>{row?.status}</div>
    ),
    width: "200px",

    // sortable: true,
  },
  {
    name: "Out Of Plan Visit",
    selector: (row) => <div className={` `}>-</div>,
    width: "200px",
    // sortable: true,
  },
  // {
  //   name: "Action",
  //   // sortable: true,
  //   cell: (row) => (
  //     <div className="ms-3">
  //       <DropDownComp
  //         dropDownText={<Dots color="#7572EA" />}
  //         options={OptionDropDown(row)}
  //       />
  //     </div>
  //   ),
  //   width: "100px",
  // },
];

const ListCycle = [
  {
    name: "Proposed Days In Field",
    path: "proposedDaysInField",
  },
  {
    name: "Proposed Calls PerDay",
    path: "proposedCallsPerDay",
  },
  {
    name: "Proposed Goals",
    path: "proposedGoals",
  },
  {
    name: "Average Calls Per Target",
    path: "averageCallsPerTarget",
  },
  {
    name: "Sum Of MCCP Targets",
    path: "sumOfMCCPTargets",
  },
  {
    name: "Calls Per Department",
    path: "callsPerDepartment",
  },
];

export const columnsDataIII = () => [
  {
    name: "S/N",
    // selector: (row) => (
    //   <span>{ListCycle?.find((item) => row?.label === item?.path)?.name}</span>
    // ),
    selector: (row, id) => (
      <span className="font-Inter text-[14px] text-[#1B2128] px-4">
        {id + 1}
      </span>
    ),
    sortable: true,
    minWidth: "100px",
    width: "100px",
  },
  {
    name: "Cycle",
    selector: (row) => (
      <div className="px-4 text-[16px] font-[600] text-[#1B2128] capitalize">
        {row.label}
      </div>
    ),
    // width: "200px",
    sortable: true,
  },
  {
    name: "Month",
    selector: (row) => (
      <div className="px-4 font-Inter text-[14px] text-[#1B2128]">
        {row?.value ? row?.value.toFixed(1) : "0.0"}
      </div>
    ),
    width: "400px",
    // sortable: true,
  },
];

const StatusColor = (status) => {
  switch (status) {
    case "pending":
      return "bg-[#DBEBFE] px-3 py-2 rounded-md capitalize";
      break;
    case "completed":
      return "bg-[#DAF1E9] text-[#044E54] capitalize";
      break;
    default:
      break;
  }
};

export const customStyles = {
  // header: {
  // 	style: {

  // 		backgroundColor: '#F1F1F6',

  // 	},
  // },

  rows: {
    style: {
      minHeight: "72px", // override the row height
      // paddingLeft:'15px',
      // paddingRight:'15px',
      // paddingBottom:'100px'
    },
  },
  headCells: {
    style: {
      // paddingLeft: '8px', // override the cell padding for head cells
      // paddingRight: '8px',
      fontWeight: 500,
      color: "#1A1A1A",
      fontSize: "14px",
      whiteSpace: "pre-line",

      textTransform: "capitalize",
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
      borderWidth: 0.2,
    },
  },
  headRow: {
    style: {
      backgroundColor: "#F1F1F6",
      borderWidth: 0.2,
    },
  },
  tableWrapper: {
    style: {
      // backgroundColor:'red'
    },
  },
};

export const OptionDropDownII = () => [
  { name: "January" },
  { name: "February" },
  { name: "March" },
  { name: "April" },
  { name: "May" },
  { name: "June" },
];
