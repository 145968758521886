import React from 'react'
import AdminLayout from '../Layout/AdminLayout'
import DashBoardComp from '../Components/modules/DashBoard/DashBoardComp'
import DefaultLayout from '../Layout/DefaultLayout';

 function DashBoard() {
  return (
    // <DefaultLayout active={1}>
      <DashBoardComp/>
    // </DefaultLayout>
  )
}

export default (DashBoard);
