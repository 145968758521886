import React from "react";
import ButtonComp from "../../../Components/Ui/Button";
import { tableData } from "./Data";

export default function CyclePlanComp() {
  return (
    <div className="container">
      <div className="flex flex-row justify-content-between align-items-center pb-[45px]">
        <div className="flex  align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[24px] font-[600]">
            Plan{" "}
            <span className="text-[24px] text-[#777777] font-[500]">
              (His Grace Pharmacy)
            </span>
          </div>
        </div>
        <div className="ms-2">
          <img src="/Plan/back-icon.svg" alt="" />
        </div>
      </div>
      <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
        <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
          <div className="border-r-2 ps-2 pe-3">
            <img className="" src="/icon/search.png" alt="search" />
          </div>

          <input
            className="w-100 text-[16px] font-[400]"
            type="text"
            placeholder="Search for Call Reports by ID, Name, Date, HCOs"
          />
        </div>
        <div className="">
          <div className="flex items-center space-x-2 bg-[#A162F7] rounded-[5px] py-[8px] px-[24px] w-[fit-content]">
            <ButtonComp
              className="text-[#FFFFFF] font-[500] text-[18px] "
              text={"Add New plan"}
            />
            <div className="">
              <img src="/Plan/add.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:flex-col lg:flex-row flex-col lg:mb-[62px] mb-[34px]">
        <div className=" p-[10px]  border-b-2  pe-3">
          <div className="text-[16px] text-[#000000] font-[400]">Med Rep</div>
        </div>
        <div className=" p-[10px] border-b-2  pe-3">
          <div className="text-[16px] text-[#000000]  font-[400]">
            Department
          </div>
        </div>
        <div className=" p-[10px]  border-b-2 !border-[#A162F7] pe-3">
          <div className="text-[16px] text-[#A162F7] font-[400]">
            Cycle Plan
          </div>
        </div>
        <div className="w-[68%] lg:border-b-2">{""}</div>
      </div>
      <div className="table-container border border-1 rounded mb-[32px] w-full ">
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto ">
            <thead className="!bg-[#A162F71A] text-center">
              <tr className="text-[16px] font-[500] font-instrument-sans">
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] border-r lg:ps-[7px] py-[14px]"></th>
                <th className="md:px-[1rem] px-[1rem]  text-center lg:px-[0rem] py-[14px] border-r">
                  Month 1
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px] border-r">
                  Month 2
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px] border-r">
                  Month 3
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px] border-r">
                  Cycle
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data, i) => (
                <tr
                  className="text-[16px] text-[#333333] text-center font-[400] font-instrument-sans"
                  key={i}
                >
                  <td className="px-[2rem] lg:px-0 lg:ps-[7px] py-[10px] border-b border-r">
                    {data.null}
                  </td>
                  <td className="px-[2rem] py-[10px] border-b border-r">
                    {data.Month1}
                  </td>
                  <td className="px-[2rem] py-[10px] border-b border-r">
                    {data.Month2}
                  </td>
                  <td className="px-[2rem] py-[10px] border-b border-r">
                    {data.Month3}
                  </td>
                  <td className="px-[2rem] py-[10px] border-b border-r">
                    {data.Cycle}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
