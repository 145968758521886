import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useGetUserProfileQuery } from "../store/User/userApi";
import { logout, setCredentials } from "../store/auth";
import { getToken } from "../utils/helper";

export const ProtectedRoute = ({  redirectPath = "/login", children }) => {
  const navigate =useNavigate()
  const dispatch = useDispatch();
  const {data:userProfile,isSuccess,isError} =useGetUserProfileQuery();
  const user =userProfile?.data

  // useEffect(() => {
  //   if(isError){
  //     navigate('/login')
  //   }
  
  // }, [isError,navigate])
  

  return children 

  // return children ? children : <Outlet />;
};
