import React, { useMemo } from "react";
import {
  useCreatePlanMutation,
  useGetMedRepDetailsHCpQuery,
  useGetMedRepDetailsQuery,
  useGetPlansQuery,
  useGetPlansSummaryQuery,
  useGetSinglePlanQuery,
  useUpdatePlanMutation,
} from "../../../../store/Plans/PlansApi";
import { useParams, useNavigate } from "react-router-dom";
import ButtonComp from "../../../../Components/Ui/Button";
import { toast } from "react-toastify";
import { LanguageName, storage } from "../../../../utils/helper";
import NewFormUI from "../../../../Components/Ui/NewFormUi";
import { useForm } from "react-hook-form";
import AboutMedRep from "../submodules/AboutMedRep";

import Country from "../../../../country.json";
import { useEffect } from "react";
import useMultiDateSelect from "../../../../Components/Ui/UseMultiDateSelect";
import { useGetHCPQuery } from "../../../../store/Schedules/SchedulesApi";
import { ImageComponent } from "../../../../Components/Common/FallbackImage";
import ModalComp from "../../../../Components/Ui/ModalComp";
import { useGetMedRepQuery } from "../../../../store/MedRep/MedRepApi";

export default function EditPlan() {
  const companyIdReal = storage["localStorage"].get("companyId")?._id;
 
  const [updatePlan, { isLoading: HCPLoader }] = useUpdatePlanMutation();
  const [showModal, setShowModal] = React.useState(false);
  const { data: HCPData, isLoading: loading } = useGetHCPQuery();
 
  const {
    datePickerComponent,
    selectedDates,
    setSelectedDates,
    firstMonthName,
    firstMonth,
    inactiveDays,
    days,
    callsPerDay,
  } = useMultiDateSelect();
  const { plan_id } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setValue, setError } = useForm({
    defaultValues: {
      category: "",
      email: "",
      lastName: "",
      phone: "",
      address: "",
      institutionName: "",
      country: "",
      state: "Oyo",
      image: "",
      companyId: "",
    },
  });
  const [selectedMedRep, setSelectedMedRep] = React.useState(null);
  const { data, isLoading } = useGetSinglePlanQuery(plan_id, {
    skip: !plan_id,
  });
  const { data: PlansData, isLoading: PDLoader } = useGetPlansQuery();
  const { data: PlansSummary, refetch: refetchPlanSummary } =
    useGetPlansSummaryQuery();

  const companyIde = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;
  const { data: MedRepData, isLoading: MDLoader } = useGetMedRepQuery(
    companyIde,
    {
      skip: !companyIde,
    },
  );

  console.log("plasn summary", PlansSummary);

  const {
    data: MedRepDetail,
    isLoading: GMDLoader,
    refetch,
  } = useGetMedRepDetailsQuery(selectedMedRep?._id, {
    skip: !selectedMedRep,
  });

  const Plan = data?.data?.plan;
  console.log(Plan, "PlanPlan");

  const [selectedHCP, setSelectedHCP] = React.useState(null);
  const [addNewPlan, setAddNewPlan] = React.useState(false);

  const [drafts, setDrafts] = React.useState([]);

  const savedHCPData = storage["localStorage"].get("planHcpEditData");

  useEffect(() => {
    if (selectedMedRep) {
      refetch();
      console.log("MedRep hcsps", MedRepDetail);
      console.log("selected Medrep", selectedMedRep);
    }
  }, [MedRepDetail, selectedMedRep]);

  useEffect(() => {
    console.log("drafts", drafts);
  }, [drafts]);

  useEffect(() => {
    setValue("category", [{ label: Plan?.category, value: Plan?.category }]);
    setValue("institutionName", Plan?.institutionName);
    setValue("institutionDepartment", Plan?.institutionDepartment);
    setValue("location", Plan?.location);
    setValue("status", Plan?.status);
    setSelectedDates(Plan?.selectedDates || []);
    setSelectedMedRep({ ...Plan?.medRep, medrepId: Plan?.medRep?._id });
  }, [Plan]);

  useEffect(() => {
    console.log(HCPData, "hcps");
  }, [HCPData]);

  useEffect(() => {
    console.log(savedHCPData, "savedHCPData");
    console.log(selectedHCP, "selected");
    if (!addNewPlan && savedHCPData) {
      setSelectedHCP(savedHCPData);
    }
    if (addNewPlan) {
      setSelectedHCP(null);
    }
  }, [addNewPlan]);

  const transformedArray = useMemo(() => {
    return Country.map((item) => ({
      label: item.name, // replace with the actual property you want as label
      value: item.name,
      id: item?.id, // replace with the actual property you want as value
    }));
  }, [Country]);

  //   console.log(data,'datadata')
  // selected hcp
  console.log("PlansData 22222", PlansData);

  const AddPlanForm = [
    {
      name: "HealthCarePractitioner",
      label: "Assign Medical Representative",
      type: "select",
      label2: selectedMedRep
        ? selectedMedRep?.firstName + " " + selectedMedRep?.lastName
        : "",
      value: selectedMedRep ? selectedMedRep?.medrepId : "",
      placeholder: "Select Medical Representative",
      // options: MedRepData?.data?.medreps?.map((item) => ({
      //   value: item?.medrepId,
      //   label: item?.firstName + " " + item?.lastName,
      //   full: item,
      //   type: "medRep",
      // })),
    },
    {
      name: "HealthCarePractitioner",
      label: "Select Healthcare Practitioner",
      type: "select",
      label2: Plan?.hcp?.firstName + " " + Plan?.hcp?.lastName,
      value: Plan?.hcp?._id,
      placeholder: "Select Health Care Practitioner",
      // options: MedRepDetail?.data?.plans?.map((item) => ({
      //   value: item?.hcp?._id,
      //   label: item?.hcp?.firstName + " " + item?.hcp?.lastName,
      //   full: item,
      //   type: "hcp",
      // })),
    },
    {
      name: "department",
      label: "Department",
      type: "input",
      value: Plan?.institutionDepartment,
      placeholder: "Select Department",
    },
    {
      name: "institution",
      label: "Institution",
      type: "input",
      placeholder: "Select Institution",
      value: Plan?.institutionName,
    },
    {
      name: "data",
      label: "Select Date",
      type: "multiDate",
      component: datePickerComponent,
      placeholder: "",
    },
  ];

  const handleOnSubmit = async () => {
    if (selectedDates.length <= 0) {
      return;
    }
    storage["localStorage"].set("medRepId", Plan?.medRep?._id)
    const plan = {
      plans: [
        {
          medRep: Plan?.medRep?._id,
          company: Plan?.company,
          hcp: Plan?.hcp?._id,
          institutionName: Plan?.institutionName,
          institutionDepartment: Plan?.institutionDepartment,
          category: "HCP",
          location: Plan?.location,
          country: Plan?.country,
          targets: {
            date: selectedDates[0],
            month: firstMonth,
            year: new Date().getFullYear(),
            callsPerDay: selectedDates.length,
            callsPerMonth: selectedDates.length,
            inactiveDays: inactiveDays,
            _id: Plan?._id,
          },
          status: Plan?.status,
          completedPercentage: Plan?.completedPercentage,
          selectedDates: selectedDates,
          id: Plan?._id,
          createdAt: Plan?.createdAt,
          updatedAt: Plan?.updatedAt,
          __v: Plan?.__v,
        },
      ],
    };

    // const planData = drafts?.map((item) => {
    //   return {
    //     hcp: item.hcpId,
    //     category: item?.category,
    //     location: item.location,
    //     country: item.country,
    //     company: item.company,
    //     institutionName: item.institution,
    //     institutionDepartment: item.department,
    //     targets: {
    //       date: item.selectedDates[0],
    //       callsPerDay: item.callsPerDay,
    //       callsPerMonth: item.callsPerMonth,
    //       inactiveDays: item.inactiveDays,
    //     },
    //     selectedDates: item.selectedDates,
    //   };
    // });

    console.log("finale plans", plan);

    // setLoading(true);
    try {
      const response = await updatePlan(plan);
      console.log(response, "update plan response");
      if (!response?.data?.status) {
        toast.error(response?.message || "An error occurred");
        return;
      }
      setShowModal(true);
      setLoading(false);
    } catch (error) {
      console.log(error, "create plan error");
      // setLoading(false);
    }
  };

  return (
    <div className="bg-[#FCFCFC]">
      <div className="flex bg-white h-full justify-content-between items-center">
        <div className="flex w-full lg:flex-row  md:flex-row flex-col justify-content-between items-center pb-[20px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem] w-full">
            <div
              onClick={() => {
                navigate(-1);
              }}
              className="flex w-[200px] items-center mr-10 cursor-pointer"
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.711914"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="3.5"
                  fill="white"
                />
                <rect
                  x="0.711914"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="3.5"
                  stroke="#E4E7EC"
                />
                <path
                  d="M7.35836 12.3536C7.1631 12.1583 7.1631 11.8417 7.35836 11.6464L9.35836 9.64645C9.55362 9.45118 9.87021 9.45118 10.0655 9.64645C10.2607 9.84171 10.2607 10.1583 10.0655 10.3536L8.91902 11.5L16.7119 11.5C16.9881 11.5 17.2119 11.7239 17.2119 12C17.2119 12.2761 16.9881 12.5 16.7119 12.5L8.91902 12.5L10.0655 13.6464C10.2607 13.8417 10.2607 14.1583 10.0655 14.3536C9.87021 14.5488 9.55362 14.5488 9.35836 14.3536L7.35836 12.3536Z"
                  fill="black"
                />
              </svg>
              <p className="font-[500] ml-3 text-[14px] font-Inter text-[#AD3307]">
                Go Back
              </p>
            </div>
            <div className="flex justify-start items-center w-full">
              <div>
                <div className="text-[25px] font-[700] mr-5">
                  {LanguageName("PLAN")}
                </div>
                {/* <div className="text-[#777777] text-[14px]"> */}
                {/*   {LanguageName("MR")}/{LanguageName("Institutions")}/ */}
                {/*   {LanguageName("CPN")} */}
                {/* </div> */}
              </div>
              <div className="flex space-x-3 bg-[#fcfaff] border-[1px] border-[#ebebeb] rounded align-items-center lg:w-[50%] md:w-100 w-[62%] py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
                <div className="ps-2 pe-3">
                  <img className="" src="/icon/search.png" alt="search" />
                </div>

                <input
                  className="w-100 text-[16px] font-[400] bg-[#fcfaff]"
                  type="text"
                  // placeholder={LanguageName("SCR")}
                  placeholder={"Search for Instintution, Department"}
                />
              </div>
            </div>
            <div className="ms-2"></div>
          </div>
        </div>
        {/* <div className="flex w-[300px] items-center lg:flex-row md:flex-row flex-col justify-content-between"> */}
        {/*   <ButtonComp */}
        {/*     // onClick={() => navigate("/plan/summary")} */}
        {/*     onClick={handleOnSubmit} */}
        {/*     loader={HCPLoader} */}
        {/*     className="text-white bg-[#3DA84A] rounded-[5px] font-[500] md:text-[18px] text-[14px] px-[8px] py-[8px] md:px-[27px] md:py-[10px]" */}
        {/*     // text={LanguageName("proceedToSubmit")} */}
        {/*     text="Save and Submit" */}
        {/*   /> */}
        {/* </div> */}
      </div>
      <div className="border"></div>

      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-4">
        <div className="md:col-4 w-full md:w-[700px] py-4 px-3">
          <p className="text-[#242731] font-[700] text-[20px]">Drafts</p>
          <p className="text-[16px] font-[500] text-[#333333] mb-4">
            view your saved plans here before submission
          </p>

          <div className="border border-[#EBEBEB] bg-white rounded-[8px] p-4 mb-4">
            <div className="flex items-center text-[#589E67] text-[14px] font-[500] justify-between mb-4">
              <p>New Plan</p>
              <div className="flex items-center">
                <p className="mr-4">{firstMonthName}</p>
                <div
                  onClick={() => {
                    // setSelectedMedRep({
                    //   draftIndex: index,
                    //   _id: draft.medRepId,
                    //   medRep: {
                    //     firstName: draft.medRep.split(" ")[0],
                    //     lastName: draft.medRep.split(" ")[1],
                    //   },
                    // });
                    //
                    // setSelectedHCP({
                    //   hcp: {
                    //     _id: draft.hcpId,
                    //     title: draft.hcp.split(" ")[0],
                    //     firstName: draft.hcp.split(" ")[1],
                    //     lastName: draft.hcp.split(" ")[2],
                    //   },
                    //   institutionName: draft.institution,
                    //   institutionDepartment: draft?.department,
                    //   targets: {
                    //     callsPerDay: draft.callsPerDay,
                    //     callsPerMonth: draft.callsPerMonth,
                    //     inactiveDays: draft.inactiveDays,
                    //   },
                    //   selectedDates: draft.selectedDates,
                    //   days: draft.days,
                    // });
                    // setSelectedDates(draft.selectedDates);
                  }}
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="28" height="28" rx="14" fill="#F9F9F9" />
                    <path
                      d="M9.33236 18.5924H10.2752L16.4843 12.3832L15.5415 11.4404L9.33236 17.6496V18.5924ZM19.999 19.9257H7.99902V17.0972L16.9557 8.14057C17.2161 7.88023 17.6382 7.88023 17.8985 8.14057L19.7842 10.0262C20.0445 10.2865 20.0445 10.7087 19.7842 10.969L12.1608 18.5924H19.999V19.9257ZM16.4843 10.4976L17.4271 11.4404L18.3699 10.4976L17.4271 9.55479L16.4843 10.4976Z"
                      fill="#555555"
                    />
                  </svg>
                </div>
                <div className="w-2" />
                <div
                  onClick={() => {
                    // setDrafts((prev) => {
                    //   const newDrafts = [...prev];
                    //   newDrafts.splice(index, 1);
                    //   return newDrafts;
                    // });
                  }}
                  className="cursor-pointer"
                >
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="28" height="28" rx="14" fill="#F9F9F9" />
                    <path
                      d="M10.9879 8.98568V7.65234H17.6545V8.98568H20.9879V10.319H19.6545V20.319C19.6545 20.6872 19.3561 20.9857 18.9879 20.9857H9.65454C9.28635 20.9857 8.98787 20.6872 8.98787 20.319V10.319H7.65454V8.98568H10.9879ZM10.3212 10.319V19.6523H18.3212V10.319H10.3212ZM12.3212 12.319H13.6545V17.6523H12.3212V12.319ZM14.9879 12.319H16.3212V17.6523H14.9879V12.319Z"
                      fill="#D55F5A"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex items-center justify-between mb-3">
                <div className="flex flex-col items-start w-full">
                  <p className="text-[11px] text-[#777777]">
                    Medical Representative
                  </p>
                  <p className="text-[16px] font-[500] text-[#333333]">
                    {selectedMedRep?.medRep?.firstName || Plan?.medRep?.fr}{" "}
                    {selectedMedRep?.medRep?.lastName || Plan?.medRep?.lastName}
                  </p>
                </div>
                <div className="flex flex-col items-start w-[50%]">
                  <p className="text-[11px] text-[#777777]">Calls per Day</p>
                  <p className="text-[16px] font-[500] text-[#333333]">
                    {selectedDates.length || Plan?.targets?.callsPerDay}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex flex-col items-start w-full">
                  <p className="text-[11px] text-[#777777]">
                    Healthcare Practitioner
                  </p>
                  <p className="text-[16px] font-[500] text-[#333333]">
                    {Plan?.hcp?.title} {Plan?.hcp?.firstName}{" "}
                    {Plan?.hcp?.lastName}
                  </p>
                </div>
                <div className="flex flex-col items-start w-[50%]">
                  <p className="text-[11px] text-[#777777]">Calls per Month</p>
                  <p className="text-[16px] font-[500] text-[#333333]">
                    {selectedDates.length || Plan?.targets?.callsPerDay}
                  </p>
                </div>
              </div>
              <div className="flex items-center justify-between mb-3">
                <div className="flex flex-col items-start w-full">
                  <p className="text-[11px] text-[#777777]">Institution</p>
                  <p className="text-[16px] font-[500] text-[#333333]">
                    {Plan?.institutionName}
                  </p>
                </div>
                <div className="flex flex-col items-start w-[50%]">
                  <p className="text-[11px] text-[#777777]">Inactive Days</p>
                  <p className="text-[16px] font-[500] text-[#333333]">
                    {Plan?.targets?.inactiveDays}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <p className="text-[11px] text-[#777777]">Days Selected</p>
              <div className="flex w-[360px] overflow-x-auto pb-4 items-center justify-content-start">
                {days.length > 0 &&
                  days?.map((item) => (
                    <span className="rounded-[6px] mr-4 border-[#EBEBEB] text-[#777777] text-[14px] font-[500] p-[20px] border">
                      {item}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="md:col-8 md:mr-4 w-full py-4 px-5 border-l">
          <div className="p-5 bg-white h-[100vh] ">
            <div className="flex justify-between items-center">
              <div className="w-full">
                <div className="text-[#242731] font-[700] text-[28px]">
                  Edit Plan
                </div>
                <div className="font-[500] text-[18px] text-[#333333] mb-5">
                  Follow the prompt to edit plan
                </div>
                <div className="border border-[#4F008A1A] rounded-[12px] py-4 px-5">
                  <div className="text-black font-[500] text-[16px] mb-5">
                    Kindly fill in the following information to create a new
                    plan
                  </div>
                  <NewFormUI
                    containerClassName="mb-4 bg-[#FCFAFF] border border-[#EDE6F5] rounded-[8px]"
                    setSelectedHCP={setSelectedHCP}
                    setSelectedMedRep={setSelectedMedRep}
                    wrapperClassName={"mb-8"}
                    formData={AddPlanForm}
                    control={control}
                  />
                </div>
              </div>
            </div>
            <div className="col-6" />
            <div
              onClick={handleOnSubmit}
              className="cursor-pointer flex align-items-center mt-5"
            >
              <div className="flex ml-4 align-items-center rounded-[50px] bg-[#3DA84A] lg:mb-0 md:mb-0 mb-[1rem] px-5 py-3">
                <ButtonComp
                  loader={HCPLoader}
                  className="text-[#ffffff] font-[500] text-[16px] "
                  text={"Update Plan"}
                />
              </div>
            </div>
          </div>
          <div className="h-[100px]" />
        </div>
      </div>
      <ModalComp
        show={showModal}
        handleClose={() => setShowModal(false)}
        bodyComponents={
          <div className="flex flex-col items-center py-5">
            <div className="flex justify-center mb-4">
              <ImageComponent src="/icon/timer.svg" width={70} />
            </div>
            <div>
              <div className="text-center text-[#282728] text-[24px] font-semibold mb-3">
                Plan Created Successfully
              </div>
              <p className="text-center text-[#333333] text-[14px] self-center px-[15%] mb-4">
                Le client est très important, le client sera suivi par le
                client. Le bateau flatte, l'élite à eleifend pulvinar, ex ex
                ultris sem, un vestibulum dolor
              </p>
              <div className="flex w-full justify-center">
                <ButtonComp
                  className="bg-[#A162F71A] px-4 text-[#A162F7] font-[500] text-[18px] py-2 rounded-[5px]"
                  // onClick={handleResendPassword}
                  // loader={resendLoader}
                  onClick={() => navigate("/plan_overview")}
                  text={"Go Home"}
                />
                <div className="w-4" />
                <ButtonComp
                  className="text-white px-4 bg-[#A162F7] font-[500] text-[18px] py-2 rounded-[5px]"
                  // onClick={handleResendPassword}
                  onClick={() => navigate("/plan/summary")}
                  // loader={resendLoader}
                  text={"View Plans"}
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
