import moment from "moment";
import DropDownComp from "../../../Components/Ui/DropDown";
import {
  AvatarIcon,
  Dots,
  EyeIcon,
  TrackIcon,
  TrashIcon,
} from "../../../utils/svg";
import { Link } from "react-router-dom";
import { LanguageName } from "../../../utils/helper";
import { ProgressBar } from "react-bootstrap";

export const tableData = [
  {
    id: "#12343",
    medReps: "/Plan/person-2.svg",
    name: "Olakunbi Olabode 0.",
    Status: "Active",
    Territory: "Ikorodu",
    Activity: "30/07/2023",
    Hcps: "/Plan/hcps.svg",
    Progress: "/Plan/bar-75.svg",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    medReps: "/Plan/person.svg",
    name: "Olakunbi Olabode 0.",
    Status: "Active",
    Territory: "Ikorodu",
    Activity: "30/07/2023",
    Hcps: "/Plan/hcps.svg",
    Progress: "/Plan/bar-50.svg",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    medReps: "/Plan/person-2.svg",
    name: "Olakunbi Olabode 0.",
    Status: "Active",
    Territory: "Ikorodu",
    Activity: "30/07/2023",
    Hcps: "/Plan/hcps.svg",
    Progress: "/Plan/bar-25.svg",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    medReps: "/Plan/person.svg",
    name: "Olakunbi Olabode 0.",
    Status: "Active",
    Territory: "Ikorodu",
    Activity: "30/07/2023",
    Hcps: "/Plan/hcps.svg",
    Progress: "/Plan/bar-100.svg",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    medReps: "/Plan/person-2.svg",
    name: "Olakunbi Olabode 0.",
    Status: "Active",
    Territory: "Ikorodu",
    Activity: "30/07/2023",
    Hcps: "/Plan/hcps.svg",
    Progress: "/Plan/bar-50.svg",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    medReps: "/Plan/person.svg",
    name: "Olakunbi Olabode 0.",
    Status: "Active",
    Territory: "Ikorodu",
    Activity: "30/07/2023",
    Hcps: "/Plan/hcps.svg",
    Progress: "/Plan/bar-25.svg",
    img: "/Overview/more.svg",
  },
];

const StatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case "pending":
      return "bg-[#DBEBFE] px-3 py-2 rounded-md capitalize";
    case "active":
      return "text-[#3DA84A] bg-[#EFFDF9]  px-3 py-2 rounded-md capitalize";
    case "inactive":
      return "bg-[#D634341A] text-[#D63434] px-3 py-2 rounded-md capitalize";
    case "completed":
      return "bg-[#DAF1E9] text-[#044E54] capitalize";
    default:
  }
};

const OptionDropDown = (row, setShowModal) => [
  // {
  //   name: (
  //     <Link
  //       // to={`/plan_col/${row?._id}`}
  //       to={`/account/med_rep`}
  //       className="flex gap-1 font-Sans items-center text-[#000] text-[13px]"
  //     >
  //       <AvatarIcon />
  //
  //       {LanguageName("VFP")}
  //     </Link>
  //   ),
  //   // action:;
  // },
  {
    name: (
      <div className="relative inline-block" onClick={() => setShowModal(true)}>
        <Link
          to={`/plan/${row?._id}`}
          className="flex gap-1 font-Sans items-center text-[#000] text-[13px]"
        >
          <EyeIcon />
          {LanguageName("VCP")}
        </Link>
        {/* <div>{LanguageName("VCP")}</div>
        <div className="absolute top-[-10px] right-[-10px] bg-yellow-100 text-yellow-800 text-xs font-semibold rounded-full px-2 py-0.5">
          Beta
        </div> */}
      </div>
    ),
    // action:;
  },
];
export const columnsDataII = () => [
  {
    name: "ID",
    selector: (row) => <span>#{row?._id?.substring(0, 6)}</span>,
    sortable: true,
    width: "70px",
  },
  {
    name: LanguageName("CON"),
    selector: (row) => <div>{row?.institutionName}</div>,
    width: "250px",
    // sortable: true,
  },

  {
    name: <div>{LanguageName("EA")}</div>,
    selector: (row) => row?.medRep?.email,
    width: "150px",
    // sortable: true,
  },

  {
    name: <div className="whitespace-nowrap">{LanguageName("COTY")}</div>,
    selector: (row) => row?.country,
    width: "150px",
    // sortable: true,
  },

  {
    name: LanguageName("Status"),
    selector: (row) => (
      <div className={`${StatusColor(row?.status)} `}>{row?.status}</div>
    ),
    width: "150px",
    // sortable: true,
  },
  {
    name: LanguageName("DA"),
    selector: (row) => moment(row?.createdAt).format("MMMM Do YYYY, h:mm"),
    // width: "200px",

    // sortable: true,
  },

  {
    name: LanguageName("Action"),
    // sortable: true,
    cell: (row) => (
      <div className="ms-3">
        <DropDownComp
          dropDownText={<Dots color="#7572EA" />}
          options={OptionDropDown(row)}
        />
      </div>
    ),
    width: "100px",
  },
];

export const columnsDataIIMed = (func) => [
  {
    name: "ID",
    selector: (row) => <span>#{row?._id?.substring(0, 6)}</span>,
    sortable: true,
    width: "200px",
  },
  {
    name: LanguageName("MR"),
    selector: (row) => (
      <div>
        {row?.medRep?.firstName} {row?.medRep?.lastName}
      </div>
    ),
    width: "250px",
    // sortable: true,
  },

  {
    name: LanguageName("Status"),
    selector: (row) => (
      <div className={`${StatusColor(row?.status)} `}>{row?.status}</div>
    ),

    width: "250px",
    // sortable: true,
  },

  {
    name: LanguageName("TRY"),
    selector: (row) => row?.medRep?.territory,
    width: "150px",
    // sortable: true,
  },

  {
    name: LanguageName("LA"),
    // selector: (row) => <div className={` `}>{row?.lastActivity}</div>,
    selector: (row) => moment(row?.lastActivity).format("MMMM Do YYYY"),
    width: "250px",
    // sortable: true,
  },
  {
    name: " Hcps",
    selector: (row) => (
      <div className={`w-full text-center `}>
        {/* <div className=""> */}
        {/*   <img src="/Plan/people.svg" alt="" /> */}
        {/* {row?.hcp?.firstName} {row?.hcp?.lastName} */}
        {/* </div> */}
        {row?.uniqueHcpsPlans}
      </div>
    ),
    width: "100px",
    // sortable: true,
  },
  {
    name: LanguageName("PPS"),
    selector: (row) => (
      <div className={` w-full`}>
        <div className="flex w-[150px] text-[14px]">
          <ProgressBar now={90} />
        </div>
        <ProgressBar now={row?.planProgress} />
      </div>
    ),
    width: "150px",
    // sortable: true,
  },
  // {
  //   name: "Date Added",
  //   selector: (row) => moment(row?.Status).format("MMMM Do YYYY, h:mm"),
  //   // width: "200px",

  //   // sortable: true,
  // },

  {
    name: LanguageName("Action"),
    // sortable: true,
    cell: (row) => (
      <div className="ms-3">
        <DropDownComp
          dropDownText={<Dots color="#7572EA" />}
          options={OptionDropDown(row, func)}
        />
      </div>
    ),
    width: "150px",
  },
];
