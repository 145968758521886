import React, { useState } from "react";


import { toast } from "react-toastify";
import SingleHCOComp from "./SingleHCOComp";
import { useGetSingleHCOQuery } from "../../../../store/Event/EventApi";
import { useParams } from "react-router-dom";
import { useDisableHcpMutation } from "../../../../store/Schedules/SchedulesApi";
import { extractData } from "../../../../utils/helper";

function SingleHCO() {
  const { id } = useParams();
  const { data: HCOData, isLoading } = useGetSingleHCOQuery(id);
  const [activatedRes, setActivatedRes] = useState(null)
  const [disableHCP, { isLoading: DHloading, isError }] =
    useDisableHcpMutation();
  const handleDisableHcp = async (id, status) => {
    const payload = {
      id,
      status
    };
    const Response = await disableHCP(payload);
    if (!Response?.data?.status) {
      toast.error("oops, something went wrong");
    } else {
      toast.success(Response?.data?.message);
      setActivatedRes(Response?.data?.deactivated)
    }
  };

  console.log(extractData(HCOData))
  return (
    <div>
      
      {HCOData && extractData(HCOData) && <SingleHCOComp
        HCO={HCOData}
        newData={extractData(HCOData)}
        hcoLoader={isLoading}
        handleDisableHcp={handleDisableHcp}
        activated={activatedRes}
      />}

    </div>
  );
}

export default SingleHCO;
