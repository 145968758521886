import React, { useEffect } from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import HCPComp from "./HCPComp";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetHCPQuery,
  useGetActualHcpSchedulesQuery,
  useLazyGetHcpSchedulesQuery,
  useLazyGetNewHcpSchedulesQuery,
} from "../../../store/Schedules/SchedulesApi";
import { useGetHCPActivitiesQuery } from "../../../store/MedRep/MedRepApi";
import { useGetMedRepDetailsQuery } from "../../../store/Plans/PlansApi";

function HCP() {
  const { id, createdById } = useParams();
  const { data: HCPData, isLoading } = useGetHCPQuery();
  const { data: getMedRepActivities, isLoading: medRepActLoader } =
    useGetHCPActivitiesQuery(id, {
      skip: !id,
    });
  const SingleHcp = HCPData?.data?.hcps?.find((item) => item?._id == id);

  const location = useLocation();
  const receivedData = location.state;
  console.log(
    "hcp",
    SingleHcp,
    HCPData?.data?.hcps.find((item) => item?._id == id)
  );

  const [trigger, { data: getHcpSchedules, isLoading: ScheduleLoader }] =
    useLazyGetNewHcpSchedulesQuery();

  const { data: getMedRepPlan, isLoading: PlanLoader } =
    useGetMedRepDetailsQuery(createdById, {
      skip: !createdById, // Replace 'someCondition' with your actual condition
    });
 
  useEffect(() => {
    trigger({ id, page: 1, limit: 300 });
  }, [trigger]);

  return (
    <div>
      {HCPData && <HCPComp
        userSchedule={receivedData}
        userDetail={SingleHcp}
        activities={{
          data: getMedRepActivities?.data?.activities,
          loader: medRepActLoader,
        }}
        schedule={{
          data: getHcpSchedules?.data?.schedules,
          loader: ScheduleLoader,
        }}
        planData={{
          data: getMedRepPlan?.data?.plans,
          loader: PlanLoader,
        }}
      />}
    </div>
  );
}

export default HCP;
