import React from "react";
import { FormDatedView } from "../../../../utils/helper";
import Skeleton from "react-loading-skeleton";
import ActivitiesLoadingState from "./submodules/AcitivitiesLoadingState";
export default function Activities({ activities }) {
  const ImageType = (key) => {
    switch (key) {
      case "checkIn":
        return <img src="/HCPList/tick.png" alt="" />;

      case "report":
        return <img src="/HCPList/note.png" alt="" />;

      default:
        return <img src="/HCPList/note.png" alt="" />;
    }
  };

  function removeFirstTwoWords(inputString) {
    // Split the input string into an array of words
    let words = inputString.split(" ");

    // Check if there are at least two words in the string
    if (words.length < 2) {
      return ""; // Return an empty string if there are fewer than two words
    }

    // Join the words from the third one onwards
    return words.slice(2).join(" ");
  }

  // Example usage
  

  // console.log(activities?.data?.length,'activities?.data')
  return (
    <div>
      {activities?.loader &&
        [1, 2, 3, 4]?.map((item, i) => <ActivitiesLoadingState key={i} />)}
      {!activities?.loader && activities?.data?.length < 1 && (
        <div className="text-center text-lg font-semibold">
          No Activities Added
        </div>
      )}
      {!activities?.loader &&
        activities?.data?.map((item, i) => (
          <div className="flex justify-content-between align-items-center mb-[50px]">
            <div className="flex align-items-center">
              <div className="me-[15px]">{ImageType(item?.activityType)}</div>
              <div className="text-[16px] text-[#757575] font-[400]">
                {FormDatedView(item?.createdAt)}
                <br />
                {/* <b className="text-[16px] text-[#292D32] capitalize font-[500]"> */}
                {/*   {item?.activityType} */}
                {/* </b>{" "} */}
                {/* <span className="text-[16px] text-[#757575] font-[400] me-[3px]"> */}
                {/*   - */}
                {/* </span> */}
                <b className="text-[16px] text-[#292D32] font-[500]">
                  {removeFirstTwoWords(item?.activity)}
                </b>
              </div>
            </div>
            {/* <div className="">
              <img src="/HCPList/more.svg" alt="more icon" />
            </div> */}
          </div>
        ))}
    </div>
  );
}
