import moment from "moment";
import React, { useState } from "react";
import Activities from "./HCPListCompSubmodules/Activities";
import Schedule from "./HCPListCompSubmodules/Schedule";
import Plan from "./HCPListCompSubmodules/Plan";
import ButtonComp from "../../../Components/Ui/Button";
import { LanguageName, downloadCSV } from "../../../utils/helper";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft } from "../../../utils/svg";

export default function HCPComp({
  userSchedule,
  userDetail,
  schedule,
  activities,
  planData,
}) {
  const [selectHeader, setSelectHeader] = useState(
    LanguageName("Activities") || "Activities"
  );
  const { createdById, id } = useParams();
  const navigate = useNavigate();
  const List = [
    {
      name: LanguageName("Activities"),
      components: <Activities activities={activities} />,
    },
    {
      name: LanguageName("Sched"),
      components: <Schedule userSchedule={schedule} />,
    },
    {
      name: "Plan",
      components: <Plan planData={planData} />,
    },
  ];

  return (
    <div className="container">
      <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
        <div
          className="flex align-items-center cursor-pointer lg:mb-0 mb-[1rem]"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft />
          <div className="text-[25px] font-[700] ml-4">Go Back to HCPs</div>

        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
          <div className="flex align-items-center  border-1 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
            <div className="me-1">
              <img src="/Schedule/document-download.png" alt="" />
            </div>
            <ButtonComp
              onClick={() =>
                downloadCSV(
                  [...activities?.data, ...schedule?.data, ...planData?.data],
                  `HCP. ${userDetail?.firstName} ${userDetail?.lastName}.csv`,
                  planData?.loader
                )
              }
              className="text-[#44AA43] font-[500] text-[16px] "
              text={LanguageName("DR")}
            />
          </div>
        </div>
      </div>
      <div className="row gap-3 d-flex">
        <div className="col-md-5 border rounded pt-10">
          <div className="flex flex-col">
            <div className="flex justify-center items-center mx-auto w-32 h-32 rounded-full overflow-hidden">
              <img
                src="/HCPList/profile.svg"
                className="w-full h-full object-cover"
                alt=""
              />
            </div>

            <div className="text-[28px] text-[#242731] text-center font-[700] mt-[30px]">
              {userDetail?.title} {userDetail?.firstName} {userDetail?.lastName}
            </div>
            <div className="text-center mt-1 mb-6">
              <p className="text-lg font-medium">
                {`${userDetail?.specialty} | ${userDetail?.hco?.institutionName}`}
              </p>
            </div>
            <div className="flex space-x-4 flex-row justify-center border-b mx-4 pb-[30px]">
              <div
                className={`flex justify-center grow align-items-center bg-[#3DA84A26] border-[#3DA84A] border-2 py-2 rounded ${
                  userDetail?.deactivated
                    ? "border-[#ff0000]"
                    : "border-[#3DA84A]"
                }`}
              >
                <div
                  className={`me-2 w-4 h-4 rounded-full ${
                    userDetail?.deactivated ? "bg-[#ff0000]" : "bg-[#44AA43]"
                  }`}
                />
                <div
                  className={`${
                    userDetail?.deactivated
                      ? "text-[#ff0000]"
                      : "text-[#44AA43]"
                  } text-[16px] font-[500] `}
                >
                  {userDetail?.deactivated
                    ? "Profile Inactive"
                    : "Profile Active"}
                </div>
              </div>
              <div
                onClick={() =>
                  navigate(`/account/hcp/${createdById}/${id}/edit`)
                }
                className="flex justify-center grow-[2] align-items-center bg-[#8354CF] text-white py-2 rounded cursor-pointer"
              >
                Edit Profile
              </div>
            </div>
          </div>
          <div className="px-4">
            <div className="flex justify-between items-center my-[40px]">
              <p className="font-normal text-[#A1A1A1]">
                {LanguageName("DateCr")}
              </p>
              <div className="text-[#333333] text-lg font-[500]">
                {moment(userDetail?.createdAt).format("Do MMMM  YY")}
              </div>
            </div>
            <div className="flex justify-between items-center mb-[40px]">
              <p className="font-normal text-[#A1A1A1]">{LanguageName("EA")}</p>
              <div className="text-[#333333] text-lg font-[500]">
                {userDetail?.email || "No Email Added yet"}
              </div>
            </div>
            <div className="flex justify-between items-center mb-[40px]">
              <p className="font-normal text-[#A1A1A1]">
                {LanguageName("PNB")}
              </p>
              <div className="text-[#333333] text-lg font-[500]">
                {userDetail?.phoneNumber || "-"}
              </div>
            </div>
            <div className="flex justify-between items-center mb-[40px]">
              <p className="font-normal text-[#A1A1A1]">{LanguageName("MR")}</p>
              <div className="text-lg font-[500] text-[#4F008A] underline">
                {userDetail?.createdBy?.firstName || "-"}{" "}
                {userDetail?.createdBy?.lastName}
              </div>
            </div>

            <div className="flex justify-between ">
              <p className="font-normal text-[#A1A1A1]">
                {LanguageName("Local")}
              </p>
              {userDetail?.hco?.address?.map((address) => (
                <div className="text-[18px] font-[500] text-[#333333] mb-[80px]">
                  {address.slice(0, 25)}
                </div>
              ))}
              {/* , {userDetail?.country} */}
            </div>
          </div>
        </div>
        <div className="col-md-6 border rounded pt-10 px-4 flex-grow-1 ">
          <div className="flex mb-[65px]">
            {List?.map((item, i) => (
              <div
                onClick={() => setSelectHeader(item?.name)}
                className={`${
                  selectHeader === item?.name
                    ? "border-[#4F008A] text-[#4F008A] "
                    : "text-[#555555]"
                } cursor-pointer text-[18px] font-[500] pb-[20px] border-b  pe-4`}
              >
                {item?.name}
              </div>
            ))}

            <div className="text-[18px] w-[10%] font-[500] text-[#555555] pb-[20px] border-b">
              {""}
            </div>
          </div>
          {List?.find((item, i) => item?.name === selectHeader)?.components}
        </div>
      </div>
    </div>
  );
}
