import React from "react";
import { Spinner } from "react-bootstrap";

export default function ButtonComp({
  text,
  btnClassName,
  onClick,
  loader,
  title,
  wrapper,
  disabled,
  ...props
}) {
  return (
    <div className={`${wrapper} `}>
      <button
        onClick={onClick}
        disabled={disabled || loader}
        className={`flex items-center gap-3 justify-center ${btnClassName}`}
        {...props}
      >
        {title === "upload" && (
          <div className="item_container">
            <img src="/icon/upload.svg" alt="" />
          </div>
        )}
        {title === "assign" && (
          <div className="item_container">
            <img src="/icon/plus4.svg" alt="" />
          </div>
        )}
        {loader && <Spinner size="sm" animation="border" />}
        {text}
      </button>
    </div>
  );
}
