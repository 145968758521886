
import { LanguageName } from "../../../utils/helper";

export default function TestModal() {
  
  return (
    <div className="container">
      <h2 className="my-4 font-bold text-2xl">{LanguageName("TestInfo")}</h2>
    </div>
  );
}
