import { REGEX_PATTERNS } from "../../../utils/constants/errors";
import { PersonalInformActive } from "../../../utils/svg";

export const LabelDesc = [
  {
    name: "Institution",
    desc: "Follow the prompt to add",
    image: "/NHC/hospital.png",
    active: (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.4752 23.0776H1.14971C0.692034 23.0776 0.3125 23.4709 0.3125 23.9451C0.3125 24.4193 0.692034 24.8126 1.14971 24.8126H23.4752C23.9329 24.8126 24.3125 24.4193 24.3125 23.9451C24.3125 23.4709 23.9329 23.0776 23.4752 23.0776Z"
          fill="#4F008A"
        />
        <path
          d="M17.8939 0.8125H6.7311C3.38227 0.8125 2.26599 2.88286 2.26599 5.439V23.945H8.96365V16.9358C8.96365 16.3344 9.43249 15.8486 10.013 15.8486H14.6232C15.1925 15.8486 15.6725 16.3344 15.6725 16.9358V23.945H22.3701V5.439C22.359 2.88286 21.2427 0.8125 17.8939 0.8125ZM15.1032 9.19802H13.1497V11.2221C13.1497 11.6963 12.7702 12.0896 12.3125 12.0896C11.8548 12.0896 11.4753 11.6963 11.4753 11.2221V9.19802H9.52179C9.06412 9.19802 8.68458 8.80477 8.68458 8.33056C8.68458 7.85634 9.06412 7.46309 9.52179 7.46309H11.4753V5.439C11.4753 4.96478 11.8548 4.57153 12.3125 4.57153C12.7702 4.57153 13.1497 4.96478 13.1497 5.439V7.46309H15.1032C15.5608 7.46309 15.9404 7.85634 15.9404 8.33056C15.9404 8.80477 15.5608 9.19802 15.1032 9.19802Z"
          fill="#4F008A"
        />
      </svg>
    ),
    inactive: (
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.4752 23.0776H1.14971C0.692034 23.0776 0.3125 23.4709 0.3125 23.9451C0.3125 24.4193 0.692034 24.8126 1.14971 24.8126H23.4752C23.9329 24.8126 24.3125 24.4193 24.3125 23.9451C24.3125 23.4709 23.9329 23.0776 23.4752 23.0776Z"
          fill="#4F008A"
        />
        <path
          d="M17.8939 0.8125H6.7311C3.38227 0.8125 2.26599 2.88286 2.26599 5.439V23.945H8.96365V16.9358C8.96365 16.3344 9.43249 15.8486 10.013 15.8486H14.6232C15.1925 15.8486 15.6725 16.3344 15.6725 16.9358V23.945H22.3701V5.439C22.359 2.88286 21.2427 0.8125 17.8939 0.8125ZM15.1032 9.19802H13.1497V11.2221C13.1497 11.6963 12.7702 12.0896 12.3125 12.0896C11.8548 12.0896 11.4753 11.6963 11.4753 11.2221V9.19802H9.52179C9.06412 9.19802 8.68458 8.80477 8.68458 8.33056C8.68458 7.85634 9.06412 7.46309 9.52179 7.46309H11.4753V5.439C11.4753 4.96478 11.8548 4.57153 12.3125 4.57153C12.7702 4.57153 13.1497 4.96478 13.1497 5.439V7.46309H15.1032C15.5608 7.46309 15.9404 7.85634 15.9404 8.33056C15.9404 8.80477 15.5608 9.19802 15.1032 9.19802Z"
          fill="#4F008A"
        />
      </svg>
    ),
  },
  {
    name: "Personal Information",
    desc: "Follow the prompt to add",
    image: "/NHC/user.png",
    active: <PersonalInformActive />,
    inactive: (
      <svg
        width="23"
        height="26"
        viewBox="0 0 23 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.3076 13C14.6213 13 17.3076 10.3137 17.3076 7C17.3076 3.68629 14.6213 1 11.3076 1C7.99391 1 5.30762 3.68629 5.30762 7C5.30762 10.3137 7.99391 13 11.3076 13Z"
          stroke="#4F008A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M21.6157 25.0001C21.6157 20.3561 16.9958 16.6001 11.3078 16.6001C5.61975 16.6001 0.999756 20.3561 0.999756 25.0001"
          stroke="#4F008A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    name: "Address",
    desc: "Follow the prompt to add",
    image: "/NHC/location.png",
    active: (
      <svg
        width="18"
        height="22"
        viewBox="0 0 18 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.4938 7.2C16.4538 2.57 12.4138 0.5 8.87377 0.5C8.87377 0.5 8.87377 0.5 8.86377 0.5C5.33377 0.5 1.30377 2.57 0.253774 7.19C-0.926226 12.35 2.23377 16.72 5.09377 19.48C6.15377 20.5 7.51377 21.01 8.87377 21.01C10.2338 21.01 11.5938 20.5 12.6438 19.48C15.5038 16.72 18.6638 12.36 17.4938 7.2ZM11.6238 10.25H6.12377C5.71377 10.25 5.37377 9.91 5.37377 9.5C5.37377 9.09 5.71377 8.75 6.12377 8.75H11.6238C12.0338 8.75 12.3738 9.09 12.3738 9.5C12.3738 9.91 12.0338 10.25 11.6238 10.25Z"
          fill="#4F008A"
        />
      </svg>
    ),
    inactive: (
      <svg
        width="24"
        height="27"
        viewBox="0 0 24 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.04858 12.3027H15.6502"
          stroke="#4F008A"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M2.29114 9.28995C4.65573 -1.10465 20.0556 -1.09264 22.4082 9.30195C23.7885 15.3995 19.9956 20.5608 16.6707 23.7536C14.2581 26.0821 10.4412 26.0821 8.01657 23.7536C4.70374 20.5608 0.910791 15.3875 2.29114 9.28995Z"
          stroke="#4F008A"
          stroke-width="2"
        />
      </svg>
    ),
  },
  {
    name: "Assign Med Rep",
    desc: "Confirm information of the HCPs",
    image: "/NHC/tick.png",
    active: (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 0.5C5.388 0.5 0 5.888 0 12.5C0 19.112 5.388 24.5 12 24.5C18.612 24.5 24 19.112 24 12.5C24 5.888 18.612 0.5 12 0.5ZM17.736 9.74L10.932 16.544C10.764 16.712 10.536 16.808 10.296 16.808C10.056 16.808 9.828 16.712 9.66 16.544L6.264 13.148C5.916 12.8 5.916 12.224 6.264 11.876C6.612 11.528 7.188 11.528 7.536 11.876L10.296 14.636L16.464 8.468C16.812 8.12 17.388 8.12 17.736 8.468C18.084 8.816 18.084 9.38 17.736 9.74Z"
          fill="#4F008A"
        />
      </svg>
    ),
    inactive: (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 25C19.6 25 25 19.6 25 13C25 6.4 19.6 1 13 1C6.4 1 1 6.4 1 13C1 19.6 6.4 25 13 25Z"
          stroke="#4F008A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.89999 13L11.296 16.396L18.1 9.604"
          stroke="#4F008A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  // {
  //     name:'',
  //     desc:'',
  //     image:''
  // },
];

export const FormLabel = [
  {
    name: "institutionType",
    label: "Institution Type",
    className: "",
    // type:'select'
  },
  {
    name: "institutionName",
    label: "Institution Name",
    className: "",
    // type:'select'
  },
  {
    name: "specialty",
    label: "Specialty",
    className: "",
    className: "col-6",
    // type:'select'
  },
  {
    name: "department",
    label: "Department",
    className: "",
    className: "col-6",
    // type:'select'
  },
  {
    name: "position",
    label: "Position Held",
  },
  {
    name: "firstName",
    label: "First Name",
    className: "col-6",
  },
  {
    name: "lastName",
    label: "Last Name",
    className: "col-6",
  },
  {
    name: "title",
    label: "Title",
    className: "col-12",
    type: "select",
    options: [
      { value: "Mr", label: "Mr" },
      { value: "Mrs", label: "Mrs" },
      { value: "Miss", label: "Miss" },
    ],
  },
  {
    name: "email",
    label: "Email Address",
    className: "col-12",
    pattern: REGEX_PATTERNS?.EMAIL,
  },
  {
    name: "phoneNumber",
    label: "Phone Number",
    className: "col-12",
    pattern: REGEX_PATTERNS?.NUMBER,
  },
  {
    name: "Consent",
    label: "Consent to receive newsletter from us ",
    type: "checkbox",
    className: "",
  },
  {
    name: "address",
    label: "Street Address",
    className: "",
  },
  {
    name: "country",
    label: "Country",
    className: "",
  },
  {
    name: "postalCode",
    label: "Postal Code",
    className: "",
  },
  {
    name: "city",
    label: "City",
    className: "col-6",
  },
  {
    name: "state",
    label: "State",
    className: "col-6",
  },
  {
    name: "",
    label: "Med Rep",
    className: "",
    type: "select",
  },
];
