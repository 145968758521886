import React, { useState, useEffect } from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import HealthCareOrganizationComp from "./HealthCareOrganizationComp";
import {
  useGetHOCQuery,
  useLazyGetNewHOCQuery,
} from "../../../store/Event/EventApi";

function HealthCareOrganization() {
  const { data: HcoData, isLoading: HCOLoader } = useGetHOCQuery();

  const [trigger, { data, isLoading }] = useLazyGetNewHOCQuery();

  useEffect(() => {
    trigger({ page: 1, search: "" });
  }, [trigger]);

  return (
    <div>
      {/* <AdminLayout active={1}> */}
      {HcoData && (
        <HealthCareOrganizationComp
          Data={data?.data?.hcos}
          isLoading={HCOLoader}
          pagination={data?.data?.pagination}
          setPage={trigger}
          total={data?.data?.total}
        />
      )}
      {/* </AdminLayout> */}
    </div>
  );
}

export default HealthCareOrganization;
