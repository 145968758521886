import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import DepartmentComp from "./DepartmentComp";

function Department() {
  return (
    <AdminLayout active={1}>
      <DepartmentComp />
    </AdminLayout>
  );
}

export default Department;
