import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import NewHealthCareMedRepComp from "./NewHealthCareMedRepComp";

function NewHealthCareMedRep() {
  return (
    <div>
      <AdminLayout active={1}>
        <NewHealthCareMedRepComp />
      </AdminLayout>
    </div>
  );
}

export default NewHealthCareMedRep;
