import React, { useEffect, useState } from "react";
import MediaComp from "./Media";
import ModalComp from "../../Components/Ui/ModalComp";
import MediaModal from "./MediaModal";
import {
  useGetMediaQuery,
  useLazyGetNewMediaQuery,
} from "../../store/Media/MediaApi";

export default function Media() {
  const [showModal, setShowModal] = useState(false);
  // const { data, isLoading, refetch } = useGetMediaQuery();
  const [trigger, { data, isLoading, refetch }] = useLazyGetNewMediaQuery();

  useEffect(() => {
    trigger({ page: 1 });
  }, []);

  return (
    <>
      {/* <ButtonComp onClick={() => setShowModal(true)} text={"Show"} /> */}
      <ModalComp
        handleClose={() => setShowModal(false)}
        show={showModal}
        bodyComponents={<MediaModal setShowModal={setShowModal} />}
      />
      {data && <MediaComp
        refetch={trigger}
        addMedia={() => setShowModal(true)}
        Medias={data?.data?.folders}
        pagination={data?.data?.pagination}
        setPage={trigger}
      />}
    </>
  );
}
