import { REGEX_PATTERNS } from "../../../../utils/constants/errors";
import { LanguageName } from "../../../../utils/helper";

export const FormLabel=[
   
    {
        name:'currentPassword',
        label:'Current Password',
        className:'col-lg-12',
        type:'password',
        placeholder:'Enter Current Password'
    },
    {
        name:'newPassword',
        label:'New Password',
        className:'col-lg-12',
        type:'password',
        placeholder:'Enter New Password'
    },
    {
        name:'confirmNewPassword',
        label:'Confirm New Password',
        className:'col-lg-12',
        type:'password',
        placeholder:'Confirm New Password'
    },

    
]

export const FormLabelII=()=>{
    return [
        {
            name:'currentPassword',
            label:LanguageName('CC'),
            className:'col-lg-12',
            type:'password',
            placeholder:'Enter Current Password'
        },
        {
            name:'newPassword',
            label:LanguageName('NP'),
            className:'col-lg-12',
            type:'password',
            placeholder:'Enter New Password'
        },
        {
            name:'confirmNewPassword',
            label:LanguageName('T'),
            className:'col-lg-12',
            type:'password',
            placeholder:'Confirm New Password'
        },
    
    
    ]
}