import React, { useMemo } from "react";
import {
  useCreatePlanMutation,
  useGetMedRepDetailsHCpQuery,
  useGetMedRepDetailsQuery,
  useGetNewMedRepDetailsHCpQuery,
  useGetPlansQuery,
  useGetSinglePlanQuery,
} from "../../../../store/Plans/PlansApi";
import { useParams, useNavigate } from "react-router-dom";
import ButtonComp from "../../../../Components/Ui/Button";
import { toast } from "react-toastify";
import { hcpExtraction, LanguageName, storage } from "../../../../utils/helper";
import NewFormUI from "../../../../Components/Ui/NewFormUi";
import { useForm } from "react-hook-form";
import AboutMedRep from "../submodules/AboutMedRep";

import Country from "../../../../country.json";
import { useEffect } from "react";
import useMultiDateSelect from "../../../../Components/Ui/UseMultiDateSelect";
import { useGetHCPQuery } from "../../../../store/Schedules/SchedulesApi";
import { ImageComponent } from "../../../../Components/Common/FallbackImage";
import ModalComp from "../../../../Components/Ui/ModalComp";
import { useGetMedRepQuery } from "../../../../store/MedRep/MedRepApi";

export default function AddPlan() {
  const companyIdReal = storage["localStorage"].get("companyId")?._id;
  // 64cbb8f1af9faf444f0cc5d4
  const [createPlan, { isLoading: HCPLoader }] = useCreatePlanMutation();
  const [showModal, setShowModal] = React.useState(false);
  const { data: HCPData, isLoading: loading } = useGetHCPQuery();
  // const { data: MedRepHCP, isLoading: loading } = useGetMedRepDetailsHCpQuery(
  //   companyIdReal,
  //   {
  //     skip: !companyIdReal,
  //   },
  // );
  const {
    datePickerComponent,
    selectedDates,
    setSelectedDates,
    firstMonthName,
    inactiveDays,
    days,
    callsPerDay,
  } = useMultiDateSelect();
  const { plan_id } = useParams();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, setValue, setError } = useForm({
    defaultValues: {
      category: "",
      email: "",
      lastName: "",
      phone: "",
      address: "",
      institutionName: "",
      country: "",
      state: "Oyo",
      image: "",
      companyId: "",
    },
  });
  const [selectedMedRep, setSelectedMedRep] = React.useState(null);
  const { data, isLoading } = useGetSinglePlanQuery(plan_id, {
    skip: !plan_id,
  });
  const { data: PlansData, isLoading: PDLoader } = useGetPlansQuery();

  const companyIde = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;
  const { data: MedRepData, isLoading: MDLoader } = useGetMedRepQuery(
    companyIde,
    {
      skip: !companyIde,
    }
  );

  // const {
  //   data: MedRepDetail,
  //   isLoading: GMDLoader,
  //   refetch,
  // } = useGetMedRepDetailsQuery(selectedMedRep?.medrepId, {
  //   skip: !selectedMedRep,
  // });

  // const {
  //   data: MedRepDetail,
  //   isLoading: GMDLoader,
  //   refetch,
  // } = useGetMedRepDetailsHCpQuery(selectedMedRep?.medrepId, {
  //   skip: !selectedMedRep,
  // });

  const {
    data: MedRepDetail,
    isLoading: GMDLoader,
    refetch,
  } = useGetNewMedRepDetailsHCpQuery(selectedMedRep?.medrepId, {
    skip: !selectedMedRep,
  });

  const Plan = data?.data?.plan;

  const [selectedHCP, setSelectedHCP] = React.useState(null);
  const [addNewPlan, setAddNewPlan] = React.useState(false);

  const [drafts, setDrafts] = React.useState([]);

  const savedHCPData = storage["localStorage"].get("planHcpEditData");

  useEffect(() => {
    if (selectedMedRep) {
      refetch();
      // console.log("MedRep hcsps", MedRepDetail);
      // console.log("selected Medrep", selectedMedRep);
    }
  }, [MedRepDetail, selectedMedRep]);

  useEffect(() => {
    setValue("category", [{ label: Plan?.category, value: Plan?.category }]);
    setValue("institutionName", Plan?.institutionName);
    setValue("institutionDepartment", Plan?.institutionDepartment);
    setValue("location", Plan?.location);
    setValue("status", Plan?.status);
  }, [Plan?._id]);

  useEffect(() => {
    console.log(savedHCPData, "savedHCPData");
    console.log(selectedHCP, "selected");
    if (!addNewPlan && savedHCPData) {
      setSelectedHCP(savedHCPData);
    }
    if (addNewPlan) {
      setSelectedHCP(null);
    }
  }, [addNewPlan, selectedHCP]);

  const transformedArray = useMemo(() => {
    return Country.map((item) => ({
      label: item.name, // replace with the actual property you want as label
      value: item.name,
      id: item?.id, // replace with the actual property you want as value
    }));
  }, [Country]);

  const AddPlanForm = [
    {
      name: "HealthCarePractitioner",
      label: LanguageName("AMRF"),
      type: "select",
      label2: selectedMedRep
        ? selectedMedRep?.firstName + " " + selectedMedRep?.lastName
        : "",
      value: selectedMedRep ? selectedMedRep?.medrepId : "",
      placeholder: "Select Medical Representative",
      options: MedRepData?.data?.medreps
        ?.filter((item) => item.hcpsNum > 0)
        ?.map((item) => ({
          value: item?.medrepId,
          label: item?.firstName + " " + item?.lastName,
          full: item,
          type: "medRep",
        })),
    },
    {
      name: "HealthCarePractitioner",
      label: LanguageName("SHCP"),
      type: "select",
      label2: selectedHCP
        ? selectedHCP?.firstName + " " + selectedHCP?.lastName
        : "",
      value: selectedHCP ? selectedHCP?._id : "",
      placeholder: LanguageName("SHCP"),
      disabled: MedRepDetail?.data?.hcps,
      options:
        Array.isArray(MedRepDetail?.data?.hcps) &&
        MedRepDetail?.data?.hcps?.map((item) => ({
          value: item?._id,
          label: item?.firstName + " " + item?.lastName,
          full: item,
          type: "hcp",
        })),
    },
    {
      name: "department",
      label: LanguageName("Department"),
      type: "input",
      value: selectedHCP ? selectedHCP?.department : "",
      placeholder: "Select Department",
    },
    {
      name: "institution",
      label: "Institution",
      type: "input",
      placeholder: "Select Institution",
      value: selectedHCP ? selectedHCP?.hco?.institutionName : "",
    },
    {
      name: "data",
      label: LanguageName("SD"),
      type: "multiDate",
      component: datePickerComponent,
      placeholder: "",
    },
  ];

  const handleOnSubmit = async () => {
    // TODO: handle errors
    if (drafts.length === 0) {
      return;
    }

    const planData = drafts?.map((item) => {
      console.log(item);
      return {
        hcp: item.hcpId,
        category: item?.category,
        location: item.location,
        country: item.country,
        company: item.company,
        medRep: item.medRepId,
        institutionName: item.institution,
        institutionDepartment: item.department,
        targets: {
          date: item.selectedDates[0],
          callsPerDay: item.callsPerDay,
          callsPerMonth: item.callsPerMonth,
          inactiveDays: item.inactiveDays,
        },
        selectedDates: item.selectedDates,
      };
    });

    console.log("finale plans", planData);

    // setLoading(true);
    try {
      const response = await createPlan({
        plans: planData,
      });
      console.log(response, "create plan response");
      if (!response?.data?.status) {
        toast.error(response?.data?.message);
        return;
      }
      setShowModal(true);
      // setLoading(false);
    } catch (error) {
      console.log(error, "create plan error");
      // setLoading(false);
    }
  };

  return (
    <div className="bg-[#FCFCFC]">
      <div className="flex bg-white h-full justify-content-between items-center">
        <div className="flex w-full lg:flex-row  md:flex-row flex-col justify-content-between items-center pb-[20px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem] w-full">
            <div
              onClick={() => {
                navigate(-1);
              }}
              className="flex w-[200px] items-center mr-10 cursor-pointer"
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.711914"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="3.5"
                  fill="white"
                />
                <rect
                  x="0.711914"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="3.5"
                  stroke="#E4E7EC"
                />
                <path
                  d="M7.35836 12.3536C7.1631 12.1583 7.1631 11.8417 7.35836 11.6464L9.35836 9.64645C9.55362 9.45118 9.87021 9.45118 10.0655 9.64645C10.2607 9.84171 10.2607 10.1583 10.0655 10.3536L8.91902 11.5L16.7119 11.5C16.9881 11.5 17.2119 11.7239 17.2119 12C17.2119 12.2761 16.9881 12.5 16.7119 12.5L8.91902 12.5L10.0655 13.6464C10.2607 13.8417 10.2607 14.1583 10.0655 14.3536C9.87021 14.5488 9.55362 14.5488 9.35836 14.3536L7.35836 12.3536Z"
                  fill="black"
                />
              </svg>
              <p className="font-[500] ml-3 text-[14px] font-Inter text-[#AD3307]">
                Go Back
              </p>
            </div>
            <div className="flex justify-start items-center w-full">
              <div>
                <div className="text-[25px] font-[700] mr-5">Plan</div>
                {/* <div className="text-[#777777] text-[14px]"> */}
                {/*   {LanguageName("MR")}/{LanguageName("Institutions")}/ */}
                {/*   {LanguageName("CPN")} */}
                {/* </div> */}
              </div>
              <div className="flex space-x-3 bg-[#fcfaff] border-[1px] border-[#ebebeb] rounded align-items-center lg:w-[50%] md:w-100 w-[62%] py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
                <div className="ps-2 pe-3">
                  <img className="" src="/icon/search.png" alt="search" />
                </div>

                <input
                  className="w-100 text-[16px] font-[400] bg-[#fcfaff]"
                  type="text"
                  // placeholder={LanguageName("SCR")}
                  placeholder={LanguageName("SearchPlans")}
                />
              </div>
            </div>
            <div className="ms-2"></div>
          </div>
        </div>
        <div className="flex w-[300px] items-center lg:flex-row md:flex-row flex-col justify-content-between">
          <ButtonComp
            // onClick={() => navigate("/plan/summary")}
            onClick={handleOnSubmit}
            loader={HCPLoader}
            className="text-white bg-[#3DA84A] rounded-[5px] font-[500] md:text-[18px] text-[14px] px-[8px] py-[8px] md:px-[27px] md:py-[10px]"
            // text={LanguageName("proceedToSubmit")}
            text={`${LanguageName("SAS")}`}
          />
        </div>
      </div>
      <div className="border"></div>

      <div className="flex flex-col-reverse md:flex-row md:justify-between mt-4">
        <div className="md:col-8 md:mr-4 w-full py-4 px-5 border-r">
          <div className="p-5 bg-white h-[100vh] ">
            <div className="flex justify-between items-center">
              <div className="w-full">
                <div className="text-[#242731] font-[700] text-[28px]">
                  {LanguageName("createNewPlan")}
                </div>
                <div className="font-[500] text-[18px] text-[#333333] mb-5">
                  {LanguageName("CPFormInfo")}
                </div>
                <div className="border border-[#4F008A1A] rounded-[12px] py-4 px-5">
                  <div className="text-black font-[500] text-[16px] mb-5">
                    {LanguageName("CPKFormInfo")}
                  </div>
                  <NewFormUI
                    containerClassName="mb-4 bg-[#FCFAFF] border border-[#EDE6F5] rounded-[8px]"
                    setSelectedHCP={setSelectedHCP}
                    setSelectedMedRep={setSelectedMedRep}
                    wrapperClassName={"mb-8"}
                    formData={AddPlanForm}
                    control={control}
                  />
                </div>
              </div>
            </div>
            <div className="col-6" />
            <div className="flex align-items-center mt-5">
              <div
                onClick={() => {
                  if (!selectedHCP || !selectedMedRep) {
                    return toast.error("Please select HCP and MedRep");
                  }
                  if (selectedDates.length === 0) {
                    return toast.error("Please select dates");
                  }
                  console.log(selectedHCP, "hcp selecet");
                  //prevent duplicate draft
                  if (drafts.length > 0) {
                    const isDraftExist = drafts.find(
                      (draft) =>
                        draft.hcpId === selectedHCP?._id &&
                        draft.medRepId === selectedMedRep?.medrepId &&
                        draft.selectedDates === selectedDates
                    );
                    if (isDraftExist) {
                      return toast.error("Draft already exist");
                    }
                  }

                  if (selectedMedRep?.draftIndex !== null) {
                    const newDrafts = drafts.filter(
                      (draft, index) => index !== selectedMedRep?.draftIndex
                    );
                    setDrafts(newDrafts);
                  }
                  setDrafts((prev) => [
                    ...prev,
                    {
                      id: drafts.length + 1,
                      medRepId: selectedMedRep?.medrepId,
                      hcpId: selectedHCP?._id,
                      location: selectedHCP?.hco?.state,
                      country: selectedHCP?.hco?.country,
                      // category: selectedHCP?.category,
                      category: "HCP",
                      company: selectedHCP?.company,
                      medRep:
                        selectedMedRep?.firstName +
                        " " +
                        selectedMedRep?.lastName,
                      callsPerDay: selectedDates.length,
                      callsPerMonth: selectedDates.length,
                      inactiveDays: inactiveDays,
                      hcp:
                        selectedHCP?.title +
                        " " +
                        selectedHCP?.firstName +
                        " " +
                        selectedHCP?.lastName,
                      institution: selectedHCP?.hco?.institutionName,
                      department: selectedHCP?.department,
                      selectedDates: selectedDates,
                      days: days,
                      firstMonth: firstMonthName,
                    },
                  ]);
                  toast.success("Draft saved Successfully...");
                  setSelectedHCP(null);
                  setSelectedMedRep(null);
                  setSelectedDates([]);
                }}
                className="cursor-pointer flex align-items-center  border rounded-[50px] border-[#8354CF] lg:mb-0 md:mb-0 mb-[1rem] px-4 py-3 lg:me-2 md:me-2 me-0"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.2998 9.09961V14.4996L9.0998 12.6996"
                    stroke="#4F008A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.3 14.5002L5.5 12.7002"
                    stroke="#4F008A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M19 8.2V12.7C19 17.2 17.2 19 12.7 19H7.3C2.8 19 1 17.2 1 12.7V7.3C1 2.8 2.8 1 7.3 1H11.8"
                    stroke="#4F008A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M18.9998 8.2H15.3998C12.6998 8.2 11.7998 7.3 11.7998 4.6V1L18.9998 8.2Z"
                    stroke="#4F008A"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <ButtonComp
                  className="ml-3 text-[#282728] font-[500] text-[16px] "
                  text={LanguageName("STDrafts")}
                />
              </div>
              <div
                onClick={() => {
                  // storage["localStorage"].set("planHcpEditData", selectedHCP);
                  // toast.success("Draft saved Successfully...");
                  setAddNewPlan(true);
                  setSelectedHCP(null);
                  setSelectedMedRep(null);
                  setSelectedDates([]);
                }}
                className="cursor-pointer flex ml-4 align-items-center rounded-[50px] bg-[#4F008A] border-1 border-[#4F008A] lg:mb-0 md:mb-0 mb-[1rem] px-4 py-3 lg:me-2 md:me-2 me-0"
              >
                <ButtonComp
                  className="text-[#ffffff] font-[500] text-[16px] "
                  text={LanguageName("ANPL")}
                />
              </div>
            </div>
          </div>
          <div className="h-[100px]" />
        </div>
        <div className="md:col-4 w-full md:w-[700px] py-4 px-3">
          <p className="text-[#242731] font-[700] text-[20px]">
            {LanguageName("Drafts")}
          </p>
          <p className="text-[16px] font-[500] text-[#333333] mb-4">
            {LanguageName("VSPBS")}
          </p>

          {drafts.length > 0 ? (
            drafts.map((draft, index) => {
              return (
                <div className="border border-[#EBEBEB] bg-white rounded-[8px] p-4 mb-4">
                  <div className="flex items-center text-[#589E67] text-[14px] font-[500] justify-between mb-4">
                    <p>{LanguageName("newPlan")}</p>
                    <div className="flex items-center">
                      <p className="mr-4">{draft?.firstMonth}</p>
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setSelectedMedRep({
                            draftIndex: index,
                            _id: draft.medRepId,
                            firstName: draft.medRep.split(" ")[0],
                            lastName: draft.medRep.split(" ")[1],
                          });

                          setSelectedHCP({
                            _id: draft.hcpId,
                            title: draft.hcp.split(" ")[0],
                            firstName: draft.hcp.split(" ")[1],
                            lastName: draft.hcp.split(" ")[2],
                            company: draft?.company,
                            state: draft?.location,
                            country: draft.country,
                            institutionName: draft.institution,
                            department: draft?.department,
                            targets: {
                              callsPerDay: draft.callsPerDay,
                              callsPerMonth: draft.callsPerMonth,
                              inactiveDays: draft.inactiveDays,
                            },
                            selectedDates: draft.selectedDates,
                            days: draft.days,
                          });
                          setSelectedDates(draft.selectedDates || []);
                        }}
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="28" height="28" rx="14" fill="#F9F9F9" />
                          <path
                            d="M9.33236 18.5924H10.2752L16.4843 12.3832L15.5415 11.4404L9.33236 17.6496V18.5924ZM19.999 19.9257H7.99902V17.0972L16.9557 8.14057C17.2161 7.88023 17.6382 7.88023 17.8985 8.14057L19.7842 10.0262C20.0445 10.2865 20.0445 10.7087 19.7842 10.969L12.1608 18.5924H19.999V19.9257ZM16.4843 10.4976L17.4271 11.4404L18.3699 10.4976L17.4271 9.55479L16.4843 10.4976Z"
                            fill="#555555"
                          />
                        </svg>
                      </div>
                      <div className="w-2" />
                      <div
                        onClick={() => {
                          setDrafts((prev) => {
                            const newDrafts = [...prev];
                            newDrafts.splice(index, 1);
                            return newDrafts;
                          });
                        }}
                        className="cursor-pointer"
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect width="28" height="28" rx="14" fill="#F9F9F9" />
                          <path
                            d="M10.9879 8.98568V7.65234H17.6545V8.98568H20.9879V10.319H19.6545V20.319C19.6545 20.6872 19.3561 20.9857 18.9879 20.9857H9.65454C9.28635 20.9857 8.98787 20.6872 8.98787 20.319V10.319H7.65454V8.98568H10.9879ZM10.3212 10.319V19.6523H18.3212V10.319H10.3212ZM12.3212 12.319H13.6545V17.6523H12.3212V12.319ZM14.9879 12.319H16.3212V17.6523H14.9879V12.319Z"
                            fill="#D55F5A"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex items-center justify-between mb-3">
                      <div className="flex flex-col items-start w-full">
                        <p className="text-[11px] text-[#777777]">
                          {LanguageName("MRIVE")}
                        </p>
                        <p className="text-[16px] font-[500] text-[#333333]">
                          {draft?.medRep}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[50%]">
                        <p className="text-[11px] text-[#777777]">
                          Calls per Day
                        </p>
                        <p className="text-[16px] font-[500] text-[#333333]">
                          {draft?.callsPerDay}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-3">
                      <div className="flex flex-col items-start w-full">
                        <p className="text-[11px] text-[#777777]">
                          Healthcare Practitioner
                        </p>
                        <p className="text-[16px] font-[500] text-[#333333]">
                          {draft?.hcp}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[50%]">
                        <p className="text-[11px] text-[#777777]">
                          Calls per Month
                        </p>
                        <p className="text-[16px] font-[500] text-[#333333]">
                          {draft?.callsPerMonth}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mb-3">
                      <div className="flex flex-col items-start w-full">
                        <p className="text-[11px] text-[#777777]">
                          Institution
                        </p>
                        <p className="text-[16px] font-[500] text-[#333333]">
                          {draft?.institution}
                        </p>
                      </div>
                      <div className="flex flex-col items-start w-[50%]">
                        <p className="text-[11px] text-[#777777]">
                          Inactive Days
                        </p>
                        <p className="text-[16px] font-[500] text-[#333333]">
                          {draft?.inactiveDays}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className="text-[11px] text-[#777777]">Days Selected</p>
                    <div className="flex w-[360px] overflow-x-auto pb-4 items-center justify-content-start">
                      {draft?.days?.map((item, index) => (
                        <span
                          key={index}
                          className="rounded-[6px] mr-4 border-[#EBEBEB] text-[#777777] text-[14px] font-[500] p-[20px] border"
                        >
                          {item}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="w-full h-[50%] flex  flex-col justify-center items-center">
              <svg
                width="150"
                height="150"
                viewBox="0 0 150 150"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M75 137.5C109.5 137.5 137.5 109.5 137.5 75C137.5 40.5 109.5 12.5 75 12.5C40.5 12.5 12.5 40.5 12.5 75C12.5 109.5 40.5 137.5 75 137.5Z"
                  stroke="#CECDCE"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M118.127 31.25L30.6274 118.75"
                  stroke="#CECDCE"
                  stroke-width="3"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <p className="text-[#cecdce] mt-4 font-[500] text-[24px] font-[Instrument Sans]">
                No Date Added
              </p>
            </div>
          )}
        </div>
      </div>
      <ModalComp
        show={showModal}
        handleClose={() => setShowModal(false)}
        bodyComponents={
          <div className="flex flex-col items-center py-5">
            <div className="flex justify-center mb-4">
              <ImageComponent src="/icon/timer.svg" width={70} />
            </div>
            <div>
              <div className="text-center text-[#282728] text-[24px] font-semibold mb-3">
                Plan Created Successfully
              </div>
              {/* <p className="text-center text-[#333333] text-[14px] self-center px-[15%] mb-4">
                Le client est très important, le client sera suivi par le
                client. Le bateau flatte, l'élite à eleifend pulvinar, ex ex
                ultris sem, un vestibulum dolor
              </p> */}
              <div className="flex w-full justify-center">
                <ButtonComp
                  className="bg-[#A162F71A] px-4 text-[#A162F7] font-[500] text-[18px] py-2 rounded-[5px]"
                  // onClick={handleResendPassword}
                  // loader={resendLoader}
                  onClick={() => navigate("/plan_overview")}
                  text={LanguageName("GH")}
                />
                <div className="w-4" />
                <ButtonComp
                  className="text-white px-4 bg-[#A162F7] font-[500] text-[18px] py-2 rounded-[5px]"
                  // onClick={handleResendPassword}
                  onClick={() => navigate("/plan/summary")}
                  // loader={resendLoader}
                  text={LanguageName("VP")}
                />
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}
