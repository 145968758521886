import { Dropdown } from "react-bootstrap";
import { RiDownloadCloud2Fill } from "react-icons/ri";

export default function DropDownComp({
  dropDownText,
  dropDownTextClassName,
  options = [],
  row = {},
  icon = null,
  // noDivider=true
}) {
  return (
    <Dropdown className={`border-none ${dropDownTextClassName} mb-0 pb-0`}>
      <Dropdown.Toggle variant="" className="border-0 m-0 flex items-center gap-2">
        {dropDownText}
        <span>{icon}</span>
      </Dropdown.Toggle>

      {/* <Dropdown.Menu></Dropdown.Menu> */}
      {/* <Dropdown.Menu>
        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
      </Dropdown.Menu> */}
      <Dropdown.Menu className="">
        {Array.isArray(options) &&
          options?.map((item, i) => (
            <span key={i}>
              <Dropdown.Item
                className="text-[14px] flex items-center gap-2 mb-1"
                onClick={() => {
                  console.log(row);
                  item?.action && item?.action(row);
                }}
              >
                <span className=" 7text-xs">{item?.icon}</span>
                <span
                  className={`text-base font-medium ${
                    item?.name === "Delete" ? "text-[#D63434]" : ""
                  }`}
                >
                  {item?.name || ""}
                </span>
              </Dropdown.Item>
              {options?.length === 0 && (
                <Dropdown.Divider className="bg-[#eeeaf100] border-[0.2px] border-[#EEEAF1] mb-3 opacity-5" />
              )}
            </span>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
