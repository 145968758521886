import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import PanadolEfficacyComp from "./PanadolEfficacyComp";

function PanadolEfficacy() {
  return (
    // <AdminLayout active={1}>
      <PanadolEfficacyComp />
    // </AdminLayout>
  );
}

export default PanadolEfficacy;
