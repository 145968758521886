import DataTable from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import NoData from "./NoData";
import { Spinner } from "react-bootstrap";

import React, { useState } from "react";
import { Pagination } from "./Pagination";
import { CommonPagination } from "./CommonPagination";

export function TableCustom({
  columnsComp,
  pagination,
  total,
  setPage,
  Data = [],
  sortId = 1,
  loader = false,
  customStylesI,
  searchInput,
  title,
}) {
  // States for pagination
  const { current, perPage, number_of_pages, next } = pagination;

  const hasRows = Data && Data.length > 0;

  const conditionalRowStyles = hasRows ? {} : { display: "table-header-group" };

  return loader ? (
    <div className="h-[50vh] flex justify-center items-center">
      <Spinner color="#f5f0f8" />
    </div>
  ) : (
    <>
      <DataTable
        fixedHeader
        columns={columnsComp ? columnsComp : columns}
        data={Data} // Use the paginated data
        pagination={false} // Disable default pagination
        defaultSortFieldId={sortId}
        customStyles={customStylesI ? customStylesI : customStyles}
        noHeader={false}
        expandOnRowClicked={(id) => console.log(id)}
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={!loader && <NoData />}
      />
      {/* Custom Pagination Controls */}

      {title === "HealthCarePractitioner" ? (
        <CommonPagination
          current={current}
          perPage={perPage}
          numberOfPages={number_of_pages}
          next={next}
          setPage={setPage}
          searchInput={searchInput}
        />
      ) : (
        <Pagination
          current={current}
          numberOfPages={number_of_pages}
          next={next}
          setPage={setPage}
        />
      )}
    </>
  );
}

// Custom Styles (same as before)
const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "30px",
      paddingRight: "30px",
      fontWeight: 500,
      color: "#1A1A1A",
      fontSize: "14px",
      whiteSpace: "pre-line",
      textTransform: "capitalize",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px",
      paddingRight: "15px",
    },
  },
  headRow: {
    style: {
      backgroundColor: "#F1F1F6",
    },
  },
};
