import React from "react";
import Pagination from "react-bootstrap/Pagination";
import { tableData } from "./Data";

export default function DepartmentComp() {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <div className="container">
      <div className="flex flex-row justify-content-between align-items-center pb-[45px]">
        <div className="flex  align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[24px] font-[600]">
            Plan{" "}
            <span className="text-[24px] text-[#777777] font-[500]">
              (His Grace Pharmacy)
            </span>
          </div>
        </div>
        <div className="ms-2">
          <img src="/Plan/back-icon.svg" alt="" />
        </div>
      </div>
      <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
        <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
          <div className="border-r-2 ps-2 pe-3">
            <img className="" src="/icon/search.png" alt="search" />
          </div>

          <input
            className="w-100 text-[16px] font-[400]"
            type="text"
            placeholder="Search for Call Reports by ID, Name, Date, HCOs"
          />
        </div>
      </div>
      <div className="flex md:flex-col lg:flex-row flex-col lg:mb-[62px] mb-[34px]">
        <div className=" p-[10px]  border-b-2  pe-3">
          <div className="text-[16px] text-[#000000] font-[400]">Med Rep</div>
        </div>
        <div className=" p-[10px] border-b-2 !border-[#A162F7] pe-3">
          <div className="text-[16px] text-[#A162F7]  font-[400]">
            Department
          </div>
        </div>
        <div className=" p-[10px]  border-b-2  pe-3">
          <div className="text-[16px] text-[#000000] font-[400]">
            Cycle Plan
          </div>
        </div>
        <div className="w-[68%] lg:border-b-2">{""}</div>
      </div>
      <div className="table-container border border-1 rounded mb-[32px] w-full ">
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto ">
            <thead className="!bg-[#A162F71A] text-center">
              <tr className="text-[16px] font-[500] font-instrument-sans">
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem]  lg:ps-[7px] py-[14px]">
                  S/N
                </th>
                <th className="md:px-[1rem] px-[1rem]  text-center lg:px-[0rem] py-[14px] border-r">
                  Departments
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px] border-r">
                  Calls Per Target
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px] border-r">
                  Calls Completed
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px] border-r">
                  Calls Remaining
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px] border-r">
                  Status
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px]">
                  Out Of Plan Visit
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((data, i) => (
                <tr
                  className="text-[16px] text-[#333333] text-center font-[400] font-instrument-sans"
                  key={i}
                >
                  <td className="px-[1rem] lg:px-0 lg:ps-[7px] py-[10px] border-b">
                    {data.Serial}
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[10px] border-b border-r">
                    {data.Practitioner}
                  </td>
                  <td className="px-[2.5rem] lg:px-[0rem] py-[10px] border-b border-r">
                    {data.Target}
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[10px] border-b border-r">
                    {data.Completed}
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[10px] border-b border-r">
                    {data.Remaining}
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] my-[18px]  border-b border-r text-[#333333] ">
                    <div className=" me-[10px] text-center py-[1px] px-[5px]">
                      {data.Status}
                    </div>
                  </td>
                  <td className="px-[3rem] lg:px-[0rem] py-[10px] border-b">
                    {data.Visit}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between align-items-baseline pt-4 pb-2 px-3">
          <div className="text-[16px] lg:mb-0 mb-[1rem] font-[500]">
            <b>9</b> out 0f <b>14</b>
          </div>
          <div className="flex space-x-2 align-items-baseline">
            <div className="text-[16px] font-[500]">Previous</div>
            <Pagination size="sm">{items}</Pagination>
            <div className="text-[16px] font-[500]">Next</div>
          </div>
        </div>
      </div>
    </div>
  );
}
