import React, { useState, useEffect, useMemo } from "react";
// import { ImageComponent } from '../../../Common/FallbackImage
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import ButtonComp from "../../../../Components/Ui/Button";
import FormUi from "../../../../Components/Ui/FormUi";
import { FormLabel } from "../Data";
import {
  useCreateMedRepMutation,
  useGetAllProfileQuery,
  useUploadFileMutation,
} from "../../../../store/User/userApi";
import { storage } from "../../../../utils/helper";
import InputComp from "../../../../Components/Ui/InputComp";
import { useCreateMedRepMainMutation } from "../../../../store/MedRep/MedRepApi";
import Country from "../../../../country.json";
import axios from "axios";

export default function AddFieldRep({
  data = [],
  edit,
  onNext,
  setShowCavans,
  refetch,
  onClose,
}) {
  const [userData, setUserData] = useState(data);
  const [state, setState] = useState();
  const [CILLoader, setCILLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [addMedRep, { isLoading }] = useCreateMedRepMainMutation();

  // const companyId = useSelector(selectCurrentUserData);
  const companyId = storage["localStorage"].get("user_details_profile")?.user
    ?.id;
  const { data: CompanyData, isLoading: CLoader } = useGetAllProfileQuery(
    companyId,
    {
      skip: !companyId,
    },
  );
  const navigate = useNavigate();

  const transformedArray = useMemo(() => {
    return Country.map((item) => ({
      label: item.name, // replace with the actual property you want as label
      value: item.name,
      id: item?.id, // replace with the actual property you want as value
    }));
  }, [Country]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    handleSubmit && handleSubmit(userData);
    // console.log(e.target)
  };

  const { control, handleSubmit, reset, watch, setValue, setError } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      phoneNumber: "",
      state: "",
      country: "",
      image: "",
    },
  });

  const transformedArrayState = useMemo(() => {
    return watch("country")?.id
      ? Country.find((item) => item?.id === watch("country")?.id)?.state?.map(
          (item) => ({
            label: item.name, // replace with the actual property you want as label
            value: item.name,
            id: item?.id, // replace with the actual property you want as value
          }),
        )
      : [];
  }, [watch("country"), Country]);

  // console.log(transformedArrayState,'Country')

  // const handleFileInputChange = async (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     const response = await convertImageToLink(formData);
  //     if (response?.data) {
  //       const imageUrl = URL.createObjectURL(file);
  //       setSelectedImage(imageUrl);
  //       setValue("image", response?.data?.file_link);
  //     } else {
  //       toast.error("Please Check your image format");
  //     }
  //     //  console.log(response?.data?.file_link,'response')
  //   }
  // };
  const handleChange = async (file) => {
    setCILLoader(true);
    const formData = new FormData();
    formData.append("file", file);

    // Retrieve the token from local storage
    const token = storage["localStorage"].get("user_details_profile")?.token;

    // Create headers object
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    // If the token exists, add it to the headers
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}/file/upload`,
        data: formData,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
          // Update UI with progress
          // setProgress(progress);
          console.log(`Upload progress: ${progress}%`);
        },
      });

      console.log("response", response);
      if (response) {
        setSelectedImage(response?.data?.data?.file_link);
        setCILLoader(false);
      } else {
        console.error("Invalid response format. Missing file_link.");
        setCILLoader(false);
      }
    } catch (error) {
      // Handle the error
      console.error("Upload failed:", error);
      setCILLoader(false);
    }
  };

  const handleOnSubmit = async (values) => {
    const response = await addMedRep({
      ...values,
      companyId: CompanyData?.data?.company?._id,
      state: [values?.state],
      lgas: [values?.lgas],
      country: values?.country?.label,
      state: [values?.state?.label],
      image: selectedImage,
    });
    // console.log(response, "value");

    if (response?.error) {
      toast.error(response?.error?.data?.message);
    }
    if (response?.data) {
      if (!response?.data?.status) {
        return toast.error(response?.data?.message);
      }
      toast.success("Med Rep was successfully added");
      reset();
      onClose();
    }
  };

  // console.log(Country?.find((item)=>item?.name === values?.country)?.state?.find((item)=>item?.name === values?.state)?.city,values?.state,'values')

  return (
    <>
      {
        <div>
          <div className="text-[30px] font-semibold mt-3 mb-5 ml-5">
            Add Med Rep
          </div>
          <div className="border-[rgba(79, 0, 138, 0.1)] border-[1px] py-[50px] pl-[20px]  xl:pl-[30px] pr-[20px] xl:pr[0px] flex-1 mb-[30px]">
            <div className="row gy-4">
              <FormUi
                formData={FormLabel(transformedArray, transformedArrayState)}
                // la={item?.label}
                control={control}
                containerClassName={"h-[35px] bg-[#FCFAFF] border-1"}
                labelClassName="!text-[14px] mb-1 text-[#4F008A]"
              />
              <InputComp
                label={"Profile Image"}
                // containerClassName={item}
                // value={value}
                onChange={(e) => handleChange(e.target.files[0])}
                // error={errors[item?.name]?.message}
                type={"file"}
                accept={"image/*"}
              />
              <div className="row  mb-[64px] gap-[15px] justify-center mt-5">
                <div className="col-5 ">
                  <ButtonComp
                    text={"Cancel"}
                    // onClick={()=>setShowModal()}
                    btnClassName={
                      "text-[14px] lg:text-[16px] w-full  py-[5px] lg:py-[13px]  px-[20px] lg:px-[34px] border-[0.64px] rounded-full border-[#D63434] text-[#D63434]"
                    }
                  />
                </div>
                <div className="col-5">
                  <ButtonComp
                    disabled={isLoading || CILLoader}
                    loader={isLoading || CILLoader}
                    type="submit"
                    onClick={handleSubmit(handleOnSubmit)}
                    btnClassName={
                      "text-[14px] lg:text-[16px] w-full py-[5px] lg:py-[13px] px-[25px] lg:px-[47px] border-[0.64px] rounded-full border-[#3DA84A] text-[#fff] bg-[#3DA84A]"
                    }
                    text={"Proceed"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div></div>
    </>
  );
}
