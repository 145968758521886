import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const SchedulesApi = createApi({
  reducerPath: "SchedulesApi",
  baseQuery: baseQuery,
  tagTypes: ["hcp"],
  endpoints: (builder) => ({
    getSchedules: builder.query({
      query: () => ({
        url: `/admin/company/schedules`,
        method: "GET",
      }),
    }),
    getMedRep: builder.query({
      query: () => ({
        url: `/admin/company/${companyId}/med-reps`,
        method: "GET",
      }),
    }),
    getHCP: builder.query({
      query: (payload = 1) => ({
        url: `/hcp/company?page=${payload}`,
        method: "GET",
      }),
      providesTags: ["hcp"],
    }),
    getNewHCP: builder.query({
      query: ({ page, search }) => ({
        url: `/hcp/company?page=${page}&search=${search}`,
        method: "GET",
      }),
      providesTags: ["hcp"],
    }),
    getHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: "GET",
      }),
    }),
    getNewHcpSchedules: builder.query({
      query: ({id, page, limit}) => ({
        url: `/schedules/hcp/${id}?page=${page}&limit=${limit}`,
        method: "GET",
      }),
    }),
    getActualHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/hcp/schedules/${id}`,
        method: "GET",
      }),
    }),
    getMap: builder.query({
      query: ({ medRepUserId, scheduleId }) => ({
        url: `/schedules/${medRepUserId}/checkins/`,
        method: "GET",
      }),
    }),
    getMedRepMap: builder.query({
      query: ({
        medRepUserId,
        scheduleId,
        startDate = null,
        endDate = null,
      }) => ({
        url: `/location/${medRepUserId}/all${
          startDate ? `?startDate=${startDate}` : ""
        }${startDate ? `&endDate=${endDate}` : ""}`,
        method: "GET",
      }),
    }),
    disableHcp: builder.mutation({
      query: (payload) => ({
        url: `/hcp/activate/${payload.id}`,
        method: "PUT",
        body: { deactivated: payload.status },
      }),
      invalidatesTags: ["hcp"],
      // transformResponse: (response) => response.data,
      // invalidatesTags:['getMedia']
    }),
  }),
});

//schedules/:medRepUserId/checkins/:scheduleId

export const {
  useGetSchedulesQuery,
  useLazyGetNewHcpSchedulesQuery,
  useGetHCPQuery,
  useLazyGetNewHCPQuery,
  useGetHcpSchedulesQuery,
  useGetActualHcpSchedulesQuery,
  useGetMapQuery,
  useGetMedRepMapQuery,
  useDisableHcpMutation,
} = SchedulesApi;
