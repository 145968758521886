import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const MediaApi = createApi({
  reducerPath: "MediaApi",
  baseQuery: baseQuery,
  tagTypes: ["getMedia", "MedRep"],

  endpoints: (builder) => ({
    getMedia: builder.query({
      query: () => ({
        url: `/media/folders`,
        method: "GET",
      }),
      providesTags: () => ["getMedia"],
    }),
    getNewMedia: builder.query({
      query: ({page}) => ({
        url: `/media/folders?page=${page}`,
        method: "GET",
      }),
      providesTags: () => ["getMedia"],
    }),
    createMediaFolder: builder.mutation({
      query: (payload) => ({
        url: `/media/folders`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["getMedia"],
    }),
    createSingleMediaFolder: builder.mutation({
      query: (payload) => ({
        url: `/media/folders/${payload?.id}/files`,
        method: "PATCH",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["getMedia"],
    }),
    getHCP: builder.query({
      query: () => ({
        url: `/hcp/company`,
        method: "GET",
      }),
    }),
    getHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: "GET",
      }),
    }),
    deleteFolder: builder.mutation({
      query: (id) => ({
        url: `/media/folders/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["getMedia"]
    }),
    deleteFile: builder.query({
      query: (ids) => ({
        url: `/media/folders/${ids.folderId}/files/${ids.fileId}`,
        method: "DELETE",
      }),
    }),
    deleteFolderFile: builder.mutation({
      query: (ids) => ({
        url: `/media/folders/${ids.folderId}/files/${ids.fileId}`,
        method: "DELETE",
      }),
    }),
    getSingleMedia: builder.query({
      query: (id) => ({
        url: `/media/folders/${id}`,
        method: "GET",
      }),
      providesTags: () => ["getMedia"],
    }),
    getAllMedReps: builder.query({
      query: (id) => ({
        url: `admin/company/${id}/med-reps`,
        method: "GET",
      }),
      transformResponse: (response) => response.data.medreps,
    }),
    assignMedRep: builder.mutation({
      query: (payload) => ({
        url: `medrep/folder`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags:  ["MedRep"]
    }),
    updateMedRep: builder.mutation({
      query: (payload) => ({
        url: `medrep/folder/${payload.folderId}/update`,
        method: "PATCH",
        body: { medRepId: payload.medRepId, folderId: payload.id },
      }),
      invalidatesTags:  ["MedRep"]
    }),
    getAssignedMedRep: builder.query({
      query: (folderId) => ({
        url: `medrep/folder/${folderId}`,
        method: "GET",
      }),
      transformResponse: (response) => response, // Optional, remove if unnecessary
      providesTags: () => ["MedRep"],
    }),
  }),
});

export const {
  useGetMediaQuery,
  useLazyGetNewMediaQuery,
  useCreateMediaFolderMutation,
  useDeleteFolderMutation,
  useDeleteFileQuery,
  useDeleteFolderFileMutation,
  useGetSingleMediaQuery,
  useCreateSingleMediaFolderMutation,
  useGetAllMedRepsQuery,
  useAssignMedRepMutation,
  useGetAssignedMedRepQuery,
  useUpdateMedRepMutation,
} = MediaApi;
