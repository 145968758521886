
import ButtonComp from "../../Components/Ui/Button";

export default function ConfirmUpdateModal({ setUpdateModal, save, loader }) {
  
  return (
    <div className="container">
      <h2 className="my-4 font-bold text-2xl">Assign to Medical Reps?</h2>
      <p className="font-medium text-lg mt-10 mb-7">
        Are you sure you want to assign this folder to to the selected medical
        representatives?
      </p>
      <div className="flex justify-center gap-3">
        <ButtonComp
          text={"Cancel"}
          btnClassName={
            "border-2 border-[#D63434] w-32 mt-3 rounded text-[#D63434] h-10"
          }
          onClick={() => setUpdateModal(false)}
        />{" "}
        <ButtonComp
          text={"Yes, I'm sure"}
          btnClassName={
            "bg-[#3DA84A] w-32 mt-3 mr-5 rounded text-white h-10"
          }
          loader={loader}
          disabled={loader}
          onClick={save}
        />{" "}
      </div>
    </div>
  );
}
