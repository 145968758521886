import React, { useState } from "react";
import AdminLayout from "../../Layout/AdminLayout";
import OffCanvasComp from "../../Components/Ui/OffCanvas";
import ButtonComp from "../../Components/Ui/Button";
import CallReportsComp from "./CallReportsComp";
import CallReportModalMessages from "./CallReportModalMessages";
import {
  useApproveReportMutation,
  useGetCallReportQuery,
  useGetSingleCallReportQuery,
} from "../../store/CallReport/CallReportApi";
import ModalComp from "../../Components/Ui/ModalComp";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectCurrentUserData } from "../../store/auth";
import { storage } from "../../utils/helper";
import { useGetAllProfileQuery } from "../../store/User/userApi";
// import toast from "react-hot-toast";

function CallReports() {
  const getUser = useSelector(selectCurrentUserData);
  const [userData, setUserData] = useState({});
  // const [sin]
  // console.log(getUser,'getUser')
  // const companyId = storage["localStorage"].get("user_details_profile")?.user
  //   ?.id;
  const companyId = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;
  const companyIdReal = storage["localStorage"].get("companyId")?._id;
  const { data: CompanyData, isLoading: CLoader } = useGetAllProfileQuery(
    companyId,
    {
      // skip: !companyId,
    }
  );
  const { data: allCallReport, isLoading: CRloader } = useGetCallReportQuery(
    companyId,
    {
      skip: !companyId,
    }
  );

  console.log(userData, "user Data niyii");

  const { data: singleCallReport, isLoading: CRLoading } =
    useGetSingleCallReportQuery(userData?._id, {
      skip: !userData?._id,
    });

  // console.log(companyId, "company ID");
  console.log(singleCallReport, "singleCallReport");
  console.log(allCallReport, "All call report");
  // console.log(userData, "UserData");

  const [approveReport, { isLoading: ARLoader }] = useApproveReportMutation();
  const [showOffCanvas, setOffCanvas] = useState(false);

  const [modal, setModal] = useState(false);

  const handleApproveCallReport = async (id) => {
    console.log(id);
    const response = await approveReport(id);

    if (response.data) {
      toast.success(response?.data?.message);
      setModal(false);
    }
    // console.log(response?.data?.message)
  };
  return (
    <>
      {/* <ButtonComp onClick={() => setOffCanvas(true)} text={"Show"} /> */}
      <OffCanvasComp
        handleClose={() => setOffCanvas(false)}
        show={showOffCanvas}
        bg="bg-white"
        bodyComponent={
          <CallReportModalMessages
            extraData={userData}
            userData={singleCallReport?.data?.callReport}
          />
        }
      />
      <CallReportsComp
        setUserData={setUserData}
        Data={allCallReport?.data?.reports || []}
        setModal={setModal}
        setOffCanvas={setOffCanvas}
        CRloader={CRloader}
      />
      <ModalComp
        show={modal}
        handleClose={() => setModal(false)}
        bodyComponents={
          <div className="py-4">
            <div className="text-[30px]  text-center mb-[30px] font-bold">
              Approve Call Report
            </div>
            <div className="text-center mb-[30px]">
              Are you sure you want to approve this?
            </div>

            <div className="flex justify-around align-items-center mt-[20px]">
              <ButtonComp
                onClick={() => setModal()}
                className="text-[#4F008A] text-[14px] font-[500] border-solid border-[0.57px] rounded border-[#8354CF73] py-[9px] lg:px-[30px] md:px-[30px] px-[20px]"
                text={"Cancel"}
              />
              <ButtonComp
                loader={ARLoader}
                className="text-[#FFFFFF] text-[14px] font-[500] border-solid border-[0.57px] rounded border-[#8354CF73] bg-[#8354CF] py-[9px] lg:px-[30px] md:px-[30px] px-[28px]"
                text={"Approve"}
                onClick={() => handleApproveCallReport(userData?._id)}
              />
            </div>
          </div>
        }
      />
    </>
  );
}

export default CallReports;
