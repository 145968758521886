import React from "react";
import HeaderII from "../../Common/HeaderII";
import MetricsCard from "./submodules/MetricsCard";

export default function DashBoardComp() {
  return (
    <div>
      <HeaderII />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
       <MetricsCard/>
       <MetricsCard/>
       <MetricsCard/>
       <MetricsCard/>
      </div>
    </div>
  );
}
