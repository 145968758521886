import React from "react";
import AdminLayout from "../../Layout/AdminLayout";
import TrackMedRepComp from "./TrackMedRepComp";

function TrackMedRep() {
  return (
    <div>
      <TrackMedRepComp />
    </div>
  );
}

export default TrackMedRep;
