import React, { useState } from "react";
import Pagination from "react-bootstrap/Pagination";
import {
  columnsData,
  columnsDataII,
  columnsDataIII,
  customStyles,
  OptionDropDownII,
  tableData,
} from "./Data";
import { ProgressBar } from "react-bootstrap";
import DropDownComp from "../../../Components/Ui/DropDown";
import { ArrowDown } from "../../../utils/svg";
import { TableComp } from "../../../Components/Common/TableComp";
import SelectCompI from "../../../Components/Ui/SelectCompI";
import DropDownIII from "../../../Components/Ui/DropDownIII";
import { useGetMedRepQuery } from "../../../store/MedRep/MedRepApi";
import AboutMedRep from "./submodules/AboutMedRep";
import { useNavigate, useParams } from "react-router-dom";
import { LanguageName, downloadCSV, storage } from "../../../utils/helper";
import { useGetPlansQuery } from "../../../store/Plans/PlansApi";
import PlanMedRepsComp from "../PlanMedReps/PlanMedRepsComp";
import InstitutionsComp from "../PlanMedReps/Instituation";
import ButtonComp from "../../../Components/Ui/Button";

export default function HisGracePharmaComp({ Data, CycleData }) {
  const navigate = useNavigate();
  const { hcp } = useParams();
  const { data: PlansData, isLoading: PDLoader } = useGetPlansQuery();
  const [plan, setPlan] = useState("Med Reps");
  // const [subTitle, setSubTitle] = useState(LanguageName("HCO"));
  const [subTitle, setSubTitle] = useState("Healthcare Practitioner");
  const companyIde = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;
  const { data: MedRepData, isLoading } = useGetMedRepQuery(companyIde, {
    skip: !companyIde,
  });

  console.log(MedRepData?.data?.medreps, "MedRepData");
  console.log("Cycle Data", CycleData);
  console.log("Plans Data", PlansData?.data);

  const TopList = [
    {
      name: LanguageName("Overview"),
      path: "/plan_overview",
    },
    {
      name: LanguageName("MR"),
      // path: <PlanMedRepsComp />,
      // path: <PlanMedRepsComp Data={PlansData?.data} />,
    },
    // {
    //   name: "Institutions",
    // path: <InstitutionsComp Data={PlansData?.data?.plans} />,
    // },
  ];
  const TopListII = [
    {
      // name: LanguageName("HCO"),
      name: "Healthcare Practitioner",
      icon: (
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.66683 0.333008C4.93045 0.333008 4.3335 0.929962 4.3335 1.66634V4.33301H1.66683C0.93045 4.33301 0.333496 4.92996 0.333496 5.66634V8.33301C0.333496 9.06939 0.93045 9.66634 1.66683 9.66634H4.3335V12.333C4.3335 13.0694 4.93045 13.6663 5.66683 13.6663H8.3335C9.06988 13.6663 9.66683 13.0694 9.66683 12.333V9.66634H12.3335C13.0699 9.66634 13.6668 9.06939 13.6668 8.33301V5.66634C13.6668 4.92996 13.0699 4.33301 12.3335 4.33301H9.66683V1.66634C9.66683 0.929961 9.06988 0.333008 8.3335 0.333008H5.66683ZM4.3335 5.66634C5.06988 5.66634 5.66683 5.06939 5.66683 4.33301V1.66634H8.3335V4.33301C8.3335 5.06939 8.93045 5.66634 9.66683 5.66634H12.3335V8.33301H9.66683C8.93045 8.33301 8.3335 8.92996 8.3335 9.66634V12.333H5.66683V9.66634C5.66683 8.92996 5.06988 8.33301 4.3335 8.33301H1.66683V5.66634H4.3335Z"
            fill="#8652CE"
          />
        </svg>
      ),
      table: (
        <TableComp
          columnsComp={columnsData()}
          Data={Data}
          customStylesI={customStyles}
        />
      ),
      // path: <Overview />,
    },
    // {
    //   name: "Departments",
    //   table: (
    //     <TableComp
    //       columnsComp={columnsDataII()}
    //       Data={Data}
    //       customStylesI={customStyles}
    //     />
    //   ),
    //   // path: <PlanMedRepsComp />,
    // },
    // columnsDataIII
    {
      name: LanguageName("CYP"),
      icon: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.00016 5.66634C8.00016 5.29815 7.70169 4.99967 7.3335 4.99967C6.96531 4.99967 6.66683 5.29815 6.66683 5.66634V8.99967C6.66683 9.36786 6.96531 9.66634 7.3335 9.66634H9.3335C9.70169 9.66634 10.0002 9.36786 10.0002 8.99967C10.0002 8.63148 9.70169 8.33301 9.3335 8.33301H8.00016V5.66634Z"
            fill="#98A2B3"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.00016 1.33301C4.31826 1.33301 1.3335 4.31778 1.3335 7.99967C1.3335 11.6816 4.31826 14.6663 8.00016 14.6663C11.6821 14.6663 14.6668 11.6816 14.6668 7.99967C14.6668 4.31778 11.6821 1.33301 8.00016 1.33301ZM2.66683 7.99967C2.66683 5.05416 5.05464 2.66634 8.00016 2.66634C10.9457 2.66634 13.3335 5.05416 13.3335 7.99967C13.3335 10.9452 10.9457 13.333 8.00016 13.333C5.05464 13.333 2.66683 10.9452 2.66683 7.99967Z"
            fill="#98A2B3"
          />
        </svg>
      ),
      path: "",
      table: (
        <TableComp
          columnsComp={columnsDataIII()}
          // Data={CycleData.map((item) => {
          //   return item;
          // })}
          Data={CycleData.flat()}
          customStylesI={customStyles}
        />
      ),
    },
  ];
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>,
    );
  }
  return (
    <div className="container bg-[#fcfcfc]">
      <div>
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[20px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem] w-full">
            <div
              onClick={() => {
                navigate(-1);
              }}
              className="flex w-[100px] items-center mr-10 cursor-pointer"
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.711914"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="3.5"
                  fill="white"
                />
                <rect
                  x="0.711914"
                  y="0.5"
                  width="23"
                  height="23"
                  rx="3.5"
                  stroke="#E4E7EC"
                />
                <path
                  d="M7.35836 12.3536C7.1631 12.1583 7.1631 11.8417 7.35836 11.6464L9.35836 9.64645C9.55362 9.45118 9.87021 9.45118 10.0655 9.64645C10.2607 9.84171 10.2607 10.1583 10.0655 10.3536L8.91902 11.5L16.7119 11.5C16.9881 11.5 17.2119 11.7239 17.2119 12C17.2119 12.2761 16.9881 12.5 16.7119 12.5L8.91902 12.5L10.0655 13.6464C10.2607 13.8417 10.2607 14.1583 10.0655 14.3536C9.87021 14.5488 9.55362 14.5488 9.35836 14.3536L7.35836 12.3536Z"
                  fill="black"
                />
              </svg>
              <p className="font-[500] ml-3 text-[14px] font-Inter text-[#AD3307]">
                Go Back
              </p>
            </div>
            <div className="flex justify-start items-center w-full">
              <div>
                <div className="text-[25px] font-[700] mr-5">
                  {LanguageName("PLAN")}
                </div>
                {/* <div className="text-[#777777] text-[14px]"> */}
                {/*   {LanguageName("MR")}/{LanguageName("Institutions")}/ */}
                {/*   {LanguageName("CPN")} */}
                {/* </div> */}
              </div>
              <div className="flex space-x-3 bg-[#fcfaff] border-[1px] border-[#ebebeb] rounded align-items-center lg:w-[50%] md:w-100 w-[62%] py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
                <div className="ps-2 pe-3">
                  <img
                    className=""
                    src="/icon/search.png"
                    alt="searc65712153h"
                  />
                </div>

                <input
                  className="w-100 text-[16px] font-[400] bg-[#fcfaff]"
                  type="text"
                  // placeholder={LanguageName("SCR")}
                  placeholder={"Search for Instintution, Department"}
                />
              </div>
            </div>
            <div className="ms-2"></div>
          </div>
        </div>

        <div className="flex justify-between items-center mb-3 border-b-[0.5px] shadow-lg flex-wrap" />
      </div>
      <div className="row">
        <div className="col-xl-9 px-4 py-2  border border-[#ebebeb] rounded-[10px]">
          <div className=" flex justify-between items-center mb-[11px]">
            <div className="text-[24px] font-[600]">
              {/* {TopListII?.find((item, i) => item?.name === subTitle)?.name} */}
              Healthcare Practitioners
            </div>
            {/* <div className="bg-white px-[10px] py-[10px] text-[18px]"> */}
            {/*   {LanguageName("Institution")}: General Hospital */}
            {/* </div> */}
            <div className="flex items-center">
              {/* <div className="flex mr-4 bg-[#F0F2F5] border border-[#D0D5DD] text-[14px] text-[#344054] font-[500] align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
                <DropDownComp
                  dropDownText={LanguageName("SB")}
                  options={OptionDropDownII()}
                />
                <div className="">
                  <img src="/icon/sort.png" alt="" />
                </div>
              </div> */}
              <div
                onClick={() => {
                  if (subTitle === "Healthcare Practitioner") {
                    downloadCSV(
                      Data,
                      "Healthcare_Practitioner_Report.csv",
                      false,
                    );
                  }
                  if (subTitle === LanguageName("CYP")) {
                    downloadCSV(CycleData, "Cycle_Plan_Report.csv", false);
                  }
                }}
                className="cursor-pointer flex align-items-center  bg-[#3da84a] lg:mb-0 md:mb-0 mb-[1rem] rounded px-4 py-3 lg:me-2 md:me-2 me-0"
              >
                <div className="mr-2">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.5303 10.2803L9.53033 13.2803C9.23744 13.5732 8.76256 13.5732 8.46967 13.2803L5.46967 10.2803C5.17678 9.98744 5.17678 9.51256 5.46967 9.21967C5.76256 8.92678 6.23744 8.92678 6.53033 9.21967L8.25 10.9393V2.25C8.25 1.83579 8.58579 1.5 9 1.5C9.41421 1.5 9.75 1.83579 9.75 2.25V10.9393L11.4697 9.21967C11.7626 8.92678 12.2374 8.92678 12.5303 9.21967C12.8232 9.51256 12.8232 9.98744 12.5303 10.2803Z"
                      fill="white"
                    />
                    <path
                      d="M3 13.125C3 12.7108 2.66421 12.375 2.25 12.375C1.83579 12.375 1.5 12.7108 1.5 13.125V14.25C1.5 15.9069 2.84315 17.25 4.5 17.25H13.5C15.1569 17.25 16.5 15.9069 16.5 14.25V13.125C16.5 12.7108 16.1642 12.375 15.75 12.375C15.3358 12.375 15 12.7108 15 13.125V14.25C15 15.0784 14.3284 15.75 13.5 15.75H4.5C3.67157 15.75 3 15.0784 3 14.25V13.125Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <ButtonComp
                  className="text-[#FFFFFF] font-[500] text-[14px]  rounded-[5px]"
                  // text={LanguageName("VPL")}
                  text={"Download Report"}
                />
              </div>
            </div>
          </div>

          <div className="pb-2 mb-5 flex justify-between">
            <div className="flex items-center gap-2">
              {TopListII?.map((item, i) => (
                <div
                  onClick={() => setSubTitle(item?.name)}
                  className={`${
                    item?.name === subTitle
                      ? "bg-[#4F008A1A] border border-[#8652CE1A] text-[#101928]"
                      : "text-[#344054] border border-[#D0D5DD] bg-[#F0F2F5]"
                  } flex items-center cursor-pointer px-[10px] font-[500] text-[14px] rounded-[6px] py-[10px] `}
                  key={i}
                >
                  <p className="mr-2">{item?.icon}</p>
                  <p>{item?.name}</p>
                </div>
              ))}
            </div>
            {/* <div className="flex items-center gap-4"> */}
            {/*   <div> */}
            {/*     <DropDownIII */}
            {/*       dropDownText={ */}
            {/*         <div className="flex items-center gap-2 text-[#555555]"> */}
            {/*           {LanguageName("MR")} <ArrowDown /> */}
            {/*         </div> */}
            {/*       } */}
            {/*       options={MedRepData?.data?.medreps} */}
            {/*       name="firstName" */}
            {/*       renderItem={(item) => `${item?.firstName} ${item?.lastName}`} */}
            {/*       handleClick={(item) => alert(item?._id)} */}
            {/*     /> */}
            {/*   </div> */}
            {/* </div> */}
          </div>
          <div className="table-container border border-1 rounded mb-[32px] w-full  text-[12px]">
            {TopListII?.find((item, i) => item?.name === subTitle)?.table}
          </div>
        </div>
        <div className="col-xl-3 h-full">
          <AboutMedRep
            medRep={PlansData?.data.filter((item) => item?._id === hcp)[0]}
          />
        </div>
      </div>
    </div>
  );
}
