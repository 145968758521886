import React from "react";
import { useGetPlansOverviewQuery } from "../../../../store/Plans/PlansApi";
import { useSelector } from "react-redux";
import { selectAllCurrentUserData } from "../../../../store/auth";
import { ProgressBar } from "react-bootstrap";
import { LanguageName } from "../../../../utils/helper";

export default function Overview({ Data }) {
  return (
    <div>
      <div className="row">
        <div className="col-lg-4 mb-[64px]">
          <div className="flex flex-col  rounded-[10px] border border-[#E5E5EF]">
            <div className="py-[30px] px-[30px]">
              <div className="flex text-[18px] text-[#000000] font-[600]">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.621094"
                    width="23"
                    height="23"
                    rx="5.5"
                    fill="white"
                  />
                  <rect
                    x="0.5"
                    y="0.621094"
                    width="23"
                    height="23"
                    rx="5.5"
                    stroke="#F0F2F5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.333 5.4541C13.4125 5.4541 12.6663 6.20029 12.6663 7.12077V17.1208C12.6663 18.0412 13.4125 18.7874 14.333 18.7874H16.9997C17.9201 18.7874 18.6663 18.0412 18.6663 17.1208V7.12077C18.6663 6.20029 17.9201 5.4541 16.9997 5.4541H14.333ZM13.9997 7.12077C13.9997 6.93667 14.1489 6.78743 14.333 6.78743H16.9997C17.1838 6.78743 17.333 6.93667 17.333 7.12077V17.1208C17.333 17.3049 17.1838 17.4541 16.9997 17.4541H14.333C14.1489 17.4541 13.9997 17.3049 13.9997 17.1208V7.12077Z"
                    fill="#8652CE"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.99967 11.4541C6.0792 11.4541 5.33301 12.2003 5.33301 13.1208V17.1208C5.33301 18.0412 6.0792 18.7874 6.99967 18.7874H9.66634C10.5868 18.7874 11.333 18.0412 11.333 17.1208V13.1208C11.333 12.2003 10.5868 11.4541 9.66634 11.4541H6.99967ZM6.66634 13.1208C6.66634 12.9367 6.81558 12.7874 6.99967 12.7874H9.66634C9.85044 12.7874 9.99967 12.9367 9.99967 13.1208V17.1208C9.99967 17.3049 9.85044 17.4541 9.66634 17.4541H6.99967C6.81558 17.4541 6.66634 17.3049 6.66634 17.1208V13.1208Z"
                    fill="#8652CE"
                  />
                </svg>
                <p className="ml-2">{LanguageName("HCPUni")}</p>
              </div>
              <br />
              <div className="text-[32px] text-[#060D1C] font-[600]">
                {Data?.customerCount}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-[64px]">
          <div className="flex flex-col  rounded-[10px] border border-[#E5E5EF]">
            <div className="py-[30px] pb-[10px] px-[30px]">
              <div className="flex text-[18px] text-[#000000] font-[600]">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.621094"
                    width="23"
                    height="23"
                    rx="5.5"
                    fill="white"
                  />
                  <rect
                    x="0.5"
                    y="0.621094"
                    width="23"
                    height="23"
                    rx="5.5"
                    stroke="#F0F2F5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.333 5.4541C13.4125 5.4541 12.6663 6.20029 12.6663 7.12077V17.1208C12.6663 18.0412 13.4125 18.7874 14.333 18.7874H16.9997C17.9201 18.7874 18.6663 18.0412 18.6663 17.1208V7.12077C18.6663 6.20029 17.9201 5.4541 16.9997 5.4541H14.333ZM13.9997 7.12077C13.9997 6.93667 14.1489 6.78743 14.333 6.78743H16.9997C17.1838 6.78743 17.333 6.93667 17.333 7.12077V17.1208C17.333 17.3049 17.1838 17.4541 16.9997 17.4541H14.333C14.1489 17.4541 13.9997 17.3049 13.9997 17.1208V7.12077Z"
                    fill="#8652CE"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.99967 11.4541C6.0792 11.4541 5.33301 12.2003 5.33301 13.1208V17.1208C5.33301 18.0412 6.0792 18.7874 6.99967 18.7874H9.66634C10.5868 18.7874 11.333 18.0412 11.333 17.1208V13.1208C11.333 12.2003 10.5868 11.4541 9.66634 11.4541H6.99967ZM6.66634 13.1208C6.66634 12.9367 6.81558 12.7874 6.99967 12.7874H9.66634C9.85044 12.7874 9.99967 12.9367 9.99967 13.1208V17.1208C9.99967 17.3049 9.85044 17.4541 9.66634 17.4541H6.99967C6.81558 17.4541 6.66634 17.3049 6.66634 17.1208V13.1208Z"
                    fill="#8652CE"
                  />
                </svg>
                <p className="ml-2">{LanguageName("TA")}</p>
              </div>
              <br />
              <div className="flex justify-between items-center">
                <div className="flex items-center font-Inter font-[500] text-[14px]">
                  <div className="text-[32px] text-[#060D1C] font-[600]">
                    {Data?.territoriesCount?.current}
                  </div>
                  <div className="flex items-center py-1 px-2">
                    <div className="me-2">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.99984 12.9548V1.28809M6.99984 1.28809L1.1665 7.12142M6.99984 1.28809L12.8332 7.12142"
                          stroke="#12B76A"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="text-[14px] text-[#027A48]">
                      {Data?.territoriesCount?.percentageDifference}%
                    </div>
                  </div>
                  <div className="">vs last month</div>
                </div>

                <svg
                  width="130"
                  height="67"
                  viewBox="0 0 130 67"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M129 1.12109C108.204 2.85409 106.916 44.3429 86.3333 49.1211C69.3602 53.0613 60.7655 30.6949 43.6667 33.1211C25.1204 35.7527 19.1855 59.0593 1 65.1211H129V1.12109Z"
                    fill="#ECFDF3"
                  />
                  <path
                    d="M129 1.12109C108.204 2.85409 106.916 44.3429 86.3333 49.1211C69.3602 53.0613 60.7655 30.6949 43.6667 33.1211C25.1204 35.7527 19.1855 59.0593 1 65.1211H129V1.12109Z"
                    fill="url(#paint0_linear_3952_16193)"
                  />
                  <path
                    d="M1 65.1211C19.1855 59.0593 25.1204 35.7527 43.6667 33.1211C60.7655 30.6949 69.3602 53.0613 86.3333 49.1211C106.916 44.3429 108.204 2.85409 129 1.12109"
                    stroke="#12B76A"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3952_16193"
                      x1="65"
                      y1="1.12109"
                      x2="65"
                      y2="65.1211"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop
                        offset="0.641167"
                        stop-color="white"
                        stop-opacity="0"
                      />
                      <stop offset="1" stop-color="white" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 mb-[64px]">
          <div className="flex flex-col  rounded-[10px] border border-[#E5E5EF]">
            <div className="py-[30px] pb-[10px] px-[30px]">
              <div className="flex text-[18px] text-[#000000] font-[600]">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.621094"
                    width="23"
                    height="23"
                    rx="5.5"
                    fill="white"
                  />
                  <rect
                    x="0.5"
                    y="0.621094"
                    width="23"
                    height="23"
                    rx="5.5"
                    stroke="#F0F2F5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.333 5.4541C13.4125 5.4541 12.6663 6.20029 12.6663 7.12077V17.1208C12.6663 18.0412 13.4125 18.7874 14.333 18.7874H16.9997C17.9201 18.7874 18.6663 18.0412 18.6663 17.1208V7.12077C18.6663 6.20029 17.9201 5.4541 16.9997 5.4541H14.333ZM13.9997 7.12077C13.9997 6.93667 14.1489 6.78743 14.333 6.78743H16.9997C17.1838 6.78743 17.333 6.93667 17.333 7.12077V17.1208C17.333 17.3049 17.1838 17.4541 16.9997 17.4541H14.333C14.1489 17.4541 13.9997 17.3049 13.9997 17.1208V7.12077Z"
                    fill="#8652CE"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.99967 11.4541C6.0792 11.4541 5.33301 12.2003 5.33301 13.1208V17.1208C5.33301 18.0412 6.0792 18.7874 6.99967 18.7874H9.66634C10.5868 18.7874 11.333 18.0412 11.333 17.1208V13.1208C11.333 12.2003 10.5868 11.4541 9.66634 11.4541H6.99967ZM6.66634 13.1208C6.66634 12.9367 6.81558 12.7874 6.99967 12.7874H9.66634C9.85044 12.7874 9.99967 12.9367 9.99967 13.1208V17.1208C9.99967 17.3049 9.85044 17.4541 9.66634 17.4541H6.99967C6.81558 17.4541 6.66634 17.3049 6.66634 17.1208V13.1208Z"
                    fill="#8652CE"
                  />
                </svg>
                <p className="ml-2">{LanguageName("TDS")}</p>
              </div>
              <br />
              <div className="flex justify-between items-center">
                <div className="flex items-center font-Inter font-[500] text-[14px]">
                  <div className="text-[32px] text-[#060D1C] font-[600]">0</div>
                  <div className="flex items-center py-1 px-2">
                    <div className="me-2">
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.99984 4.28809V15.9548M9.99984 15.9548L15.8332 10.1214M9.99984 15.9548L4.1665 10.1214"
                          stroke="#F04438"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="text-[16px] text-[#D63434]">0%</div>
                  </div>
                  <div>vs last month</div>
                </div>
                <svg
                  width="130"
                  height="67"
                  viewBox="0 0 130 67"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.12109C21.7959 2.85409 23.0837 44.3429 43.6667 49.1211C60.6398 53.0613 69.2345 30.6949 86.3333 33.1211C104.88 35.7527 110.815 59.0593 129 65.1211H1V1.12109Z"
                    fill="#FEF3F2"
                  />
                  <path
                    d="M1 1.12109C21.7959 2.85409 23.0837 44.3429 43.6667 49.1211C60.6398 53.0613 69.2345 30.6949 86.3333 33.1211C104.88 35.7527 110.815 59.0593 129 65.1211H1V1.12109Z"
                    fill="url(#paint0_linear_3952_16213)"
                  />
                  <path
                    d="M129 65.1211C110.815 59.0593 104.88 35.7527 86.3333 33.1211C69.2345 30.6949 60.6398 53.0613 43.6667 49.1211C23.0837 44.3429 21.7959 2.85409 0.999996 1.12109"
                    stroke="#F04438"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_3952_16213"
                      x1="65"
                      y1="1.12109"
                      x2="65"
                      y2="65.1211"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop
                        offset="0.641167"
                        stop-color="white"
                        stop-opacity="0"
                      />
                      <stop offset="1" stop-color="white" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="flex flex-col">
            <div className="flex flex-col   rounded-[10px] border border-[#E5E5EF] mb-[56px]">
              <div className="py-[30px] px-[30px]">
                <div className="flex text-[18px] text-[#000000] font-[600]">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.621094"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="white"
                    />
                    <rect
                      x="0.5"
                      y="0.621094"
                      width="23"
                      height="23"
                      rx="5.5"
                      stroke="#F0F2F5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.333 5.4541C13.4125 5.4541 12.6663 6.20029 12.6663 7.12077V17.1208C12.6663 18.0412 13.4125 18.7874 14.333 18.7874H16.9997C17.9201 18.7874 18.6663 18.0412 18.6663 17.1208V7.12077C18.6663 6.20029 17.9201 5.4541 16.9997 5.4541H14.333ZM13.9997 7.12077C13.9997 6.93667 14.1489 6.78743 14.333 6.78743H16.9997C17.1838 6.78743 17.333 6.93667 17.333 7.12077V17.1208C17.333 17.3049 17.1838 17.4541 16.9997 17.4541H14.333C14.1489 17.4541 13.9997 17.3049 13.9997 17.1208V7.12077Z"
                      fill="#8652CE"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.99967 11.4541C6.0792 11.4541 5.33301 12.2003 5.33301 13.1208V17.1208C5.33301 18.0412 6.0792 18.7874 6.99967 18.7874H9.66634C10.5868 18.7874 11.333 18.0412 11.333 17.1208V13.1208C11.333 12.2003 10.5868 11.4541 9.66634 11.4541H6.99967ZM6.66634 13.1208C6.66634 12.9367 6.81558 12.7874 6.99967 12.7874H9.66634C9.85044 12.7874 9.99967 12.9367 9.99967 13.1208V17.1208C9.99967 17.3049 9.85044 17.4541 9.66634 17.4541H6.99967C6.81558 17.4541 6.66634 17.3049 6.66634 17.1208V13.1208Z"
                      fill="#8652CE"
                    />
                  </svg>
                  <p className="ml-2">{LanguageName("TotalPlans")}</p>
                </div>
                <br />
                <div className="text-[32px] text-[#060D1C] font-[600]">
                  {Data?.totalPlans}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="w-full flex flex-col   rounded-[10px] border border-[#E5E5EF] lg:mb-0 mb-[56px]">
            <div className="py-[30px] px-[30px]">
              <div className="flex text-[18px] text-[#000000] font-[600]">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.621094"
                    width="23"
                    height="23"
                    rx="5.5"
                    fill="white"
                  />
                  <rect
                    x="0.5"
                    y="0.621094"
                    width="23"
                    height="23"
                    rx="5.5"
                    stroke="#F0F2F5"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.333 5.4541C13.4125 5.4541 12.6663 6.20029 12.6663 7.12077V17.1208C12.6663 18.0412 13.4125 18.7874 14.333 18.7874H16.9997C17.9201 18.7874 18.6663 18.0412 18.6663 17.1208V7.12077C18.6663 6.20029 17.9201 5.4541 16.9997 5.4541H14.333ZM13.9997 7.12077C13.9997 6.93667 14.1489 6.78743 14.333 6.78743H16.9997C17.1838 6.78743 17.333 6.93667 17.333 7.12077V17.1208C17.333 17.3049 17.1838 17.4541 16.9997 17.4541H14.333C14.1489 17.4541 13.9997 17.3049 13.9997 17.1208V7.12077Z"
                    fill="#8652CE"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.99967 11.4541C6.0792 11.4541 5.33301 12.2003 5.33301 13.1208V17.1208C5.33301 18.0412 6.0792 18.7874 6.99967 18.7874H9.66634C10.5868 18.7874 11.333 18.0412 11.333 17.1208V13.1208C11.333 12.2003 10.5868 11.4541 9.66634 11.4541H6.99967ZM6.66634 13.1208C6.66634 12.9367 6.81558 12.7874 6.99967 12.7874H9.66634C9.85044 12.7874 9.99967 12.9367 9.99967 13.1208V17.1208C9.99967 17.3049 9.85044 17.4541 9.66634 17.4541H6.99967C6.81558 17.4541 6.66634 17.3049 6.66634 17.1208V13.1208Z"
                    fill="#8652CE"
                  />
                </svg>
                <p className="ml-2">{LanguageName("CPLs")}</p>
              </div>
              <br />
              <div className="text-[32px] text-[#060D1C] font-[600]">
                {Data?.completedPlans}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="flex flex-col">
            <div className="flex flex-col   rounded-[10px] border border-[#E5E5EF] mb-[56px]">
              <div className="py-[30px] px-[30px]">
                <div className="flex text-[18px] text-[#000000] font-[600]">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.621094"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="white"
                    />
                    <rect
                      x="0.5"
                      y="0.621094"
                      width="23"
                      height="23"
                      rx="5.5"
                      stroke="#F0F2F5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M14.333 5.4541C13.4125 5.4541 12.6663 6.20029 12.6663 7.12077V17.1208C12.6663 18.0412 13.4125 18.7874 14.333 18.7874H16.9997C17.9201 18.7874 18.6663 18.0412 18.6663 17.1208V7.12077C18.6663 6.20029 17.9201 5.4541 16.9997 5.4541H14.333ZM13.9997 7.12077C13.9997 6.93667 14.1489 6.78743 14.333 6.78743H16.9997C17.1838 6.78743 17.333 6.93667 17.333 7.12077V17.1208C17.333 17.3049 17.1838 17.4541 16.9997 17.4541H14.333C14.1489 17.4541 13.9997 17.3049 13.9997 17.1208V7.12077Z"
                      fill="#8652CE"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.99967 11.4541C6.0792 11.4541 5.33301 12.2003 5.33301 13.1208V17.1208C5.33301 18.0412 6.0792 18.7874 6.99967 18.7874H9.66634C10.5868 18.7874 11.333 18.0412 11.333 17.1208V13.1208C11.333 12.2003 10.5868 11.4541 9.66634 11.4541H6.99967ZM6.66634 13.1208C6.66634 12.9367 6.81558 12.7874 6.99967 12.7874H9.66634C9.85044 12.7874 9.99967 12.9367 9.99967 13.1208V17.1208C9.99967 17.3049 9.85044 17.4541 9.66634 17.4541H6.99967C6.81558 17.4541 6.66634 17.3049 6.66634 17.1208V13.1208Z"
                      fill="#8652CE"
                    />
                  </svg>
                  <p className="ml-2">{LanguageName("PDPS")}</p>
                </div>
                <br />
                <div className="text-[32px] text-[#060D1C] font-[600]">
                  {Data?.pendingPlans}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
