import React from "react";
import { StatusColor, downloadCSV } from "../../../utils/helper";
import moment from "moment";
import ButtonComp from "../../../Components/Ui/Button";

export default function ViewEvent({ singleEvent }) {
  console.log(singleEvent, "singleEvent");
  return (
    <div className="px-[24px] pt-[30px] border relative h-full">
      <div className="flex justify-between items-center mb-[50px]">
        <div className="text-[#444444] text-[24px] font-bold font-3">
          {singleEvent?.name}
        </div>
        <div className={`${StatusColor(singleEvent?.status)}`}>
          {singleEvent?.status}
        </div>
      </div>

      <div className="flex items-center gap-4  mb-[50px] border-b pb-6">
        <div>
          <svg
            width="55"
            height="55"
            viewBox="0 0 55 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="55" height="55" rx="5.72917" fill="#F5F5F5" />
            <path
              d="M21.9141 30.293H23.776V32.1549H21.9141V30.293ZM26.569 30.293H28.431V32.1549H26.569V30.293ZM31.224 30.293H33.0859V32.1549H31.224V30.293Z"
              fill="#3DA84A"
            />
            <path
              d="M38.2064 39.6029V17.7246H34.0169V13.5352H20.9831V17.7246H16.7936V39.6029H13.5352V41.4648H41.4648V39.6029H38.2064ZM22.8451 15.3971H32.1549V24.707H22.8451V15.3971ZM36.3444 39.6029H28.431V35.8789H26.569V39.6029H18.6556V19.5866H20.9831V26.569H34.0169V19.5866H36.3444V39.6029Z"
              fill="#3DA84A"
            />
            <path
              d="M28.431 17.2588H26.569V19.1208H24.707V20.9827H26.569V22.8447H28.431V20.9827H30.293V19.1208H28.431V17.2588Z"
              fill="#3DA84A"
            />
          </svg>
        </div>
        <div className="">
          <div className="text-[20px] font-semibold">
            {singleEvent?.institution}
          </div>
          <div className="text-[14px] text-[#777777] font-normal">
            {singleEvent?.location}
          </div>
        </div>
      </div>
      <div className="row g-5">
        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Event Type</div>
          <div className="text-[16px] font-medium text-black">
            Product Lunch
          </div>
        </div>

        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">
            Event Title
          </div>
          <div className="text-[16px] font-medium text-black">
            {singleEvent?.name}
          </div>
        </div>

        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Time</div>
          <div className="text-[16px] font-medium text-black">
            {singleEvent?.time}
          </div>
        </div>

        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Date</div>
          <div className="text-[16px] font-medium text-black">
            {moment(singleEvent?.date).format("LL")}
          </div>
        </div>

        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Department</div>
          <div className="text-[16px] font-medium text-black">
            {singleEvent?.department || "-"}
          </div>
        </div>

        <div className="col-6 font-5">
          <div className="text-[#555555] text-[16px] mb-[10px]">Med Rep</div>
          <div className="text-[16px] font-medium text-black">
            {singleEvent?.user?.firstName || "-"}{" "}
            {singleEvent?.user?.lastName || "-"}
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center absolute bottom-[50px]">
        <div
          onClick={() => downloadCSV([singleEvent], "Event_Report.csv", false)}
          className="flex align-items-center border-1 border-[#4F0084] bg-[#ffffff] text-black rounded-[50px] px-8 py-2 ms-4"
        >
          <div className="me-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.30005 9.1001V14.5001L9.10005 12.7001"
                stroke="#4F008A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.3 14.5002L5.5 12.7002"
                stroke="#4F008A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19 8.2V12.7C19 17.2 17.2 19 12.7 19H7.3C2.8 19 1 17.2 1 12.7V7.3C1 2.8 2.8 1 7.3 1H11.8"
                stroke="#4F008A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19 8.2H15.4C12.7 8.2 11.8 7.3 11.8 4.6V1L19 8.2Z"
                stroke="#4F008A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <ButtonComp
            // onClick={() => setOpenDrawer("CreateEvent")}
            className=" font-[500] text-[16px] "
            text={"Report"}
          />
        </div>
      </div>
    </div>
  );
}
