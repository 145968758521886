import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const CustomerApi = createApi({
  reducerPath: "CustomerApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getAllCustomer: builder.query({
      query: (month) => ({
        url: `/customer/all`,
        method: "GET",
      }),
    }),
    getAllLeads: builder.query({
      query: (month) => ({
        url: `/leads/company`,
        method: "GET",
      }),
    }),
    getAllVisit: builder.query({
      query: (month) => ({
        url: `/leads/company`,
        method: "GET",
      }),
    }),

    updateLeads: builder.mutation({
      query: (data) => ({
        url: `/leads/update/${data?.id}`,
        method: "PATCH",
        body: data,
      }),
      transformResponse: (response) => response.data,
    }),
    getSchedule: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `/visitation?startDate=${moment(startDate).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(endDate).format("YYYY-MM-DD")}`,
        method: "GET",
      }),
    }),
    getLocations: builder.query({
      query: ({ id }) => ({
        url: `/visitation/sales-rep/check-in/${id}?startDate=2023-04-27&endDate=${moment()
          .add(1, "days")
          .format("YYYY-MM-DD")}`,
        method: "GET",
      }),
    }),
    getCompany: builder.query({
      query: () => ({
        url: `/company/all`,
        method: "GET",
      }),
    }),
    addFieldRep: builder.mutation({
      query: (payload) => ({
        url: `/auth/sales-rep/create`,
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetAllCustomerQuery,
  useGetAllLeadsQuery,
  useGetScheduleQuery,
  useGetLocationsQuery,
  useUpdateLeadsMutation,
  useAddFieldRepMutation,
  useGetCompanyQuery
} = CustomerApi;
