import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import HCPListComp from "./HCPListComp";
import { useLocation, useParams } from "react-router-dom";
import {
  useGetHCPQuery,
  useGetHcpSchedulesQuery,
} from "../../../store/Schedules/SchedulesApi";
import {
  useGetMedRepActivitiesQuery,
  useGetSingleMedRepQuery,
} from "../../../store/MedRep/MedRepApi";
import {
  useGetMedRepDetailsHCpQuery,
  useGetMedRepDetailsQuery,
} from "../../../store/Plans/PlansApi";

function HCPList() {
  const { id, createdById } = useParams();
  // const {data:HCPData,isLoading } =useGetHCPQuery();
  const { data, isLoading: MedRepDataLoader } = useGetSingleMedRepQuery(
    createdById,
    {
      skip: !createdById,
    }
  );
  const { data: getMedRepActivities, isLoading: medRepActLoader } =
    useGetMedRepActivitiesQuery(createdById, {
      skip: !createdById,
    });
  const location = useLocation();
  const receivedData = location.state;
  //
  const { data: getHcpSchedules, isLoading: ScheduleLoader } =
    useGetHcpSchedulesQuery(createdById, {
      skip: !createdById, // Replace 'someCondition' with your actual condition
    });

  const { data: getMedRepPlan, isLoading: PlanLoader } =
    useGetMedRepDetailsQuery(createdById, {
      skip: !createdById, // Replace 'someCondition' with your actual condition
    });
  // console.log(getHcpSchedules?.data?.schedules,SingleHcp,'getHcpSchedules')

  return (
    <div>
      {!MedRepDataLoader && (
        <HCPListComp
          userSchedule={receivedData}
          userDetail={data?.data}
          activities={{
            data: getMedRepActivities?.data?.activities,
            loader: medRepActLoader,
          }}
          schedule={{
            data: getHcpSchedules?.data?.schedules,
            loader: ScheduleLoader,
          }}
          planData={{
            data: getMedRepPlan?.data?.plans,
            loader: PlanLoader,
          }}
        />
      )}
    </div>
  );
}

export default HCPList;
