import React, { useState } from "react";
import HeaderII from "../../Components/Common/HeaderII";
import Skeleton from "react-loading-skeleton";
import OverviewDashboard from "./subModules/Overview";
import Analysis from "./subModules/Analysis";
import { useTranslation } from "react-i18next";

export default function OverviewComp({ Overview, OMLoader }) {
  const [page, setPage] = useState("overview");
  const { t } = useTranslation();

  return (
    <div className="px-3">
      <HeaderII page={page} setPage={setPage} />
      <div className="grid  grid-cols-1 lg:grid-cols-3 mb-[32px] gap-4">
        <div className="">
          <div className="flex flex-col bg-[#4F008A] px-[33px] py-[18px] rounded">
            <div className="flex justify-content-between align-items-center ">
              {/* <div className="">
                <img src="/Overview/overview-calendar.svg" alt="" />{" "}
              </div> */}
              {/* <div className="">
                <img src="/icon/more-details.svg" alt="" />{" "}
              </div> */}
            </div>
            <div className="font-instrument-sans text-[#FFFFFF] text-[18px] font-[700] pt-[29px] pb-[33px]">
              {t(`lang.TCS`)}
            </div>
            <div className="font-instrument-sans text-[#FFFFFF] text-[24px] font-[600]">
              {OMLoader ? <Skeleton width={50} /> : Overview?.totalSchedules}
            </div>
          </div>
        </div>
        <div className="">
          <div className="flex flex-col  border border-1 rounded px-2 lg:my-[0rem] md:my-[2rem] my-[2rem]">
            <div className="flex justify-content-between align-items-center border-b border-b-1  px-[20px] pt-[40px] pb-[26px]">
              <div className="font-[500]  text-[18px] text-[#444444] font-instrument-sans">
                {t(`lang.THN`)}
              </div>
              <div className="text-[24px] font-[600] text-[#060D1C]">
                {" "}
                {OMLoader ? <Skeleton width={50} /> : Overview?.totalHcps}
              </div>
            </div>
            <div className="flex justify-content-between align-items-center border-b border-b-1  px-[20px] pt-[40px] pb-[26px]">
              <div className="font-[500]  text-[18px] text-[#444444] font-instrument-sans">
                {t(`lang.THCO`)}
              </div>
              <div className="text-[24px] font-[600] text-[#060D1C]">
                {" "}
                {OMLoader ? <Skeleton width={50} /> : Overview?.totalHcos}
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="flex flex-col border border-1 rounded px-2">
            <div className="flex justify-content-between align-items-center border-b border-b-1  px-[20px] pt-[40px] pb-[26px]">
              <div className="font-[500]  text-[18px] text-[#444444] font-instrument-sans">
                {t(`lang.TP`)}
              </div>
              <div className="text-[24px] font-[600] text-[#060D1C]">
                {" "}
                {OMLoader ? <Skeleton width={50} /> : Overview?.totalPlans}
              </div>
            </div>
            <div className="flex justify-content-between align-items-center border-b border-b-1  px-[20px] pt-[40px] pb-[26px]">
              <div className="font-[500]  text-[18px] text-[#444444] font-instrument-sans">
                {t(`lang.TNS`)}
              </div>

              <div className="text-[24px] font-[600] text-[#060D1C]">
                {OMLoader ? (
                  <Skeleton width={50} />
                ) : (
                  Overview?.totalCompletedPlans
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {page === "overview" ? (
          <OverviewDashboard />
        ) : (
          <Analysis setPage={setPage} />
        )}
      </div>
    </div>
  );
}
