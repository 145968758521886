import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import PlanMedRepsComp from "./PlanMedRepsComp";

function PlanMedReps() {
  return (
    // <AdminLayout active={1}>
      <PlanMedRepsComp />
    // </AdminLayout>
  );
}

export default PlanMedReps;
