import React from "react";
import { storage } from "../../utils/helper";

export function Pagination({ current, next, numberOfPages, setPage }) {
  // Getting the companyId for medrep page request
  const companyIde = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;
  // Function to handle changing page
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  return (
    <div className="items_container md:w-full min-w-[30rem]  overflow-x-scroll flex items-center justify-between mb-[3.5rem] px-[1.5rem] mt-[2.7rem]">
      <div className="page_number flex items-center justify-center gap-3">
        <span className="block font-semibold">{current}</span>
        <span>out of</span>
        <span className="font-semibold">{numberOfPages}</span>
      </div>
      <div className="pagination-controls  flex items-center justify-end gap-[2rem]">
        <button
          onClick={() =>
            handlePageChange({ page: current - 1, companyId: companyIde, search: "" })
          }
          disabled={current === 1}
          className={`${current === 1 ? "text-[#757575] " : "text-[#282728]"}`}
        >
          Previous
        </button>
        <div className="item_container flex items-center justify-center gap-1">
          {Array.from({ length: numberOfPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() =>
                handlePageChange({ page: index + 1, companyId: companyIde, search: "" })
              }
              className={
                current === index + 1
                  ? "active text-white rounded-[5px] h-[2rem] w-[2rem] bg-[#4F008A] border-none outline-none"
                  : "in-active h-[2rem] w-[2rem] rounded-[5px] border-[1px] border-solid border-gray-300"
              }
            >
              {index + 1}
            </button>
          ))}
        </div>
        <button
          onClick={() =>
            handlePageChange({ page: next, companyId: companyIde, search: "" })
          }
          disabled={current === numberOfPages}
          className={`${
            current === numberOfPages ? "text-[#757575] " : "text-[#282728]"
          }`}
        >
          Next
        </button>
      </div>
    </div>
  );
}
