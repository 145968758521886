import React, { useEffect, useState } from "react";
import SelectComp from "../../../../Components/Ui/SelectComp";
import { TableComp } from "../../../../Components/Common/TableComp";
import { useGetAchievementQuery } from "../../../../store/MedRep/MedRepApi";
import Example from "./submodules/PieChart";
import { useParams } from "react-router-dom";
import {
  months,
  createYearArray,
  column,
  LanguageName,
} from "../../../../utils/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSpeedometer from "react-d3-speedometer";
import { ArrowDown } from "../../../../utils/svg";
import Skeleton from "react-loading-skeleton";

const Achievement = () => {
  const { createdById } = useParams();
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  // Trigger the query with the parameters
  const { data, isLoading, isFetching, refetch } = useGetAchievementQuery(
    { medRepId: createdById, month, year },
    { skip: !month || !year }
  );
  const [startDate, setStartDate] = useState(new Date());
  const handleChange = (date) => {
    const dateCon = new Date(date);
    setMonth(dateCon.getMonth() + 1);
    setYear(dateCon.getFullYear());
    // setStartDate(dateCon.getMonth())
    setStartDate(date);
  };
  useEffect(() => {
    // Get the current month & year
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    // Set the initial month & year
    setMonth(currentMonth);
    setYear(currentYear);
  }, []);

  useEffect(() => {
    // Trigger the query whenever month or year changes
    if (month && year) {
      refetch(); // Refetch the data with the updated parameters
      console.log(data);
    }
  }, [month, year, refetch]);
  return (
    <div>
      <div className="flex mb-4 gap-4">
        <div className="w-3/4 border p-3 rounded">
          <h3 className="mb-4 text-lg font-semibold">{LanguageName("CLPL")}</h3>
          <div className="flex flex-wrap justify-center gap-1">
            <div className="basis-[20%] ">
              <ReactSpeedometer
                width={300}
                height={175}
                maxValue={100}
                value={Math.round(data?.callPlan?.average)}
                segments={3}
                segmentColors={["#FF0000", "#FFFF00", "#00FF00"]}
                needleColor="#000000"
                ringWidth={15}
                textColor="#333333"
                needleHeightRatio={0.8}
              />
            </div>
            <div className="flex flex-wrap basis-[100%] ">
              <div className="basis-1/2 flex-grow-0 p-2">
                <h4 className="">{isFetching || isLoading ? <Skeleton/> : data?.callPlan?.actualCall}</h4>
                <p>{LanguageName("ALCL")}</p>
              </div>
              <div className="basis-1/2 flex-grow-0 p-2">
                <h4 className="">{isFetching || isLoading ? <Skeleton/> : data?.callPlan?.plannedCalls}</h4>
                <p>{LanguageName("PLCL")}</p>
              </div>
              <div className="basis-full flex-grow-0 p-2">
                <h4 className="">{isFetching || isLoading ? <Skeleton/> : data?.callPlan?.unplannedCall}</h4>
                <p>{LanguageName("UPLCL")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 ">
          <div className="relative border w-full px-2 py-3 rounded">
            <DatePicker
              selected={startDate}
              onChange={handleChange}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="w-full"
            />
            <div className="absolute right-3 top-1/2 pointer-events-none">
              <ArrowDown />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap sm:flex-nowrap gap-2 mb-4">
        <div className="w-full flex-grow-0 md:basis-[32.1%] p-3 border rounded">
          <h3 className="w-10 rounded-full bg-[#DCCCE880] text-center py-[0.5px]">
            {isFetching || isLoading ? <Skeleton/> : data?.hcp?.addedHcp}
          </h3>
          <p className="mt-3">{LanguageName("AHCP")}</p>
        </div>
        <div className="w-full flex-grow-0 md:basis-[32.1%] p-3 border rounded">
          <h3 className="w-10 rounded-full bg-[#DCCCE880] text-center py-[0.5px]">
            {isFetching || isLoading ? <Skeleton/> : data?.hcp?.unsubmittedCalls}
          </h3>
          <p className="mt-3">{LanguageName("USCL")}</p>
        </div>
        <div className="w-full flex-grow-0 md:basis-[32.1%] p-3 border rounded">
          <h3 className="w-10 rounded-full bg-[#DCCCE880] text-center py-[0.5px]">
            {isFetching || isLoading ? <Skeleton/> : data?.hcp?.totalHcp}
          </h3>
          <p className="mt-3">{LanguageName("THN")}</p>
        </div>
      </div>

      <div className="border-2 w-[700px] md:w-[99%] px-4 py-3 mb-4 rounded">
        <h3 className="mb-4 text-lg font-semibold">{LanguageName("TPGL")}</h3>
        {Array.isArray(data?.totalGoals) && (
          <TableComp columnsComp={column()} Data={data?.totalGoals} />
        )}
      </div>
    </div>
  );
};

export default Achievement;
