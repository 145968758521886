import React, { useEffect, useRef, useState } from "react";
import FormUi from "../../../../Components/Ui/FormUi";
import { useForm } from "react-hook-form";
import { FormLabel } from "./Data";
import { storage } from "../../../../utils/helper";
import {
  useGetAllProfileQuery,
  useUpdateManagerMutation,
  useUploadFileMutation,
} from "../../../../store/User/userApi";
import ButtonComp from "../../../../Components/Ui/Button";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AddImage } from "../../../../utils/svg";
import axios from "axios";

export default function PersonalInformation({}) {
  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      firstName: "",
      email: "",
      lastName: "",
      phoneNumber: "",
      // companyAddress: "",
      country: "",
      state: "",
      image: "",
      companyId: "",
    },
  });
  const companyId = storage["localStorage"].get("user_details_profile")?.user
    ?.id;
  const { t } = useTranslation();
  // console.log(companyId, "companyId");
  const [selectedImage, setSelectedImage] = useState(null);
  const [convertImageToLink, { isLoading: CILLoader }] =
    useUploadFileMutation();
  const { data: CompanyData, isLoading: CLoader } = useGetAllProfileQuery(
    companyId,
    {
      skip: !companyId,
    }
  );
  const [updateCompanyProfile, { isLoading: UCLoader }] =
    useUpdateManagerMutation();
  const CompanyDetails = CompanyData?.data;
  const fileInputRef = useRef(null);
  const handleChange = async (file) => {
    setSelectedImage(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("file", file);

    // Retrieve the token from local storage
    const token = storage["localStorage"].get("user_details_profile")?.token;

    // Create headers object
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    // If the token exists, add it to the headers
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}file/upload`,
        data: formData,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // Update UI with progress
          // setProgress(progress);
          console.log(`Upload progress: ${progress}%`);
        },
      });

      console.log("response", response);
      if (response) {
        // setCILLoader(false);
        toast.success(response?.data.message);
        setSelectedImage(response?.data?.data?.file_link);
      } else {
        console.error("Invalid response format. Missing file_link.");
        // setCILLoader(false);
      }
    } catch (error) {
      // Handle the error
      console.error("Upload failed:", error);
      // setCILLoader(false);
    }
  };
  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(URL.createObjectURL(file))
      const formData = new FormData();
      formData.append("file", file);
      const response = await convertImageToLink(formData).unwrap();
      if (response?.data) {
        
        setSelectedImage(response?.data?.file_link);
        setValue("image", response?.data?.file_link);
      } else {
        toast.error("Please Check your image format");
      }
      //  console.log(response?.data?.file_link,'response')
    }
  };
  const handleSelectImage = () => {
    fileInputRef.current.click();
  };
  
  console.log(FormLabel)
  useEffect(() => {
    setValue("firstName", CompanyDetails?.firstName);
    setValue("email", CompanyDetails?.email);

    setValue("lastName", CompanyDetails?.lastName);

    setValue("phoneNumber", CompanyDetails?.phoneNumber);

    setValue("address", CompanyDetails?.company.address);
    setValue("contactPerson", `${CompanyDetails?.firstName}  ${CompanyDetails?.lastName}`);
    setValue("country", CompanyDetails?.country);
    setValue("state", CompanyDetails?.state[0]);
    setValue("image", CompanyDetails?.image);
    setValue("id", CompanyDetails?.id);
    setValue("companyId", companyId);
    setValue("image", CompanyDetails?.company?.image)
    setSelectedImage(CompanyDetails?.company?.image)
    console.log(CompanyDetails)
  }, [CompanyData?.data?.firstName]);

  const handleUpdateCompany = async (data) => {
    console.log(data)
    const payload = {
      ...data,
      name: `${data?.firstName} ${data?.lastName}`,
      contactPhoneNo: data?.phone,
      companyAddress: data?.companyAddress,
      image: selectedImage
    };
    console.log(payload)
    const response = await updateCompanyProfile(payload);
    if (response?.data) {
      // reset();
      toast.success("Company Update Successful");
    }
    console.log(data, "handleUpdateCompany");
  };

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-5">Admin Console</div>
      <div className="lg:mb-[20px] md:mb-[45px] mb-[45px] border px-4 py-6 rounded">
        <div className="row gap-5 mb-4">
          <div className="col-md-3 mb-4">
            <h3 className="font-semibold text-lg">Profile photo</h3>
            <p className="font-normal text-base text-[#667185] mt-1 mb-3">
              This image will be displayed on your profile
            </p>
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept={"image/*"}
              onChange={(e) => handleChange(e.target.files[0])}
            />
            <div>
              <div
                className="flex justify-center items-center mt-4 w-40 py-[1.5px] rounded border-[2px] border-[#8652CE] cursor-pointer"
                onClick={handleSelectImage}
              >
                <AddImage />
                <ButtonComp
                  text={"Upload Photo"}
                  btnClassName={"text-center text-[#8652CE] ml-2 font-semibold"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="border-2 flex justify-center items-center w-36 h-36 rounded-full overflow-hidden">
              <img
                src={selectedImage || "/HCPList/profile.svg"}
                className="w-full h-full object-cover"
                alt="Profile"
              />
            </div>
          </div>
        </div>
        <div className="border-t"></div>
        <div className="row gap-5 mt-4">
          <div className="col-md-3 mb-4">
            <div className="">
              <h3 className="font-semibold text-lg">Personal Information</h3>
              <p className="font-normal text-base text-[#667185] mt-1 mb-3">
                Update you personal details here.
              </p>
              <ButtonComp
                onClick={handleSubmit(handleUpdateCompany)}
                text={"Save changes"}
                loader={UCLoader}
                btnClassName={
                  "bg-[#8652CE] text-white rounded px-3 py-1 font-semibold text-base"
                }
              />
            </div>
          </div>
          <div className="col-md-8 grow-[1]">
            <div className="row">
            <FormUi
              formData={FormLabel}
              // la={item?.label}
              control={control}
              containerClassName={"h-[35px] bg-[#FCFAFF] border-1"}
              labelClassName="!text-[14px] mb-1 text-[#4F008A]"
              // CompanyData={CompanyData?.data?.company}
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
