import moment from "moment";
import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useParams } from "react-router-dom";
import {
  useGetPlansAnalyticsQuery,
  useGetPlansQuery,
} from "../../../../store/Plans/PlansApi";

export default function AboutMedRep({ Data, medRep }) {
  const { hcp } = useParams();
  const { data: PlansData, isLoading: PALoader } = useGetPlansAnalyticsQuery();
  
  return (
    <div className={` border-[#F0F2F5] border bg-white rounded-[8px] w-full text-wrap`}>
      <div className="pt-4 px-3 flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={medRep?.medRep?.avatar}
            className="rounded-full w-[48px] h-[48px] object-fit mr-2"
          />
          <div>
            <p className="text-[20px] font-[600] text-[#101928]">
              {medRep?.medRep?.firstName} {medRep?.medRep?.lastName}
            </p>
            <p className="text-[14px] font-[400] text-[#475467]">
              {medRep?.medRep?.email ? medRep?.medRep?.email : "No email"}
            </p>
          </div>
        </div>
        <svg
          width="14"
          height="20"
          viewBox="0 0 14 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.2073 7.04305L7.00025 0.835938L0.793119 7.04305L2.20733 8.45725L7.00025 3.66437L11.7931 8.45725L13.2073 7.04305ZM0.792969 12.9574L7.00005 19.1645L13.2071 12.9574L11.7929 11.5432L7.00005 16.3361L2.20718 11.5432L0.792969 12.9574Z"
            fill="black"
          />
        </svg>
      </div>
      <div className="p-4">
        <div className="flex justify-between items-center border-t py-[14px] px-[16px]">
          <div className="flex items-center">
            <svg
              width="18"
              height="15"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.6668 0C7.90536 0 5.66678 2.23858 5.66678 5C5.66678 7.76142 7.90536 10 10.6668 10C13.4282 10 15.6668 7.76142 15.6668 5C15.6668 2.23858 13.4282 0 10.6668 0ZM7.33345 5C7.33345 3.15905 8.82583 1.66667 10.6668 1.66667C12.5077 1.66667 14.0001 3.15905 14.0001 5C14.0001 6.84095 12.5077 8.33333 10.6668 8.33333C8.82583 8.33333 7.33345 6.84095 7.33345 5Z"
                fill="#98A2B3"
              />
              <path
                d="M5.79628 14.606C5.55364 14.9971 5.03991 15.1174 4.64883 14.8748C4.25775 14.6321 4.13741 14.1184 4.38005 13.7273C5.52122 11.888 7.85578 10 10.6668 10C13.4778 10 15.8124 11.888 16.9535 13.7273C17.1962 14.1184 17.0758 14.6321 16.6848 14.8748C16.2937 15.1174 15.7799 14.9971 15.5373 14.606C14.6022 13.0988 12.7371 11.6667 10.6668 11.6667C8.59651 11.6667 6.73141 13.0988 5.79628 14.606Z"
                fill="#98A2B3"
              />
              <path
                d="M5.58166 2.96643C5.7843 3.37966 5.61358 3.87891 5.20035 4.08155C4.44313 4.45287 4.00011 5.12649 4.00011 5.83333C4.00011 6.54017 4.44313 7.2138 5.20035 7.58512C5.61358 7.78775 5.7843 8.28701 5.58166 8.70024C5.37903 9.11346 4.87977 9.28418 4.46654 9.08155C3.23587 8.47806 2.33345 7.27794 2.33345 5.83333C2.33345 4.38872 3.23587 3.1886 4.46654 2.58512C4.87977 2.38248 5.37903 2.5532 5.58166 2.96643Z"
                fill="#98A2B3"
              />
              <path
                d="M2.46295 14.606C2.22031 14.9971 1.70658 15.1174 1.3155 14.8748C0.924416 14.6321 0.80408 14.1184 1.04672 13.7273C1.5293 12.9495 2.30141 12.3003 3.16281 11.7966C3.56011 11.5643 4.07052 11.698 4.30283 12.0953C4.53515 12.4926 4.40141 13.003 4.00411 13.2354C3.28435 13.6562 2.75479 14.1356 2.46295 14.606Z"
                fill="#98A2B3"
              />
            </svg>
            <div className="ml-[15px]">
              <p className="font-Inter text-[12px] text-[#475467]">Medrep ID</p>
              <p className="text-[14px] font-[500] text-[#101928]">
                {medRep?._id}
              </p>
            </div>
          </div>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6665 2.16699C9.82555 2.16699 8.33317 3.65938 8.33317 5.50033V7.16699H4.99984C3.15889 7.16699 1.6665 8.65938 1.6665 10.5003V15.5003C1.6665 17.3413 3.15889 18.8337 4.99984 18.8337H8.33317C10.1741 18.8337 11.6665 17.3413 11.6665 15.5003V13.8337H14.9998C16.8408 13.8337 18.3332 12.3413 18.3332 10.5003V5.50033C18.3332 3.65938 16.8408 2.16699 14.9998 2.16699H11.6665ZM11.6665 12.167H14.9998C15.9203 12.167 16.6665 11.4208 16.6665 10.5003V5.50033C16.6665 4.57985 15.9203 3.83366 14.9998 3.83366H11.6665C10.746 3.83366 9.99984 4.57985 9.99984 5.50033V7.61293C10.9962 8.18928 11.6665 9.26652 11.6665 10.5003V12.167ZM3.33317 10.5003C3.33317 9.57985 4.07936 8.83366 4.99984 8.83366H8.33317C9.25365 8.83366 9.99984 9.57985 9.99984 10.5003V15.5003C9.99984 16.4208 9.25365 17.167 8.33317 17.167H4.99984C4.07936 17.167 3.33317 16.4208 3.33317 15.5003V10.5003Z"
              fill="#98A2B3"
            />
          </svg>
        </div>
        <div className="flex justify-between items-center border-t py-[14px] px-[16px]">
          <div className="flex items-center">
            <svg
              width="20"
              height="15"
              viewBox="0 0 20 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.8337 15C17.6747 15 19.167 13.5076 19.167 11.6667V4.18557C19.1673 4.17283 19.1673 4.16005 19.167 4.14725V3.33333C19.167 1.49238 17.6747 0 15.8337 0H4.16705C2.3261 0 0.833718 1.49238 0.833718 3.33333V4.14726C0.833422 4.16005 0.833423 4.17282 0.833718 4.18556V11.6667C0.833718 13.5076 2.3261 15 4.16705 15H15.8337ZM2.50038 11.6667C2.50038 12.5871 3.24658 13.3333 4.16705 13.3333H15.8337C16.7542 13.3333 17.5004 12.5871 17.5004 11.6667V5.39753L11.2384 7.90234C10.4436 8.22022 9.55712 8.22022 8.76241 7.90234L2.50038 5.39753V11.6667ZM10.6194 6.35488L17.5004 3.60247V3.33333C17.5004 2.41286 16.7542 1.66667 15.8337 1.66667H4.16705C3.24658 1.66667 2.50038 2.41286 2.50038 3.33333V3.60247L9.3814 6.35488C9.77875 6.51382 10.222 6.51382 10.6194 6.35488Z"
                fill="#98A2B3"
              />
            </svg>
            <div className="ml-[15px]">
              <p className="font-Inter text-[12px] text-[#475467]">Email</p>
              <p className="text-[14px] font-[500] text-[#101928]">
                {medRep?.medRep?.email ? medRep?.medRep?.email : "No email"}
              </p>
            </div>
          </div>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6665 2.16699C9.82555 2.16699 8.33317 3.65938 8.33317 5.50033V7.16699H4.99984C3.15889 7.16699 1.6665 8.65938 1.6665 10.5003V15.5003C1.6665 17.3413 3.15889 18.8337 4.99984 18.8337H8.33317C10.1741 18.8337 11.6665 17.3413 11.6665 15.5003V13.8337H14.9998C16.8408 13.8337 18.3332 12.3413 18.3332 10.5003V5.50033C18.3332 3.65938 16.8408 2.16699 14.9998 2.16699H11.6665ZM11.6665 12.167H14.9998C15.9203 12.167 16.6665 11.4208 16.6665 10.5003V5.50033C16.6665 4.57985 15.9203 3.83366 14.9998 3.83366H11.6665C10.746 3.83366 9.99984 4.57985 9.99984 5.50033V7.61293C10.9962 8.18928 11.6665 9.26652 11.6665 10.5003V12.167ZM3.33317 10.5003C3.33317 9.57985 4.07936 8.83366 4.99984 8.83366H8.33317C9.25365 8.83366 9.99984 9.57985 9.99984 10.5003V15.5003C9.99984 16.4208 9.25365 17.167 8.33317 17.167H4.99984C4.07936 17.167 3.33317 16.4208 3.33317 15.5003V10.5003Z"
              fill="#98A2B3"
            />
          </svg>
        </div>
        <div className="flex justify-between items-center border-t py-[14px] px-[16px]">
          <div className="flex items-center">
            <svg
              width="14"
              height="18"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.00016 0.166992C4.69898 0.166992 2.8335 2.03247 2.8335 4.33366C2.8335 6.63485 4.69898 8.50033 7.00016 8.50033C9.30135 8.50033 11.1668 6.63485 11.1668 4.33366C11.1668 2.03247 9.30135 0.166992 7.00016 0.166992ZM4.50016 4.33366C4.50016 2.95295 5.61945 1.83366 7.00016 1.83366C8.38087 1.83366 9.50016 2.95295 9.50016 4.33366C9.50016 5.71437 8.38087 6.83366 7.00016 6.83366C5.61945 6.83366 4.50016 5.71437 4.50016 4.33366Z"
                fill="#98A2B3"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.00016 17.667C5.71641 17.667 3.85834 17.3755 2.46302 16.7648C1.77802 16.465 1.08137 16.0359 0.676624 15.4148C0.46376 15.0882 0.329191 14.7034 0.333601 14.2738C0.337972 13.8482 0.478029 13.4404 0.713425 13.061C1.85459 11.2217 4.18915 9.33366 7.00016 9.33366C9.81116 9.33366 12.1457 11.2217 13.2869 13.061C13.5223 13.4404 13.6624 13.8482 13.6667 14.2738C13.6711 14.7034 13.5366 15.0882 13.3237 15.4148C12.919 16.0359 12.2223 16.465 11.5373 16.7648C10.142 17.3755 8.28391 17.667 7.00016 17.667ZM2.12966 13.9397C2.01993 14.1165 2.0008 14.2301 2.00018 14.2909C1.9996 14.3479 2.01422 14.4147 2.07297 14.5049C2.21145 14.7174 2.54772 14.9826 3.13127 15.238C4.27303 15.7377 5.89453 16.0003 7.00016 16.0003C8.10579 16.0003 9.72729 15.7377 10.8691 15.238C11.4526 14.9826 11.7889 14.7174 11.9274 14.5049C11.9861 14.4147 12.0007 14.3479 12.0001 14.2909C11.9995 14.2301 11.9804 14.1165 11.8707 13.9397C10.9355 12.4324 9.07044 11.0003 7.00016 11.0003C4.92988 11.0003 3.06478 12.4324 2.12966 13.9397Z"
                fill="#98A2B3"
              />
            </svg>
            <div className="ml-[15px]">
              <p className="font-Inter text-[12px] text-[#475467]">Full Name</p>
              <p className="text-[14px] font-[500] text-[#101928]">
                {medRep?.medRep?.firstName} {medRep?.medRep?.lastName}
              </p>
            </div>
          </div>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6665 2.16699C9.82555 2.16699 8.33317 3.65938 8.33317 5.50033V7.16699H4.99984C3.15889 7.16699 1.6665 8.65938 1.6665 10.5003V15.5003C1.6665 17.3413 3.15889 18.8337 4.99984 18.8337H8.33317C10.1741 18.8337 11.6665 17.3413 11.6665 15.5003V13.8337H14.9998C16.8408 13.8337 18.3332 12.3413 18.3332 10.5003V5.50033C18.3332 3.65938 16.8408 2.16699 14.9998 2.16699H11.6665ZM11.6665 12.167H14.9998C15.9203 12.167 16.6665 11.4208 16.6665 10.5003V5.50033C16.6665 4.57985 15.9203 3.83366 14.9998 3.83366H11.6665C10.746 3.83366 9.99984 4.57985 9.99984 5.50033V7.61293C10.9962 8.18928 11.6665 9.26652 11.6665 10.5003V12.167ZM3.33317 10.5003C3.33317 9.57985 4.07936 8.83366 4.99984 8.83366H8.33317C9.25365 8.83366 9.99984 9.57985 9.99984 10.5003V15.5003C9.99984 16.4208 9.25365 17.167 8.33317 17.167H4.99984C4.07936 17.167 3.33317 16.4208 3.33317 15.5003V10.5003Z"
              fill="#98A2B3"
            />
          </svg>
        </div>
        <div className="flex justify-between items-center border-t py-[14px] px-[16px]">
          <div className="flex items-center">
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.22854 1.83959C4.92679 0.537843 2.81625 0.537846 1.5145 1.83959L1.06822 2.28587C0.452914 2.90118 0.0320579 3.82324 0.272109 4.82207C0.640119 6.35333 1.74753 9.14369 5.05003 12.4462C8.35253 15.7487 11.1429 16.8561 12.6742 17.2241C13.673 17.4642 14.5951 17.0433 15.2104 16.428L15.6566 15.9817C16.9584 14.68 16.9584 12.5694 15.6566 11.2677L14.8826 10.4936C13.8215 9.43257 12.1823 9.21128 10.8779 9.95301L10.7974 9.99875C10.2629 10.3027 9.64883 10.2779 9.2138 9.96302C8.86278 9.70898 8.52729 9.44164 8.29094 9.20529C8.05458 8.96894 7.78724 8.63345 7.53321 8.28242C7.21837 7.8474 7.19353 7.23333 7.49748 6.6988L7.54322 6.61835C8.28494 5.31394 8.06366 3.67471 7.00261 2.61366L6.22854 1.83959ZM2.69301 3.0181C3.34388 2.36723 4.39916 2.36723 5.05003 3.0181L5.8241 3.79217C6.35462 4.32269 6.46527 5.14231 6.0944 5.79452L6.04866 5.87496C5.46124 6.90801 5.44063 8.23374 6.18303 9.25956C6.45572 9.63636 6.78343 10.0548 7.11243 10.3838C7.44142 10.7128 7.85987 11.0405 8.23666 11.3132C9.26249 12.0556 10.5882 12.035 11.6213 11.4476L11.7017 11.4018C12.3539 11.031 13.1735 11.1416 13.7041 11.6721L14.4781 12.4462C15.129 13.0971 15.129 14.1523 14.4781 14.8032L14.0318 15.2495C13.7095 15.5719 13.3542 15.6734 13.0636 15.6036C11.8394 15.3094 9.31993 14.3591 6.22854 11.2677C3.13715 8.17629 2.18685 5.65681 1.89263 4.43261C1.82281 4.14207 1.92436 3.78676 2.24673 3.46438L2.69301 3.0181Z"
                fill="#98A2B3"
              />
            </svg>
            <div className="ml-[15px]">
              <p className="font-Inter text-[12px] text-[#475467]">
                Phone Number
              </p>
              <p className="text-[14px] font-[500] text-[#101928]">
                {medRep?.medRep?.phoneNumber
                  ? medRep?.medRep?.phoneNumber
                  : "No phone number"}
              </p>
            </div>
          </div>
          <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6665 2.16699C9.82555 2.16699 8.33317 3.65938 8.33317 5.50033V7.16699H4.99984C3.15889 7.16699 1.6665 8.65938 1.6665 10.5003V15.5003C1.6665 17.3413 3.15889 18.8337 4.99984 18.8337H8.33317C10.1741 18.8337 11.6665 17.3413 11.6665 15.5003V13.8337H14.9998C16.8408 13.8337 18.3332 12.3413 18.3332 10.5003V5.50033C18.3332 3.65938 16.8408 2.16699 14.9998 2.16699H11.6665ZM11.6665 12.167H14.9998C15.9203 12.167 16.6665 11.4208 16.6665 10.5003V5.50033C16.6665 4.57985 15.9203 3.83366 14.9998 3.83366H11.6665C10.746 3.83366 9.99984 4.57985 9.99984 5.50033V7.61293C10.9962 8.18928 11.6665 9.26652 11.6665 10.5003V12.167ZM3.33317 10.5003C3.33317 9.57985 4.07936 8.83366 4.99984 8.83366H8.33317C9.25365 8.83366 9.99984 9.57985 9.99984 10.5003V15.5003C9.99984 16.4208 9.25365 17.167 8.33317 17.167H4.99984C4.07936 17.167 3.33317 16.4208 3.33317 15.5003V10.5003Z"
              fill="#98A2B3"
            />
          </svg>
        </div>
        <div className="flex justify-between items-center border-t py-[14px] px-[16px]">
          <div className="flex items-center">
            <svg
              width="20"
              height="19"
              viewBox="0 0 20 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0002 0.333008C4.93755 0.333008 0.833496 4.43706 0.833496 9.49967C0.833496 14.5623 4.93755 18.6663 10.0002 18.6663C15.0628 18.6663 19.1668 14.5623 19.1668 9.49967C19.1668 4.43706 15.0628 0.333008 10.0002 0.333008ZM3.46627 8.91826L2.5008 9.40099C2.52334 7.65321 3.14372 6.04958 4.16683 4.78523V6.46629L3.46627 8.91826ZM5.8335 3.26263C7.02524 2.4649 8.45837 1.99967 10.0002 1.99967C14.0291 1.99967 17.3161 5.17656 17.4927 9.16196L14.6209 12.0338L14.213 15.7056C13.0121 16.5223 11.5619 16.9997 10.0002 16.9997C6.43259 16.9997 3.4468 14.5088 2.68712 11.1712L4.86739 10.0811L5.18119 8.98279C5.06137 9.67416 5.38439 10.4152 6.07828 10.7621L7.95043 11.6982L8.14593 14.6306C8.25669 16.292 10.4668 16.7819 11.2693 15.3229L14.2975 9.81712C14.5298 9.39464 14.5678 8.89223 14.4017 8.4396L13.7018 6.53291C13.424 5.77602 12.6442 5.32654 11.8499 5.4655L8.03261 6.13342C7.69496 6.1925 7.38374 6.35425 7.14136 6.59664L5.64512 8.09287C5.48036 8.25763 5.35858 8.44554 5.27783 8.64456L5.8335 6.69972V3.26263ZM16.2128 12.7989L17.1059 11.9058C16.8657 12.6153 16.5222 13.2772 16.0934 13.8736L16.2128 12.7989ZM8.69579 10.2075L6.82363 9.27138L8.31987 7.77515L12.1372 7.10723L12.8371 9.01392L9.8089 14.5197L9.61341 11.5873C9.57406 10.9971 9.22485 10.472 8.69579 10.2075Z"
                fill="#98A2B3"
              />
            </svg>
            <div className="ml-[15px]">
              <p className="font-Inter text-[12px] text-[#475467]">Territory</p>
              <p className="text-[14px] font-[500] text-[#AD3307] capitalize">
                {medRep?.medRep?.territory}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center border-t py-[14px] px-[16px]">
          <div className="flex items-center">
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.1665 14.0833C3.1665 13.6231 3.5396 13.25 3.99984 13.25H9.83317C10.2934 13.25 10.6665 13.6231 10.6665 14.0833C10.6665 14.5436 10.2934 14.9167 9.83317 14.9167H3.99984C3.5396 14.9167 3.1665 14.5436 3.1665 14.0833Z"
                fill="#98A2B3"
              />
              <path
                d="M12.3332 13.25C11.8729 13.25 11.4998 13.6231 11.4998 14.0833C11.4998 14.5436 11.8729 14.9167 12.3332 14.9167H13.9998C14.4601 14.9167 14.8332 14.5436 14.8332 14.0833C14.8332 13.6231 14.4601 13.25 13.9998 13.25H12.3332Z"
                fill="#98A2B3"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.6665 0.75C6.12674 0.75 6.49984 1.1231 6.49984 1.58333V2.41667H11.4998V1.58333C11.4998 1.1231 11.8729 0.75 12.3332 0.75C12.7934 0.75 13.1665 1.1231 13.1665 1.58333V2.41667H13.9998C15.8408 2.41667 17.3332 3.90905 17.3332 5.75V14.9167C17.3332 16.7576 15.8408 18.25 13.9998 18.25H3.99984C2.15889 18.25 0.666504 16.7576 0.666504 14.9167V5.75C0.666504 3.90905 2.15889 2.41667 3.99984 2.41667H4.83317V1.58333C4.83317 1.1231 5.20627 0.75 5.6665 0.75ZM11.4998 4.08333C11.4998 4.54357 11.8729 4.91667 12.3332 4.91667C12.7934 4.91667 13.1665 4.54357 13.1665 4.08333H13.9998C14.9203 4.08333 15.6665 4.82953 15.6665 5.75V6.16667H2.33317V5.75C2.33317 4.82953 3.07936 4.08333 3.99984 4.08333H4.83317C4.83317 4.54357 5.20627 4.91667 5.6665 4.91667C6.12674 4.91667 6.49984 4.54357 6.49984 4.08333H11.4998ZM15.6665 7.83333H2.33317V14.9167C2.33317 15.8371 3.07936 16.5833 3.99984 16.5833H13.9998C14.9203 16.5833 15.6665 15.8371 15.6665 14.9167V7.83333Z"
                fill="#98A2B3"
              />
            </svg>
            <div className="ml-[15px]">
              <p className="font-Inter text-[12px] text-[#475467]">
                Last visited
              </p>
              <p className="text-[14px] font-[500] text-[#101928]">
                {moment(medRep?.lastActivity).format("MMMM Do YYYY")}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center border-t py-[14px] px-[16px]">
          <div className="flex items-center">
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.1665 14.0833C3.1665 13.6231 3.5396 13.25 3.99984 13.25H9.83317C10.2934 13.25 10.6665 13.6231 10.6665 14.0833C10.6665 14.5436 10.2934 14.9167 9.83317 14.9167H3.99984C3.5396 14.9167 3.1665 14.5436 3.1665 14.0833Z"
                fill="#98A2B3"
              />
              <path
                d="M12.3332 13.25C11.8729 13.25 11.4998 13.6231 11.4998 14.0833C11.4998 14.5436 11.8729 14.9167 12.3332 14.9167H13.9998C14.4601 14.9167 14.8332 14.5436 14.8332 14.0833C14.8332 13.6231 14.4601 13.25 13.9998 13.25H12.3332Z"
                fill="#98A2B3"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5.6665 0.75C6.12674 0.75 6.49984 1.1231 6.49984 1.58333V2.41667H11.4998V1.58333C11.4998 1.1231 11.8729 0.75 12.3332 0.75C12.7934 0.75 13.1665 1.1231 13.1665 1.58333V2.41667H13.9998C15.8408 2.41667 17.3332 3.90905 17.3332 5.75V14.9167C17.3332 16.7576 15.8408 18.25 13.9998 18.25H3.99984C2.15889 18.25 0.666504 16.7576 0.666504 14.9167V5.75C0.666504 3.90905 2.15889 2.41667 3.99984 2.41667H4.83317V1.58333C4.83317 1.1231 5.20627 0.75 5.6665 0.75ZM11.4998 4.08333C11.4998 4.54357 11.8729 4.91667 12.3332 4.91667C12.7934 4.91667 13.1665 4.54357 13.1665 4.08333H13.9998C14.9203 4.08333 15.6665 4.82953 15.6665 5.75V6.16667H2.33317V5.75C2.33317 4.82953 3.07936 4.08333 3.99984 4.08333H4.83317C4.83317 4.54357 5.20627 4.91667 5.6665 4.91667C6.12674 4.91667 6.49984 4.54357 6.49984 4.08333H11.4998ZM15.6665 7.83333H2.33317V14.9167C2.33317 15.8371 3.07936 16.5833 3.99984 16.5833H13.9998C14.9203 16.5833 15.6665 15.8371 15.6665 14.9167V7.83333Z"
                fill="#98A2B3"
              />
            </svg>
            <div className="ml-[15px]">
              <p className="font-Inter text-[12px] text-[#475467]">
                Plan Progress
              </p>
              {/* <p className="text-[14px] w-full font-[500] text-[#101928]"> */}
              {/*   <ProgressBar now={medRep?.planProgress} /> */}
              {/* </p> */}
              <div className={` w-full`}>
                <div className="flex w-[220px] text-[14px]">
                  <ProgressBar now={90} />
                </div>
                <ProgressBar now={medRep?.planProgress} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="m-[5px] bg-white border-t border-b border-[#F0F2F5] h-full p-2 pt-4 mb-3">
        <div className="text-left text-[#000000] text-[18px] font-[600] mb-[25px]">
          {" "}
          Top Visited Institutions
        </div>
        {PlansData?.data &&
          PlansData?.data?.topVisitedDepartments
            ?.map((data) => {
              return (
                <div className="text-[16px] text-black mb-3">
                  <div className="mb-1">{data?._id}</div>
                  <ProgressBar now={data?.count * 10} />
                </div>
              );
            })
            .slice(0, 4)}
      </div>

      <div className="m-[5px] bg-white p-2 pt-4 mb-3 h-[10vh] w-full"></div>
    </div>
  );
}
