import moment from "moment";
import { Dots, TrackIcon, TrashIcon } from "../../utils/svg";
import DropDownComp from "../../Components/Ui/DropDown";
import { LanguageName } from "../../utils/helper";

export const tableData = [
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Product Launch",
    callMethod: "Physical",
    availability: "On-Going",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Product Launch",
    callMethod: "Physical",
    availability: "Canceled",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Product Launch",
    callMethod: "Physical",
    availability: "Completed",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Product Launch",
    callMethod: "Physical",
    availability: "Completed",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Product Launch",
    callMethod: "Physical",
    availability: "Completed",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Product Launch",
    callMethod: "Physical",
    availability: "Completed",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Product Launch",
    callMethod: "Physical",
    availability: "Completed",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
];

export const columnsData = (OptionDropDown) => [
  {
    name: "Id",
    selector: (row) => <span>#{row?._id.substring(0, 6)}</span>,
    sortable: true,
    width: "100px",
  },
  {
    name: "Institution",
    selector: (row) => (
      <div>
        <div className="whitespace-nowrap text-[16px] text-[#000000]">
          {row.institution}
        </div>
      </div>
    ),
    width: "200px",
    // sortable: true,
  },
  {
    name: LanguageName("Department"),
    selector: (row) => <span>{row?.department || "-"}</span>,
    // row.medRep,
  },
  {
    name: LanguageName("ET"),
    selector: (row) => row.type,
    sortable: true,
  },

  {
    name: <div className="whitespace-nowrap">{LanguageName("EM")}</div>,
    selector: (row) => row.name,
    width: "200px",
    sortable: true,
  },

  {
    name: LanguageName("Status"),
    selector: (row) => (
      <div className={`${StatusColor(row?.status)} `}>{row.status}</div>
    ),
    // sortable: true,
  },
  {
    name: LanguageName("ETi"),
    selector: (row) => moment(row?.date).format("MMMM Do YYYY"),
    width: "200px",

    // sortable: true,
  },
  {
    name: LanguageName("Action"),
    // sortable: true,
    cell: (row) => (
      <div className="ms-3">
        <DropDownComp
          dropDownText={<Dots color="#7572EA" />}
          options={OptionDropDown()}
          row={row}
        />
      </div>
    ),
    width: "100px",
  },
];

// const OptionDropDown = (setSingleEvent, row) => [
//   {
//     name: (
//       <div
//         onClick={() => setSingleEvent(row)}
//         className="flex gap-1 items-center text-[#656468] text-[12px]"
//       >
//         <TrackIcon />
//         {LanguageName("VEI")}
//       </div>
//     ),
//     // action:;
//   },
// ];

const StatusColor = (status) => {
  switch (status) {
    case "pending":
      return "bg-[#DBEBFE] px-3 py-2 rounded-full capitalize ";
      break;
    case "completed":
      return "bg-[#DAF1E9] text-[#044E54] capitalize";
      break;
    default:
      break;
  }
};

export const TopHeaderCount = (Data) => {
  return [
    {
      name: LanguageName("TE"),
      count: Data?.length,
    },
    {
      name: LanguageName("CE2"),
      count: Data?.filter(item => item.status === "completed").length,
      findBy: "completed",
    },
    {
      name: LanguageName("CE1"),
      count: Data?.filter(item => item.status === "pending").length,
      findBy: "pending",
    },
    {
      name: LanguageName("OE"),
      count: Data?.filter(item => item.status === "checked-in").length,
      findBy: "checked-in",
    },
  ];
};
