import React, { useEffect, useState, useRef } from "react";
import FormUi from "../../../../Components/Ui/FormUi";
import { useForm } from "react-hook-form";
import ButtonComp from "../../../../Components/Ui/Button";
import { useUpdateHCPMutation } from "../../../../store/MedRep/MedRepApi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AddImage, ArrowLeft } from "../../../../utils/svg";
import { formData } from "../Data";
import { LanguageName, storage } from "../../../../utils/helper";
import axios from "axios";

export function EditHCPProComp({ getHcp, isSuccess }) {
  const { id } = useParams();
  const [updateHCP, { data, isLoading }] = useUpdateHCPMutation();
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      country: "",
      state: "",
      city: "",
      email: "",
      address: "",
      phoneNumber: "",
      position: "",
      specialty: "",
      institutionName: "",
      institutionType: "",
    },
  });

  const [selectedImage, setSelectedImage] = useState("");
  const [loader, setLoader] = useState(false);
  const fileInputRef = useRef(null);
  const handleChange = async (file) => {
    setSelectedImage(URL.createObjectURL(file));
    setLoader(true);
    const formData = new FormData();
    formData.append("file", file);

    // Retrieve the token from local storage
    const token = storage["localStorage"].get("user_details_profile")?.token;

    // Create headers object
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    // If the token exists, add it to the headers
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    try {
      const response = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_BASE_URL}file/upload`,
        data: formData,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // Update UI with progress
          setProgress(progress);
          // console.log(`Upload progress: ${progress}%`);
        },
      });

      if (response) {
        setLoader(false);
        toast.success(response?.data.message);
        setSelectedImage(response?.data?.data?.file_link);
      } else {
        console.error("Invalid response format. Missing file_link.");
        setLoader(false);
      }
    } catch (error) {
      // Handle the error
      console.error("Upload failed:", error);
      setLoader(false);
    }
  };
  const onSubmit = async (values) => {
    const finalData = {
      // address: [values.address],
      phoneNumber: values.phoneNumber,
      email: values.email,
      image: [selectedImage],
    };
    const payload = {
      body: finalData,
      id,
    };

    try {
      const response = await updateHCP(payload);
      if (response?.error) {
        toast.error(response?.error?.data?.message);
      }
      console.log(response?.data.data);
      if (response?.data) {
        toast.success(response?.data.message);
        setValue("email", response?.data.data.email);
        setValue("phoneNumber", response?.data.data.phoneNumber);
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    console.log(getHcp);
    setValue("firstName", getHcp?.firstName);
    setValue("lastName", getHcp?.lastName);
    setValue(
      "country",
      getHcp?.hco?.country ? getHcp?.hco?.institutionName : "-"
    );
    setValue("state", getHcp?.hco?.state ? getHcp?.hco?.institutionName : "-");
    setValue("city", getHcp?.hco?.city ? getHcp?.hco?.institutionName : "-");
    setValue("email", getHcp?.email);
    setValue("address", getHcp?.address);
    setValue("phoneNumber", getHcp?.phoneNumber);
    setValue("position", getHcp?.position);
    setValue(
      "institutionName",
      getHcp?.hco?.institutionName ? getHcp?.hco?.institutionName : "-"
    );
    setValue(
      "institutionType",
      getHcp?.hco?.institutionType ? getHcp?.hco?.institutionType : "-"
    );
    setValue("specialty", getHcp?.specialty);
  }, [isSuccess]);

  return (
    <div className="container">
      <div className=" row  mb-[1rem]">
        <div
          className=" col-3 flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft />
          {/* <span className="text-base font-[700] ml-2">Go Back</span> */}
        </div>
        <div className="col-8 text-[25px] font-[700] ">
          {LanguageName("EHCP")}
        </div>

        {/* <div className="ms-2">
          <img src="/icon/info-circle.svg" alt="" />
        </div> */}
      </div>
      <div className="row my-5">
        <div className="col-lg-3 flex flex-col items-center mb-5 lg:items-start">
          <div className="border-2 flex justify-center items-center w-36 h-36 md:w-40 md:h-40 lg:w-44 lg:h-44 rounded-full overflow-hidden ">
            <img
              src={selectedImage || "/HCPList/profile.svg"}
              className="w-full h-full object-cover"
              alt=""
            />
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept={"image/*"}
              onChange={(e) => handleChange(e.target.files[0])}
            />
          </div>
          {/* {loader && <div className="h-3 w-36 md:w-40 lg:w-44 rounded-lg border overflow-hidden mt-2">
            <div
              className="bg-[#3DA84A] h-3"
              style={{ width: `${progress}` }}
            ></div>
          </div>} */}
          <div
            onClick={() => {
              fileInputRef.current.click();
            }}
            className="flex justify-center items-center mt-4 w-40 py-[1.5px] rounded border-[2px] border-[#8652CE]"
          >
            <AddImage />
            <ButtonComp
              text={LanguageName("CHNP")}
              btnClassName={
                "text-center text-[#8652CE] ml-2 font-semibold cursor-not-allowed"
              }
            />
          </div>
        </div>
        <div className="col-lg-9">
          <div className=" row gy-4">
            <FormUi
              control={control}
              formData={formData()}
              labelClassName="!text-[14px] mb-1 text-[#8652CE]"
            />
          </div>
        </div>
      </div>
      <ButtonComp
        onClick={handleSubmit(onSubmit)}
        text="Update Details"
        btnClassName="bg-[#3DA84A] rounded-full px-5 py-[10px] text-white !text-base mx-auto  mb-10"
        loader={isLoading}
        disabled={isLoading}
      />
    </div>
  );
}

export default EditHCPProComp;
