import React from "react";
import ButtonComp from "../../Components/Ui/Button";
import { useNavigate } from "react-router-dom";
import { LanguageName } from "../../utils/helper";
import DropDownComp from "../../Components/Ui/DropDown";
import {
  useGetAssignedMedRepQuery,
  useAssignMedRepMutation,
  useDeleteFolderFileMutation,
  useUpdateMedRepMutation,
  useDeleteFolderQuery,
  useDeleteFolderMutation,
} from "../../store/Media/MediaApi";
import { toast } from "react-toastify";
import ModalComp from "../../Components/Ui/ModalComp";
import AssignMRModal from "./AssignMedRepModal";
import { BsThreeDotsVertical } from "react-icons/bs";
import SuccessAmrModal from "./SuccessAmrModal";
import SuccessDelModal from "./SuccessDelModal";
import ConfirmAssignModal from "./ConfirmAssignModal";
import ConfirmDelModal from "./ConfirmDelModal";
import { AssignIcon, DelIcon } from "../../utils/svg";
import ConfirmUpdateModal from "./ConfirmUpdateModal";
import { Pagination } from "../../Components/Common/Pagination";

export default function Media({ Medias, addMedia, refetch, pagination, setPage }) {
  const navigate = useNavigate();
  const [payload, setPayload] = React.useState({ folderId: "", fileId: "" });
  const [folderId, setFolderId] = React.useState("");
  const [delFolderId, setDelFolderId] = React.useState(null)
  const [deleteFile, { data:DFData, isLoading:DFLoader }] = useDeleteFolderFileMutation();
 
  const [deleteFold, { data, isLoading }] = useDeleteFolderMutation();
  const [
    assignMedRep,
    { data: assignStatus, isLoading: AMRLoader, isSuccess, isFetching },
  ] = useAssignMedRepMutation();
  const [updateMedRep, { data: updateStatus, isLoading: UMRLoader }] =
    useUpdateMedRepMutation();
  const {
    data: assignedMedRepData,
    isSuccess: AMRSuccess,
    isLoading: loader,
    isFetching: AMRFetcher,
  } = useGetAssignedMedRepQuery(folderId, { skip: !folderId });
  const [isAssigned, setIsAssigned] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredItems, setFilteredItems] = React.useState(Medias);
  const [sortOrder, setSortOrder] = React.useState("name"); // default sort by name

  const [medRep, setMedRep] = React.useState({});
  const [showAMRModal, setShowAMRModal] = React.useState(false);
  const [assuranceModal, setAssuranceModal] = React.useState(false);
  const [updateModal, setUpdateModal] = React.useState(false);
  const [successModal, setSuccessModal] = React.useState(false);
  const [successDelModal, setSuccessDelModal] = React.useState(false);
  const [confirmDelModal, setConfirmDelModal] = React.useState(false);

  const {current, number_of_pages, next} = pagination

  function sortItems(items, sortOrder) {
    if (!items) return [];
    return items?.slice().sort((a, b) => {
      if (sortOrder === "name") {
        return a.name.localeCompare(b.name);
      } else if (sortOrder === "date") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });
  }

  // Add a function to handle setting the sort order
  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  const handleSubmit = async () => {
    const response = await assignMedRep(medRep);
    if (response?.data?.status) {
      setAssuranceModal(false);
      setSuccessModal(true);
    }
  };
  const handleUpdate = async () => {
    const response = await updateMedRep(medRep);
    if (response?.data?.status) {       
      setUpdateModal((prev) => !prev);
      setSuccessModal(true);
    }
  };
  function searchItems(items, searchTerm) {
    if (!items) return [];
    return items?.filter((item) => {
      const searchLower = searchTerm.toLowerCase();
      const nameMatch = item.name.toLowerCase().includes(searchLower);
      const idMatch = item._id.toString().includes(searchLower);
      return nameMatch || idMatch;
    });
  }

  React.useEffect(() => {
    setFilteredItems(sortItems(searchItems(Medias, searchTerm), sortOrder));
  }, [searchTerm, Medias, sortOrder]);

  React.useEffect(() => {
    setFilteredItems(searchItems(Medias, searchTerm));
  }, [searchTerm, Medias]);

  React.useEffect(() => {
    if(data?.status){
      // refetch({page: 1})
      toast.success(data?.message)
    }
  }, [data]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const OptionDropDown = (row) => [
    {
      name: LanguageName("Name"),
      action: () => handleSortChange("name"),
    },
    {
      name: LanguageName("DateCr"),
      action: () => handleSortChange("date"),
    },
  ];

  const OptionDropDownIII = (id) => [
    {
      name: "Delete",
      action: () => {
        deleteFold(id)
      },
    },
  ];
  const OptionDropDownII = (fiId, foId) => [
    {
      icon: <AssignIcon />,
      name: LanguageName("AssMR"),
      action: async () => {
        setFolderId(foId);
        setShowAMRModal(true);
      },
    },
    {
      icon: <DelIcon />,
      name: LanguageName("Del"),
      action: () => {
        setPayload((prev) => ({ ...prev, folderId: foId, fileId: fiId }));
        setConfirmDelModal(true);
      },
    },
  ];

  return (
    <div className="container">
      <div className="border-b-2 mb-[71px]">
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem]">
            <div className="text-[25px] font-[700]">{LanguageName("MDA")}</div>
            <div className="ms-2">
              <img src="/icon/info-circle.svg" alt="" />
            </div>
          </div>
          <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
            <div className="flex align-items-center  border-[1px] border-[#4F008A] bg-[#4F008A] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
              <div className="me-2">
                <img src="/Media/cross.png" alt="" />
              </div>
              <ButtonComp
                onClick={addMedia}
                className="text-[#FFFFFF] font-[500] text-[16px] "
                text={LanguageName("CNPT")}
              />
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
          <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
            <div className="border-r-2 ps-2 pe-3">
              <img className="" src="/icon/search.png" alt="search" />
            </div>
            {/* <input className="border-0 mb-3 ">
                   <form
                     className="border-0"
                     placeholder="Search for Call Reports by ID, Name, Date, HCOs"
                     aria-label="Search for Call Reports by ID, Name, Date, HCOs"
                     aria-describedby="basic-addon2"
                   ><form
                 </input> */}
            <input
              className="w-100 text-[16px] font-[400]"
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder={LanguageName("SCR")}
            />
          </div>
          <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <DropDownComp
                dropDownText={LanguageName("SB")}
                options={OptionDropDown()}
              />
              <div className="">
                <img src="/icon/sort.png" alt="" />
              </div>
            </div>
            {/* <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3"> */}
            {/*   <div className=""> */}
            {/*     {" "} */}
            {/*     {LanguageName("CTY")}: {LanguageName("All")} */}
            {/*   </div>{" "} */}
            {/*   <div className=""> */}
            {/*     <img src="/icon/select-arrows.png" alt="" /> */}
            {/*   </div> */}
            {/* </div> */}
          </div>
        </div>
      </div>

      {filteredItems?.map((item, i) => (
        <div key={i}>
          <div className="flex justify-between text-[24px] text-[#000000] font-[600] mb-[37px]">
            <p onClick={() => navigate(`/media/${item?._id}`)}>{item?.name}</p>
            <div className="flex align-items-center">
              <div className="">
                <img src="/icon/sort.png" alt="" />
              </div>
              <DropDownComp
                dropDownText={LanguageName("Options")}
                options={OptionDropDownIII(item?._id)}
              />
            </div>
          </div>
          <div className="row bg-[#FCFAFF] py-[25px] px-[35px] mb-[44px] gap-4">
            {item?.files?.length == 0 && (
              <div
                onClick={() => navigate(`/media/${item?._id}`)}
                className="col-lg-2 bg-white pb-3"
                key={i}
              >
                <div>
                  <svg
                    className="w-full"
                    width="163"
                    height="115"
                    viewBox="0 0 163 115"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="163" height="115" rx="6" fill="#FCFAFF" />
                    <g clip-path="url(#clip0_3_24839)">
                      <path
                        d="M124 62.3092V19.4271C124 17.0209 122.03 15 119.624 15H96.5186C94.8156 15 93.2687 16.0274 92.5585 17.5752L90.2281 22.6918C89.5179 24.2396 87.971 25.2687 86.2681 25.2687H46.3503C43.9442 25.2687 42.0342 27.1805 42.0342 29.5868V69.261C42.0342 71.6672 43.9442 73.678 46.3503 73.678H119.624C119.739 73.678 119.853 73.612 119.966 73.6032V81.9296H120.029C120.029 83.0298 120.923 83.8799 122.014 83.8799C123.106 83.8799 124 82.9692 124 81.8777V62.3092Z"
                        fill="#A162F7"
                      />
                      <path
                        d="M122.014 83.8799C120.923 83.8799 120.029 83.0298 120.029 81.9296H119.966V41.0715C119.966 38.6651 118.07 36.6374 115.664 36.6374H42.3899C39.9837 36.6374 38 38.6651 38 41.0715V84.9313C38 87.3375 39.9837 89.2643 42.3899 89.2643H115.664C115.918 89.2643 116.166 89.2533 116.409 89.2115C116.709 89.2531 117.015 89.2821 117.328 89.2821C121.002 89.2821 123.954 86.3062 123.954 82.6315C123.954 82.5336 123.947 82.4375 123.943 82.3407C123.733 83.2146 122.947 83.8799 122.014 83.8799Z"
                        fill="#4F008A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_24839">
                        <rect
                          width="86"
                          height="74.4478"
                          fill="white"
                          transform="translate(38 15)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="row w-full mx-auto">
                  <div className="text-center text-[#000000] text-[12px] mb-[10px]">
                    No Folder Add yet
                  </div>
                  <div className="flex justify-center  gap-3">
                    <div className="flex items-center gap-2">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11 5.5V8.5C11 10.5 10.5 11 8.5 11H3.5C1.5 11 1 10.5 1 8.5V3.5C1 1.5 1.5 1 3.5 1H4.25C5 1 5.165 1.22 5.45 1.6L6.2 2.6C6.39 2.85 6.5 3 7 3H8.5C10.5 3 11 3.5 11 5.5Z"
                          stroke="#635F65"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M4 1H8.5C9.5 1 10 1.5 10 2.5V3.19"
                          stroke="#635F65"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <div className="text-[10px] text-[#635F65]">{0}</div>
                    </div>
                    <div className="flex items-center gap-2">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.5 11H7.5C10 11 11 10 11 7.5V4.5C11 2 10 1 7.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11Z"
                          stroke="#635F65"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.5 5C5.05228 5 5.5 4.55228 5.5 4C5.5 3.44772 5.05228 3 4.5 3C3.94772 3 3.5 3.44772 3.5 4C3.5 4.55228 3.94772 5 4.5 5Z"
                          stroke="#635F65"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.33496 9.4743L3.79996 7.8193C4.19496 7.5543 4.76496 7.5843 5.11996 7.8893L5.28496 8.0343C5.67496 8.3693 6.30496 8.3693 6.69496 8.0343L8.77496 6.2493C9.16496 5.9143 9.79496 5.9143 10.185 6.2493L11 6.9493"
                          stroke="#635F65"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <div className="text-[10px] text-[#635F65]">{0}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {item?.files?.map((subItem, i) => (
              <div className="col-lg-2 bg-white pb-3" key={i}>
                <div
                  className="cursor-pointer"
                  onClick={() => navigate(`/media/${item?._id}`)}
                >
                  <svg
                    className="w-full"
                    width="163"
                    height="115"
                    viewBox="0 0 163 115"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="163" height="115" rx="6" fill="#FCFAFF" />
                    <g clip-path="url(#clip0_3_24839)">
                      <path
                        d="M124 62.3092V19.4271C124 17.0209 122.03 15 119.624 15H96.5186C94.8156 15 93.2687 16.0274 92.5585 17.5752L90.2281 22.6918C89.5179 24.2396 87.971 25.2687 86.2681 25.2687H46.3503C43.9442 25.2687 42.0342 27.1805 42.0342 29.5868V69.261C42.0342 71.6672 43.9442 73.678 46.3503 73.678H119.624C119.739 73.678 119.853 73.612 119.966 73.6032V81.9296H120.029C120.029 83.0298 120.923 83.8799 122.014 83.8799C123.106 83.8799 124 82.9692 124 81.8777V62.3092Z"
                        fill="#A162F7"
                      />
                      <path
                        d="M122.014 83.8799C120.923 83.8799 120.029 83.0298 120.029 81.9296H119.966V41.0715C119.966 38.6651 118.07 36.6374 115.664 36.6374H42.3899C39.9837 36.6374 38 38.6651 38 41.0715V84.9313C38 87.3375 39.9837 89.2643 42.3899 89.2643H115.664C115.918 89.2643 116.166 89.2533 116.409 89.2115C116.709 89.2531 117.015 89.2821 117.328 89.2821C121.002 89.2821 123.954 86.3062 123.954 82.6315C123.954 82.5336 123.947 82.4375 123.943 82.3407C123.733 83.2146 122.947 83.8799 122.014 83.8799Z"
                        fill="#4F008A"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3_24839">
                        <rect
                          width="86"
                          height="74.4478"
                          fill="white"
                          transform="translate(38 15)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="row w-full mx-auto">
                  <div className="col-8 p-0">
                    <div className="text-[#000000] text-[12px] mb-[10px] break-all">
                      {subItem?.name}
                    </div>
                    <div className="flex justify-start gap-3 ">
                      <div className="flex gap-2">
                        <svg
                          className="w-full"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11 5.5V8.5C11 10.5 10.5 11 8.5 11H3.5C1.5 11 1 10.5 1 8.5V3.5C1 1.5 1.5 1 3.5 1H4.25C5 1 5.165 1.22 5.45 1.6L6.2 2.6C6.39 2.85 6.5 3 7 3H8.5C10.5 3 11 3.5 11 5.5Z"
                            stroke="#635F65"
                            stroke-miterlimit="10"
                          />
                          <path
                            d="M4 1H8.5C9.5 1 10 1.5 10 2.5V3.19"
                            stroke="#635F65"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <div className="text-[10px] text-[#635F65]">
                          {item?.files?.filter((item) => item?.type != "photo")
                            ?.length || 0}
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <svg
                          className="w-full"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.5 11H7.5C10 11 11 10 11 7.5V4.5C11 2 10 1 7.5 1H4.5C2 1 1 2 1 4.5V7.5C1 10 2 11 4.5 11Z"
                            stroke="#635F65"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M4.5 5C5.05228 5 5.5 4.55228 5.5 4C5.5 3.44772 5.05228 3 4.5 3C3.94772 3 3.5 3.44772 3.5 4C3.5 4.55228 3.94772 5 4.5 5Z"
                            stroke="#635F65"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.33496 9.4743L3.79996 7.8193C4.19496 7.5543 4.76496 7.5843 5.11996 7.8893L5.28496 8.0343C5.67496 8.3693 6.30496 8.3693 6.69496 8.0343L8.77496 6.2493C9.16496 5.9143 9.79496 5.9143 10.185 6.2493L11 6.9493"
                            stroke="#635F65"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <div className="text-[10px] text-[#635F65]">
                          {item?.files?.filter((item) => item?.type == "photo")
                            ?.length || 0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2 cursor-pointer">
                    <DropDownComp
                      dropDownText={<BsThreeDotsVertical />}
                      options={OptionDropDownII(subItem?.id, item._id)}
                    />
                  </div>
                </div>
              </div>
            ))}
            
            <div className="col-lg-2"></div>
            <div className="col-lg-2"></div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      ))}
      <Pagination current={current} next={next} numberOfPages={number_of_pages} setPage={setPage}/>
      {/* Assign Med Rep Modal */}
      <ModalComp
        handleClose={() => setShowAMRModal(false)}
        show={showAMRModal}
        position={"fixed top-2 right-[10%]"}
        bodyComponents={
          <AssignMRModal
            setShowAMRModal={setShowAMRModal}
            setAssuranceModal={setAssuranceModal}
            Data={assignedMedRepData}
            AMRSuccess={AMRSuccess}
            setMedRep={setMedRep}
            folderId={folderId}
            setIsAssigned={setIsAssigned}
            isAssigned={isAssigned}
            loader={loader}
            AMRFetcher={AMRFetcher}
            setUpdateModal={setUpdateModal}
          />
        }
      />

      {/* Assign Confirmation Modal */}
      <ModalComp
        handleClose={() => setAssuranceModal(false)}
        show={assuranceModal}
        position="hidden"
        bodyComponents={
          <ConfirmAssignModal
            setAssuranceModal={setAssuranceModal}
            // setSuccessModal={setSuccessModal}
            // assuranceModal={assuranceModal}
            save={handleSubmit}
            // isSuccess={isSuccess}
            // isFetching={isFetching}
            loader={AMRLoader}
            setIsAssigned={setIsAssigned}
          />
        }
      />
      {/* Update Confirmation Modal */}
      <ModalComp
        handleClose={() => setUpdateModal(false)}
        show={updateModal}
        position="hidden"
        bodyComponents={
          <ConfirmUpdateModal
            setUpdateModal={setUpdateModal}
            // setSuccessModal={setSuccessModal}
            // assuranceModal={assuranceModal}
            save={handleUpdate}
            // isSuccess={isSuccess}
            // isFetching={isFetching}
            loader={UMRLoader}
            setIsAssigned={setIsAssigned}
          />
        }
      />

      {/* Success Modal */}
      <ModalComp
        handleClose={() => setSuccessModal(false)}
        show={successModal}
        bodyComponents={<SuccessAmrModal setSuccessModal={setSuccessModal} />}
      />

      {/* Confirmation Delete Modal */}
      <ModalComp
        handleClose={() => setConfirmDelModal(false)}
        show={confirmDelModal}
        position="hidden"
        bodyComponents={
          <ConfirmDelModal
            setConfirmDelModal={setConfirmDelModal}
            setSuccessDelModal={setSuccessDelModal}
            payload={payload}
            onDelete={deleteFile}
            refetch={refetch}
            loader={isLoading}
          />
        }
      />
      {/* Success Delete Modal */}
      <ModalComp
        handleClose={() => setDeleteModal(false)}
        show={successDelModal}
        bodyComponents={
          <SuccessDelModal setSuccessDelModal={setSuccessDelModal} />
        }
      />
    </div>
  );
}
