export const tableData = [
  {
    null: "Proposed Goals",
    Month1: "225.0",
    Month2: "240.0",
    Month3: "255.0",
    Cycle: "Pending",
  },
  {
    null: "Proposed Days in Field",
    Month1: "15.0",
    Month2: "16.0",
    Month3: "17.0",
    Cycle: "Pending",
  },
  {
    null: "Sum of MCCP targets",
    Month1: "56.3",
    Month2: "120.0",
    Month3: "51.0",
    Cycle: "Completed",
  },
  {
    null: "Calls Per Target",
    Month1: "4.0",
    Month2: "2.0",
    Month3: "5.0",
    Cycle: "Pending",
  },
  {
    null: "Proposed Calls Per Day",
    Month1: "15.0",
    Month2: "15.0",
    Month3: "15.0",
    Cycle: "Pending",
  },
  {
    null: "Calls Per Department",
    Month1: "8.0",
    Month2: "9.0",
    Month3: "7.0",
    Cycle: "Pending",
  },
  {
    null: "Out Of Plan Visit",
    Month1: "5.0",
    Month2: "2.0",
    Month3: "3.0",
    Cycle: "Completed",
  },
];
