import React from "react";
import ButtonComp from "../../../Components/Ui/Button";
import Pagination from "react-bootstrap/Pagination";
import { HGPData } from "./Data";

export default function HisGracePharmacyComp() {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <div className="container">
      <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
        <div className="flex align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[25px] font-[700] text-sm-center">
            His Grace Pharmacy
          </div>
          <div className="ms-2">
            <img src="/icon/info-circle.svg" alt="" />
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
          <div className="flex align-items-center  border-2 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
            <div className="me-1">
              <img src="/Schedule/document-download.png" alt="" />
            </div>
            <ButtonComp
              className="text-[#44AA43] font-[500] text-[16px] "
              text={"Download Report"}
            />
          </div>
          {/* <div className="flex align-items-center border-2 border-[#4F0084]  bg-[#4F008A] rounded px-3 py-2 ms-2">
            <div className="me-1"></div>
            <ButtonComp
              className="text-[#FFFFFF] font-[500] text-[16px] "
              text={"Add New HCP"}
            />
          </div> */}
        </div>
      </div>
      <div className="flex md:flex-row flex-col mb-[65px]">
        <div className="text-[16px] font-[500] text-[#555555] pb-[20px] md:pt-0 pt-[10px] border-b pe-4">
          All Department
        </div>
        <div className="text-[16px] font-[500] text-[#A162F7] pb-[20px] md:pt-0 pt-[10px] border-b border-[#A162F7] pe-4">
          Dermatology
        </div>
        <div className="text-[16px] font-[500] text-[#555555] pb-[20px] md:pt-0 pt-[10px] border-b pe-4">
          Cardiology
        </div>
        <div className="text-[16px] font-[500] text-[#555555] pb-[20px] md:pt-0 pt-[10px] border-b pe-4">
          Internal Medicine
        </div>
        <div className="text-[16px] w-[25%] w-[46%] font-[500] text-[#555555] pb-[20px] md:border-b-[1px] md:border-b border-b-0">
          {""}
        </div>
      </div>
      <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
        <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
          <div className="border-r-2 ps-2 pe-3">
            <img className="" src="/icon/search.png" alt="search" />
          </div>
          {/* <input className="border-0 mb-3 ">
               <form
                 className="border-0"
                 placeholder="Search for Call Reports by ID, Name, Date, HCOs"
                 aria-label="Search for Call Reports by ID, Name, Date, HCOs"
                 aria-describedby="basic-addon2"
               ><form
             </input> */}
          <input
            className="w-100 text-[16px] font-[400]"
            type="text"
            placeholder="Search for Call Reports by ID, Name, Date, HCOs"
          />
        </div>
        <div className="flex  lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
          <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
            <div className="">Sort By</div>{" "}
            <div className="">
              <img src="/icon/sort.png" alt="" />
            </div>
          </div>
          <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
            <div className=""> Category: All</div>{" "}
            <div className="">
              <img src="/icon/select-arrows.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="table-container border border-1 rounded mb-[32px] w-full ">
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto ">
            <thead className="!bg-[#E5E5EF] ">
              <tr className="text-[14px] font-[500] font-instrument-sans">
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem]  lg:ps-[7px] py-[14px]">
                  ID
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px]">
                  HCP Info
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px]">
                  Speciality
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px]">
                  Phone Number
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px]">
                  Med Rep
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px]">
                  Status
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px]">
                  Date Added
                </th>
                <th className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[14px]">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {HGPData.map((data, i) => (
                <tr
                  className="text-[16px] font-[400] font-instrument-sans"
                  key={i}
                >
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] lg:ps-[7px] py-[18px] border-b">
                    {data.id}
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[18px] border-b">
                    <div className="lg:flex lg:space-x-2">
                      <div className="">
                        <img className="rounded-full" src={data.img} alt="" />
                      </div>
                      <div className="lg:flex lg:flex-col">
                        <div className="text-[14px] font-[500] text-[#333333]">
                          {data.name}
                        </div>
                        <div className="text-[10px] font-[400] text-[#777777]">
                          {data.post}
                          <span className="text-[10px] text-[#3DA84A] font-[400]">
                            {data.span}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[18px] border-b">
                    {data.speciality}
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[18px] border-b">
                    {data.phoneNumber}
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[18px] border-b">
                    {data.medRep}
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] my-[18px]  border-b text-[#044E54] ">
                    <div className="border border-1 border-#0071FB me-[10px] text-center py-[1px] px-[5px] rounded-full bg-[#0071FB24]">
                      {data.status}
                    </div>
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[18px] border-b">
                    {data.date}
                  </td>
                  <td className="md:px-[1rem] px-[1rem] lg:px-[0rem] py-[18px] border-b">
                    <img className="m-auto" src={data.icon} alt="" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between align-items-baseline pt-4 pb-2 px-3">
          <div className="text-[16px] lg:mb-0 mb-[1rem] font-[500]">
            <b>7</b> out 0f <b>14</b>
          </div>
          <div className="flex space-x-2 align-items-baseline">
            <div className="text-[16px] font-[500]">Previous</div>
            <Pagination size="sm">{items}</Pagination>
            <div className="text-[16px] font-[500]">Next</div>
          </div>
        </div>
      </div>
    </div>
  );
}
