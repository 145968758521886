import { useEffect } from "react";
import ButtonComp from "../../Components/Ui/Button";

export default function ConfirmDelModal({ setConfirmDelModal, setSuccessDelModal, payload, onDelete, refetch, loader }) {
    const handleDelete = async (param) => {
        await onDelete(param)
        refetch()
        setSuccessDelModal(true)
        setConfirmDelModal(false)
    }
  return (
    <div className="container">
      <h2 className="my-4 font-bold text-2xl">Delete Folder?</h2>
      <p className="font-medium text-lg mt-10 mb-7">
      This action will permanently delete the folder.<br/>  Are you sure you want to continue?
      </p>
      <div className="flex justify-center gap-3">
        <ButtonComp
          text={"Cancel"}
          btnClassName={
            "border-2 border-[#3DA84A] w-32 mt-3 rounded text-[#3DA84A] h-10"
          }
          onClick={() => setConfirmDelModal(false)}
        />{" "}
        <ButtonComp
          text={"Delete"}
          btnClassName={
            "bg-[#D63434] w-32 mt-3 mr-5 rounded text-white h-10"
          }
          loader={loader}
          disabled={loader}
          onClick={() => handleDelete(payload)}
        />{" "}
      </div>
    </div>
  );
}
