import { useState } from 'react';
// import Header from '../components/Header';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Components/Sidebar';
import withAuth from '../Hoc/withAuth';
import Notifications from '../Components/Common/Notification';
import { useTranslation } from 'react-i18next';

const DefaultLayout = () => {
  const { t } = useTranslation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showNotification,setShowNotification] = useState(false);

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex flex-nowrap h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar showNotification={showNotification} setShowNotification={setShowNotification} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-x-hidden">
          {showNotification &&<Notifications/>}

          <main onClick={()=>setShowNotification(false )}>
            <div className=" xl:px-5  pt-3">
              <Outlet />
            </div>
          </main>
        </div>
       
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
};

export default  withAuth( DefaultLayout);
