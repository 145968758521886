import React, { useState } from "react";
import Nav from "react-bootstrap/Nav";
import ButtonComp from "../../Components/Ui/Button";
import { LanguageName, downloadCSV } from "../../utils/helper";
import KeyMessage from "./ModalMessageSubModules/KeyMessage";
import CallReportModal from "./ModalMessageSubModules/CallReportModal";
import CallReportModalNote from "./ModalMessageSubModules/CallReportModalNote";

export default function CallReportModalMessages({ userData, extraData }) {
  //KeyMessage

  const TopHeader = [
    {
      name: LanguageName("BIO"),
      component: <CallReportModal userData={userData} extraData={extraData} />,
    },
    {
      name: LanguageName("Note"),
      component: <CallReportModalNote userData={userData} />,
    },
    {
      name: LanguageName("KM"),
      component: <KeyMessage userData={userData} />,
    },
  ];
  const [selected, setSelected] = useState("Basic Info");
  return (
    <div className="p-4 bg-[#FFFFFF]">
      <div className="container">
        <div className="flex justify-content-between items-center mb-[57px]">
          <div className="text-[24px] font-[700]">
            {LanguageName("CR")} #{userData?._id?.substring(0, 5)}
          </div>
          <div className="text-[14px] text-[#044E54] bg-[#04A06826] rounded-full py-1 px-3 font-[500] capitalize">
            {userData?.status}
          </div>
        </div>
        <div className="flex align-items-center space-x-2 mb-[54px]">
          <div className="">
            <img
              className=" rounded-full"
              src="/CallReports/man-portrait.svg"
              alt=""
            />
          </div>
          <div className="flex flex-col">
            {/* <div className="text-[20px] font-[500]"> */}
            {/*   {userData?.medRep?.firstName} {userData?.medRep?.lastName} */}
            {/* </div> */}
            <div className="text-[20px] font-[500]">
              {userData?.personalInformation?.practionerName}
            </div>
            <div className="text-[14px] text-[#777777] font-[400]">
              {userData?.personalInformation?.hcp?.position || "Doctor"} at{" "}
              <span className="text-[#3DA84A]">
                {userData?.schedule?.institutionName}
              </span>
            </div>
          </div>
        </div>
        <Nav
          className="bg-[#4F008A0D] rounded w-[90%] pr-2 !mb-[53px] grid grid-cols-3 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5"
          defaultActiveKey="/home"
        >
          {TopHeader?.map(
            (item, i) => (
              //  <Nav.Item className="">
              <div
                onClick={() => setSelected(item?.name)}
                className={`${
                  selected === item?.name
                    ? "bg-[#FFFFFF] !text-[#4F008A]"
                    : "bg-transparent text-[#555555]"
                } py-2 px-3 cursor-pointer my-2 ms-2 !text-[12px] !font-[500] `}
              >
                {item?.name}
              </div>
            )
            //  </Nav.Item>
          )}
        </Nav>

        <div className="mb-20">
          {TopHeader?.find((item) => item?.name === selected)?.component}
        </div>
      </div>
      <div className="flex px-9 pb-8 space-x-4 absolute bottom-0 right-0 bg-white py-3 left-0 justify-start items-start shadow-sm">
        <div
          className="flex py-2 px-7 rounded-full border-[0.64px] !border-[#8354CF] cursor-pointer"
          onClick={() => downloadCSV([userData], "Call_Report.csv", false)}
        >
          <div className="mr-2">
            <img
              className=""
              src="/CallReports/download-purple.png"
              alt="Download icon"
            />
          </div>
          <ButtonComp className="text-[#282728]" text={LanguageName("R")} />
        </div>
        {/* <div className="">
          <ButtonComp
            className="py-2 px-3 rounded-full bg-[#4F008A] text-[#FFFFFF]"
            text={LanguageName("ACT")}
          />
        </div> */}
      </div>
    </div>
  );
}
