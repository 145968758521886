import React from "react";
import ButtonComp from "../../../Components/Ui/Button";
import { Dots } from "../../../utils/svg";
import { LanguageName } from "../../../utils/helper";

export default function CallReportCard({
  item,
  setOffCanvas,
  setUserData,
  setModal,
}) {
  const extractAbbreviation = (str) => {
    const match = str.match(/\(([^)]+)\)/);
    return match ? match[1] : str;
  };
  return (
    <div className="">
      <div className="flex flex-col border border-1 rounded py-[15px] px-[20px]">
        <div className="flex pr-3  justify-between">
          <div className="">
            <img src="/Overview/phone.svg" alt="" />
          </div>
          <div className="text-[15px] font-[500] ps-[10px] mb-[19px] ">
            {item?.personalInformation?.practionerName}
            <br />
            <span className="text-[10px] font-[400] text-[#777777] pe-[30px]">
              {item?.personalInformation?.hcp?.position} at
              <span className="ps-1 text-[10px] font-[400] text-[#3DA84A]">
                {item?.personalInformation?.hcp?.institutionName}
              </span>
            </span>
          </div>
          {/* <div className="">
            <Dots />
          </div> */}
        </div>
        <div className="">
          <div className="flex mb-[25px]">
            <div className="bg-[#FEECEC80] font-[500] text-[12px] px-2 py-1 rounded-full flex align-items-center me-2">
              <div className="me-2">
                <img src="/Overview/person.svg" alt="" />
              </div>
              {item?.medRep?.firstName} {item?.medRep?.lastName}
            </div>
            <div className="bg-[#F3F1FB] px-2 py-1 rounded-full font-[500] text-[12px]">
              {extractAbbreviation(item?.personalInformation?.category)}
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="mb-[17px] text-[13px] font-[500]">
            <span className="text-[11px] font-[500]  text-[#555555]">
              {LanguageName("Department")}
            </span>{" "}
            <br /> {item?.personalInformation?.hcp?.department}
          </div>
          <div className=" text-[13px] font-[500]">
            <span className="text-[11px] font-[500]  text-[#555555]">
              {LanguageName("CO")}
            </span>{" "}
            <br /> {item?.notes?.objectionNotes}
          </div>
        </div>
        <div className="flex justify-content-between align-items-center mt-[20px]">
          <ButtonComp
            onClick={() => {
              setUserData(item);
              setOffCanvas(true);
            }}
            className="text-[#4F008A] text-[14px] font-[500] rounded border border-[0.57px] border-[#8354CF73] py-[9px] px-[30px]"
            text={LanguageName("VD")}
          />
          <ButtonComp
            onClick={() => {
              setUserData(item);
              setModal(true);
            }}
            className={`text-[#FFFFFF] text-[14px] font-[500]  border-[0.57px] rounded border-[#8354CF73] bg-[#8354CF] py-[9px] lg:px-[30px] md:px-[30px] px-[28px] ${
              item?.status === "approved" ? "opacity-30" : ""
            }`}
            text={LanguageName("Approve")}
            disabled={item?.status === LanguageName("Approved")}
          />
        </div>
      </div>
    </div>
  );
}
