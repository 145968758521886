export const TrackData = [
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
  {
    number: "01",
    name: "Olakunbi Olabode 0.",
    pending: "030",
    completed: "010",
  },
];
