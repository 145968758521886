export const HGPData = [
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
];
