import React, { useEffect, useState } from "react";
import ButtonComp from "../../Components/Ui/Button";
import Pagination from "react-bootstrap/Pagination";
import { TopHeaderCount, columnsData, tableData } from "./Data";
import { TableComp } from "../../Components/Common/TableComp";
import OffCanvasComp from "../../Components/Ui/OffCanvas";
import ViewEvent from "./canvas";
import TopHeaderCounts from "../../Components/Common/TopHeaderCounts";
import { TrackIcon } from "../../utils/svg";
import UpdateEvent from "./canvas/updateEvent";
import { LanguageName, downloadCSV } from "../../utils/helper";
import CreateEvent from "./canvas/createEvent";
import DropDownComp from "../../Components/Ui/DropDown";
import moment from "moment";

export default function EventsComp({ Events, eventLoader, refetch }) {
  const [singleEvent, setSingleEvent] = useState();
  const [headerSelected, setHeaderSelected] = useState("Total Events");
  const [searchEvents, setSearchEvents] = useState("");
  const [openDrawer, setOpenDrawer] = useState("");
  const [filteredItems, setFilteredItems] = React.useState(Events);
  const [sortOrder, setSortOrder] = React.useState("date"); // default sort by name

  const OptionDropDown = () => [
    {
      name: (
        <div className="flex gap-1 items-center text-[#656468] text-[12px]">
          <TrackIcon />
          {LanguageName("VEI")}
        </div>
      ),
      action: (data) => {
        setSingleEvent(data);
        setOpenDrawer("ViewEvent");
      },
    },
  ];

  const DrawerPages = [
    {
      name: "ViewEvent",
      component: <ViewEvent singleEvent={singleEvent} />,
    },
    {
      name: "UpdateEvent",
      component: (
        <UpdateEvent
          singleEvent={singleEvent}
          setOpenDrawer={setOpenDrawer}
          refetch={refetch}
        />
      ),
    },
    {
      name: "CreateEvent",
      component: (
        <CreateEvent
          singleEvent={singleEvent}
          setOpenDrawer={setOpenDrawer}
          refetch={refetch}
        />
      ),
    },
  ];

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };
  function sortItems(items, sortOrder) {
    console.log(items, "eventItem");
    if (!items) return [];
    return items?.slice().sort((a, b) => {
      if (sortOrder === "name") {
        return a.institution.localeCompare(b.institution);
      } else if (sortOrder === "date") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
    });
  }
  const OptionSortDropDown = (row) => [
    {
      name: "Date created",
      action: () => handleSortChange("date"),
    },
    {
      name: "Name",
      action: () => handleSortChange("name"),
    },  
  ];
  const OptionCategoryDropDown = (row) => [
    {
      name: LanguageName("TE"),
      action: () => {
        setHeaderSelected(LanguageName("TE"));
        setSearchEvents("");
        handleSortChange("date");
      },
    },
    {
      name: LanguageName("CE2"),
      action: () => {
        setHeaderSelected(LanguageName("CE2"));
        setSearchEvents("completed");
        handleSortChange("date");
      },
    },
    {
      name: LanguageName("CE1"),
      action: () => {
        setHeaderSelected(LanguageName("CE1"));
        setSearchEvents("pending");
        handleSortChange("date");
      },
    },
    {
      name: LanguageName("OE"),
      action: () => {
        setHeaderSelected(LanguageName("OE"));
        setSearchEvents("checked-in");
        handleSortChange("date");
      },
    },
  ];

  function searchItems(items, searchTerm) {
    const search = searchTerm?.toLowerCase() || "";
    return items?.filter((item) => {
      const itemName = item?.institution?.toLowerCase() || "";
      const itemStatus = item?.status?.toLowerCase() || "";
      const idMatch = item?._id.toString().toLowerCase();
      const dateMatch = moment(item?.createdAt).format('YYYY-MM-DD').toLowerCase() || ""; // Format date to 'YYYY-MM-DD'
  
      return (
        itemName.includes(search) ||
        itemStatus.includes(search) ||
        idMatch.includes(search) ||
        dateMatch.includes(search)
      );
    });
  }

  useEffect(() => {
    const filtered = searchItems(Events, searchEvents);
    setFilteredItems(sortItems(filtered, sortOrder));
  }, [searchEvents, Events, sortOrder]);

  return (
    <div>
      <div className="container">
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem]">
            <div className="text-[25px] font-[700]">{LanguageName("EVT")}</div>
            <div className="ms-2">
              <img src="/icon/info-circle.svg" alt="" />
            </div>
          </div>
          <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
            <div className="flex align-items-center  border-[1px] border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
              <div className="me-1">
                <img src="/Schedule/document-download.png" alt="" />
              </div>
              <ButtonComp
                onClick={() =>
                  downloadCSV(
                    Events?.filter((item) => {
                      const itemName = item?.institution?.toLowerCase() || "";
                      const itemCompany = item?.type?.toLowerCase() || "";
                      const itemStatus = item?.name?.toLowerCase() || "";
                      const search = searchSchedule?.toLowerCase() || "";

                      return (
                        itemName.includes(search) ||
                        itemCompany.includes(search) ||
                        itemStatus.includes(search)
                      );
                    }),
                    "Events_Report.csv",
                    eventLoader
                  )
                }
                className="text-[#44AA43] font-[500] text-[16px] "
                text={LanguageName("SDR")}
              />
            </div>
            <div className="flex align-items-center border-1 border-[#4F0084]  bg-[#4F008A] rounded px-3 py-2 ms-2">
              <div className="me-1">
                <img src="/Events/plus.svg" alt="radar" />
              </div>
              <ButtonComp
                onClick={() => setOpenDrawer("CreateEvent")}
                className="text-[#FFFFFF] font-[500] text-[16px] "
                text={LanguageName("CE")}
              />
            </div>
          </div>
        </div>
        <div className="flex overflow-x-scroll !xl:overflow-x-hidden lg:overflow-hidden  lg:mb-[20px] md:mb-[45px] mb-[45px] customScrollHorizontal">
          {TopHeaderCount(Events)?.map(({ name, count, findBy }, i) => (
            <TopHeaderCounts
              key={i}
              name={name}
              count={count}
              isActive={headerSelected === name}
              setHeaderSelected={setHeaderSelected}
              Data={() => {
                setSearchEvents(findBy);
              }}
            />
          ))}
        </div>
        <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
          <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
            <div className="border-r-[1px] ps-2 pe-3">
              <img className="" src="/icon/search.png" alt="search" />
            </div>
            <input
              className="w-100 text-[16px] font-[400]"
              type="text"
              placeholder={LanguageName("SER")}
              onChange={(e) => setSearchEvents(e.target.value)}
            />
          </div>
          <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <DropDownComp
                dropDownText={LanguageName("SB")}
                options={OptionSortDropDown()}
              />
              <div className="">
                <img src="/icon/sort.png" alt="" />
              </div>
            </div>
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <div className="">
              <DropDownComp
                  dropDownText={`${LanguageName("CTY")}: ${LanguageName(
                    "All"
                  )}`}
                  options={OptionCategoryDropDown()}
                />
              </div>{" "}
              <div className="">
                <img src="/icon/select-arrows.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="table-container border border-1 rounded mb-[32px] w-full ">
          <div className="overflow-x-auto">
            <TableComp
              loader={eventLoader}
              columnsComp={columnsData(OptionDropDown)}
              // Data={Events}
              Data={filteredItems}
            />
          </div>
        </div>
      </div>
      <OffCanvasComp
        show={openDrawer ? true : false}
        handleClose={() => setOpenDrawer(null)}
        bodyComponent={
          DrawerPages?.find((item) => item?.name === openDrawer)?.component
        }
      />
    </div>
  );
}
