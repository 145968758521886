import moment from "moment";
import React, { useState } from "react";
import Activities from "./HCPListCompSubmodules/Activities";
import Schedule from "./HCPListCompSubmodules/Schedule";
import Plan from "./HCPListCompSubmodules/Plan";
import ButtonComp from "../../../Components/Ui/Button";
import { toast } from "react-toastify";
import { LanguageName, downloadCSV } from "../../../utils/helper";
import { useActivateMedrepMutation } from "../../../store/MedRep/MedRepApi";
import { ArrowLeft } from "../../../utils/svg";
import { useNavigate } from "react-router-dom";
import Achievement from "./HCPListCompSubmodules/Achievement";

export default function HCPListComp({
  userSchedule,
  loader,
  userDetail,
  schedule,
  activities,
  planData,
}) {
  const [selectHeader, setSelectHeader] = useState(
    LanguageName("Activities") || "Activities"
  );
  const List = [
    {
      name: LanguageName("Activities"),
      components: <Activities activities={activities} />,
    },
    {
      name: LanguageName("Sched"),
      components: <Schedule userSchedule={schedule} />,
    },
    {
      name: "Plan",
      components: <Plan planData={planData} />,
    },
    {
      name: LanguageName("Achievement"),
      components: <Achievement />,
    },
  ];
  const navigate = useNavigate();
  const [activateMedrep, { isLoading }] = useActivateMedrepMutation();
  const [res, setRes] = useState(null);
  const onActivate = async (parameter) => {
    try {
      const response = await activateMedrep({
        id: userDetail.id,
        body: { active: parameter },
      });

      if (!response?.data?.status) {
        toast.error(response?.data?.message);
      } else {
        if (parameter === false) {
          toast.success("Medrep Account Activated");
        } else {
          toast.success("Medrep Account Deactivated");
        }
        setRes(response?.data?.data?.active);
      }
    } catch (error) {
      // Handle error
      console.error("An error occurred:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  // console.log(userDetail)
  return (
    <div className="container">
      <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
        <div className="flex align-items-center lg:mb-0 mb-[1rem]">
          <div
            className="flex align-items-center cursor-pointer lg:mb-0 mb-[1rem]"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft />
          </div>
          <div className="text-[25px] font-[700] ml-4">
            {LanguageName("MR")}
          </div>

          <div className="ms-2">
            <img src="/icon/info-circle.svg" alt="" />
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
          <div className="flex align-items-center  border-1 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
            <div className="me-1">
              <img src="/Schedule/document-download.png" alt="" />
            </div>
            <ButtonComp
              onClick={() =>
                downloadCSV(
                  [...activities?.data, ...schedule?.data, ...planData?.data],
                  `MedRep. ${userDetail?.firstName} ${userDetail?.lastName}.csv`,
                  planData?.loader
                )
              }
              className="text-[#44AA43] font-[500] text-[16px] "
              text={LanguageName("DR")}
            />
          </div>
          {/* <div className="flex align-items-center border-1 border-[#4F0084]  bg-[#4F008A] rounded px-3 py-2 ms-2"> */}
          {/*   <div className="me-1"></div> */}
          {/*   <ButtonComp */}
          {/*     className="text-[#FFFFFF] font-[500] text-[16px] " */}
          {/*     onClick={() => setShowCavans(true)} */}
          {/*     // text={LanguageName("ANMR")} */}
          {/*     text="oastersnot" */}
          {/*   /> */}
          {/* </div> */}
        </div>
      </div>
      <div className="row gap-3 d-flex mb-5">
        <div className="col-md-5 border rounded pt-10">
          <div className="flex flex-col">
            <div className="flex justify-center items-center mx-auto w-32 h-32 rounded-full overflow-hidden">
              <img
                src="/HCPList/profile.svg"
                className="w-full h-full object-cover"
                alt=""
              />
            </div>

            <div className="text-[28px] text-[#242731] text-center font-[700] mt-[30px]">
              {userDetail?.title} {userDetail?.firstName} {userDetail?.lastName}
            </div>
            <div className="text-center mt-1 mb-6">
              <p className="text-lg font-medium">
                {`${LanguageName("MR")} ${
                  userDetail?.state.length > 0
                    ? `| ${userDetail?.state[0]}`
                    : ""
                }`}
              </p>
            </div>
            <div className="flex space-x-4 flex-row justify-center border-b mx-4 pb-[30px]">
              {/* <div className="flex align-items-center">
                <div className="me-2">
                  <img className="" src="/HCPList/calendar.png" alt="" />
                </div>
                <div className="text-[#777777] text-[16px] font-[500]">
                  {moment(userDetail?.createdAt).format("Do MMMM  YY")}
                </div>
              </div> */}
              <div
                className={`flex justify-center grow align-items-center bg-[#3DA84A26] border-2 py-2 rounded ${
                  res || userDetail?.status
                    ? "border-[#3DA84A]"
                    : "border-[#ff0000]"
                }`}
              >
                <div
                  className={`me-2 w-4 h-4 rounded-full ${
                    res || userDetail?.status ? "bg-[#44AA43]" : "bg-[#ff0000]" 
                  }`}
                />
                <div
                  className={`${
                    res || userDetail?.status
                    ? "text-[#44AA43]"
                    : "text-[#ff0000]"
                  } text-sm font-[500] `}
                >
                  {res || !userDetail?.status
                    ? LanguageName("PAC")
                    : LanguageName("PIAC")}
                </div>
              </div>
              {res || userDetail?.status ? (
                <ButtonComp
                  btnClassName="cursor-not-allowed flex border border-2 justify-center grow-2 text-sm items-center text-white py-2 rounded bg-[#D63434] w-full md:w-auto px-4"
                  text={LanguageName("DAC")}
                  onClick={() => onActivate(false)}
                  loader={isLoading}
                />
              ) : (
                <ButtonComp
                  btnClassName="cursor-not-allowed flex border border-2 justify-center grow-2 text-sm items-center text-white py-2 rounded bg-[#44AA43] w-full md:w-auto px-4"
                  text={LanguageName("AAC")}
                  onClick={() => onActivate(true)}
                  loader={isLoading}
                />
              )}
            </div>
          </div>
          <div className="px-4">
            <div className="flex justify-between items-center my-[40px]">
              <p className="font-normal text-[#A1A1A1]">{LanguageName("DC")}</p>
              <div className="text-[#333333] text-lg font-[500]">
                {moment(userDetail?.createdAt).format("Do MMMM  YY")}
              </div>
            </div>
            <div className="flex justify-between items-center mb-[40px]">
              <p className="font-normal text-[#A1A1A1]">{LanguageName("EA")}</p>
              <div className="text-[#333333] text-lg font-[500]">
                {userDetail?.email || "No Email Added yet"}
              </div>
            </div>
            <div className="flex justify-between items-center mb-[40px]">
              <p className="font-normal text-[#A1A1A1]">
                {LanguageName("PNB")}
              </p>
              <div className="text-[#333333] text-lg font-[500]">
                {userDetail?.phoneNumber || "-"}
              </div>
            </div>
            <div className="flex justify-between items-center mb-[40px]">
              <p className="font-normal text-[#A1A1A1]">
                {LanguageName("Address")}
              </p>
              <div className="text-[#333333] text-lg font-[500] ">
                {userDetail?.company.address}
              </div>
            </div>

            {/* <div className="flex justify-between ">
              <p className="font-normal text-[#A1A1A1]">Location</p>
              {userDetail?.address?.map((address) => (
                <div className="text-[18px] font-[500] text-[#333333] mb-[80px]">
                  {address.slice(0, 25)}
                </div>
              ))}
              {/* , {userDetail?.country} */}
            {/* </div>  */}
          </div>
        </div>
        <div className="col-md-6 border rounded pt-10 px-4 flex-grow-1 ">
          <div className="flex mb-[65px]">
            {List?.map((item, i) => (
              <div
                key={i}
                onClick={() => setSelectHeader(item?.name)}
                className={`${
                  selectHeader === item?.name
                    ? "border-[#4F008A] text-[#4F008A] "
                    : "text-[#555555]"
                } cursor-pointer text-[18px] font-[500] pb-[20px] border-b  pe-4`}
              >
                {item?.name}
              </div>
            ))}

            <div className="text-[18px] w-[10%] font-[500] text-[#555555] pb-[20px] border-b">
              {""}
            </div>
          </div>
          {List?.find((item, i) => item?.name === selectHeader)?.components}
        </div>
      </div>
    </div>
  );
}
