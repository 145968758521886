import React from "react";
import EditHCPProComp from "./EditHCPProComp";
import { useParams } from "react-router-dom";
import { useGetHCPQuery, useGetSingleHCPQuery } from "../../../../store/MedRep/MedRepApi";

function EditHCPProfile() {
  const { id } = useParams();
  const { data, isSuccess } = useGetSingleHCPQuery(id);
  
  return (
    <div>
      <EditHCPProComp
        getHcp={data?.data?.hcp}
        isSuccess={isSuccess}
      />
    </div>
  );
}

export default EditHCPProfile;
