import React from "react";
import ButtonComp from "../../Components/Ui/Button";

export default function PanadolEfficacyComp() {
  return (
    <div className="container">
      <div className="border-b-2 mb-[71px]">
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem]">
            <div className="text-[25px] font-[700]">
              Panadol <span className="text-[#4F008A]">Efficacy</span>
            </div>
            <div className="ms-2">
              <img src="/icon/info-circle.svg" alt="" />
            </div>
          </div>
          <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
            <div className="flex align-items-center  border-2 border-[#4F008A] bg-[#4F008A] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
              <div className="me-1">
                <img
                  className="me-2"
                  src="/PanadolEfficacy/arrow-up.png"
                  alt=""
                />
              </div>
              <ButtonComp
                className="text-[#FFFFFF] font-[500] text-[16px] "
                text={"Upload"}
              />
            </div>
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
          <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
            <div className="border-r-2 ps-2 pe-3">
              <img className="" src="/icon/search.png" alt="search" />
            </div>
            {/* <input className="border-0 mb-3 ">
                   <form
                     className="border-0"
                     placeholder="Search for Call Reports by ID, Name, Date, HCOs"
                     aria-label="Search for Call Reports by ID, Name, Date, HCOs"
                     aria-describedby="basic-addon2"
                   ><form
                 </input> */}
            <input
              className="w-100 text-[16px] font-[400]"
              type="text"
              placeholder="Search for Call Reports by ID, Name, Date, HCOs"
            />
          </div>
          <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <div className="">Sort By</div>{" "}
              <div className="">
                <img src="/icon/sort.png" alt="" />
              </div>
            </div>
            <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
              <div className=""> Category: All</div>{" "}
              <div className="">
                <img src="/icon/select-arrows.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#FCFAFF] py-[25px] px-[35px] mb-[44px]">
        <div className="row mb-[38px]">
          <div className="col-lg-2 col-md-6 my-3">
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/malaria.png"
              alt=""
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3">
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/malaria.png"
              alt=""
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3">
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/txt.png"
              alt="clinical-safety"
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3">
            {" "}
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/txt.png"
              alt="clinical-safety"
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3">
            {" "}
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/mp4.png"
              alt="clinical-safety"
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3"></div>
        </div>
        <div className="row">
          <div className="col-lg-2 col-md-6 my-3">
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/txt.png"
              alt=""
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3">
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/txt.png"
              alt=""
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3">
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/mp4.png"
              alt="clinical-safety"
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3">
            {" "}
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/jpt.png"
              alt="clinical-safety"
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3">
            {" "}
            <img
              className="mx-auto lg:mx-auto"
              src="/PanadolEfficacy/jpt.png"
              alt="clinical-safety"
            />
          </div>
          <div className="col-lg-2 col-md-6 my-3"></div>
        </div>
      </div>
    </div>
  );
}
