import React from "react";
import InputComp from "./InputComp";
import { Controller } from "react-hook-form";
import SelectComp from "./SelectComp";
import CheckboxComp from "./Checkbox";
import SelectCompI from "./SelectCompI";
import { useTranslation } from "react-i18next";

export default function FormUi({ formData = [], control,containerClassName,wrapperClassName,labelClassName }) {
  const { t } = useTranslation();
  return formData?.map((item, i) => (
    <Controller
      name={item?.name}
      rules={{ required:`${item?.label} is required`,pattern:item?.pattern,minLength:item?.minLength }}
      control={control}
      render={({ field: { onChange, onBlur, value },formState:{errors} }) => (
        item?.type ==="select" ?
        <SelectCompI
          label={item?.label}
          labelClassName={item?.labelClassName||labelClassName}
          wrapperClassName={wrapperClassName||item?.className}
          containerClassName={containerClassName||item?.containerClassName}
          value={value}
          onChange={onChange}
          error={errors[item?.name]?.message}
          options={item?.options}
          placeholder={item?.placeholder}

        />
        :item?.type ==="input"||item?.type ==="password"||item?.type ==="date"||!item?.type ?
        <InputComp
          label={item?.label}
          labelClassName={item?.labelClassName || labelClassName}
          wrapperClassName={item?.className || wrapperClassName}
          containerClassName={item?.containerClassName || containerClassName}
          value={value}
          onChange={onChange}
          placeholder={item?.placeholder}
          error={errors[item?.name]?.message}
          type={item?.type}
          disabled={item.disabled}
        />
        :item?.type ==="upload" ?
        <InputComp
          label={item?.label}
          // containerClassName={item}
          value={value}
          onChange={(e)=>onChange(e.target.files)}
          error={errors[item?.name]?.message}
          type={'file'}
          accept={item?.accept}
        />: item?.type === "telephone" ?
        <InputComp
          label={item?.label}
          // containerClassName={item}
          value={value}
          onChange={(e)=>onChange(e.target.files)}
          error={errors[item?.name]?.message}
          type={'telephone'}
          accept={item?.accept}
        />
        :item?.type ==="checkbox" &&
        <CheckboxComp
          label={item?.label}
          // containerClassName={item}
          value={value}
          onChange={onChange}
          error={errors[item?.name]?.message}

        />
      )}
    />
  ));
}
