import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const PlansApi = createApi({
  reducerPath: "PlansApi",
  baseQuery: baseQuery,
  tagTypes: ["Plans", "MedRepDetails", "SinglePlan"], // Define the tag types used in the API
  endpoints: (builder) => ({
    createPlan: builder.mutation({
      query: (payload) => ({
        url: "/plans/create",
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response,
      invalidatesTags: ["Plans"], // Invalidates getPlans query
    }),
    updatePlan: builder.mutation({
      query: (payload) => ({
        url: "/plans/update",
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response,
      invalidatesTags: (result, error, { medRepId, planId }) => [
        { type: "MedRepDetails", id: medRepId },
        { type: "SinglePlan", id: planId },
        "Plans", // Also invalidate getPlans
      ],
    }),
    getPlans: builder.query({
      query: () => ({
        url: "/plans/company/medreps",
        method: "GET",
      }),
      providesTags: ["Plans"],
    }),
    getPlansSummary: builder.query({
      query: () => ({
        url: `/plans/hcps/summary`,
        method: "GET",
      }),
    }),
    getNewPlansSummary: builder.query({
      query: () => ({
        url: `/plans/hcps/summary`,
        method: "GET",
      }),
    }),
    getPlansOverview: builder.query({
      query: (id) => ({
        url: `/plans/overview?medRepId=${id}`,
        method: "GET",
      }),
    }),
    getPlansAnalytics: builder.query({
      query: () => ({
        url: "/analytics/admin/overview",
        method: "GET",
      }),
    }),
    getHCP: builder.query({
      query: () => ({
        url: "/hcp/company",
        method: "GET",
      }),
    }),
    getPlanHOC: builder.query({
      query: () => ({
        url: "/plans/hco?hco=institution&date=2023-10-21",
        method: "GET",
      }),
    }),
    createHCP: builder.mutation({
      query: (payload) => ({
        url: "/hcp",
        method: "POST",
        body: payload,
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["Media"],
    }),
    getHcpSchedules: builder.query({
      query: (id) => ({
        url: `/schedules/${id}`,
        method: "GET",
      }),
    }),
    getMedRepDetails: builder.query({
      query: (id) => ({
        url: `/plans/medRep/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "MedRepDetails", id }],
    }),
    getNewMedRepDetails: builder.query({
      query: ({ id, page }) => ({
        url: `/plans/medRep/${id}?page=${page}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "MedRepDetails", id }],
    }),
    getMedRepHCPDetails: builder.query({
      query: (id) => ({
        url: `/plans/medRep/hcp/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'MedRepDetails', id }],
    }),
    getMedRepHCP: builder.query({
      query: (id) => ({
        url: `hcp/med-rep/staff/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, id) => [{ type: 'MedRepDetails', id }],
    }),
    getMedRepDetailsHCp: builder.query({
      query: (id) => ({
        url: `/hcp/med-rep/${id}`,
        method: "GET",
      }),
    }),
    getNewMedRepDetailsHCp: builder.query({
      query: (id) => ({
        url: `/hcp/med-rep/${id}?limit=300`,
        method: "GET",
      }),
    }),
    getMedRepHCP: builder.query({
      query: (id) => ({
        url: `/hcp/med-rep/staff/${id}`,
        method: 'GET',
      }),
    }),
    getMedRepCyclePlan: builder.query({
      query: (id) => ({
        url: `/plans/cycle?medRepId=${id}&date=2023-10-02`,
        method: "GET",
      }),
    }),
    getHCPViaMedRep: builder.query({
      query: (id) => ({
        url: `/plans/medrep/hcps/${id}`,
        method: "GET",
      }),
    }),
    getSinglePlan: builder.query({
      query: (id) => ({
        url: `/plans/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "SinglePlan", id }],
    }),
  }),
});

export const {
  useCreatePlanMutation,
  useUpdatePlanMutation,
  useGetPlansQuery,
  useGetPlansOverviewQuery,
  useGetPlansAnalyticsQuery,
  useCreateHCPMutation,
  useGetPlanHOCQuery,
  useGetPlansSummaryQuery,
  useLazyGetMedRepDetailsQuery,
  useGetMedRepDetailsQuery,
  useGetMedRepHCPDetailsQuery,
  useGetMedRepCyclePlanQuery,
  useGetMedRepDetailsHCpQuery,
  useGetMedRepHCPQuery,
  useGetHCPViaMedRepQuery,
  useGetSinglePlanQuery,
  useLazyGetNewMedRepDetailsQuery,
  useGetNewMedRepDetailsHCpQuery,
} = PlansApi;
