import { REGEX_PATTERNS } from "../../../../utils/constants/errors";
import { LanguageName } from "../../../../utils/helper";

export const FormLabel = [
  {
    name: "firstName",
    label: "First Name",
    className: "col-lg-6",
    type: "",
    placeholder: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
    className: "col-lg-6",
    type: "",
    placeholder: "Last Name",
  },
  {
    name: "email",
    label: "Email Address",
    className: "col-lg-12 mt-4 mb-4",
    type: "",
    placeholder: "Enter Email Address",
  },
  {
    name: "address",
    label: "Company’s Full Address",
    className: "col-lg-12 mb-4",
    type: "",
    placeholder: "",
  },
  // {
  //   name: "role",
  //   label: "Role",
  //   className: "col-lg-12 mb-4",
  //   type: "",
  //   placeholder: "",
  // },
  {
    name: "phoneNumber",
    label: "Phone Number",
    className: "col-lg-12 mb-4",
    type: "",
    pattern: REGEX_PATTERNS?.NUMBER,
    placeholder: "Phone Number",
  },
  {
    name: "contactPerson",
    label: "Contact Person's name",
    className: "col-lg-12 mb-4 cursor-not-allowed",
    type: "",
    placeholder: "",
  },

  {
    name: "country",
    label: "Country",
    className: "col-lg-6 mb-4",
    type: "",
    placeholder: "Choose Country",
  },
  {
    name: "state",
    label: "State",
    className: "col-lg-6 mb-4",
    type: "",
    placeholder: "Choose State",
  },
];

export const FormLabelII = () => {
  return [
    {
      name: "firstName",
      label: LanguageName("MFN"),
      className: "col-lg-6",
      type: "",
      placeholder: "Enter Company’s Name",
    },
    {
      name: "email",
      label: LanguageName("EA"),
      className: "col-lg-6",
      type: "",
      placeholder: "Enter Email Address",
    },
    {
      name: "lastName",
      label: LanguageName("MLN"),
      className: "col-lg-6",
      type: "",
      placeholder: "Enter Manager’s Name",
    },
    {
      name: "phoneNumber",
      label: LanguageName("PNN"),
      className: "col-lg-6",
      type: "",
      placeholder: "",
    },
    {
      name: "companyAddress",
      label: LanguageName("CFA"),
      className: "col-lg-6",
      type: "",
      placeholder: "",
    },

    {
      name: "country",
      label: LanguageName("COTY"),
      className: "col-lg-6",
      type: "",
      placeholder: "Choose Country",
    },
    {
      name: "state",
      // label:LanguageName('S'),
      label: "State",
      className: "col-lg-6",
      type: "",
      placeholder: "Choose State",
    },
  ];
};
