import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import CyclePlanComp from "./CyclePlanComp";

function CyclePlan() {
  return (
    <AdminLayout active={1}>
      <CyclePlanComp />
    </AdminLayout>
  );
}

export default CyclePlan;
