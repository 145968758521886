import React from "react";
import Form from "react-bootstrap/Form";
import ButtonComp from "../../../Components/Ui/Button";

export default function NewHealthCareCompiii() {
  return (
    <div className="container">
      <div className="text-[28px] text-[#242731] font-[700] lg:text-left text-center mb-[24px]">
        Add New Health Care Practitioners
      </div>
      <div className="text-[18px] text-[#333333] font-[500] lg:text-left text-center mb-[59px]">
        Follow the prompt to add New Health Care Practitioners
      </div>
      <div className="row border-t mb-[80px]">
        <div className="col-lg-5 col-md-6 pt-[80px] md:pb-0 pb-5 md:border-r md:border-b-0 border-b border-r-0">
          <div className="flex space-x-[3rem]">
            <div className="flex flex-col ms-4">
              <div className="text-right text-[22px] text-[#242731] font-[500]">
                Institution
              </div>
              <div className="text-right text-[18px] text-[#333333] font-[500]">
                Follow the prompt to add{" "}
              </div>
            </div>
            <div className="flex flex-col justify-content-center align-items-center">
              <div className="">
                <img src="/NHC/hospital.png" alt="" />
              </div>
              <div className="">
                <img src="/NHC/line.png" alt="" />
              </div>
            </div>
          </div>
          <div className="flex space-x-[3rem]">
            <div className="flex flex-col ms-4">
              <div className="text-right text-[22px] text-[#242731] font-[500]">
                Personal Information
              </div>
              <div className="text-right text-[18px] text-[#333333] font-[500]">
                Follow the prompt to add
              </div>
            </div>
            <div className="flex flex-col justify-content-center align-items-center">
              <div className="">
                <img src="/NHCii/user-pur.png" alt="" />
              </div>
              <div className="">
                <img src="/NHC/line.png" alt="" />
              </div>
            </div>
          </div>
          <div className="flex space-x-[3rem]">
            <div className="flex flex-col ms-4">
              <div className="text-right text-[22px] text-[#242731] font-[500]">
                Address
              </div>
              <div className="text-right text-[18px] text-[#333333] font-[500]">
                Follow the prompt to add
              </div>
            </div>
            <div className="flex flex-col justify-content-center align-items-center">
              <div className="">
                <img src="/NHCii/location-pur.png" alt="" />
              </div>
              <div className="">
                <img src="/NHC/line.png" alt="" />
              </div>
            </div>
          </div>
          <div className="flex md:space-x-[1.5rem] space-x-[3rem]">
            <div className="flex flex-col">
              <div className="text-right text-[22px] text-[#242731] font-[500]">
                Assign Med Rep
              </div>
              <div className="text-right text-[17px] text-[#333333] font-[500]">
                Confirm information of the HCPs
              </div>
            </div>
            <div className="flex flex-col justify-content-center align-items-center">
              <div className=" mb-[2rem] me-[.2rem]">
                <img src="/NHC/tick.png" alt="" />
              </div>
              {/* <div className="">
                <img src="/NHC/line.png" alt="" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-6">
          <div className="ms-3 lg:me-[5rem] ">
            <div className="text-end text-[24px] mt-[46px]  mb-[17px]">1/4</div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-[16px] text-[#555555] font-[500]">
                Street Address
              </Form.Label>
              <Form.Control
                className="!border-0 !outline-0 !bg-[#FCFAFF] text-[16px] font-[500] text-[#000000]"
                type="text"
                placeholder="Road 9 Gbagada Express"
              />
            </Form.Group>
            <div className="insert">
              <Form.Label
                className="text-[16px] text-[#555555] font-[500]"
                htmlFor="input"
              >
                Country
              </Form.Label>
              <Form.Select
                className="!border-0 !outline-0 !bg-[#FCFAFF]"
                id="input"
                aria-label="Default select example"
              >
                <option></option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </Form.Select>
            </div>
            {/* <div className="flex  justify-content-between">
              <Form.Group
                className="mb-3 me-3 lg:me-0"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="text-[16px] text-[#555555] font-[500]">
                  Specialty
                </Form.Label>
                <Form.Control
                  className="!border-0 !outline-0 !bg-[#FCFAFF]"
                  type="text"
                  placeholder=""
                />
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="text-[16px] text-[#555555] font-[500]">
                  Department
                </Form.Label>
                <Form.Control
                  className="!border-0 !outline-0 !bg-[#FCFAFF]"
                  type="text"
                  placeholder=""
                />
              </Form.Group>
            </div> */}
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="text-[16px] text-[#555555] font-[500]">
                Postal Code
              </Form.Label>
              <Form.Control
                className="!border-0 !outline-0 !bg-[#FCFAFF]"
                type="text"
                placeholder=""
              />
            </Form.Group>
            <div className="flex justify-content-between mb-[24px]">
              <div className="insert w-[45%]">
                <Form.Label
                  className="text-[16px] text-[#555555] font-[500]"
                  htmlFor="input"
                >
                  City
                </Form.Label>
                <Form.Select
                  className="!border-0 !outline-0 !bg-[#FCFAFF] me-3 lg:me-0"
                  id="input"
                  aria-label="Default select example"
                >
                  <option></option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
              <div className="insert w-[45%]">
                <Form.Label
                  className="text-[16px] text-[#555555] font-[500]"
                  htmlFor="input"
                >
                  State
                </Form.Label>
                <Form.Select
                  className="!border-0 !outline-0 !bg-[#FCFAFF]"
                  id="input"
                  aria-label="Default select example"
                >
                  <option></option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </Form.Select>
              </div>
            </div>
            <div className="flex justify-content-end mb-[37px]">
              <div className="flex space-x-2 align-items-center">
                <div className="">
                  <img src="/NHCii/plus-pur.png" alt="" />
                </div>
                <div className="text-[16px] font-[500] text-[#4F008A]">
                  Add New Address
                </div>
              </div>
            </div>
            <div className="flex space-x-4">
              <ButtonComp
                className="py-1 px-3 text-[#4F008A] text-[18px] font-[500] rounded bg-[#EDE6F5]"
                text={"Previous"}
              />
              <ButtonComp
                className="py-1 px-[2rem] text-[18px] font-[500] text-[#FFFFFF] rounded bg-[#3DA84A]"
                text={"Next"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
