import { t } from "i18next";
import Cookie from "js-cookie";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const getToken = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("user:accesskey");
  }
};

export const setLanguage = (token) => {
  localStorage.setItem("language", token);
};
export const getLanguage = () => {
  if (typeof window !== "undefined") {
    return localStorage.getItem("language") || "en";
  }
};

export const LanguageName = (name) => {
  return t(`lang.${name}`);
};

export function LanguageNameII() {
  const { t } = useTranslation();
  return t(`lang.${name}`);
}

// export const storage = {
//   localStorage: {
//     set: (key, value) => localStorage?.setItem(key, JSON.stringify(value)),
//     get: (key) =>
//       typeof localStorage?.getItem(key) === "string" &&
//       JSON?.parse(localStorage?.getItem(key) || ""),
//     remove: (key) => localStorage?.removeItem(key),
//   },
//   cookieStorage: {
//     set: (key, value, options) => Cookie?.set(key, value, options),
//     get: (key) => Cookie?.get(key) || "",
//     remove: (key, options) => Cookie.remove(key, options),
//   },
// };
export const storage = {
  localStorage: {
    set: (key, value) => localStorage?.setItem(key, JSON.stringify(value)),
    get: (key) => {
      const item = localStorage?.getItem(key);
      if (item) {
        try {
          return JSON.parse(item);
        } catch (error) {
          console.error("Error parsing JSON from localStorage:", error);
          return null; // or a sensible default
        }
      }
      return null; // or a sensible default if the item doesn't exist
    },
    remove: (key) => localStorage?.removeItem(key),
  },
  cookieStorage: {
    set: (key, value, options) => Cookie?.set(key, value, options),
    get: (key) => Cookie?.get(key) || "",
    remove: (key, options) => Cookie?.remove(key, options),
  },
};

export const setToken = (token) => {
  localStorage.setItem("user:accesskey", token);
};
export const DeleteAuthTokenMaster = (name) => {
  localStorage.removeItem(name);
};

export const YearMonth = () => {
  const { t } = useTranslation();

  return [
    t("lang.Jan"),
    t("lang.Feb"),
    t("lang.Mar"),
    t("lang.Apr"),
    t("lang.May"),
    t("lang.June"),
    t("lang.July"),
    t("lang.Aug"),
    t("lang.Sept"),
    t("lang.Oct"),
    t("lang.Nov"),
    t("lang.Dec"),
  ];
};

export const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export function formatNumber(number) {
  const billion = 1000000000;
  const million = 1000000;
  const thousand = 1000;

  if (number >= billion) {
    return (number / billion).toFixed(1) + "b";
  } else if (number >= million) {
    return (number / million).toFixed(1) + "m";
  } else if (number >= thousand) {
    return (number / thousand).toFixed(1) + "k";
  } else {
    return number.toString();
  }
}

export function firstDateOfTheMonth() {
  // Get the current date
  const currentDate = new Date();

  // Subtract 10 days from the current date
  // const

  // Return the new date in international standard format
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
}

export const currentDate = new Date();

export const handleKeyPress = (event) => {
  if (!/[0-9]/.test(event.key)) {
    event.preventDefault();
  }
};

export const SortOrder = (dataset = []) => {
  return dataset?.sort((a, b) => {
    let bDate = new Date(b.createdAt);
    let aDate = new Date(a.createdAt);

    return aDate - bDate;
  });
};

export const NumberTable = (item, name) => {
  // console.log(item,'result')
  const index = item?.findIndex((object) => {
    // console.log(object._id, name,'result')
    return object?.id === name;
  });

  // console.log(index,item,'result'); // 👉️ 1
  return index ? index + 1 : 1;
};

export const StatusColor = (status) => {
  switch (status) {
    case "pending":
      return "bg-[#DBEBFE] px-3 py-2 rounded-full capitalize";
      break;
    case "completed":
      return "bg-[#DAF1E9] text-[#044E54] rounded-full capitalize";
      break;
    default:
      break;
  }
};

export const FormDatedView = (date) => {
  // Input timestamp
  const timestamp = date;

  // Convert timestamp to moment object
  const momentObj = moment(timestamp);

  // Format the moment object as required
  const formattedDate = momentObj.format("h:mma - Do MMMM, YYYY");

  return formattedDate;
};

export const flattenObject = (obj, parent = "", res = {}) => {
  for (let key in obj) {
    let propName = parent ? parent + "." + key : key;
    if (typeof obj[key] == "object") {
      flattenObject(obj[key], propName, res);
    } else {
      res[propName] = obj[key];
    }
  }
  return res;
};

export const convertToCSV = (data) => {
  console.log(data, "csv");
  const csvRows = [];
  const flattenedData = data.map((item) => flattenObject(item));
  const headers = Object.keys(flattenedData[0]);
  csvRows.push(headers.join(","));

  for (const row of flattenedData) {
    const values = headers.map((header) => {
      const escaped = ("" + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(","));
  }

  return csvRows.join("\n");
};

export const downloadCSV = (ScheduleData, name = "Report.csv", isLoading) => {
  // console.log("ScheduleData1", ScheduleData, isLoading);
  if (!isLoading && ScheduleData) {
    // console.log("ScheduleData", ScheduleData);
    const csvData = convertToCSV(ScheduleData);
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.click();
  }
};

export const isObjectEmpty = (objectName) => {
  return (
    Object.keys(objectName).length === 0 && objectName.constructor === Object
  );
};

export function searchItems(items, searchTerm, name = "institutionName") {
  return items?.filter((item) => {
    const itemName = item[`${name}`]?.toLowerCase() || "";
    const itemLName = item?.lastName?.toLowerCase() || "";
    const itemCompany = item?.medRep?.firstName?.toLowerCase() || "";
    // const itemStatus = item?.status?.toLowerCase() || "";
    const idMatch = String(item?._id ?? item?.medRepId)?.toLowerCase();
    const date = item?.createdAt?.toString()?.toLowerCase();
    const itemActive = String(item?.status ?? item?.deactivated);
    const search = searchTerm?.toLowerCase() || "";

    return (
      itemName?.includes(search) ||
      itemLName?.includes(search) ||
      itemCompany?.includes(search) ||
      // itemStatus.includes(search) ||
      idMatch?.includes(search) ||
      date?.includes(search) ||
      itemActive === search
    );
  });
}

export function searchHCOItems(items, searchTerm, name = "firstName") {
  const search = searchTerm?.toLowerCase() || "";
  return items?.filter((item) => {
    const itemName = item[`${name}`]?.toLowerCase() || "";
    const itemLName = item?.lastName?.toLowerCase() || "";
    const idMatch = String(item?._id ?? item?.medRepId)?.toLowerCase();
    const date = item?.createdDate?.toString()?.toLowerCase();
    const department = item?.department?.toLowerCase();
    console.log(item, search, "medrep");
    return (
      itemName?.includes(search) ||
      itemLName?.includes(search) ||
      idMatch?.includes(search) ||
      date?.includes(search) ||
      department?.includes(search)
    );
  });
}

export function sortItems(items, sortOrder) {
  if (!items) return [];
  return items?.slice().sort((a, b) => {
    if (sortOrder === "name") {
      return a.institutionName.localeCompare(b.institutionName);
    } else if (sortOrder === "date") {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
  });
}

export function sortMedrepItems(items, sortOrder) {
  if (!items) return [];
  return items?.slice().sort((a, b) => {
    if (sortOrder === "name") {
      return a.firstName.localeCompare(b.firstName);
    } else if (sortOrder === "date") {
      return new Date(b.createdAt) - new Date(a.createdAt);
    }
  });
}

export const column = () => [
  {
    name: LanguageName("HCPCat"),
    selector: (row) => <div className="px-3">{row.hcpCategory}</div>,
  },
  {
    name: LanguageName("TotCalls"),
    selector: (row) => <div className="px-3">{row.totalCalls}</div>,
  },
  {
    name: LanguageName("LastCall"),
    selector: (row) => <div className="px-3">{row.lastCall}</div>,
  },
  {
    name: LanguageName("successRate"),
    selector: (row) => <div className="px-3">{row.successRate}</div>,
  },
];
export const months = () => [
  { id: 1, name: LanguageName("January"), value: 1 },
  { id: 2, name: LanguageName("February"), value: 2 },
  { id: 3, name: LanguageName("March"), value: 3 },
  { id: 4, name: LanguageName("April"), value: 4 },
  { id: 5, name: LanguageName("May"), value: 5 },
  { id: 6, name: LanguageName("June"), value: 6 },
  { id: 7, name: LanguageName("July"), value: 7 },
  { id: 8, name: LanguageName("August"), value: 8 },
  { id: 9, name: LanguageName("September"), value: 9 },
  { id: 10, name: LanguageName("October"), value: 10 },
  { id: 11, name: LanguageName("November"), value: 11 },
  { id: 12, name: LanguageName("December"), value: 12 },
];
export const createYearArray = () => {
  const startYear = 2010;
  const currentYear = new Date().getFullYear();
  const years = [];

  for (let year = startYear; year <= currentYear; year++) {
    years.push(year);
  }
  return years.map((year) => ({
    name: year, // Set the name property
    value: year, // Set the value property
  }));
};

export const extractData = (data) => {
  const newDataArray = data?.data?.hcps?.map((item) => ({
    ...item,
    createdBy: data.data.createdBy._id,
    createdByy: {
      _id: data.data.createdBy._id,
      firstName: data.data.createdBy.firstName,
      lastName: data.data.createdBy.lastName,
    },
  }));
  console.log(newDataArray);
  return newDataArray;
};

export const hcpExtraction = (arr) => {
  const newDataArray = arr?.map((item) => ({
    ...item,
    institutionName: item.hco.institutionName,
    institutionType: item.hco.institutionType,
    country: item.hco.country,
  }));
  return newDataArray;
};
