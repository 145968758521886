import React from "react";
import {
  ComposedChart,
  Line,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
  BarChart,
} from "recharts";

const data = [
  {
    name: "Cardiology",
    uv: 590,
    pv: 800,
    amt: 1400,
  },
  {
    name: "Surgery",
    uv: 868,
    pv: 967,
    amt: 1506,
  },
  {
    name: "Pharmacy",
    uv: 1397,
    pv: 1098,
    amt: 989,
  },
  {
    name: "Neurology",
    uv: 1480,
    pv: 1200,
    amt: 1228,
  },
  {
    name: "Others",
    uv: 1520,
    pv: 1108,
    amt: 1100,
  },
];

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value } = props;
  const radius = 10;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
      <text
        x={x + width / 2}
        y={y - radius}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value.split(" ")[1]}
      </text>
    </g>
  );
};

const BarChartComp = ({ height = 500 }) => {
  return (
    <div style={{ width: "100%", height: height }}>
      <ResponsiveContainer>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
          <YAxis yAxisId="right" orientation="right" stroke="#252B41A3" />
          <Tooltip />
          <Legend />
          <Bar yAxisId="right" barSize={20} dataKey="pv" fill="#8354CF" />
          {/* <Bar yAxisId="right" dataKey="uv" fill="#82ca9d" /> */}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

// <CartesianGrid strokeDasharray="3 3" />
// <XAxis dataKey="name" />
// <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
// <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
// <Tooltip />
// <Legend />
// <Bar yAxisId="left" dataKey="pv" fill="#8884d8" />
// <Bar yAxisId="right" dataKey="uv" fill="#82ca9d" />
// </BarChart>
export default BarChartComp;
