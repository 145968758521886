import React from "react";
import { FormDatedView } from "../../../../utils/helper";
import { useParams } from "react-router-dom";
import moment from "moment";

export default function Plan({ planData }) {
  const { id, createdById } = useParams();
  const ImageType = (key) => {
    switch (key) {
      case "checkIn":
        return <img src="/HCPList/tick.png" alt="" />;

      case "report":
        return <img src="/HCPList/note.png" alt="" />;

      default:
        return <img src="/HCPList/note.png" alt="" />;
    }
  };
  console.log(planData)
  return (
    <div>
      <div>
        {planData?.loader &&
          [1, 2, 3, 4]?.map((item, i) => <ActivitiesLoadingState key={i} />)}
        {(!planData?.loader && planData?.data?.length < 1) ||
          (planData?.data?.filter((item) => item?.hcp?._id === id).length <
            1 && (
            <div className="text-center text-lg font-semibold">
              No Plan Added
            </div>
          ))}
        {!planData?.loader &&
          planData?.data
            ?.filter((item) => item?.hcp?._id === id)
            ?.map((item, i) => (
              <div className="mb-[50px]" key={item?._id}>
                <div className="flex items-center">
                  <div className=" basis-20">
                    {ImageType(item?.activityType)}
                  </div>
                  <div className="basis-full text-[16px] text-[#757575] font-[400]">
                    {FormDatedView(item?.targets?.date)}
                    <br />
                    <b className="text-[16px] text-[#292D32] font-[500]">
                      Plan
                    </b>{" "}
                    <span className="text-[16px] text-[#757575] font-[400] me-[3px]">
                      for {item?.hcp?.title}{" "}
                      {item?.callFocus || item?.reason || item?.hcp?.firstName}{" "}
                      {item?.hcp?.lastName}
                    </span>
                    <div className="text-[16px] w-full text-[#292D32] font-[500]">
                      {/* {moment(item?.selectedDates[0]).format("MMM Do YYYY")} */}
                      <b>Dates - </b>
                      {item?.selectedDates?.map((date, index) => (
                        <span key={index}>
                          {moment(date).format("MMM Do YYYY")}
                          {index !== item.selectedDates.length - 1 ? " - " : ""}
                        </span>
                      ))}{" "}
                      at {item.location}
                    </div>
                  </div>
                </div>
                {/* <div className=""> */}
                {/*   <img src="/HCPList/more.svg" alt="more icon" /> */}
                {/* </div> */}
              </div>
            ))
            .reverse()}
      </div>
    </div>
  );
}
