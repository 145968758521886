import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import PlanComp from "./PlanComp.jsx";

function Plan() {
  return (
    // <AdminLayout active={1}>
      <PlanComp />
    // </AdminLayout>
  );
}

export default Plan;
