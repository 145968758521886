import React, { useMemo } from "react";
import {
  useGetMedRepDetailsHCpQuery,
  useGetMedRepDetailsQuery,
  useGetPlansQuery,
  useGetPlansSummaryQuery,
  useLazyGetNewMedRepDetailsQuery,
  useGetSinglePlanQuery,
} from "../../../../store/Plans/PlansApi";
import { useNavigate, useParams } from "react-router-dom";
import ButtonComp from "../../../../Components/Ui/Button";
import { LanguageName, storage } from "../../../../utils/helper";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Events from "../../../../utils/Events.js";
import moment from "moment";

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
// import "react-big-calendar/lib/css/react-big-calendar.css";

import Country from "../../../../country.json";
import { useEffect } from "react";
import { AccordionPagination } from "../../../../Components/Common/AccordionPagination.jsx";

export default function PlanSummary() {
  const navigate = useNavigate();
  const companyIde = storage["localStorage"].get("userProfile_pharmaServe")
    ?.company?._id;
  const managerId = storage["localStorage"].get("user_details_profile").user.id;
  // const { data: MedRepData, isLoading } = useGetMedRepQuery(companyIde, {
  //   skip: !companyIde,
  // });
  const { data: PlansData, isLoading: PDLoader } = useGetPlansQuery();
  const { data: PlansSummary, refetch } = useGetPlansSummaryQuery();
  const [currentMonth, setCurrentMonth] = React.useState({
    month: PlansSummary?.data[0]?.month,
    targets: [],
  });
  const [selectedMedRep, setSelectedMedRep] = React.useState(null);

  console.log(PlansSummary, "Plans Summary");
  console.log(PlansData, "Plans Data");

  // const [shouldFetch, setShouldFetch] = useState(false);
 
  const [
    trigger,
    { data: MedRepHCP2, isLoading: isMedRepHCPLoading2, isFetching2 },
  ] = useLazyGetNewMedRepDetailsQuery();

  const [hcp, setHcp] = React.useState(null);
  const [currentMonthData, setCurrentMonthData] = React.useState(null);
  const [previousMonthData, setPreviousMonthData] = React.useState(null);

  // const [selectedDate, setSelectedDate] = React.useState(
  //   PlansSummary?.data[0]?.selectedDates
  // );

  const now = new Date();

  const [calenderDates, setCalenderDates] = React.useState(null);

  const currentM = useMemo(() => {
    return PlansSummary?.data?.filter(
      (item) => item?.month === PlansSummary?.data[0]?.month
    );
  }, [PlansSummary]);

  const otherMonthData = useMemo(() => {
    return PlansSummary?.data?.filter(
      (item) => item?.month !== PlansSummary?.data[0]?.month
    );
  }, [PlansSummary?.data]);

  console.log(currentMonthData, "currentMonthData");
  console.log(otherMonthData, "otherMonthData");

  const months = useMemo(() => {
    let otherMonth = PlansSummary?.data?.filter(
      (item) => item?.month !== PlansSummary?.data[0]?.month
    );

    if (otherMonth?.length > 0) {
      const groupedByMonth = otherMonth?.reduce((acc, obj) => {
        // Get the month of the current object
        const key = obj.month;
        // If the key doesn't exist yet, create it
        if (!acc[key]) {
          acc[key] = [];
        }
        // Push the current object into the appropriate array
        acc[key].push(obj);
        // Return the accumulator for the next iteration
        return acc;
      }, {});

      // To convert the object into an array of arrays
      const result = Object.keys(groupedByMonth).map(
        (key) => groupedByMonth[key]
      );

      return result;
    }
  }, [PlansSummary?.data]);

  useEffect(() => {
    setCurrentMonthData(currentM);
    setPreviousMonthData(months);
  }, [currentM, months]);

  useEffect(() => {
    console.log("selected hcp", hcp);
    console.log("months", months);
  }, [hcp, months]);

  function getMonthName(monthNumber) {
    const date = new Date(0, monthNumber - 1); // Month number starts from 0 in JS Date
    return date.toLocaleString("default", { month: "long" });
  }

  const handleHcpChange = (item) => {
    setHcp(item);

    setCalenderDates(
      item?.selectedDates?.map((date) => {
        return {
          id: item?.hcpId,
          title:
            item?.hcp?.title +
            " " +
            item?.hcp?.firstName +
            " " +
            item?.hcp?.lastName,
          start: new Date(
            Number(date.split("-")[0]),
            Number(date.split("-")[1]) - 1,
            Number(date.split("-")[2]),
            0,
            0,
            0
          ),
          end: new Date(
            Number(date.split("-")[0]),
            Number(date.split("-")[1]) - 1,
            Number(date.split("-")[2]),
            0,
            0,
            0
          ),
        };
      })
    );
  };


  // useEffect(() => {
  //   console.log(MedRepHCP);
  // }, [selectedMedRep, MedRepHCP]);

  useEffect(() => {
    refetch();
  }, []);

  // TODO: add the "go back" button
  return (
    <div className="px-3">
      <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[20px]">
        <div className="flex align-items-center lg:mb-0 mb-[1rem] w-full">
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="flex w-[200px] items-center mr-10 cursor-pointer"
          >
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.711914"
                y="0.5"
                width="23"
                height="23"
                rx="3.5"
                fill="white"
              />
              <rect
                x="0.711914"
                y="0.5"
                width="23"
                height="23"
                rx="3.5"
                stroke="#E4E7EC"
              />
              <path
                d="M7.35836 12.3536C7.1631 12.1583 7.1631 11.8417 7.35836 11.6464L9.35836 9.64645C9.55362 9.45118 9.87021 9.45118 10.0655 9.64645C10.2607 9.84171 10.2607 10.1583 10.0655 10.3536L8.91902 11.5L16.7119 11.5C16.9881 11.5 17.2119 11.7239 17.2119 12C17.2119 12.2761 16.9881 12.5 16.7119 12.5L8.91902 12.5L10.0655 13.6464C10.2607 13.8417 10.2607 14.1583 10.0655 14.3536C9.87021 14.5488 9.55362 14.5488 9.35836 14.3536L7.35836 12.3536Z"
                fill="black"
              />
            </svg>
            <p className="font-[500] ml-3 text-[14px] font-Inter text-[#AD3307]">
              Go Back
            </p>
          </div>
          <div className="flex justify-start items-center w-full">
            <div>
              <div className="text-[25px] font-[700] mr-5">
                {LanguageName("VP")}
              </div>
              {/* <div className="text-[#777777] text-[14px]"> */}
              {/*   {LanguageName("MR")}/{LanguageName("Institutions")}/ */}
              {/*   {LanguageName("CPN")} */}
              {/* </div> */}
            </div>
            <div className="flex space-x-3 bg-[#fcfaff] border-[1px] border-[#ebebeb] rounded align-items-center lg:w-[50%] md:w-100 w-[62%] py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
              <div className="ps-2 pe-3">
                <img className="" src="/icon/search.png" alt="search" />
              </div>

              <input
                className="w-100 text-[16px] font-[400] bg-[#fcfaff]"
                type="text"
                // placeholder={LanguageName("SCR")}
                placeholder={LanguageName("SearchPlans")}
              />
            </div>
          </div>
          <div className="ms-2"></div>
        </div>
      </div>
      <div className="border"></div>
      <div className="flex md:flex-row flex-col mt-4 mb-5">
        <div className="col-2 md:mr-4 overflow-auto md:h-[85vh] w-full md:w-[350px] h-[30vh]">
          {/* <div className="flex mb-4 p-2 justify-around items-center text-[14px] font-[500] border border-[#EBEBEB]"> */}
          {/*   <div>Targets</div> */}
          {/*   <div className="flex flex-col text-[10px] font-[500] p-2 justify-center items-center border border-[#EBEBEB]"> */}
          {/*     HCP */}
          {/*   </div> */}
          {/* </div> */}
          <div className="flex space-x-3 bg-[#fcfaff] border-[1px] border-[#ebebeb] rounded align-items-center lg:w-full md:w-full w-[62%] py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
            <div className="ps-2 pe-3">
              <img className="" src="/icon/search.png" alt="search" />
            </div>

            <input
              className="w-100 text-[16px] font-[400] bg-[#fcfaff]"
              type="text"
              // placeholder={LanguageName("SCR")}
              placeholder={LanguageName("SearchMedrepPlans")}
            />
          </div>

          <div>
            <div className="flex text-[16px] text-black font-[500] font-Inter items-center justify-between py-2 px-3 rounded-[4px] mb-2">
              <div className="flex items-center">
                <p>{LanguageName("MRIVE")}</p>
                <div className="px-[8px] ml-2 text-white font-[500] font-Inter text-[12px] rounded-[12px] bg-[#8652CE]">
                  {PDLoader
                    ? "00"
                    : String(PlansData?.data?.length).padStart(2, "0") ||
                      "00"}
                </div>
              </div>
            </div>
            <Accordion className="border-0 px-3">
              {PlansData?.data.length === 0 ? (
                <span>No Data</span>
              ) : (
                PlansData?.data
                  ?.filter((medrep) => medrep?.medRep?._id !== managerId)
                  .map((medrep, i) => {
                    return (
                      <AccordionItem
                        onClick={() => {
                          setSelectedMedRep(medrep?.medRep)
                          trigger({ id: medrep?.medRep?._id, page: 1 });
                        }}
                        key={medrep?.medRep?.medrepId}
                      >
                        <AccordionItemHeading>
                          <AccordionItemButton className="flex text-[14px] text-black font-[500] font-Inter items-center justify-between py-2 px-3 rounded-[4px] bg-[#F5F5F5]">
                            <div className="flex items-center">
                              <p>
                                {medrep?.medRep?.firstName}{" "}
                                {medrep?.medRep?.lastName}
                              </p>
                              <div className="px-[8px] ml-2 text-white font-[500] font-Inter text-[12px] rounded-[12px] bg-[#4F008A80]">
                                {String(medrep?.uniqueHcpsPlans || 0).padStart(
                                  2,
                                  "0"
                                ) || "00"}
                              </div>
                            </div>
                            <svg
                              width="10"
                              height="7"
                              viewBox="0 0 10 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.00042 4.28126L8.30028 0.981445L9.24308 1.92425L5.00042 6.16693L0.757813 1.92425L1.70062 0.981445L5.00042 4.28126Z"
                                fill="black"
                              />
                            </svg>
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {isMedRepHCPLoading2 || isFetching2 ? (
                            <p className="font-[500] text-[12px] text-black mr-2">
                              {LanguageName("LoadPW")}
                            </p>
                          ) : !MedRepHCP2?.data?.plans ? (
                            <p className="font-[500] text-[12px] text-black mr-2">
                              {MedRepHCP2?.message}
                            </p>
                          ) : (
                            MedRepHCP2?.data?.plans
                              ?.map((item, i) => {
                                return (
                                  <div
                                    key={i}
                                    onClick={() => {
                                      // hcp here
                                      handleHcpChange(item);
                                    }}
                                    className={`flex items-center rounded-[8px] p-[10px] hover:bg-[#F5F5F5] cursor-pointer ${
                                      hcp?._id === item?._id && "bg-[#F5F5F5]"
                                    }`}
                                  >
                                    <p className="font-[500] text-[12px] text-black mr-2">
                                      {item?.hcp?.title} {item?.hcp?.firstName}{" "}
                                      {item?.hcp?.lastName}
                                    </p>
                                    <p className="font-[400] text-[11px] text-[#777777]">
                                      ({item?.hcp?.hco?.institutionName})
                                    </p>
                                  </div>
                                );
                              })
                              // .reverse()
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    );
                  })
              )}
              {selectedMedRep && <div className="mt-4">
                <AccordionPagination
                  current={MedRepHCP2?.data?.pagination?.current}
                  next={MedRepHCP2?.data?.pagination?.next}
                  numberOfPages={MedRepHCP2?.data?.pagination?.number_of_pages}
                  setPage={trigger}
                  medRepId={selectedMedRep?._id}
                />
              </div>}
            </Accordion>
          </div>
         
          <div className="px-4">
            <div className="font-[700] text-[14px] font-Plus-Jakarta-Sans mt-5 mb-3">
              {LanguageName("SUM")}
            </div>

            <div className="mb-4">
              <div className="flex items-center">
                <div className="w-[10px] h-[10px] bg-[#8652CE] rounded-[10px] mr-2" />
                <p className="font-[500] text-[14px] text-black">
                  {LanguageName("PCs")}
                </p>
              </div>
              <div className="bg-[#4F008A0D] rounded-[6px] w-full text-[14px] font-[500] text-[#1C1C1C] py-2 flex justify-center items-center mt-2">
                {String(hcp?.targets?.callsPerMonth || 0).padStart(2, "0") ||
                  "00"}
              </div>
            </div>
            <div className="mb-4">
              <div className="flex items-center">
                <div className="w-[10px] h-[10px] bg-[#3DA84A] rounded-[10px] mr-2" />
                <p className="font-[500] text-[14px] text-black">
                  {LanguageName("CCTD")}
                </p>
              </div>
              <div className="bg-[#3DA84A0D] rounded-[6px] w-full text-[14px] font-[500] text-[#1C1C1C] py-2 flex justify-center items-center mt-2">
                {String(hcp?.visitCount || 0).padStart(2, "0") || "00"}
              </div>
            </div>
            <div className="mb-4">
              <div className="flex items-center">
                <div className="w-[10px] h-[10px] bg-[#D63434] rounded-[10px] mr-2" />
                <p className="font-[500] text-[14px] text-black">
                  {LanguageName("CRNG")}
                </p>
              </div>
              <div className="bg-[#D634340D] rounded-[6px] w-full text-[14px] font-[500] text-[#1C1C1C] py-2 flex justify-center items-center mt-2">
                {String(
                  hcp?.targets?.callsPerMonth || 0 - hcp?.visitCount || 0
                ).padStart(2, "0") || "00"}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="flex justify-end items-center ">
            <div
              onClick={() => {
                navigate("/plan/add");
              }}
              className="cursor-pointer z-[90] flex align-items-center bg-[#3da84a] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0 mr-6"
            >
              <div className="mr-2">
                <svg
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 6.56055V0.560547H8V6.56055H14V8.56055H8V14.5605H6V8.56055H0V6.56055H6Z"
                    fill="white"
                  />
                </svg>
              </div>
              <ButtonComp
                className="text-[#FFFFFF] font-[500] text-[16px]  rounded-[5px]"
                // text={LanguageName("VPL")}
                text={`${LanguageName("CRNP")}`}
              />
            </div>
            <div
              onClick={() => {
                if (!hcp) return;
                navigate(`/plan/edit/${hcp?._id}`);
              }}
              className={`cursor-pointer z-[90] ml-3 flex align-items-center ${
                !hcp ? "bg-[#8652CE80]" : "bg-[#8652CE] "
              } lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2`}
            >
              <div className="mr-2">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.58333 17.3145H5.87969L14.4172 8.77692L13.1209 7.48056L4.58333 16.0182V17.3145ZM19.25 19.1478H2.75V15.2587L15.0654 2.94329C15.4235 2.58531 16.0038 2.58531 16.3618 2.94329L18.9546 5.53601C19.3125 5.89399 19.3125 6.4744 18.9546 6.83237L8.47242 17.3145H19.25V19.1478ZM14.4172 6.1842L15.7136 7.48056L17.0099 6.1842L15.7136 4.88783L14.4172 6.1842Z"
                    fill="white"
                  />
                </svg>
              </div>
              <ButtonComp
                className="text-[#FFFFFF] font-[500] text-[16px]  rounded-[5px]"
                // text={LanguageName("VPL")}
                text={LanguageName("editPlan")}
              />
            </div>
          </div>
          <div>
            {calenderDates ? (
              <div className="h-[80vh]">
                <Calendar
                  views={{
                    month: true,
                    week: false,
                    day: false,
                  }}
                  localizer={localizer}
                  events={calenderDates}
                  startAccessor="start"
                  endAccessor="end"
                />
              </div>
            ) : (
              <div className="w-full h-full flex  flex-col justify-center items-center">
                <svg
                  width="150"
                  height="150"
                  viewBox="0 0 150 150"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M75 137.5C109.5 137.5 137.5 109.5 137.5 75C137.5 40.5 109.5 12.5 75 12.5C40.5 12.5 12.5 40.5 12.5 75C12.5 109.5 40.5 137.5 75 137.5Z"
                    stroke="#CECDCE"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M118.127 31.25L30.6274 118.75"
                    stroke="#CECDCE"
                    stroke-width="3"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p className="text-[#cecdce] mt-4 font-[500] text-[24px] font-[Instrument Sans]">
                  No HCP Selected
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
