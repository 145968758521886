import moment from "moment";
import { useNavigate } from "react-router-dom";
import { LanguageName } from "../../../utils/helper";
import DropDownComp from "../../../Components/Ui/DropDown";
import {
  ActivateIcon,
  DeactivateIcon,
  Dots,
  TrackIcon,
} from "../../../utils/svg";
import CheckboxComp from "../../../Components/Ui/Checkbox";

export const HCOData = [
  {
    hospital: "OAU Teaching Hospital",
    university: "Obafemi Awolowo University Campus",
    name: " Ademola Olalekan John",
    departments: "Departments",
    dermatology: " Dermatology",
    cardiology: " Cardiology",
    physiology: " Physiology",
    opthamology: " Opthamology",
    medicine: "Internal Medicine",
  },
  {
    hospital: "OAU Teaching Hospital",
    university: "Obafemi Awolowo University Campus",
    name: " Ademola Olalekan John",
    departments: "Departments",
    dermatology: " Dermatology",
    cardiology: " Cardiology",
    physiology: " Physiology",
    opthamology: " Opthamology",
    medicine: "Internal Medicine",
  },
];

export const TopHeaderCount = (Data) => {
  // console.log(
  //   Data?.filter((item) => item?.institutionType === "Hospital")?.length,
  //   "Data"
  // );

  return [
    {
      name: "All HCO",
      count: Data?.length,
      findBy: "",
    },
    {
      name: "Hospital",
      count: Data?.length,
      findBy: "",
    },
    {
      name: "Pharmacies",
      count: Data?.filter((item) => item?.deactivated === true)?.length,
      findBy: "",
    },
  ];
};

export const TopHeaderCount2 = (Data) => {
  // Extract department list from all hcp items
  const departmentList = Data?.data?.hcps
    ?.map((item) => item.department)
    .filter((value, index, self) => value && self.indexOf(value) === index); // Remove duplicates and avoid undefined

  let list = [
    {
      name: "All Department",
      count: departmentList?.length,
      findBy: "",
    },
  ];

  // For each unique department, get the count
  departmentList?.forEach((department) => {
    list.push({
      name: department,
      count: Data?.data?.hcps
        ?.map((item) => item.department)
        .filter((dept) => dept === department).length,
      findBy: department,
    });
  });

  return list;
};
export const TopHCOHeaderCount = (Data) => {
  // Extract department list from all hcp items
  const departmentList = Data?.map((item) => item.institutionType).filter(
    (value, index, self) => value && self.indexOf(value) === index
  ); // Remove duplicates and avoid undefined

  let list = [
    {
      name: "All HCO",
      count: Data?.length,
      findBy: "",
    },
  ];

  // For each unique department, get the count
  departmentList?.forEach((insttype) => {
    list.push({
      name: insttype,
      count: Data?.map((item) => item.institutionType).filter(
        (dept) => dept === insttype
      ).length,
      findBy: insttype,
    });
  });

  return list;
};

export const columnsData = (handleDisableHcp, institutionName, response) => {
  const StatusColor = (res, status) => {
    switch (res || status) {
      case true:
        return (
          <div className="bg-[#E9E9111A] rounded-[24px] px-4 py-2 text-[#5B5B02] capitalize">
            Inactive
          </div>
        );
      case false:
        return (
          <div className="bg-[#04A06826] rounded-[24px] px-4 py-2 text-[#044E54] capitalize">
            Active
          </div>
        );
      default:
        break;
    }
  };
  return [
    {
      name: "Id",
      selector: (row) => <span>#{row._id?.substring(0, 6)}</span>,
      sortable: true,
    },
    {
      name: "HCP Info",
      selector: (row) => (
        <div className="flex items-start gap-2">
          <div className="flex-col">
            <div className="whitespace-nowrap font-medium capitalize text-[18px]">
              {row.firstName} {row.lastName}
            </div>
            <div className="text-[10px]">
              <span className="text-[#777777]">{row?.position} at </span>
              <span className="text-[#3DA84A]">{institutionName}</span>
            </div>
          </div>
        </div>
      ),
      width: "250px",
      sortable: true,
    },
    {
      name: "department",
      selector: (row) => row.department,
      // sortable: true,
    },
    {
      name: LanguageName("Specialty"),
      selector: (row) => row.specialty,
      sortable: true,
    },
    {
      name: LanguageName("PNN"),
      selector: (row) => row.phoneNumber,
      width: "200px",
      sortable: true,
    },
    {
      name: LanguageName("MR"),
      selector: (row) => (
        <span>
          {row?.createdByy?.firstName} {row?.createdByy?.lastName}
        </span>
      ),
      sortable: true,
    },

    {
      name: LanguageName("Status"),
      selector: (row) => {
        return StatusColor(response, row.deactivated);
      },
      sortable: true,
    },
    {
      name: LanguageName("DA"),
      selector: (row) => moment(row?.createdAt).format("MMMM Do YYYY, h:mm"),
      width: "200px",
      sortable: true,
    },
    {
      name: LanguageName("Action"),
      // sortable: true,
      cell: (row) => (
        <div className="ms-3">
          <DropDownComp
            dropDownText={<Dots color="#7572EA" />}
            options={OptionDropDown(row, handleDisableHcp)}
          />
        </div>
      ),
      width: "180px",
    },
  ];
};
export const columnData = (setShowCavas) => {
  const navigate = useNavigate();
  const StatusColor = (res, status) => {
    switch (res || status) {
      case true:
        return (
          <div className="bg-[#E9E9111A] rounded-[24px] px-4 py-2 text-[#5B5B02] capitalize">
            Inactive
          </div>
        );
      case false:
        return (
          <div className="bg-[#04A06826] rounded-[24px] px-4 py-2 text-[#044E54] capitalize">
            Active
          </div>
        );
      default:
        break;
    }
  };
  return [
    {
      name: (
        <span>
          <CheckboxComp />
        </span>
      ),
      selector: (row) => (
        <span>
          <CheckboxComp />
        </span>
      ),
      // sortable: true,
    },
    {
      name: "Institution Name",
      selector: (row) => (
        <span
          onClick={() => navigate("/up")}
          className="hover:underline cursor-pointer"
        >
          {row.institutionName}
        </span>
      ),
      // sortable: true,
    },
    {
      name: "No of HCPs",
      selector: (row) => row.noOfHcps,
      width: "150px",
      // sortable: true,
    },
    {
      name: "Institution Type",
      selector: (row) => row.institutionType,
      // sortable: true,
    },
    {
      name: "Med Rep",
      selector: (row) => row.medrep,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      width: "200px",
      sortable: true,
    },
    {
      name: "Date Added",
      selector: (row) => <span>{row?.createdAt}</span>,
      sortable: true,
    },

    {
      name: LanguageName("Action"),
      // sortable: true,
      cell: (row) => (
        <div className="ms-3">
          <DropDownComp
            dropDownText={<Dots color="#7572EA" />}
            options={OptionDropDownII(row, setShowCavas)}
          />
        </div>
      ),
      width: "180px",
    },
  ];
};

const OptionDropDown = (row, handleDisableHcp) => {
  const navigate = useNavigate();
  return [
    {
      name: (
        <div
          className="flex gap-1 items-center text-[#656468] text-[12px]"
          onClick={() =>
            navigate(`/account/hcp/${row?.createdBy}/${row?._id}`, {
              state: row,
            })
          }
        >
          <TrackIcon />
          Check Profile
        </div>
      ),
    },

    {
      name: row.deactivated ? (
        <div
          onClick={() => handleDisableHcp(row?._id, false)}
          className="flex gap-1 items-center text-[#3DA84A] text-[12px]"
        >
          <ActivateIcon />
          Activate HCP
        </div>
      ) : (
        <div
          onClick={() => handleDisableHcp(row?._id, true)}
          className="flex gap-1 items-center text-[#D63434] text-[12px]"
        >
          <DeactivateIcon />
          De-activate HCP
        </div>
      ),

      // action:;
    },
  ];
};
const OptionDropDownII = (row, setShowCavas) => {
  const navigate = useNavigate();
  return [
    {
      name: (
        <div
          className="flex gap-1 items-center text-[#656468] text-[12px]"
          onClick={() => setShowCavas(true)}
        >
          <TrackIcon />
          Edit Details
        </div>
      ),
    },

    {
      name: row.deactivated ? (
        <div
          onClick={() => handleDisableHcp(row?._id, false)}
          className="flex gap-1 items-center text-[#3DA84A] text-[12px]"
        >
          <ActivateIcon />
          Activate HCO
        </div>
      ) : (
        <div
          onClick={() => handleDisableHcp(row?._id, true)}
          className="flex gap-1 items-center text-[#D63434] text-[12px]"
        >
          <DeactivateIcon />
          De-activate HCO
        </div>
      ),

      // action:;
    },
  ];
};

export const editHCOformData = () => [
  {
    name: "institutionName",
    label: LanguageName("INE"),
    placeholder: LanguageName("INE"),
    className: "col-sm-12 mb-2",
  },
  {
    name: "nofHCPs",
    label: "No of HCP's",
    placeholder: "No of HCP's",
    className: "col-sm-6 mb-2",
  },
  {
    name: "institutionType",
    label: LanguageName("IT"),
    placeholder: LanguageName("IT"),
    className: "col-sm-6 mb-2",
  },
  {
    name: "address",
    label: LanguageName("Address"),
    placeholder: LanguageName("Address"),
    className: "col-sm-12 mb-2",
  },
  {
    name: "medrep",
    label: "Med Rep",
    placeholder: "Med Rep",
    className: "col-sm-6 mb-2",
  },

  {
    name: "dateadded",
    label: "Date Added",
    placeholder: "Date Added",
    className: "col-sm-6 mb-2",
  },
];
