import React from "react";
import { privacyPolicyData } from "../Data";
export default function PrivacyPolicies() {
  return (
    <div className="container">
      <div className="font-bold text-2xl mb-5">Admin Console</div>
      <div className="mb-5">
        <div className="text-[#333333] text-[18px] font-bold">Privacy Policies</div>
        <p className="font-normal text-sm text-[#667185] mt-1 mb-3">How We Store, Process, and Secure Your Data</p>
      </div>

      <main className="row gy-4">
        {privacyPolicyData.map((item) => (
          <div className="col-lg-6">
            <div className="mb-2 text-base font-medium text-[#17502A]">{item.title}</div>
            <p className="border px-4 py-3 rounded text-sm">{item.description}</p>
          </div>
        ))}
      </main>
    </div>
  );
}
