import React from "react";
import { FormDatedView } from "../../../../utils/helper";
import moment from "moment";

export default function Plan({ planData }) {
  console.log(planData)
  const ImageType = (key) => {
    switch (key) {
      case "checkIn":
        return <img src="/HCPList/tick.png" alt="" />;

      case "report":
        return <img src="/HCPList/note.png" alt="" />;

      default:
        return <img src="/HCPList/note.png" alt="" />;
    }
  };
  return (
    <div>
      <div>
        {planData?.loader &&
          [1, 2, 3, 4]?.map((item, i) => <ActivitiesLoadingState key={i} />)}
        {!planData?.loader && (!planData?.data || planData?.data?.length < 1) && (
          <div className="text-center text-lg font-semibold">
            No Plan Added
          </div>
        )}
        {!planData?.loader &&
          planData?.data?.map((item, i) => (
            <div className="flex justify-content-between align-items-center mb-[50px]">
              <div className="flex align-items-center">
                <div className="me-[15px] w-12">{ImageType(item?.activityType)}</div>
                <div className="text-[16px] text-[#757575] font-[400] w-full">
                  {FormDatedView(item?.targets?.date)}
                  <br />
                  <b className="text-[16px] text-[#292D32] font-[500]">
                    Plan
                  </b>{" "}
                  <span className="text-[16px] text-[#757575] font-[400] me-[3px]">
                    for {item?.hcp?.title}{" "}
                    {item?.callFocus || item?.reason || item?.hcp?.firstName}{" "}
                    {item?.hcp?.lastName}
                  </span>
                  {/* <b className="text-[16px] text-[#292D32] font-[500]"> */}
                  {/*   {item?.activity} */}
                  {/* </b> */}
                  <div className="text-[16px] w-full text-[#292D32] font-[500]">
                    {/* {moment(item?.selectedDates[0]).format("MMM Do YYYY")} */}
                    <b>Dates - </b>
                    {item?.selectedDates?.map((date, index) => (
                        <span key={index}>
                          {moment(date).format("MMM Do YYYY")}
                          {index !== item.selectedDates.length - 1  ? " - " : ""}
                        </span>
                      ))}
                  </div>
                </div>
              </div>
              {/* <div className="">
                <img src="/HCPList/more.svg" alt="more icon" />
              </div> */}
            </div>
          )).reverse()}
      </div>
    </div>
  );
}
