import { LanguageName } from "../../../utils/helper";

export const formData = () => ([
    {
      name: "firstName",
      label: LanguageName("FN"),
      placeholder: LanguageName("FN"),
      className: "col-sm-6",
      containerClassName: "bg-[#EBEBEB]",
      disabled: true,
    },

    {
      name: "lastName",
      label: LanguageName("LN"),
      placeholder: LanguageName("LN"),
      className: "col-sm-6",
      containerClassName: "bg-[#EBEBEB]",
    },
    {
      name: "country",
      label: LanguageName("COTY"),
      placeholder: LanguageName("COTY"),
      className: "col-sm-6",
      containerClassName: "bg-[#EBEBEB]",
      disabled: true,
    },
    {
      name: "state",
      label: LanguageName("ST"),
      placeholder: LanguageName("ST"),
      className: "col-sm-6",
      containerClassName: "bg-[#EBEBEB]",
      disabled: true,
    },
    {
      name: "specialty",
      label: LanguageName("Specialty"),
      placeholder: LanguageName("Specialty"),
      className: "col-sm-6",
      containerClassName: "bg-[#EBEBEB]",
      disabled: true,
    },
    // {
    //   name: "DateOfBirth",
    //   label: "Date of Birth",
    //   placeholder: "Date of Birth",
    //   className: "col-sm-6",
    //   //   containerClassName: "bg-[#EBEBEB]"
    // },
    {
      name: "city",
      label: LanguageName("CITY"),
      placeholder: LanguageName("CITY"),
      className: "col-sm-6",
      containerClassName: "bg-[#EBEBEB]",
      disabled: true,
    },
    {
      name: "email",
      label: LanguageName("EA"),
      placeholder: LanguageName("EA"),
      className: "col-sm-6",
    },
    {
      name: "address",
      label: LanguageName("SAS"),
      placeholder: LanguageName("SAS"),
      className: "col-sm-6",
      containerClassName: "bg-[#EBEBEB]",
      disabled: true,
    },
    {
      name: "phoneNumber",
      label: LanguageName("PNB"),
      placeholder: LanguageName("PNB"),
      className: "col-sm-6",
    },
    {
      name: "position",
      label: "Position",
      placeholder: "Position",
      className: "col-sm-6",
      containerClassName: "bg-[#EBEBEB]",
      disabled: true,
    },
    {
      name: "institutionName",
      label: LanguageName("INE"),
      placeholder: LanguageName("INE"),
      className: "col-sm-6",
      containerClassName: "bg-[#EBEBEB]",
      disabled: true,
    },

    {
      name: "institutionType",
      label: LanguageName("IT"),
      placeholder: LanguageName("IT"),
      className: "col-sm-6",
      disabled: true,
      containerClassName: "bg-[#EBEBEB]",
    },
  ]);