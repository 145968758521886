import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import HisGracePharmacyComp from "./HisGracePharmacyComp";

function HisGracePharmacy() {
  return (
    <div>
      <AdminLayout active={1}>
        <HisGracePharmacyComp />
      </AdminLayout>
    </div>
  );
}

export default HisGracePharmacy;
