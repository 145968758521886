import React, { useEffect, useState } from "react";
import HealthCarePractitioner from "./HealthCarePractitioner";
import {
  useDisableHcpMutation,
  useLazyGetNewHCPQuery,
} from "../../../store/Schedules/SchedulesApi";
import { toast } from "react-toastify";
import { hcpExtraction } from "../../../utils/helper";

function HealthCare() {
  const [trigger, { data: HCPData, isLoading }] = useLazyGetNewHCPQuery();
  const [disableHCP, { isLoading: DHloading, isError }] =
    useDisableHcpMutation();

  const handleDisableHcp = async (id, status) => {
    const payload = {
      id,
      status,
    };
    const Response = await disableHCP(payload);
    if (!Response?.data?.status) {
      toast.error("oops, something went wrong");
    } else {
      toast.success(Response?.data?.message);
    }

    console.log(id, Response, "handleDisableHcp");
  };

  useEffect(() => {
    trigger({ page: 1, search: "" });
  }, [trigger]);

  console.log(HCPData, "HCPData");

  return (
    <div>
      {HCPData?.data && (
        <HealthCarePractitioner
          pagination={HCPData?.data?.pagination}
          totalData={HCPData?.data?.total}
          HCP={HCPData?.data?.hcps}
          hcpLoader={isLoading}
          handleDisableHcp={handleDisableHcp}
          setPage={trigger}
        />
      )}
    </div>
  );
}

export default HealthCare;
