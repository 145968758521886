import moment from "moment";
import { ImageComponent } from "../../../Components/Common/FallbackImage";
import {
  ActivateIcon,
  DeactivateIcon,
  Dots,
  TrackIcon,
  TrashIcon,
  UserProfileIcon,
} from "../../../utils/svg";
import { ProgressBar } from "react-bootstrap";
import { FaUserAlt } from "react-icons/fa";
import DropDownComp from "../../../Components/Ui/DropDown";
import { useNavigate } from "react-router-dom";
import { LanguageName } from "../../../utils/helper";
// import {}
export const healthData = [
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
];

export const columnsData = () => {
  const StatusColor = (status) => {
    switch (status) {
      case false:
        return (
          <div className="bg-[#E9E9111A] rounded-[24px] px-4 py-2 text-[#5B5B02] capitalize">
            Inactive
          </div>
        );
      case true:
        return (
          <div className="bg-[#04A06826] rounded-[24px] px-4 py-2 text-[#044E54] capitalize">
            Active
          </div>
        );
      default:
        break;
    }
  };

  return [
    {
      name: "Id",
      selector: (row) => <span>#{row?.company?.substring(0, 6)}</span>,
      sortable: true,
    },
    {
      name: LanguageName("MRI"),
      selector: (row) => (
        <div className="flex items-start gap-2">
          <div>
            <FaUserAlt size={30} />
            {/* <ImageComponent
                src={UserProfileIcon}
                /> */}
          </div>
          <div className="flex-col">
            <div className="whitespace-nowrap font-medium capitalize text-[18px]">
              {row.firstName} {row.lastName}
            </div>
            {!row.medrepId && (
              <div className="text-[10px]">
                <span className="text-[#777777]">Pharmacist at </span>
                <span className="text-[#3DA84A]">{row?.institutionName}</span>
              </div>
            )}
          </div>
        </div>
      ),
      width: "250px",
      sortable: true,
    },
    // {
    //   name: "department",
    //   selector: (row) => row.department,
    //   // sortable: true,
    // },
    {
      name: LanguageName("TRY"),
      selector: (row) =>
        Array.isArray(row?.state)
          ? row?.state?.map((item, i) => (
              <span>{typeof item === "string" && item}</span>
            ))
          : row?.state,
      width: "250px",
      sortable: true,
    },
    {
      name: LanguageName("DA"),
      selector: (row) =>
        row?.createdAt
          ? moment(row?.createdAt).format("MMMM Do YYYY, h:mm")
          : "-",
      width: "200px",
      sortable: true,
    },
    {
      name: "HCP’s",
      selector: (row) => row?.hcpsNum,
      sortable: true,
      width: "200px",
    },
    {
      name: LanguageName("Status"),
      selector: (row) => {
        return StatusColor(row.status);
      },
      sortable: true,
      width: "200px",
    },

    {
      name: LanguageName("PPS"),
      selector: (row) => {
        return (
          <div className={` w-full`}>
            <div className="flex w-[150px] text-[14px]">
              <ProgressBar now={90} />
            </div>
            <ProgressBar now={row?.completedPercentage} />
          </div>
        );
      },
      width: "150px",
      // sortable: true,
    },

    {
      name: LanguageName("Action"),
      // sortable: true,
      cell: (row) => (
        <div className="ms-3">
          <DropDownComp
            dropDownText={<Dots color="#7572EA" />}
            options={OptionDropDown(row)}
          />
        </div>
      ),
      width: "150px",
    },
  ];
};

const OptionDropDown = (row, handleDisableHcp) => {
  const navigate = useNavigate();
  return [
    {
      name: (
        <div
          className="flex gap-1 items-center text-[#656468] text-[12px]"
          onClick={() =>
            navigate(`/account/hcp_list/${row?.medrepId}/${row?.company}}`, {
              state: row,
            })
          }
        >
          <UserProfileIcon />
          {LanguageName("ORP")}
        </div>
      ),
      // action:;
    },
    // {
    //   name: (row.deactivated ? <div
    //       onClick={() => handleDisableHcp(row?._id, false)}
    //       className="flex gap-1 items-center text-[#3DA84A] text-[12px]"
    //     >
    //       <ActivateIcon />
    //       Activate Med Rep
    //     </div> : <div
    //       onClick={() => handleDisableHcp(row?._id, true)}
    //       className="flex gap-1 items-center text-[#D63434] text-[12px]"
    //     >
    //       <DeactivateIcon />
    //       Deactivate Med Rep
    //     </div>)

    //   // action:;
    // },
  ];
};

export const TopHeaderCount = (Data) => {
  return [
    {
      name: LanguageName("TMR2"),
      count: Data?.length,
      findBy: ""
    },
    {
      name: LanguageName("AMR"),
      count: Data?.filter((item) => item.status === true).length,
      findBy: true,
    },
    {
      name: LanguageName("IAMR"),
      count: Data?.filter((item) => item.status === false).length,
      findBy: false,
    },
  ];
};

export const FormLabel = (transformedArray, transformedArrayState) => [
  {
    name: "firstName",
    label: "First Name",
    className: "col-lg-6",
    type: "",
    placeholder: "Enter First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
    className: "col-lg-6",
    type: "",
    placeholder: "Enter Last Name",
  },
  {
    name: "email",
    label: "Email Address",
    className: "col-lg-12",
    type: "",
    placeholder: "Enter Email Address",
  },

  {
    name: "phoneNumber",
    label: "Phone Number",
    className: "col-lg-6",
    type: "",
    placeholder: "",
  },
  {
    name: "lgas",
    label: "Local Government Area",
    className: "col-lg-6",
    type: "",
    placeholder: "",
  },

  {
    name: "country",
    label: "Country",
    className: "col-lg-6",
    type: "select",
    placeholder: "Choose Country",
    options: transformedArray,
  },
  {
    name: "state",
    label: "State",
    className: "col-lg-6",
    type: "select",
    placeholder: "Choose State",
    options: transformedArrayState,
  },
];
