import React, { useState } from 'react'
import { useNotificationQuery } from '../../../store/DashBoard/DashBoardApi'
import moment from 'moment';

export default function Notifications() {

    const {data,isLoading}=useNotificationQuery();

    console.log(data?.data?.notifications
        ,'data')

    const [title,setTitle] =useState('all')
    const List =[
       {
        name:<div className='flex gap-2 justify-center'>All <div className='px-[6px] py-[4px] bg-[#3DA84A] rounded-[28px] text-[10px] text-white'>4 New  </div></div>,
        path:'all'
       },
       {
        name:<div>Call Reports</div>,
        path:'Reports'
       },
       {
        name:<div>Schedule</div>,
        path:'Schedule'
       },
    ]
  return (
    <div className='bg-white w-[40vw] xl:w-[30vw] shadow-sm absolute min-h-[30vh] z-50 py-5 top-10 border-[#4F008A1A] border-[1px]'>
    <div className='text-center text-[22px] font-bold mb-[40px]'>Notifications</div>

    <div className='flex justify-between  border-b-[#E5E5EF] border-b-[1px]  mb-[10px]'>
        {List?.map((item,i)=><div 
            onClick={()=>setTitle(item?.path)}
        className={`flex-1 cursor-pointer text-center text-[#8B8B8B] font-medium  pb-[20px] ${title===item?.path?'border-b-[#3DA84A] border-b-[1px]':''}`}>{item?.name}</div>)}
    </div>

    <div className='px-[22px] h-[70vh] overflow-y-scroll'>
        {data?.data?.notifications?.map((item,i)=>
        <div className='flex  gap-3 items-start mt-[34px]'>
        <div className='h-[50px] w-[50px]'>
        {Icons[0]?.path}
        </div>
       <div className='border-b-[#4F008A1A] border-b-[1px] pb-[24px]'>
        <div className='flex justify-between mb-[12px]'>
        <div className='text-[#292D32] text-[14px]'>{item?.title}</div>
        <div className='text-[#757575] text-[10px]'>{ moment(item?.createdAt, "YYYYMMDD").fromNow()}</div>
        </div>
        <div>
        {item?.body}
        </div>
       </div>
        </div>
        )}
        
    </div>
  </div>
  )
}


const Icons=[
    {
        name:'report',
        path:<svg width="50" height="47" viewBox="0 0 50 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="49.621" height="47" rx="23.5" fill="#4F008A" fill-opacity="0.06"/>
        <path d="M16.7636 20.6659C18.5738 12.7081 30.3634 12.7173 32.1645 20.675C33.2212 25.3431 30.3175 29.2944 27.7721 31.7387C25.9251 33.5214 23.003 33.5214 21.1468 31.7387C18.6106 29.2944 15.7069 25.3339 16.7636 20.6659Z" stroke="#3DA84A" stroke-width="1.5"/>
        <path d="M21.937 23.432L23.3154 24.8104L26.991 21.1348" stroke="#3DA84A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
    },
    {
        name:'schedule',
        path:<svg width="50" height="47" viewBox="0 0 50 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="49.621" height="47" rx="23.5" fill="#4F008A" fill-opacity="0.06"/>
        <path d="M32.1615 20.4461V29.4055C32.1615 32.1622 30.5167 33.0811 28.4859 33.0811H21.1346C19.1038 33.0811 17.459 32.1622 17.459 29.4055V20.4461C17.459 17.4597 19.1038 16.7705 21.1346 16.7705C21.1346 17.3402 21.3643 17.8548 21.7411 18.2316C22.1178 18.6083 22.6324 18.838 23.2022 18.838H26.4183C27.5578 18.838 28.4859 17.91 28.4859 16.7705C30.5167 16.7705 32.1615 17.4597 32.1615 20.4461Z" stroke="#4F008A" stroke-width="1.37836" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M28.486 16.7707C28.486 17.9101 27.5579 18.8382 26.4185 18.8382H23.2023C22.6326 18.8382 22.118 18.6085 21.7412 18.2317C21.3645 17.855 21.1348 17.3404 21.1348 16.7707C21.1348 15.6312 22.0629 14.7031 23.2023 14.7031H26.4185C26.9882 14.7031 27.5028 14.9329 27.8796 15.3096C28.2563 15.6864 28.486 16.2009 28.486 16.7707Z" stroke="#4F008A" stroke-width="1.37836" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.1348 24.8115H24.8104" stroke="#4F008A" stroke-width="1.37836" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21.1348 28.4863H28.486" stroke="#4F008A" stroke-width="1.37836" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
    },
    {
        name:'plan',
        path:<svg width="50" height="47" viewBox="0 0 50 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="49.621" height="47" rx="23.5" fill="#4F008A" fill-opacity="0.06"/>
        <path d="M25 33C30.5 33 35 28.5 35 23C35 17.5 30.5 13 25 13C19.5 13 15 17.5 15 23C15 28.5 19.5 33 25 33Z" stroke="#3DA84A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M20.75 22.9999L23.58 25.8299L29.25 20.1699" stroke="#3DA84A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
    }
]