import React, { useState } from "react";
import ButtonComp from "../../../Components/Ui/Button";
import Overview from "../Plan/submodules/Overview";
import PlanMedRepsComp from "../PlanMedReps/PlanMedRepsComp";
import { ProgressBar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import MedRep from "../../../Components/Common/MedRep";
import OffCanvasComp from "../../../Components/Ui/OffCanvas";
import { LanguageName } from "../../../utils/helper";

export default function PlanColComp({ HCOData }) {
  const [plan, setPlan] = useState("Med Reps");
  const navigate = useNavigate();
  const TopList = [
    {
      name: LanguageName("Overview"),
      path: "/plan_overview/Overview",
    },
    {
      name: LanguageName("MR"),
      path: "/plan_overview/Med Reps",
    },
    // {
    //   name: LanguageName("Institutions"),
    //   path: "/plan_overview/Institutions",
    // },
  ];

  console.log("HCOData", HCOData);

  const randomColor = () => {
    const colors = ["#A162F7", "#70CF97", "#E95744"];
    return colors[Math.floor(Math.random() * colors.length)];
  };

  return (
    <div className=" px-3">
      <div>
        <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
          <div className="flex align-items-center lg:mb-0 mb-[1rem] w-full">
            <div className="flex justify-between items-center w-full">
              <div>
                <div className="text-[25px] font-[700]">
                  {LanguageName("PLAN")}
                </div>
                <div className="text-[#777777] text-[14px]">
                  {LanguageName("MR")}/{LanguageName("Institution")}/
                  {LanguageName("CPN")}
                </div>
              </div>
              <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 w-[62%] py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
                <div className="border-r-2 ps-2 pe-3">
                  <img className="" src="/icon/search.png" alt="search" />
                </div>

                <input
                  className="w-100 text-[16px] font-[400]"
                  type="text"
                  placeholder={LanguageName("SCR")}
                />
              </div>
            </div>
            <div className="ms-2"></div>
          </div>
        </div>

        <div className="flex justify-between items-center    border-b-[0.5px] flex-wrap">
          <div className="flex  lg:flex-row   ">
            {TopList?.map((item, index) => (
              <div
                onClick={() => navigate(item?.path)}
                key={index}
                className=" p-[10px]  pe-3 cursor-pointer"
              >
                <div
                  className={`text-[18px] ${
                    item?.name === plan
                      ? "text-[#A162F7] border-b-[#A162F7] border-b-[1px] px-2"
                      : "text-[#333333]"
                  } font-[500] `}
                >
                  {item?.name}
                </div>
              </div>
            ))}
          </div>
          <div className="flex items-center space-x-2 ">
            {/* <ButtonComp
              className="text-[#FFFFFF] font-[500] text-[18px] bg-[#A162F7] rounded-[5px] py-[8px] px-[24px]"
              text={"View plans"}
            /> */}
          </div>
        </div>
      </div>

      <div className="row bg-[#FCFAFF]">
        <div className="col-12  col-xl-9">
          <div className="mb-5 flex justify-between items-center mt-[48px] mb-[15px]">
            <div className="text-[24px] font-medium">{LanguageName("HCO")}</div>
            <div className="bg-white px-[10px] py-[10px] text-[18px]">
              {LanguageName("Institution")}: General Hospital
            </div>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
            {false &&
              [1, 2, 3]?.map((item) => (
                <Skeleton key={item} height={"300px"} />
              ))}

            {HCOData?.map((item, i) => (
              <div
                className="border border-[#E5E5EF] rounded-[10px] bg-[#FFFFFF] p-[10px]"
                key={i}
              >
                <div className="flex flex-col justify-between">
                  <div className="mb-[70px]">
                    <div className="flex justify-between">
                      <div className="flex space-x-2">
                        <div className="">
                          <img src="/Plan/hospital.svg" alt="" />
                        </div>
                        <div className="">
                          <div className="text-[18px] text-[#000000] font-[600] mb-0">
                            {item?.institutionName}
                          </div>
                          <div className="text-[12px] text-[#000000] font-[400] mb-[5px]">
                            {item?.location}
                          </div>
                          <div className="flex items-center mb-[20px]">
                            <div className="text-[10px] text-[#8B8B8B] font-[400]">
                              {LanguageName("LVST")}:
                            </div>
                            <div>
                              <img
                                className="mx-[2px]"
                                src="/Plan/calendar.png"
                                alt=""
                              />
                            </div>
                            <div className="text-[8px] text-[#8B8B8B] font-[400]">
                              ---
                              {/* April 5, 2023 at 10:25AM */}
                            </div>
                          </div>
                          <div className="flex space-x-2 mx-auto mb-[5px]">
                            <div className="">
                              <div
                                className={`rounded-[4px] text-[9.35px] text-[#FFFFFF] font-[500] py-1 px-2`}
                                style={{ backgroundColor: randomColor() }}
                              >
                                {item?.institutionDepartment}
                              </div>
                            </div>
                            {/* <div className="">
                          <div className="bg-[#E95744] rounded-[4px] text-[9.35px] text-[#FFFFFF] font-[500] py-1 px-2">
                            Cardiology
                          </div>
                        </div>
                        <div className="">
                          <div className="bg-[#70CF97] rounded-[4px] text-[9.35px] text-[#FFFFFF] font-[500] py-1 px-2">
                            Physiology
                          </div>
                        </div> */}
                          </div>
                          <div className="text-[#A162F7] border-b w-[fit-content] border-b-[#A162F7] text-[10px] font-[400]">
                            {LanguageName("VM")}
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <img src="/Plan/more.svg" alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <div className="">
                      <img src="/Plan/people.svg" alt="" />
                    </div>
                    <div className="">
                      <Link
                        to={`/plan/${item?.medRep?._id}`}
                        className="text-[#A162F7] border-b w-[fit-content] border-b-[#A162F7] text-[10px] font-[400]"
                      >
                        {LanguageName("VD")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-xl-3 ">
          <MedRep HCOData={HCOData} />
        </div>
      </div>

      <OffCanvasComp
        show={false}
        bodyComponent={<MedRep containerStyle={"col-12"} HCOData={HCOData} />}
      />
    </div>
  );
}
