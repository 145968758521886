import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const languageKey = "language";

i18n
  .use(Backend)

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "fr",
    supportedLngs: ["en", "fr", "en-US"],

    detection: {
      order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],
      caches: ["localStorage", "cookie"],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          lang: {
            editPlan: "Edit Plan",
            proceedToSubmit: "Proceed to submit",
            addPlan: "Add Plan",
            createNewPlan: "Create New Plan",
            safetyReaction: "Safety Reaction",
            UPF: "Upload File",
            Upload: "Upload",
            UCSV: "Upload CSV",
            SCVU: "Select a CSV file to upload",
            DDHE: " or drag and Drop it here",
            UURL: " Or upload from URL",
            CNPT: " Create New Product",
            LVST: "Last Visited",
            VM: "View More",
            PLAN: "Action Plan",
            VPHCP: "View Plan HCP",
            VFP: "View Full Profile",
            VCP: "View Cycle Plan",
            CYP: "Cycle Plan",
            VPHCO: "View Plan HCO",
            CON: "Company Name",
            TION: "Total Institutions",
            AION: "Active institutions",
            IION: "Inactive institutions",
            Institutions: "Institutions",
            AMR: "Assign Med Rep",
            CIHCP: "Confirm information of the HCPs",
            Note: "Note",
            KM: "Key Messages",
            ST: "State",
            Filter: "Filter ",
            world: "World",
            Overview: "Overview",
            TSO: "Total Sales Orders (NGN)",
            TP: "Top Products ",
            Jan: "Jan",
            Feb: "Feb",
            Mar: "Feb",
            Apr: "Apr",
            May: "May",
            June: "June",
            July: "July",
            Aug: "Aug",
            Sept: "Sept",
            Oct: "Oct",
            Nov: "Nov",
            Dec: "Dec ",
            TA: "Top Areas",
            Price: "Price",
            Availability: "Availability ",
            PS: "Product SKU",
            ProductId: "Product ID",
            PrN: "Product Name",
            SM: "See more",
            AP: "Add Product",
            PC: "Product Composition",
            NAFDAC: "NAFDAC Registration Number",
            PP: " Product Price",
            AS: "Availability Status",
            Status: "Status",
            N: " Product Description",
            SL: "Shelf Life (Years)",
            DASH: "Dashboard",
            PRO: "Product",
            MQ: "Manage Orders",
            LEAD: "Leads",
            CUSTOMER: "Customer",
            SCH: "Schedule",
            AC: "Admin Console",
            LG: "Log Out",
            TD: "Total Debt",
            TSC: "Total Sales Collections",
            TOC: "Total Order Count",
            TPO: "Total Paid Order",
            UCB: "User Chart Board",
            MA: "months ago",
            SO: "Sales Order",
            RO: "Recent Orders",
            VA: "View All",
            VAH: "View All HCPs",
            P: "Products",
            SFP: "Search For Products",
            R: "Report",
            OM: "Orders Management",
            OMS: "Search Orders, Field Staffs, Customer",
            S: "Search",
            C: "Customers",
            AC: "Add Customer",
            Time: "Time",
            CF: "Call Focus",
            ACT: "Add Comment",
            CAS: "Create New Schedule",
            AC: "Admin Console",
            PI: "Personal Information",
            PW: "Password",
            PV: "Previous",
            NX: "Next",
            NFC: "Notification",
            FR: "Field Representatives",
            N: "Company Profile",
            CompanyProfile: "Company Profile",
            HCPC: "HCP Category",
            DPP: "Detailing Priority Product",
            COR: "Clinical Overview Reaction",
            ER: "Efficacy Reaction",
            PST: "Positive",
            ON: "Objection Notes",
            NSAN: "Next Step Action Note",
            PPPP: "Privacy Policy",
            UYPNH: "UNate your photo and personal details here",
            UYCNH: "UNate your company photo and details here",
            PPP: "Profile Picture",
            D: "Discard",
            SC: "Save Changes",
            PNN: "Phone Number",
            FN: "First Name",
            LN: "Last Name",
            Role: "Role",
            HA: "Home Address",
            EA: "Email Address",
            Address: "Address",
            CHNP: "Change Photo.",
            jpeg: "(Only *.jpeg and *.png)",
            CC: "Current Password",
            NP: "New Password",
            T: "Confirm New Password",
            FieldRep: "Field Representatives",
            ANFRAUT: "Add new field representative and uNate them",
            ANP: "Add New Rep",
            CL: "Company's Logo",
            CN: "Company's Name",
            CA: "Company's Address",
            CITY: "City",
            PPS: "Privacy Policies",
            PP1: "How We Store, Process, and Secure Your Data",
            PP2: "Data Centers",
            PP3: "Data centers are a critical component of modern organizations and play a crucial role in supporting business..",
            PP4: "Network Security",
            PP5: "Network security is crucial because networks are the backbone of modern organizations and are used to transmit sensitive..",
            PP6: "System Security",
            PP7: "Security Operations",
            PP8: "Access Control",
            PP9: "Vulnerabilty Assesment and Penetration Testing",
            PP10: "Application Level Security",
            PP11: "Data Protection, Continuity, and Retention",
            CCUS: "Contact us",
            Schedule: "Schedule",
            TCR: "Total Call Reports",
            TN: "Total Completed Plan",
            TCS: "Total Completed Schedules",
            Analytics: "Analytics",
            THCO: "Total HCO",
            THN: "Total HCP",
            TP: "Total Plan",
            RS: "Recent Schedules",
            TMR: "Track Med Reps",
            TMRs: "Track Med Rep",
            OM: "Open Map",
            ID: "ID",
            Institution: "Institution",
            CM: "Call Method",
            CO: "Call Objective",
            AV: "Availability",
            DA: "Date Added",
            ETi: "Event Time",
            Action: "Action",
            RPP: "Rows per page",
            RPR: "Recent Call Report",
            VD: "View Details",
            Approve: "Approve",
            Approved: "Approved",
            TV: "Top Visited",
            Daily: "Daily",
            Monthly: "Monthly",
            Annually: "Annually",
            Department: "Department",
            Cardiology: "Cardiology",
            Surgery: "Surgery",
            Pharmacy: "Pharmacy",
            Neurology: "Neurology",
            Others: "Others",
            Coverage: "Coverage",
            IT: "Institution Type",
            IA: "Institution Address",
            CBON: "Coverage based on Institution",
            // BIO: "Basic Info & Objectives",
            BIO: "Basic Info",
            MR: "Med Rep",
            FTPAHCP: " Follow the prompt to add New Health Care Practitioners",
            FTPA: " Follow the prompt to add",
            TS: "Total Schedules",
            CS: "Completed Schedule",
            CS1: "Canceled Schedules",
            TE: "Total Events",
            CE2: "Completed Events",
            CE1: "Canceled Events",
            OE: "Ongoing Events",
            HCPCS: "HCP Created Successfully!",
            DN: "Done",
            CNC: "Cancel",
            CI: "Checked In",
            SCR: "Search for Call Reports by ID, Name, Date, HCOs",
            SSR: "Search for Schedule by ID, Name, Date, HCOs",
            SER: "Search for Event by ID, Name, Date, HCOs",
            SB: "Sort By",
            DR: "Download Report",
            CTY: "Category",
            All: "All",
            TCR: "Total Call Reports",
            ACR: "Approved Call Reports",
            // RR: "Report Reports",
            RR: "Reported Schedules",
            CR: "Call Reports",
            EVT: "Events",
            ET: "Event Type",
            EM: "Event Method",
            CE: "Create Event",
            HN: "Health Care Practitioner",
            ANH: "Add New HN",
            ANHCP: "Add New HCP",
            HNInfo: "HN Info",
            SPL: "Special",
            Specialty: "Specialty",
            PN: "Phone Number",
            HCO: "Health Care Organizations",
            MR2: "Med Representatives",
            TMR2: "Total Med Reps",
            AMR: "Active Med Reps",
            IAMR: "In-Active Med Reps",
            ANMR: "Add New Med Rep",
            MRI: "Med Reps Info",
            TRY: "Territory",
            PPS: "Plan Progress",
            MDA: "Media",
            TST: "Test",
            Institution: "Institution",
            INE: "Institution Name",
            PNH: "Position Held",
            Title: "Title",
            CRN: "Consent to receive newsletter from us",
            SAS: "Street Address",
            PTC: "Postal Code",
            PCL: "Paracetamol",
            MDC: "Malaria Drugs For Children",
            PAD: "Panadol",
            NC: "nice",
            ZTX: "Zithromax 500 mg",
            CNPT: "Create New Product",
            PLAN: "Plan",
            AC: "Admin Console",
            MEDIA: "Media",
            MRS: "Medreps",
            CPN: "Cycle plan",
            OV: "Overview",
            VP: "View plans",
            CPL: "Create plans",
            CU: "Customer Universe",
            TA: "Territory Adds",
            TDS: "Territory Drops",
            TVD: "Top Visited Departments",
            PDPS: "Pending Plans",
            CPLs: "Completed Plans",
            OPV: "Out of Plan Visit",
            LA: "Last Activity",
            UPPD: "Update your photo and personal details here",
            MFN: "Manager’s First Name",
            MLN: "Manager’s Last Name",
            EA: "Email Address",
            CFA: "Company’s Full Address",
            CFN: "Company’s First Name",
            CLN: "Company’s Last Name",
            CPN: "Contact Person’s Name",
            CP: "Company Profile",
            COTY: "Country",
            UC: "Update Changes",
            U: "Update",
            N: "Notifications",
            UR: "Unread",
            MAR: "Mark all as read",
            MSG: "A new check-in has been confirmed by James John from his grace pharmacy...",
            TDY: "Today",
            AT: "at",
            UPA: "Update your password to secure your account",
            UCD: "Update your company details here",
            CPS: "Company Settings",
            Activities: "Activities",
            Accounts: "Accounts",
            firstName: "firstName",
            EnterProductName: "Enter product name",
            EnterProductComposition: "Enter product composition",
            TestInfo:
              "We are currently conducting beta testing on this feature and will update soon",
            GH: "Go Home",
            TotalPlans: "Total Plans",
            HCPUni: "HCP Universe",
            OverviewPlans: "Here is a brief overview of the plans",
            SearchPlans: "Search for Institution, Department",
            MRIVE: "Medical Representative",
            PCs: "Proposed Calls",
            SUM: "Summary",
            CRNG: "Calls Remaining",
            CCTD: "Calls Completed",
            CPFormInfo: "Follow the prompt to create a new plan",
            CPKFormInfo:
              "Kindly fill in the following information to create a new plan",
            AMRF: "Assign Medical Representative",
            SHCP: "Select Healthcare Practitioner",
            SD: "Selected Date",
            Drafts: "Drafts",
            STDrafts: "Save to drafts",
            ANPL: "Add New Plan",
            VSPBS: "view your saved plans here before submission",
            DC: "Date created",
            PAC: "Profile Active",
            PIAC: "Profile Inactive",
            AAC: "Activate Account",
            DAC: "Deactivate Account",
            PNB: "Phone Number",
            Assurance:
              "Are you sure you want to assign this folder to to the selected medical representatives?",
            YIS: "Yes I'm Sure",
            CRNP: "Create new Plan",
            SearchMedrepPlans: "Search for Medrep, HCP...",
            LoadPW: "Loading, Please Wait...",
            SAS: "Save and Submit",
            newPlan: "New Plan",
            Sched: "Schedule",
            SDR: "Download",
            TNS: "Total Completed Plans",
            UAR: "Unapproved Call Reports",
            SMRA: "Select medical representatives to be assigned",
            Save: "Save",
            SearchMR: "Search med rep",
            Name: "Name",
            DateCr: "Date Created",
            AssMR: "Assign med reps",
            Del: "Delete",
            Options: "Options",
            VEI: "View event info",
            ORP: "Open Rep Profile",
            Local: "Location",
            MRT: "Medrep Tour",
            CV: "Customer Visited",
            GPS: "GPS Tracking",
            SOG: "Start/Goal",
            EHCP: "Edit HCP Details",
            Achievement: "Achievement",
            CLPL: "Call Plan",
            ALCL: "Actual call",
            PLCL: "Planned call",
            UPLCL: "Unplanned call",
            AHCP: "Added HCP",
            USCL: "Unsubmitted Call",
            TPGL: "Top Goals",
            HCPCat: "HCP Category",
            TotCalls: "Total Calls",
            LastCall: "Last Call",
            successRate: "% of achieved calls",
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December",
            EHCODet: "Edit HCO's Details"
          },
          // here we will place our translations...
        },
      },
      fr: {
        translation: {
          lang: {
            editPlan: "Modifier le plan",
            proceedToSubmit: "Continuer à soumettre",
            addPlan: "Ajouter un plan",
            createNewPlan: "Créer un nouveau plan",
            safetyReaction: "Réaction de sécurité",
            Accounts: "Comptes",
            ST: "état",
            Filter: "Filtre",
            world: "World",
            Overview: "Tableau de bord",
            VFP: "Voir le profil complet",
            VCP: "Afficher le plan de cycle",
            CYP: "Plan de cycle",
            TSO: "Total des commandes (CFA)",
            TP: " Meilleurs produits",
            Jan: "janvier",
            Feb: "février",
            Mar: "mars",
            Apr: "avril",
            May: "mai",
            Status: "Statut",
            AMR: "Assigner un représentant médical",
            CIHCP: "Confirmer les informations des professionnels de santé",
            Schedule: "Emploi du temps",
            June: "juin",
            ProductId: "Product Identifier",
            July: "juillet",
            Aug: "août",
            Sept: "septembre",
            Oct: "octobre",
            Nov: "novembre",
            Dec: "décembre  ",
            TA: "Ajouts de territoires",
            Price: "Prix",
            Specialty: "Spécialité",
            CF: "Appeler l'attention",
            Time: "Temps",
            Availability: "disponibilité",
            PS: "UGS du produit",
            PrN: "Nom du produit",
            SM: "Voir plus",
            PV: "précédent",
            NX: "Suivant",
            FTPAHCP:
              "Suivez les instructions pour ajouter de nouveaux praticiens de la santé",
            FTPA: "Suivez les instructions pour ajouter",
            AP: "Ajouter un produit",
            PC: "Composition du produit",
            NAFDAC: "Numéro d’enregistrement NAFDAC",
            PP: "Prix du produit",
            AS: " État de disponibilité ",
            N: "Description du produit",
            SL: "Durée de Conservation (Années)",
            DASH: "Tableau de bord",
            PRO: "Produit",
            KM: "Message clé",
            ON: "Notes d'objection",
            NSAN: "Remarque sur l'action à suivre",
            MQ: "Gérer les commandes",
            LEAD: "Prospects",
            CUSTOMER: "Client",
            SCH: "Planning",
            AC: `Console d'administration`,
            LG: "se déconnecter",
            TD: "Dette Totale",
            ANHCP: "Ajouter un nouveau HCP",
            TSC: "Encaissements totaux des ventes",
            TOC: "Nombre total de commandes",
            TPO: "Commande totale payée",
            UCB: "Tableau de bord des utilisateurs",
            MA: "il y a plusieurs mois",
            SO: "Commande de vente",
            RO: "Commandes récentes",
            VA: "Voir tout",
            VAH: "Voir tout HCPs",
            P: "Produits",
            HCPCS: "Professionnel de santé créé avec succès !",
            DN: "Fait",
            CNC: "Annuler",
            SFP: "Rechercher des produits",
            R: "Rapport",
            OM: "Gestion des commandes",
            OMS: "Rechercher les commandes, les membres du personnel sur le terrain, les clients.",
            S: "Rechercher",
            C: "Clients",
            INE: "Nom de l'institution",
            PNH: "Poste occupé",
            Title: "Titre",
            CRN: "Consentement à recevoir notre bulletin d'information",
            SAS: "Adresse (rue)",
            PTC: "Code postal",
            AC: "ajouter des clients",
            CAS: "créer un emploi du temps",
            AC: "Console d'administration",
            PI: "Informations personnelles",
            PW: "mot de passe",
            NFC: "Notification",
            FR: "Représentants en externe",
            N: "profil de l'entreprise",
            PPPP: "politique de confidentialité",
            UYPNH:
              "Mettez à jour votre photo et vos coordonnées personnelles ici",
            UYCNH:
              "Mettez à jour la photo et les détails de votre entreprise ici.",
            PPP: "photo de profil",
            UPF: "Charger un fichier",
            Upload: "Télécharger",
            UCSV: "Télécharger un fichier CSV",
            SCVU: "Sélectionnez un fichier CSV à télécharger",
            DDHE: " ou faites glisser et déposez-le ici",
            UURL: " Ou téléchargez depuis une URL",
            CNPT: "Créer un nouveau produit",
            LVST: "Dernière visite",
            VM: "Voir plus",
            VPHCP: "Voir le plan du professionnel de santé",
            VPHCO: "Voir le plan de l'établissement de santé",
            CON: "Nom de l'entreprise",
            TION: "Total des établissements",
            AION: "Établissements actifs",
            IION: "Établissements inactifs",
            D: "rejeter",
            SC: "Sauvegarder les modifications",
            PNN: "numéro de téléphone",
            FN: "Prénom",
            LN: "Nom de famille",
            Role: "Poste",
            HA: "Adresse personnelle",
            EA: "Email Address",
            Institution: "Institution",
            NP: "Changer de photo.",
            jpeg: "(Uniquement *.jpeg et *.png)",
            CC: "Mot de passe actuel",
            NP: "Nouveau mot de passe",
            T: "Confirmer le nouveau mot de passe",
            FieldRep: "Représentants sur le terrain",
            ANFRAUT:
              "Ajouter un nouveau représentant sur le terrain et les mettre à jour",
            ANP: "Ajouter un nouveau représentant",
            CL: "Logo de l'entreprise",
            CN: "Nom de l'entreprise",
            CA: "Adresse de l'entreprise",
            DPP: "Produit Prioritaire pour la Détailisation",
            COR: "Réaction Vue Clinique",
            ER: "Réaction Efficacité",
            PST: "positif",
            CITY: "ville",
            PPS: "Politiques de confidentialité.",
            PP1: "Comment nous stockons, traitons et sécurisons vos données.",
            PP2: "Centres de données",
            PP3: "Les centres de données sont un élément essentiel des organisations modernes et jouent un rôle crucial dans le soutien aux activités commerciales..",
            PP4: "Sécurité du réseau.",
            PP5: "La sécurité du réseau est cruciale car les réseaux sont l'épine dorsale des organisations modernes et sont utilisés pour transmettre des informations sensibles...",
            PP6: "Sécurité du système.",
            PP7: "Opérations de sécurité.",
            PP8: "Contrôle d'accès",
            PP9: "Évaluation des vulnérabilités et tests de pénétration.",
            PP10: "Sécurité au niveau de l'application",
            PP11: "Protection des données, Continuité et Conservation",
            CCUS: "Contactez-nous",
            TCR: "Total de rapports d'appels",
            TN: "Total de plans terminés",
            TCS: "Total de plannings terminés",
            Analytics: "Analytique",
            THCO: "Total de HCO",
            THN: "Total de HCP",
            HCPC: "Catégorie de praticien de santé",
            TP: "Total de plans",
            RS: "Plannings récents",
            Institutions: "Institutions",
            TMR: "Suivre les représentants médicaux",
            TMRs: "Suivre le représentant médical",
            OM: "Ouvrir la carte",
            ID: "ID",
            ET: "Type d'événement",
            EM: "Méthode d'événement",
            CM: "Méthode d'appel",
            CO: "Objectif d'appel",
            AV: "Disponibilité",
            DA: "Date d'ajout",
            Action: "Action",
            RPP: "Lignes par page",
            RPR: "Rapport d'appels récent",
            VD: "Voir les détails",
            Approve: "Approuver",
            Approved: "Approuvé",
            TV: "Top visités",
            Daily: "Journalier",
            Monthly: "Mensuel",
            Annually: "Annuel",
            Department: "Département",
            Cardiology: "Cardiologie",
            Surgery: "Chirurgie",
            Pharmacy: "Pharmacie",
            Neurology: "Neurologie",
            Others: "Autres",
            Coverage: "Couverture",
            IT: "Type d'institution",
            IA: "Adresse de l'établissement",
            CBON: "Couverture basée sur l'institution",
            MR: "Représentant médical",
            TS: "Total de plannings",
            CS: "Planning terminé",
            CS1: "Plannings annulés",
            CI: "Enregistrement",
            TE: "Total des événements",
            CE2: "Événements terminés",
            CE1: "Événements annulés",
            OE: "Événements en cours",
            SCR: "Rechercher des rapports d'appels par ID, nom, date, HCO",
            SSR: "Rechercher un calendrier par ID, nom, date, HCO",
            SER: "Rechercher un événement par ID, nom, date, HCO",
            SB: "Trier par",
            DR: "Télécharger le rapport",
            CTY: "Catégorie",
            All: "Tout",
            ACR: "Rapports d'appels approuvés",
            RR: "Rapports de rapports",
            CR: "Rapports d'appels",
            EVT: "Événements",
            CE: "Créer un événement",
            HN: "Praticien de la santé",
            ANH: "Ajouter un nouveau HN",
            HNInfo: "Info HN",
            SPL: "Spécial",
            Note: "Remarque",
            PN: "Numéro de téléphone",
            HCO: "Organisations de soins de santé",
            MR2: "Représentants médicaux",
            TMR2: "Total des représentants médicaux",
            AMR: "Représentants médicaux actifs",
            IAMR: "Représentants médicaux inactifs",
            ANMR: "Ajouter un nouveau représentant médical",
            MRI: "Info sur les représentants médicaux",
            TRY: "Territoire",
            PPS: "Progrès du plan",
            MDA: "Média",
            TST: "Test",
            PCL: "Paracétamol",
            MDC: "Médicaments contre le paludisme pour les enfants",
            PAD: "Panadol",
            NC: "sympa",
            ZTX: "Zithromax 500 mg",
            CNPT: "Créer un nouveau produit",
            MRS: "Représentants médicaux",
            CPN: "Plan de cycle",
            OV: "Aperçu",
            VP: "Voir les plans",
            CU: "Univers client",
            TA: "Territoire Ajouts",
            TDS: "Territoire Baisse",
            TVD: "Top départements visités",
            PDPS: "Plans en attente",
            CPL: "Créer des plans",
            CPLs: "Plans terminés",
            OPV: "Visite hors plan",
            LA: "Dernière activité",
            UPPD: "Mettez à jour votre photo et vos coordonnées personnelles ici",
            MFN: "Prénom du manager",
            MLN: "Nom de famille du manager",
            EA: "Adresse e-mail",
            Address: "Adresse",
            CHNP: "Changer la photo",
            CFA: "Adresse complète de l'entreprise",
            CFN: "Prénom de l'entreprise",
            CLN: "Nom de famille de l'entreprise",
            CPN2: "Nom de la personne à contacter",
            CP: "Profil de l'entreprise",
            COTY: "Pays",
            UC: "Mettre à jour les changements",
            U: "Mettre à jour",
            N: "Notifications",
            UR: "Non lu",
            MAR: "Tout marquer comme lu",
            PLAN: "Plan d'action",
            AC: "Console d'administration",
            MEDIA: "Média",
            MSG: "Un nouveau check-in a été confirmé par James John de sa pharmacie Grace...",
            TDY: "Aujourd'hui",
            AT: "à",
            ETi: "Heure de l'événement",
            UPA: "Mettez à jour votre mot de passe pour sécuriser votre compte",
            UCD: "Mettez à jour les coordonnées de votre entreprise ici",
            CPS: "Paramètres de l'entreprise",
            Activities: "Activités",
            ACT: "ajouter un commentaire",
            BIO: "Informations de base et objectifs",
            CompanyProfile: "Profil de l'entreprise",
            EnterProductName: "Entrez le nom du produit.",
            TestInfo:
              "Nous effectuons actuellement des tests bêta sur cette fonctionnalité et nous mettrons bientôt à jour",
            GH: "rentrer chez soi",
            TotalPlans: "Forfaits totaux",
            HCPUni: "Univers HCP",
            OverviewPlans: "Voici un bref aperçu des projets",
            SearchPlans: "Rechercher une institution, un département",
            MRIVE: "Représentant médical",
            PCs: "Appels proposés",
            SUM: "Résumé",
            CRNG: "Appels restants",
            CCTD: "Appels terminés",
            CPFormInfo: "Suivez l'invite pour créer un nouveau plan",
            CPKFormInfo:
              "Veuillez remplir les informations suivantes pour créer un nouveau plan",
            AMRF: "Désigner un représentant médical",
            SHCP: "Sélectionner un professionnel de la santé",
            SD: "Date sélectionnée",
            Drafts: "Brouillons",
            STDrafts: "Enregistrer dans les brouillons",
            ANPL: "Ajouter un nouveau forfait",
            VSPBS: "consultez vos plans enregistrés ici avant de les soumettre",
            DC: "Date créée",
            PAC: "Profil actif",
            PIAC: "Profil inactif",
            AAC: "Activer le compte",
            DAC: "Désactiver le compte",
            PNB: "Numéro de téléphone",
            Assurance:
              "Êtes-vous sûr de vouloir attribuer ce dossier aux visiteurs médicaux sélectionnés?",
            YIS: "Oui, je suis sûr",
            CRNP: "Créer un nouveau plan",
            SearchMedrepPlans: "Rechercher Medrep, HCP...",
            LoadPW: "Chargement, veuillez patienter...",
            SAS: "Enregistrer et envoyer",
            newPlan: "Nouveau plan",
            Sched: "Calendrier",
            SDR: "Télécharger",
            TNS: "Total des plans réalisés",
            UAR: "Rapports non approuvés",
            SMRA: "Sélectionnez les représentants médicaux à affecter",
            Save: "Sauvegarder",
            SearchMR: "Rechercher un représentant médical",
            Name: "Nom",
            DateCr: "date créée",
            AssMR: "Affecter des représentants médicaux",
            Del: "Supprimer",
            Options: "Possibilités",
            VEI: "Afficher les informations sur l'événement",
            ORP: "Ouvrir le profil du représentant",
            Local: "emplacement",
            MRT: "Visite du Medrep",
            CV: "Client visité",
            GPS: "Suivi GPS",
            SOG: "Début/Objectif",
            EHCP: "Modifier les détails du professionnel de la santé",
            Achievement: "Réalisation",
            CLPL: "Forfait d'appel",
            ALCL: "Appel réel",
            PLCL: "Appels planifiés",
            UPLCL: "Appels non planifiés",
            AHCP: "HCP ajouté",
            USCL: "Appel non soumis",
            TPGL: "Objectifs principaux",
            HCPCat: "Catégorie HCP",
            TotCalls: "Nombre total d'appels",
            LastCall: "Dernier appel",
            successRate: "% d'appels réalisés",
            January: "Janvier",
            February: "Février",
            March: "Mars",
            April: "Avril",
            May: "Mai",
            June: "Juin",
            July: "Juillet",
            August: "Août",
            September: "Septembre",
            October: "Octobre",
            November: "Novembre",
            December: "Décembre",
            EHCODet: "Modifier les détails du HCO"
          },
          // here we will place our translations...
        },
      },
    },
  });

// Set the initial language from local storage
const storedLanguage = localStorage.getItem(languageKey);
if (storedLanguage) {
  i18n.changeLanguage(storedLanguage);
} else {
  // If no language is stored, set the default language
  const defaultLanguage = i18n.language || i18n.options.fallbackLng;
  localStorage.setItem(languageKey, defaultLanguage);
}

export default i18n;
