import React, {useEffect, useState} from "react";
import { downloadCSV, extractData, LanguageName, searchHCOItems, searchItems, sortMedrepItems } from "../../../../utils/helper";
import { columnsData } from "../Data";
import { TableComp } from "../../../../Components/Common/TableComp";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../../../Components/Ui/Button";
import TopHeaderCounts from "../../../../Components/Common/TopHeaderCounts";
import DropDownComp from "../../../../Components/Ui/DropDown";
import { TopHeaderCount2 } from "../Data";

const SingleHCOComp = ({HCO, newData, hcoLoader, handleDisableHcp, activated}) => {
  const [searchInput, setSearchInput] = useState("");
  const [headerSelected, setHeaderSelected] = useState("All Department");
  const [filteredItems, setFilteredItems] = useState(newData)
  const [sortOrder, setSortOrder] = useState("date")
  console.log(newData)
  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };

  const OptionDropDown = (row) => [
    {
      name: "Date created",
      action: () => handleSortChange("date"),
    },
    {
      name: "Name",
      action: () => handleSortChange("name"),
    },
  ];
  useEffect(() => {
    const filtered = searchHCOItems(newData, searchInput, "firstName");
    setFilteredItems(sortMedrepItems(filtered, sortOrder));
  }, [searchInput, HCO, sortOrder]);

  return (
    <div className="container">
      <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
        <div className="flex align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[25px] font-[700] ">{HCO?.data?.institutionName}</div>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
          <div className="flex align-items-center  border-1 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
            <div className="me-1">
              <img src="/Schedule/document-download.png" alt="" />
            </div>
            <ButtonComp
              onClick={() =>
                downloadCSV(
                  HCO?.data?.hcps?.filter((item) => {
                    const itemName = item?.institution?.toLowerCase() || "";
                    const itemCompany = item?.lastName?.toLowerCase() || "";
                    const itemSpecialty = item?.specialty?.toLowerCase() || "";
                    const itemPhoneNumber =
                      item?.phoneNumber?.toLowerCase() || "";
                    const search = searchInput?.toLowerCase() || "";

                    return (
                      itemName.includes(search) ||
                      itemPhoneNumber.includes(search) ||
                      itemSpecialty.includes(search) ||
                      itemCompany.includes(search)
                    );
                  }),
                  "HCO_Report.csv",
                  hcoLoader
                )
              }
              className="text-[#44AA43] font-[500] text-[16px] "
              text={LanguageName("DR")}
            />
          </div>
          {/* <div className="flex align-items-center border-1 border-[#4F0084]  bg-[#4F008A] rounded px-3 py-2 ms-2">
            <div className="me-1"></div>
            <ButtonComp
              className="text-[#FFFFFF] font-[500] text-[16px] "
              onClick={() => navigate("create_medrep")}
              text={LanguageName("ANMR")}
            />
          </div> */}
        </div>
      </div>

      <div className="flex lg:overflow-x-scroll overflow-x-hidden  lg:mb-[20px] md:mb-[45px] mb-[45px]">
        {TopHeaderCount2(HCO)?.map(({ name, count, findBy }, i) => (
          <TopHeaderCounts
            key={i}
            name={name}
            count={count}
            isActive={headerSelected === name}
            setHeaderSelected={setHeaderSelected}
            setFilteredItems={setFilteredItems}
            status={findBy}
            Data={HCO?.data?.hcps}
            filter="department"
          />
        ))}

        <div className="w-[8.5rem] lg:border-b-2">{""}</div>
      </div>
      <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
        <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
          <div className="border-r-2 ps-2 pe-3">
            <img className="" src="/icon/search.png" alt="search" />
          </div>
          {/* <input className="border-0 mb-3 ">
               <form
                 className="border-0"
                 placeholder="Search for Call Reports by ID, Name, Date, HCOs"
                 aria-label="Search for Call Reports by ID, Name, Date, HCOs"
                 aria-describedby="basic-addon2"
               ><form
             </input> */}
          <input
            className="w-100 text-[16px] font-[400]"
            type="text"
            placeholder={LanguageName("SCR")}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>
        <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
          <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
            <DropDownComp
              dropDownText={LanguageName("SB")}
              options={OptionDropDown()}
            />
            <div className="">
              <img src="/icon/sort.png" alt="" />
            </div>{" "}
          </div>
        </div>
        {/* <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3"> */}
          {/*   <div className=""> */}
          {/*     {LanguageName("CTY")}: {LanguageName("All")} */}
          {/*   </div>{" "} */}
          {/*   <div className=""> */}
          {/*     <img src="/icon/select-arrows.png" alt="" /> */}
          {/*   </div> */}
          {/* </div> */}
        {/* </div> */}
      </div>
      <div className="table-container border border-1 rounded mb-[32px] w-full ">
        <div className="overflow-x-auto">
          {console.log(filteredItems)}
          <TableComp
            loader={hcoLoader}
            columnsComp={columnsData(handleDisableHcp, HCO?.data?.institutionName, activated)}
            Data={filteredItems}
          />
        </div>
      </div>
    </div>
  );
};

export default SingleHCOComp;
