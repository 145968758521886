import React, { useEffect, useRef, useState } from "react";
import FormUi from "../../../../Components/Ui/FormUi";
import { useForm } from "react-hook-form";
import InputComp from "../../../../Components/Ui/InputComp";
import { FormLabel, FormLabelCompany } from "./Data";
import {
  useGetAllProfileQuery,
  useUpdateCompanyMutation,
  useUploadFileMutation,
} from "../../../../store/User/userApi";
import { LanguageName, storage } from "../../../../utils/helper";
import { toast } from "react-toastify";
import ButtonComp from "../../../../Components/Ui/Button";
import { AddImage } from "../../../../utils/svg";

export default function CompanySettings({}) {
  const companyId = storage["localStorage"].get("user_details_profile")?.user
    ?.id;
  console.log(companyId, "companyId");
  const [selectedImage, setSelectedImage] = useState(null);
  const [convertImageToLink, { isLoading: CILLoader }] =
    useUploadFileMutation();
  const { data: CompanyData, isLoading: CLoader } = useGetAllProfileQuery(
    companyId,
    {
      skip: !companyId,
    }
  );
  const [updateCompanyProfile, { isLoading: UCLoader }] =
    useUpdateCompanyMutation();

  const { control, handleSubmit, reset, setValue, setError } = useForm({
    defaultValues: {
      name: "",
      email: "",
      address: "",
      contactPerson: "",
      contactPhoneNo: "",
      country: "",
      state: "Oyo",
      image: "",
      companyId: "",
    },
  });

  const CompanyDetails = CompanyData?.data?.company;
  const fileInputRef = useRef(null);
  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      const response = await convertImageToLink(formData);
      if (response?.data) {
        const imageUrl = URL.createObjectURL(file);
        setSelectedImage(imageUrl);
        setValue("image", response?.data?.file_link);
      } else {
        toast.error("Please Check your image format");
      }
      //  console.log(response?.data?.file_link,'response')
    }
  };
  const handleSelectImage = () => {
    fileInputRef.current.click();
  };
  console.log(CompanyData, FormLabelCompany());
  useEffect(() => {
    setValue("name", CompanyDetails?.name);
    setValue("email", CompanyDetails?.email);

    // setValue("lastName", CompanyDetails?.name?.split(" ")[1]);

    setValue("contactPhoneNo", CompanyDetails?.contactPhoneNo);

    setValue("address", CompanyDetails?.address);
    setValue("contactPerson", CompanyData?.data.firstName);
    setValue("country", CompanyDetails?.country);
    setValue("state", CompanyDetails?.state);
    setValue("companyId", CompanyDetails?._id);
  }, [CompanyDetails?.name]);

  const handleUpdateCompany = async (data) => {
    const response = await updateCompanyProfile(data);
    if (response?.data) {
      toast.success("Company Update Successful");
    }
    console.log(data, "handleUpdateCompany");
  };

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-5">Admin Console</div>
      <div className="lg:mb-[20px] md:mb-[45px] mb-[45px] border px-4 py-6 rounded">
        <div className="row gap-5 mb-4">
          <div className="col-md-3 mb-4">
            <h3 className="font-semibold text-lg">Profile photo</h3>
            <p className="font-normal text-base text-[#667185] mt-1 mb-3">
              This image will be displayed on your profile
            </p>
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              accept={"image/*"}
              onChange={(e) => handleChange(e.target.files[0])}
            />
            <div>
              <div
                className="flex justify-center items-center mt-4 w-40 py-[1.5px] rounded border-[2px] border-[#8652CE] cursor-pointer"
                // onClick={handleButtonClick}
              >
                <AddImage />
                <ButtonComp
                  text={"Upload Photo"}
                  btnClassName={"text-center text-[#8652CE] ml-2 font-semibold"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-8">
            <div className="border-2 flex justify-center items-center w-36 h-36 rounded-full overflow-hidden">
              <img
                src={selectedImage || "/HCPList/profile.svg"}
                className="w-full h-full object-cover"
                alt="Profile"
              />
            </div>
          </div>
        </div>
        <div className="border-t"></div>
        <div className="row gap-5 mt-4">
          <div className="col-md-3 mb-4">
            <div className="">
              <h3 className="font-semibold text-lg">Personal Information</h3>
              <p className="font-normal text-base text-[#667185] mt-1 mb-3">
                Update you personal details here.
              </p>
              <ButtonComp
                onClick={handleSubmit(handleUpdateCompany)}
                text={"Save changes"}
                loader={UCLoader}
                btnClassName={
                  "bg-[#8652CE] text-white rounded px-3 py-1 font-semibold text-base"
                }
              />
            </div>
          </div>
          <div className="col-md-8 grow-[1]">
            <div className="row">
              <FormUi
                formData={FormLabelCompany()}
                // la={item?.label}
                control={control}
                containerClassName={"h-[35px] bg-[#FCFAFF] border-1"}
                labelClassName="!text-[14px] mb-1 text-[#4F008A]"
                // CompanyData={CompanyData?.data?.company}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
