import React, { useEffect } from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import PlanColComp from "./PlanColComp";
import { useGetMedRepDetailsQuery, useGetPlanHOCQuery, useLazyGetMedRepDetailsQuery } from "../../../store/Plans/PlansApi";
import { useParams } from "react-router-dom";

function PlanCol() {
  const {medRepId} =useParams()
  // const {}=
  //medRepId

  // console.log(medRepId,'medRepId')
  const {data:HCOData,isLoading}=useGetPlanHOCQuery();
  //useGetMedRepDetailsQuery
  const {data:MedRepDetail,isLoading:GMDLoader}=useGetMedRepDetailsQuery(medRepId,{
    skip:!medRepId
  });

  
  

  // console.log(MedRepDetail?.data,'HCOData')
  return (
    // <AdminLayout active={1}>
      <PlanColComp HCOData={MedRepDetail?.data?.plans} />
    // </AdminLayout>
  );
}

export default PlanCol;
