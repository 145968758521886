import React from "react";
import AdminLayout from "../../../Layout/AdminLayout";
import NewHealthCareCompiii from "./NewHealthCareCompiii";

function NewHealthCareiii() {
  return (
    <div>
      <AdminLayout active={1}>
        <NewHealthCareCompiii />
      </AdminLayout>
    </div>
  );
}

export default NewHealthCareiii;
