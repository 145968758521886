import React from "react";
import ButtonComp from "../../Components/Ui/Button";
import Pagination from "react-bootstrap/Pagination";
import { TrackData } from "./Data";

export default function TrackMedRepComp() {
  let active = 2;
  let items = [];
  for (let number = 1; number <= 5; number++) {
    items.push(
      <Pagination.Item key={number} active={number === active}>
        {number}
      </Pagination.Item>
    );
  }
  return (
    <div>
      <div className="container mt-5">
        <div className="row">
          <div className="col-lg-5">
            <div className="flex align-items-center justify-content-between mb-[50px]">
              <div className="text-[24px] font-[700] text-[#444444]">
                Track Med Rep Activity
              </div>
              <div className="flex space-x-4 align-items-center px-4 py-0 border border-[.64px] border-[#4F008A] rounded-full">
                <div className="text-[14px] font-[500] font-inter text-[#282728]">
                  Today
                </div>
                <div className="">
                  <img src="/icon/chevron-down.svg" alt="" />
                </div>
              </div>
            </div>
            <div className="table-container overflow-x-auto border rounded">
              <table className="min-w-full table-auto">
                <thead className="border-b">
                  <tr className="text-center ">
                    <th className="border-r py-[15px] text-[#282728] text-[16px]">
                      S/N
                    </th>
                    <th className="border-r py-[15px] text-[#282728] text-[16px]">
                      Med Rep Name
                    </th>
                    <th className="border-r py-[15px] text-[#282728] text-[16px]">
                      Pending
                    </th>
                    <th className="">Completed</th>
                  </tr>
                </thead>
                <tbody>
                  {TrackData.map((item, i) => (
                    <tr className="text-center" key={i}>
                      <th className="border-r py-[15px] text-[#444444] text-[16px] font-[400]">
                        {item.number}
                      </th>
                      <th className="border-r py-[15px]  text-[#444444] text-[16px] font-[400]">
                        {item.name}
                      </th>
                      <th className="border-r py-[15px]  text-[#444444] text-[16px] font-[400]">
                        {item.pending}
                      </th>
                      <th className=" py-[15px] text-[#444444] text-[16px] font-[400]">
                        {item.completed}
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex lg:flex-row md:flex-row flex-col justify-content-between align-items-baseline pt-4 pb-2 px-3">
              <div className="text-[16px] lg:mb-0 mb-[1rem] font-[500]">
                <b>7</b> out 0f <b>14</b>
              </div>
              <div className="flex space-x-2 align-items-baseline">
                <div className="text-[16px] font-[500]">Previous</div>
                <Pagination size="sm">{items}</Pagination>
                <div className="text-[16px] font-[500]">Next</div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="">
              <img src="/TrackMedRep/map-location.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
