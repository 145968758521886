import React, { useEffect, useState } from "react";
import ButtonComp from "../../../Components/Ui/Button";
import { HCOData, TopHCOHeaderCount, TopHCOHeaderCount2, TopHeaderCount, TopHeaderCount2 } from "./Data";
import Skeleton from "react-loading-skeleton";
import { LanguageName, downloadCSV, sortItems } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";
import TopHeaderCounts from "../../../Components/Common/TopHeaderCounts";
import DropDownComp from "../../../Components/Ui/DropDown";
import TestModal from "../../Overview/subModules/TestModal";
import ModalComp from "../../../Components/Ui/ModalComp";
import { Pagination } from "../../../Components/Common/Pagination";
import { CommonPagination } from "../../../Components/Common/CommonPagination";
import UploadHCODoc from "./Modal/UploadHCODoc";
import { Dots, File, UploadIcon } from "../../../utils/svg";
import { icon } from "leaflet";

export default function HealthCareOrganizationComp({
  Data,
  isLoading,
  setPage,
  pagination,
  total,
}) {
  const navigate = useNavigate();
  const [headerSelected, setHeaderSelected] = useState("All HCO");
  const [filteredItems, setFilteredItems] = React.useState(Data);
  const [sortOrder, setSortOrder] = React.useState("date"); // default sort by date
  const [showModal, setShowModal] = useState(false);

  const [searchText, setSearchText] = useState("");

  const handleSortChange = (newSortOrder) => {
    setSortOrder(newSortOrder);
  };
  const OptionDropDown = (row) => [
    {
      name: "Date created",
      action: () => handleSortChange("date"),
    },
    {
      name: "Name",
      action: () => handleSortChange("name"),
    },
  ];
  const MenuDropDown = (row) => [
    {
      name: <p>Download Report</p>,
      icon: (
        <div className="">
          <img src="/Schedule/document-download.png" alt="" />
        </div>
      ),
      action: () =>
        downloadCSV(
          filterLeadsData(Data, searchText),
          "HCO_Report.csv",
          isLoading
        ),
    },
    {
      name: (
        <div>
          <li>Upload HCO Doc</li>
          <li className="text-[12px] underline text-slate-400 flex">
            {" "}
            {/* <img
              className="mr-1 w-8 h-10"
              title="Click here to download a template for uploading your HCO document."
              src="/icon/info-circle.svg"
              alt=""
            /> */}
            Download HCO Template
          </li>
        </div>
      ),
      icon: <UploadIcon />,
      // action: () => handleSortChange("name"),
    },
    {
      name: "Assign HCO",
      icon: <File />,
      action: () => navigate("assign_hco"),
    },
    {
      name: "Assign HCP",
      icon: <File />,
      // action: () => navigate("assign_hcp"),
    },
  ];
  function filterLeadsData(Data, searchField) {
    return Data?.filter((item) => {
      const itemName = item?._id?.toLowerCase() || "";
      const itemInstitutionName = item.institutionName.toLowerCase() || "";
      const MedFirstName = item?.medRep?.firstName?.toLowerCase() || "";
      const MedLastName = item?.medRep?.lastName?.toLowerCase() || "";
      const itemStatus = item?.status?.toLowerCase() || "";
      const search = searchField?.toLowerCase() || "";
      const HCPName = item?.hcp?.name?.toLowerCase() || "";

      //hcp
      return (
        itemName.includes(search) ||
        MedFirstName.includes(search) ||
        MedLastName.includes(search) ||
        HCPName.includes(search) ||
        itemStatus.includes(search) ||
        itemInstitutionName.includes(search)
      );
    });
  }

  useEffect(() => {
    setPage({ page: pagination?.current, search: searchText });
    // const filtered = filterLeadsData(Data, searchText, "firstName");
    setFilteredItems(sortItems(Data, sortOrder));
  }, [Data, sortOrder, searchText]);

  return (
    <div className="container">
      <div className="flex lg:flex-row  md:flex-row flex-col justify-content-between align-items-center pb-[45px]">
        <div className="flex align-items-center lg:mb-0 mb-[1rem]">
          <div className="text-[25px] font-[700] md:text-left text-center">
            {LanguageName("HCO")}
          </div>
          <div className="ms-2">
            <img src="/icon/info-circle.svg" alt="" />
          </div>
        </div>
        <div className="flex lg:flex-row md:flex-row flex-col justify-content-between">
          
          <div className="flex align-items-center  border-1 border-[#44AA43] lg:mb-0 md:mb-0 mb-[1rem] rounded px-3 py-2 lg:me-2 md:me-2 me-0">
            <div className="me-1">
              <img src="/Schedule/document-download.png" alt="" />
            </div>
            <ButtonComp
              onClick={() =>
                downloadCSV(
                  filterLeadsData(Data, searchText),
                  "HCO_Report.csv",
                  isLoading
                )
              }
              className="text-[#44AA43] font-[500] text-[16px] "
              text={LanguageName("SDR")}
            />
            
          </div>
          {/* <div className="flex items-center justify-center  border-1 lg:mb-0 md:mb-0 mb-[1rem] rounded px-2 py-1 lg:me-2 md:me-2 me-0">
            <DropDownComp
              dropDownText={"Menu"}
              options={MenuDropDown()}
              icon={<Dots />}
            />
          </div> */}
        </div>
      </div>
      <div className="flex overflow-x-scroll !xl:overflow-x-hidden lg:overflow-hidden  lg:mb-[20px] md:mb-[45px] mb-[45px] customScrollHorizontal">
        {/* {TopHeaderCount(Data)?.map(({ name, count, findBy }, i) => (
          <TopHeaderCounts
            key={i}
            name={name}
            count={count}
            isActive={headerSelected === name}
            setHeaderSelected={setHeaderSelected}
            setFilteredItems={setFilteredItems}
            Data={Data}
            status={findBy}
            filter=""
          />
        ))} */}
       
        {TopHCOHeaderCount(Data)?.map(({ name, count, findBy }, i) => (
          <TopHeaderCounts
            key={i}
            name={name}
            count={count}
            isActive={headerSelected === name}
            setHeaderSelected={setHeaderSelected}
            setFilteredItems={setFilteredItems}
            status={findBy}
            Data={Data}
            filter="institutionType"
          />
        ))}
        {/* <div className="w-[8.5rem] lg:border-b-2">{""}</div> */}
      </div>
      <div className="flex lg:flex-row md:flex-col flex-col justify-content-between lg:mb-[20px] md:mb-[45px] mb-[45px]">
        <div className="flex space-x-3 border-[1px] rounded align-items-center lg:w-[50%] md:w-100 py-2 ps-3 pe-0 lg:mb-0 lg:my-5 md:mb-3 mb-3">
          <div className="border-r-2 ps-2 pe-3">
            <img className="" src="/icon/search.png" alt="search" />
          </div>

          <input
            className="w-100 text-[16px] font-[400]"
            type="text"
            placeholder={LanguageName("SCR")}
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
          />
        </div>
        <div className="flex lg:justify-content-normal justify-content-between space-x-[3.6rem] lg:space-x-4">
          <div className="flex align-items-center space-x-2 border-[1px] rounded lg:my-5 py-2 px-3">
            <DropDownComp
              dropDownText={LanguageName("SB")}
              options={OptionDropDown()}
              icon={<img src="/icon/sort.png" alt="" />}
            />
          </div>
        </div>
      </div>
      <div className="row !bg-[#4F008A08] pt-[30px] rounded">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
          {isLoading &&
            [1, 2, 3]?.map((item) => <Skeleton key={item} height={"300px"} />)}
          {!isLoading &&
            filteredItems?.map((item, i) => (
              <div className="bg-[#FFFFFF] rounded mb-[30px] p-3" key={i}>
                <div className="flex justify-content-between align-items-center  mb-[20px]">
                  <div className=" flex">
                    <div className=" me-2">
                      <img src="/HCO/hospital.png" alt="Hospital" />
                    </div>
                    <div className="text-[18px] text-[#000000] font-[600]">
                      {item.institutionName} <br />
                      <span className="text-[12px] text-[#777777] font-[400]">
                        {item.institutionType}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    {/* <img src="/icon/more.svg" alt="" /> */}
                  </div>
                </div>
                <div className="flex space-x-2 align-items-center bg-[#FEECEC80] rounded-full py-1 px-3 w-[fit-content]">
                  <div className="">
                    <img src="/HCO/users.png" alt="" />
                  </div>
                  <div className="text-[13px] font-[500] text-[#000000]">
                    {item.createdBy?.firstName} {item.createdBy?.lastName}
                  </div>
                </div>
                <div className="text-[12px] text-[#555555] font-[500] my-[15px]">
                  {LanguageName("Department")}
                </div>
                <div className="flex space-x-[0.7rem] mb-[15px]">
                  {[...new Set(item.hcps)].map((dept, i) => (
                    <div
                      key={i}
                      className="text-[12px] text-[#000000] font-[500] bg-[#F3F1FB] rounded-full py-1 px-[0.7rem]"
                    >
                      {dept?.department}
                    </div>
                  ))}
                </div>

                <div className="flex justify-between items-center">
                  <div>{/* <img src="/HCO/people.png" alt="" /> */}</div>
                  <div className="relative inline-block">
                    <ButtonComp
                      onClick={() => {
                        navigate(
                          `/account/health_care_organization/${item._id}`
                        );
                      }}
                      className="text-[14px] text-[#4F008A] font-[500] border py-1 px-4"
                      text={LanguageName("VAH")}
                    />
                    {/* <div className="absolute top-[-10px] right-[-10px] bg-yellow-100 text-yellow-800 text-xs font-semibold rounded-full px-2 py-0.5">
                      Beta
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
        </div>
        {!isLoading && filterLeadsData(Data, searchText)?.length === 0 && (
          <div className="text-center py-5">No HOC Found</div>
        )}
      </div>

      <div className="item_container w-full h-[5rem] block">
        <CommonPagination
          current={pagination?.current}
          perPage={pagination?.perPage}
          numberOfPages={pagination?.number_of_pages}
          next={pagination?.next}
          setPage={setPage}
          searchInput={searchText}
        />
      </div>
      <ModalComp
        handleClose={() => setShowModal(false)}
        show={showModal}
        position={"fixed top-2 right-[10%]"}
        bodyComponents={<UploadHCODoc />}
      />
    </div>
  );
}
