import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ButtonComp from "../../../Components/Ui/Button";
import { FormLabel, LabelDesc } from "./Data";
import FormUi from "../../../Components/Ui/FormUi";
import { useForm } from "react-hook-form";
import ModalComp from "../../../Components/Ui/ModalComp";
import { MarkIcon } from "../../../utils/svg";
import { useCreateHCPMutation } from "../../../store/Event/EventApi";
import { LanguageName } from "../../../utils/helper";

export default function NewHealthCareComp({
  Data
}) {
  const [pageNo, setPageNo] = useState(1);
  const [modalPage, setModalPage] = useState(false);
  const [createHCP, { isLoading: HCPLoader }] = useCreateHCPMutation();
  const {
    control,
    handleSubmit,
    getValues,
    watch,
    clearErrors,
    formState: { errors, isValid },
    setError,
    setValue,
  } = useForm({
    defaultValues: {
      email: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      title: "",
      address: ["address1", "address2"],
      city: "Ikaja",
      state: "Abuja",
      country: "Nigeria",
      postalCode: "123456",
      institutionType: "hospital",
      institutionName: "Lagos State teaching hospital",
      specialty: "specialty",
      department: "Radiology",
      position: "position",
      note: "note",
    },
  });

  useEffect(() => {
    setValue('institutionName',Data?.institutionName)
  }, [Data?._id])
  

  const handleOnNext = (data) => {
    if (pageNo < 4) {
      setPageNo(pageNo + 1);
    }
    // if(pageNo>)
  };
  const handleOnBack = (data) => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };
  const handleOnSubmit = async (data) => {
    console.log(data);

    const response = await createHCP(data);

    // console.log(response,'createHCP')
  };
  return (
    <div className="container">
      <div className="text-[28px] text-[#242731] font-[700] lg:text-left text-start mb-[24px]">
        {LanguageName("ANHCP")}
      </div>
      <div className="text-[18px] text-[#333333] font-[500] lg:text-left text-start mb-[59px]">
        {LanguageName("FTPAHCP")}
      </div>
      <div className="row border-t mb-[80px]">
        <div className="d-none d-lg-block col-lg-5 col-xl-4 pt-[80px] md:pb-0 pb-5 md:border-r md:border-b-0 border-b border-r-0">
          {LabelDesc?.map((item, i) => (
            <div key={i} className="flex space-x-[4rem] mb-[60px]">
              <div className="flex flex-col ms-4 flex-[0.9] items-end">
                <div className="text-right text-[18px] text-[#242731] font-[500]">
                  {item?.name}
                  {i}
                </div>
                <div className="text-right text-[14px] text-[#333333] font-[500]">
                  {item?.desc}
                </div>
              </div>
              <div className="flex flex-col ">
                <div className="bg-[#EDE6F5] rounded-full p-3 h-[58px] w-[58px] flex justify-center items-center">
                  {pageNo >= i + 1 ? item?.active : item?.inactive}
                </div>
                {/* <div className="">
                <img src="/NHC/line.png" alt="" />
              </div> */}
              </div>
            </div>
          ))}
        </div>
        <div className="col col-xl-8 col-lg-7 ps-5">
          <div className="ms-3 lg:me-[5rem] ">
            <div className="text-end text-[20px] mt-[46px]  mb-[17px]">
              {pageNo}/4
            </div>

            {pageNo === 1 && (
              <div className="row gy-3">
                <FormUi
                  formData={FormLabel.slice(0, 5)}
                  control={control}
                  containerClassName={"bg-[#FCFAFF]"}
                  labelClassName={"!text-[#555555] text-[14px] mb-2"}
                />
              </div>
            )}
            {pageNo === 2 && (
              <div className="row gy-3">
                <FormUi
                  formData={FormLabel.slice(5, 11)}
                  control={control}
                  containerClassName={"bg-[#FCFAFF]"}
                  labelClassName={"!text-[#555555] text-[14px] mb-2"}
                />
              </div>
            )}
            {pageNo === 3 && (
              <div className="row gy-3">
                <FormUi
                  formData={FormLabel.slice(11, 16)}
                  control={control}
                  containerClassName={"bg-[#FCFAFF]"}
                  labelClassName={"!text-[#555555] text-[14px] mb-2"}
                />
              </div>
            )}

            {pageNo === 4 && (
              <div className="row gy-3">
                <FormUi
                  formData={FormLabel.slice(16, 17)}
                  control={control}
                  containerClassName={"bg-[#FCFAFF]"}
                  labelClassName={"!text-[#555555] text-[14px] mb-2"}
                />
              </div>
            )}

            <div className="flex space-x-4 mt-5">
              <ButtonComp
                className="py-1 px-5 text-[#4F008A] text-[16px] font-[500] h-[54px] rounded bg-[#EDE6F5]"
                text={LanguageName("PV")}
                onClick={handleSubmit(handleOnBack)}
              />
              <ButtonComp
                className="py-1  text-[16px] font-[500] text-[#FFFFFF] rounded bg-[#3DA84A] h-[54px] px-5"
                text={LanguageName("NX")}
                onClick={
                  pageNo === 4
                    ? handleSubmit(handleOnSubmit)
                    : handleSubmit(handleOnNext)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ModalComp
        show={modalPage}
        handleClose={() => setModalPage(false)}
        bodyComponents={
          <div>
            <div className="flex justify-center mt-5 mb-4">
              <MarkIcon />
            </div>
            <div className="flex flex-col items-center justify-center mb-5">
              <h3 className="mb-3">{LanguageName("HCPCS")}</h3>
              <div className="text-center">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Phasellus blandit, elit at eleifend pulvinar, ex ex ultrices
                sem, a vestibulum dolor
              </div>
            </div>

            <div className="flex justify-center gap-5 mb-5">
              <ButtonComp
                text={LanguageName("CNC")}
                btnClassName={"bg-[#FFEBEB] h-[50px] text-[#8F0808] px-[40px]"}
              />
              <ButtonComp
                text={LanguageName("DN")}
                btnClassName={"bg-[#A162F7] h-[50px] text-[#FFFFFF] px-[40px]"}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
