import React from 'react'

export default function MetricsCard() {
  return (
    <div className="border-[#EDE6F3] border-[1px]">
    <div className={`flex justify-between px-[20px] py-[20px]`}>
      <span className="text-[#444444] text-[18px]"> Total HCO</span>
      <span className="text-[#060D1C] font-bold text-[24px]">02345</span>
    </div>
    <div className="bg-[#EDE6F3] h-[1px] mx-[20px]" />
    <div className="px-[20px] py-[24px]">
      <div className="text-[#444444] text-[18px] mb-[20px] font-medium">
        Total HCP
      </div>

      <div className={`flex justify-between  `}>
        <span className="text-[#060D1C] font-bold text-[24px]">
          02345
        </span>

        {/* <span className='text-[#060D1C] font-bold text-[24px]'>02345</span> */}
      </div>
    </div>
  </div>
  )
}
