import React, { useState } from "react";
import { TableComp } from "../../../Components/Common/TableComp";
import { columnsData, columnsDataII, tableData } from "../Data";
import CallReportCard from "./CallReportCard";
import { LanguageName, storage } from "../../../utils/helper";
import {
  useApproveReportMutation,
  useGetCallReportQuery,
  useGetSingleCallReportQuery,
} from "../../../store/CallReport/CallReportApi";
import { useGetSchedulesQuery } from "../../../store/Schedules/SchedulesApi";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import OffCanvasComp from "../../../Components/Ui/OffCanvas";
import CallReportModalMessages from "../../CallReports/CallReportModalMessages";
import ModalComp from "../../../Components/Ui/ModalComp";
import ButtonComp from "../../../Components/Ui/Button";
import { toast } from "react-toastify";
import ShowMapComp from "../../Show";

export default function OverviewDashboard() {
  // const { t } = useTranslation();
  const [userData, setUserData] = useState({});
  const { data: singleCallReport, isLoading: CRLoading } =
    useGetSingleCallReportQuery(userData?._id, {
      skip: !userData?._id,
    });
  const [showOffCanvas, setOffCanvas] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const companyId = storage["localStorage"].get("companyId")?._id;
  const { data: allCallReport, isLoading: CRloader } = useGetCallReportQuery(
    companyId,
    {
      skip: !companyId,
    },
  );
  const { data: ScheduleData, isLoading } = useGetSchedulesQuery();
  const [approveReport, { isLoading: ARLoader }] = useApproveReportMutation();
  // console.log(allCallReport,'allCallReport')
  const handleApproveCallReport = async (id) => {
    console.log(id);
    const response = await approveReport(id);

    if (response.data) {
      toast.success(response?.data?.message);
      setModal(false);
    }
    // console.log(response?.data?.message)
  };
  const {t} = useTranslation()
  return (
    <>
      <OffCanvasComp
        handleClose={() => setOffCanvas(false)}
        show={showOffCanvas}
        bg="bg-white"
        bodyComponent={
          <CallReportModalMessages
            extraData={userData}
            userData={singleCallReport?.data?.callReport}
          />
        }
      />
      <div>
        <div className="border border-1 rounded p-[22px] mb-[32px]">
          <div className="flex justify-content-between align-items-center mb-[29px]">
            <div className="text-[#1E1B39] font-instrument-sans text-[20px] font-[600]">
              {t(`lang.TMR`)}
            </div>
            <Link
              to="/show/64cbb773807926fd39e1da00/64cbb773807926fd39e1da00"
              className="flex items-center text-[#4F008A] font-instrument-sans text-[14px] font-[500]"
            >
              <span className="mr-2">Open Map</span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.9082 6.48299V14.5913C1.9082 16.1747 3.0332 16.8247 4.39987 16.0413L6.3582 14.9247C6.7832 14.683 7.49154 14.658 7.9332 14.883L12.3082 17.0747C12.7499 17.2913 13.4582 17.2747 13.8832 17.033L17.4915 14.9663C17.9499 14.6997 18.3332 14.0497 18.3332 13.5163V5.40799C18.3332 3.82465 17.2082 3.17465 15.8415 3.95799L13.8832 5.07465C13.4582 5.31632 12.7499 5.34132 12.3082 5.11632L7.9332 2.93299C7.49154 2.71632 6.7832 2.73299 6.3582 2.97465L2.74987 5.04132C2.2832 5.30799 1.9082 5.95799 1.9082 6.48299Z"
                  stroke="#4F008A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.1333 3.33301V14.1663"
                  stroke="#4F008A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.1084 5.5166V16.6666"
                  stroke="#4F008A"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="">
            <ShowMapComp />
          </div>
        </div>
        {/*  */}

        <div className="border px-3 mb-[32px] shadow-md">
          <div className="flex justify-between items-center py-[20px] px-3">
            <div className="text-[#1E1B39] text-[22px] font-semibold">
              {LanguageName("RS")}
            </div>
            <div
              className="text-[#4F008A] text-[14px] font-medium cursor-pointer"
              onClick={() => {
                // activities/Schedule
                navigate(`/activities/Schedule`);
              }}
            >
              {" "}
              {LanguageName("VA")}
            </div>
          </div>
          <TableComp
            loader={isLoading}
            columnsComp={columnsDataII()}
            Data={ScheduleData?.data?.schedules?.slice(0, 10)}
          />
        </div>

        {/*  */}

        <div className="row border border-1 rounded px-[10px] pb-[15px]">
          <div className="flex justify-content-between align-items-center py-[30px]">
            <div className="text-[20px] font-[600] text-[#1E1B39]">
              {LanguageName("RPR")}
            </div>
            <Link
              to="/activities/call_reports"
              className="text-[14px] font-[500] text-[#4F008A]"
            >
              {LanguageName("VA")}
            </Link>
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-3 2xl:gap-7.5">
            {Array?.isArray(allCallReport?.data?.reports) &&
              allCallReport?.data?.reports
                .slice(0, 3)
                ?.map((item, i) => (
                  <CallReportCard
                    setUserData={setUserData}
                    setOffCanvas={setOffCanvas}
                    setModal={setModal}
                    item={item}
                    key={i}
                  />
                ))}
          </div>
        </div>
      </div>
      <ModalComp
        show={modal}
        handleClose={() => setModal(false)}
        bodyComponents={
          <div className="py-4">
            <div className="text-[30px]  text-center mb-[30px] font-bold">
              Approve Call Report
            </div>
            <div className="text-center mb-[30px]">
              Are you sure you want to approve this?
            </div>

            <div className="flex justify-around align-items-center mt-[20px]">
              <ButtonComp
                onClick={() => setModal()}
                className="text-[#4F008A] text-[14px] font-[500] border border-[0.57px] rounded border-[#8354CF73] py-[9px] lg:px-[30px] md:px-[30px] px-[20px]"
                text={"Cancel"}
              />
              <ButtonComp
                loader={ARLoader}
                className="text-[#FFFFFF] text-[14px] font-[500] border border-[0.57px] rounded border-[#8354CF73] bg-[#8354CF] py-[9px] lg:px-[30px] md:px-[30px] px-[28px]"
                text={"Approve"}
                onClick={() => handleApproveCallReport(userData?._id)}
              />
            </div>
          </div>
        }
      />
    </>
  );
}
