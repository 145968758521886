import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";

export const MedRepApi = createApi({
  reducerPath: "MedRepApi",
  baseQuery: baseQuery,
  tagTypes: ["medReps"],

  endpoints: (builder) => ({
    getMedRep: builder.query({
      query: (companyId) => ({
        url: `/admin/company/${companyId}/med-reps?limit=300`,
        method: "GET",
      }),
      providesTags: ["medReps"],
    }),
    getNewMedRep: builder.query({
      query: ({ companyId, page, search }) => ({
        url: `/admin/company/${companyId}/med-reps?page=${page}&search=${search}`,
        method: "GET",
      }),
      providesTags: ["medReps"],
    }),
    createMedRepMain: builder.mutation({
      query: (payload) => ({
        url: `/admin/company/med-rep/create`,
        method: "POST",
        body: payload,
      }),

      // transformResponse: (response) => response.data,
      invalidatesTags: ["medReps"],
    }),
    activateMedrep: builder.mutation({
      query: (payload) => ({
        url: `/admin/company/med-rep/${payload.id}/update`,
        method: "PATCH",
        body: payload.body,
      }),

      transformResponse: (response) => response,
      invalidatesTags: ["medReps"],
    }),
    getMedRepsWithPlans: builder.query({
      query: () => ({
        url: `/admin/all/company/medReps`,
        method: "GET",
      }),
    }),
    getSingleMedRep: builder.query({
      query: (medRepId) => ({
        url: `/user/medrep/${medRepId}`,
        method: "GET",
      }),
      // providesTags:['medReps'],
    }),
    getMedRepActivities: builder.query({
      query: (medRepId) => ({
        url: `/user_activity/medrep/${medRepId}`,
        method: "GET",
      }),
      // providesTags:['medReps'],
    }),
    getHCPActivities: builder.query({
      query: (hcpId) => ({
        url: `/hcp_activity/hcp/${hcpId}`,
        method: "GET",
      }),
      // providesTags:['medReps'],
    }),
    updateHCP: builder.mutation({
      query: ({ body, id }) => ({
        url: `hcp/update/${id}`,
        method: "PUT",
        body: body,
      }),
    }),
    getHCP: builder.query({
      query: (id) => ({
        url: `hcp/${id}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data.hcp,
    }),
    getAchievement: builder.query({
      query: (payload) => ({
        url: `analytics/achievement?month=${payload.month}&medRepId=${payload.medRepId}&year=${payload.year}`,
        method: "GET",
      }),
      transformResponse: (response) => response.data,
    }),
    getSingleHCP: builder.query({
      query: (id) => ({
        url:`hcp/${id}`,
        method: "GET"
      })
    })
  }),
 
});
//admin/company/med-rep/create
//hcp/hcos

export const {
  useLazyGetNewMedRepQuery,
  useGetMedRepQuery,
  useCreateMedRepMainMutation,
  useActivateMedrepMutation,
  useGetSingleMedRepQuery,
  useGetMedRepsWithPlansQuery,
  useGetMedRepActivitiesQuery,
  useGetHCPActivitiesQuery,
  useUpdateHCPMutation,
  useGetHCPQuery,
  useGetAchievementQuery,
  useGetSingleHCPQuery
} = MedRepApi;
