import moment from "moment";
import { ImageComponent } from "../../../Components/Common/FallbackImage";
import {
  ActivateIcon,
  DeactivateIcon,
  Dots,
  TrackIcon,
  TrashIcon,
  UserProfileIcon,
} from "../../../utils/svg";
import { FaUserAlt } from "react-icons/fa";
import DropDownComp from "../../../Components/Ui/DropDown";
import { useNavigate } from "react-router-dom";
import { LanguageName } from "../../../utils/helper";
// import {}
export const healthData = [
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
  {
    id: "#12343",
    name: "Olakunbi Olabode 0.",
    post: "Pharmacist at ",
    span: "Pharmserv Nigeria LTD",
    speciality: "Dermatologist",
    phoneNumber: "2348129960536",
    medRep: "Joe Akpan",
    status: "Active",
    date: "30th January, 22",
    img: "/HealthCare/person-1.svg",
    icon: "/Overview/more.svg",
  },
];

export const columnsData = (handleDisableHcp) => {
  const StatusColor = (status) => {
    switch (status) {
      case true:
        return (
          <div className="bg-[#E9E9111A] rounded-[24px] px-4 py-2 text-[#5B5B02] capitalize">
            Inactive
          </div>
        );
      case false:
        return (
          <div className="bg-[#04A06826] rounded-[24px] px-4 py-2 text-[#044E54] capitalize">
            Active
          </div>
        );
      default:
        break;
    }
  };
  return [
    {
      name: "Id",
      selector: (row) => <span>#{row._id?.substring(0, 6)}</span>,
      sortable: true,
    },
    {
      name: "HCP Info",
      selector: (row) => (
        <div className="flex items-start gap-2">
          {console.log(row, "row")}
          {/* <div>
            <FaUserAlt size={30} />
            {/* <ImageComponent
                src={UserProfileIcon}
                /> 
          </div> */}
          <div className="flex-col">
            <div className="whitespace-nowrap font-medium capitalize text-[18px]">
              {row.firstName} {row.lastName}
            </div>
            <div className="text-[10px]">
              <span className="text-[#777777]">{row?.position} at </span>
              <span className="text-[#3DA84A]">
                {row?.hco?.institutionName}
              </span>
            </div>
          </div>
        </div>
      ),
      width: "250px",
      sortable: true,
    },
    // {
    //   name: "department",
    //   selector: (row) => row.department,
    //   // sortable: true,
    // },
    {
      name: LanguageName("Specialty"),
      selector: (row) => row.specialty,
      sortable: true,
    },
    {
      name: LanguageName("PNN"),
      selector: (row) => row.phoneNumber,
      width: "200px",
      sortable: true,
    },
    {
      name: LanguageName("MR"),
      selector: (row) => (
        <span>
          {row?.firstName} {row?.lastName}
        </span>
      ),
      sortable: true,
    },

    {
      name: LanguageName("Status"),
      selector: (row) => {
        return StatusColor(row.deactivated);
      },
      sortable: true,
    },
    {
      name: LanguageName("DA"),
      selector: (row) => moment(row?.createdAt).format("MMMM Do YYYY, h:mm"),
      width: "200px",
      sortable: true,
    },
    {
      name: LanguageName("Action"),
      // sortable: true,
      cell: (row) => (
        <div className="ms-3">
          <DropDownComp
            dropDownText={<Dots color="#7572EA" />}
            options={OptionDropDown(row, handleDisableHcp)}
          />
        </div>
      ),
      width: "180px",
    },
  ];
};

const OptionDropDown = (row, handleDisableHcp) => {
  const navigate = useNavigate();
  return [
    {
      name: (
        <div
          className="flex gap-1 items-center text-[#656468] text-[12px]"
          onClick={() =>
            navigate(`/account/hcp/${row?.hco?.createdBy}/${row?._id}`, {
              state: row,
            })
          }
        >
          <TrackIcon />
          Check Profile
        </div>
      ),
      // action:;
    },
    // {
    //   name: (
    //     <div className="flex gap-1 items-center text-[#656468] text-[12px]">
    //       <TrackIcon />
    //       Check Profile
    //     </div>
    //   ),
    //   // action:;
    // },
    {
      name: row.deactivated ? (
        <div
          onClick={() => handleDisableHcp(row?._id, false)}
          className="flex gap-1 items-center text-[#3DA84A] text-[12px]"
        >
          <ActivateIcon />
          Activate HCP
        </div>
      ) : (
        <div
          onClick={() => handleDisableHcp(row?._id, true)}
          className="flex gap-1 items-center text-[#D63434] text-[12px]"
        >
          <DeactivateIcon />
          De-activate HCP
        </div>
      ),

      // action:;
    },
  ];
};

export const TopHeaderCount = (Data, total) => {
  return [
    {
      name: "All HCP",
      count: Data?.length,
      findBy: "",
    },
    {
      name: "Active HCP",
      count: Data?.filter((item) => item?.deactivated === false)?.length,
      findBy: false,
    },
    {
      name: "Inactive HCP",
      count: Data?.filter((item) => item?.deactivated === true)?.length,
      findBy: true,
    },
  ];
};
