export const tableData = [
  {
    Serial: "1",
    Practitioner: "Radiology",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "5.00",
    Completed: "3.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "2.00",
  },
  {
    Serial: "2",
    Practitioner: "Cardiology",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "3.00",
    Completed: "1.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "1.00",
  },
  {
    Serial: "3",
    Practitioner: "Orthopedics",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "2.00",
    Completed: "2.00",
    Remaining: "0.00",
    Status: "Completed",
    Visit: "-",
  },
  {
    Serial: "4",
    Practitioner: "General Surgery",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "4.00",
    Completed: "2.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "-",
  },
  {
    Serial: "5",
    Practitioner: "Ophthamology",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "5.00",
    Completed: "3.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "-",
  },
  {
    Serial: "6",
    Practitioner: "Neurology",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "3.00",
    Completed: "1.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "2.00",
  },
  {
    Serial: "7",
    Practitioner: "Pathology",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "2.00",
    Completed: "2.00",
    Remaining: "0.00",
    Status: "Completed",
    Visit: "-",
  },
  {
    Serial: "8",
    Practitioner: "Medicine",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "5.00",
    Completed: "4.00",
    Remaining: "1.00",
    Status: "Pending",
    Visit: "-",
  },
  {
    Serial: "9",
    Practitioner: "Radiology",
    Practitionerii: "Dermatologist at General Hospital",
    Target: "4.00",
    Completed: "2.00",
    Remaining: "2.00",
    Status: "Pending",
    Visit: "1.00",
  },
];
