import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../api";
import moment from "moment";

export const DashBoardApi = createApi({
  reducerPath: "DashBoardApi",
  baseQuery: baseQuery,

  endpoints: (builder) => ({
    dashboard_metric: builder.query({
      query: ({StartDateEnhanced, EndDateEnhanced}) => ({
        url: `/metrics/orders-range?startDate=${moment(StartDateEnhanced).valueOf() / 1000
        }&endDate=${moment(EndDateEnhanced).valueOf() / 1000}`,
        method: "GET",
      }),
    }),
    graphData: builder.query({
      query: ({StartDateEnhanced,EndDateEnhanced}) => ({
        url: `/metrics/charts-range?startDate=${moment(StartDateEnhanced).valueOf() / 1000
      }&endDate=${moment(EndDateEnhanced).valueOf() / 1000}`,
        method: "GET",
      }),
    }),
    // graphData: builder.query({
    //   query: ({monthId}) => ({
    //     url: `/metrics/charts?months=${monthId}`,
    //     method: "GET",
    //   }),
    // }),
    topAreasData: builder.query({
      query: (month) => ({
        url: `/metrics/top-areas`,
        method: "GET",
      }),
    }),
    mainDashBoardMap: builder.query({
      query: (companyId) => ({
        url: `company/staffs-locations/${companyId}`,
        method: "GET",
      }),
    }),
    fieldReps: builder.query({
      query: (companyId) => ({
        url: `/company/sales-rep`,
        method: "GET",
      }),
    }),
    overviewMetrics: builder.query({
      query: (companyId) => ({
        url: `/admin/company/overview`,
        method: "GET",
      }),
    }),
    Notification: builder.query({
      query: (companyId) => ({
        url: `Notifications`,
        method: "GET",
      }),
    }),

    //dmin/company/overview
  }),
});

export const {
  useDashboard_metricQuery,
  useGraphDataQuery,
  useTopAreasDataQuery,
  useMainDashBoardMapQuery,
  useFieldRepsQuery,
  useOverviewMetricsQuery,
  useNotificationQuery
} = DashBoardApi;
