import DataTable from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import NoData from "./NoData";
import { Spinner } from "react-bootstrap";
import { Pagination } from "./Pagination";
// {LanguageName('ProductId')}
const columns = [
  {
    name: "Product ID",
    selector: (row) => row.title,
    sortable: true,
  },
  {
    name: "Product Name",
    selector: (row) => row.year,
    sortable: true,
  },
  {
    name: "Availability",
    selector: (row) => row.year,
    sortable: true,
  },
  {
    name: "Price ()",
    selector: (row) => row.year,
    sortable: true,
  },
  {
    name: "Action",
    sortable: true,
    selector: (row) => <BsThreeDotsVertical color="#7572EA" />,
  },
];

export function TableComp({
  columnsComp,
  Data = [],
  sortId = 1,
  loader = false,
  expandOnRowClicked,
  customStylesI,
  pagination,
  // setPage,
}) {
  const hasRows = Data && Data.length > 0;

  // Customize the DataTable conditional rendering
  const conditionalRowStyles = hasRows ? {} : { display: "table-header-group" };

  // const { current, perPage, number_of_pages, next } = pagination;

  // console.log(pagination);

  return loader ? (
    <div className="h-[50vh] flex justify-center items-center">
      <Spinner color="#f5f0f8" />
    </div>
  ) : (
    <>
      <DataTable
        fixedHeader
        // fixedHeaderScrollHeight="300px"
        columns={columnsComp ? columnsComp : columns}
        data={Data}
        // noHeader={false}
        pagination={false}
        defaultSortFieldId={sortId}
        customStyles={customStylesI ? customStylesI : customStyles}
        noHeader={false}
        expandOnRowClicked={(id) => console.log(id)}
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={!loader && <NoData />}
      />

      <div className="item_container">
        <Pagination
          current={1}
          numberOfPages={1}
          next={1}
          setPage={() => setPage(1)}
        />
      </div>
    </>
  );
}
const customStyles = {
  // header: {
  // 	style: {

  // 		backgroundColor: '#F1F1F6',

  // 	},
  // },

  rows: {
    style: {
      minHeight: "72px", // override the row height
      // paddingLeft:'15px',
      // paddingRight:'15px',
      // paddingBottom:'100px'
    },
  },
  headCells: {
    style: {
      paddingLeft: "30px", // override the cell padding for head cells
      paddingRight: "30px",
      fontWeight: 500,
      color: "#1A1A1A",
      fontSize: "14px",
      whiteSpace: "pre-line",

      textTransform: "capitalize",
    },
  },
  cells: {
    style: {
      paddingLeft: "15px", // override the cell padding for data cells
      paddingRight: "15px",
      // borderWidth:0.2,
    },
  },
  headRow: {
    style: {
      backgroundColor: "#F1F1F6",
      // borderWidth:0.2,
    },
  },
  tableWrapper: {
    style: {
      // backgroundColor:'red'
    },
  },
};
