import { BsThreeDotsVertical } from "react-icons/bs";
import { Dots, TrackIcon, TrashIcon } from "../../utils/svg";
import DropDownComp from "../../Components/Ui/DropDown";
import moment from "moment";
import { Link } from "react-router-dom";
import { LanguageName } from "../../utils/helper";

export const tableData = [
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Complete",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Pending",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Complete",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Complete",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Canceled",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
  {
    id: "#12343",
    institution: "OAU Teaching Hospital",
    department: "Dermatology",
    medRep: "Joe Akpan A.",
    callMethod: "Physical",
    callObjective: "Clinical Meeting",
    availability: "Canceled",
    dateAdded: "30th Jan, 22 3:00PM",
    img: "/Overview/more.svg",
  },
];

export const columnsData = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "Institution",
    selector: (row) => (
      <div>
        <div className="whitespace-nowrap">{row.institution}</div>
        <div>{row.department}</div>
      </div>
    ),
    width: "200px",
    // sortable: true,
  },
  // {
  //   name: "department",
  //   selector: (row) => row.department,
  //   // sortable: true,
  // },
  {
    name: "Call Method",
    selector: (row) => row.medRep,
    // sortable: true,
  },
  {
    name: "Call Method",
    selector: (row) => row.callMethod,
    // sortable: true,
  },
  {
    name: "Call Objective",
    selector: (row) => row.callObjective,
    // sortable: true,
  },

  {
    name: "Availability",
    selector: (row) => row.availability,
    // sortable: true,
  },
  {
    name: "Date Added",
    selector: (row) => row.dateAdded,
    // sortable: true,
  },
  {
    name: "Action",
    // sortable: true,
    selector: (row) => (
      <div className="ms-3">
        <Dots color="#7572EA" />
      </div>
    ),
    width: "100px",
  },
];

const OptionDropDown = (row) => [
  {
    name: (
      <Link
        to={`/show/${row?.medRep?._id}/${row?._id}`}
        className="flex gap-1 items-center text-[#656468] text-[12px]"
      >
        <TrackIcon />
        Track Med Rep
      </Link>
    ),
    // action:;
  },
  {
    name: (
      <div className="flex gap-1 items-center text-[#D63434] text-[12px]">
        <TrashIcon />
        Report Schedule
      </div>
    ),
    // action:;
  },
];

export const columnsDataII = () => [
  {
    name: "ID",
    selector: (row) => <span>#{row?._id.substring(0, 6)}</span>,
    sortable: true,
    width: "100px",
  },
  {
    name: LanguageName("Institution"),
    selector: (row) => (
      <div>
        <div className="whitespace-nowrap text-[16px] text-[#000000]">
          {row.institutionName}
        </div>
        <div className="text-[14px] text-[#444444]">
          {row.institutionDepartment}
        </div>
      </div>
    ),
    width: "200px",
    // sortable: true,
  },
  {
    name: LanguageName("MR"),
    selector: (row) => (
      <span>
        {row?.medRep?.firstName} {""} {row?.medRep?.lastName}
      </span>
    ),
    // row.medRep,
  },
  {
    name: <div>{LanguageName("CM")}</div>,
    selector: (row) => row.callType,
    // sortable: true,
  },

  {
    name: <div className="whitespace-nowrap">{LanguageName("CO")}</div>,
    selector: (row) => row.callObjective || "-",
    width: "200px",
    // sortable: true,
  },

  {
    name: LanguageName("Availability"),
    selector: (row) => (
      <div className={`${StatusColor(row?.status)} `}>{row.status}</div>
    ),
    width: "150px",
    // sortable: true,
  },
  {
    name: LanguageName("DA"),
    selector: (row) => moment(row?.date).format("MMMM Do YYYY, h:mm"),
    width: "200px",

    // sortable: true,
  },
  {
    name: LanguageName("Action"),
    // sortable: true,
    cell: (row) => (
      <div className="ms-3">
        <DropDownComp
          dropDownText={<Dots color="#7572EA" />}
          options={OptionDropDown(row)}
        />
      </div>
    ),
    width: "150px",
  },
];

const StatusColor = (status) => {
  switch (status) {
    case "pending":
      return "bg-[#DBEBFE] px-3 py-2 rounded-[24px] capitalize";
      break;
    case "completed":
      return "bg-[#DAF1E9] text-[#044E54] px-3 py-2 rounded-[24px] capitalize";
      break;
    default:
      break;
  }
};

export const cardData = [
  {
    name: " Olakunbi Olabode 0.",
    post: " Pharmacist at",
    title: " Pharmserv Nigeria LTD",
    admin: "Ademola Olalekan John",
    tag: "KOL",
    dept: "department",
    derma: "Dermatology Department",
    call: "Call Objectives",
    text: "Lorem ipsum dolor sit amet,consectetur sit...",
  },
  {
    name: " Olakunbi Olabode 0.",
    post: " Pharmacist at",
    title: " Pharmserv Nigeria LTD",
    admin: "Ademola Olalekan John",
    tag: "KOL",
    dept: "department",
    derma: "Dermatology Department",
    call: "Call Objectives",
    text: "Lorem ipsum dolor sit amet,consectetur sit...",
  },
  {
    name: " Olakunbi Olabode 0.",
    post: " Pharmacist at",
    title: " Pharmserv Nigeria LTD",
    admin: "Ademola Olalekan John",
    tag: "KOL",
    dept: "department",
    derma: "Dermatology Department",
    call: "Call Objectives",
    text: "Lorem ipsum dolor sit amet,consectetur sit...",
  },
];
